/*---------- 12. Brand logo style ------------*/


.brand-logo-active , .brand-logo-active-3 {
    .single-brand-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            transition: all .5s ease 0s;
            max-width: 100%;
        }
        &:hover {
            img {
                animation: 900ms ease-in-out 0s normal none 1 running swing;
            }
        }
    } 
}
.brand-logo-active-2 , .brand-logo-active , .brand-logo-active-3 {
    .slick-list {
        margin-left: -15px;
        margin-right: -15px;
        .single-brand-plr {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    .single-brand-logo {
        img {
            max-width: 100%;
        }
    }
}

.single-brand-logo-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
        transition: all .5s ease 0s;
        width: 100%;
        transform: scale(1); 
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.single-brand-logo-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #FAFAFA;
    img {
        transition: all .5s ease 0s;
        width: 100%;
        transform: scale(1); 
    }
    &:hover {
        border: 1px solid #f1f1f1;
        img {
            transform: scale(1.1);
        }
    }
}

.fashion-3-brand-logo {
    margin-right: 40px;
    @media #{$xs-layout} {
        margin-right: 0px;
    }
}

.single-brand-logo-4 {
	display: flex;
	justify-content: center;
    img {
        transition: all .5s ease 0s;
        transform: scale(1); 
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.dot-style-7 {
    .slick-dots {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        li {
            margin: 0 6px;
            button {
                border: none;
                padding: 0;
                background-color: #E5E5E5;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                font-size: 0px;
                transition: all .3s ease 0s;
            }
            &.slick-active {
                button {
                    width: 23px;
                    border-radius: 50px;
                }
            }
        }
    }
}










