// Font Family
$poppins: 'Poppins', sans-serif;
$montserrat: 'Montserrat', ;
$pacifico: 'Pacifico', ;
$chewy: 'Chewy', ;
$worksans: 'Work Sans', ;
$rochester: 'Rochester', cursive;
$vandella: 'Vandella';
$jellybelly: 'JellyBelly Font';

    
// text Colors ---------------
$white: #ffffff;
$black: #000000;
$grey: #FAFAFA;

// Theme Color
$theme-color-black: #333333;
$theme-color-dark-blue: #0A1039;
$theme-color-yellow: #EBA866;
$theme-color-green: #97D694;
$theme-color-green-2: #359043;
$theme-color-green-3: #62BC77;
$theme-color-chocolate: #885643;
$theme-color-pink: #FF437D;
$theme-color-pink-2: #D60B5E;
$theme-color-brown: #CF7F39;
$theme-color-red: #D02F3B;
$theme-color-blue: #1EA9D3;

// Responsive Variables 
$xx-layout : 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$xl-layout : 'only screen and (min-width: 1200px) and (max-width: 1365px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs-layout:'only screen and (max-width: 767px)';
$sm-layout: 'only screen and (min-width: 576px) and (max-width: 767px)';



