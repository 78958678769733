/*-------- 16. Product details style ---------*/

.modal-dialog {
	margin: 2% auto 8%;
	max-width: 960px;
	width: 960px;
    padding: 35px;
    @media #{$md-layout} {
        width: 720px;
        max-width: 720px;
    }
    @media #{$xs-layout} {
        width: 100%;
        max-width: 100%;
        padding: 35px 0;
    }
    .modal-header {
        .close {
            color: #333;
            cursor: pointer;
            opacity: 1;
        }
    }
    .modal-body {
        padding: 35px 15px;
    }
    .quickview-big-img {
        img {
            width: 100%;
        }
    }
}
.quickview-img {
    img {
        width: 100%;
    }
}

.product-details-content {
    @media #{$xs-layout} {
        margin: 20px 0 0;
    }
    @media #{$sm-layout} {
        margin: 0px 0 0;
    }
    h2 {
        font-size: 29px;
        font-weight: bold;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 25px;
        }
    }
    .product-ratting-review-wrap { 
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 14px;
        .product-ratting-digit-wrap {
            display: flex;
            flex-wrap: wrap;
            margin-right: 35px;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                top: 50%;
                transform: translateY(-50%);
                right: -18px;
                background-color: #BCBCBC;
                height: 15px;
                width: 1px;
            }
            .product-ratting {
                display: flex;
                i {
                    font-size: 12px;
                    color: #D1A868;
                }
            }
            .product-digit {
                margin-left: 18px;
                span {
                    font-size: 16px;
                    color: #484848;
                }
            }
        }
        .product-review-order {
            span {
                color: #BCBCBC;
                font-size: 16px;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &.product-ratting-mrg-none {
            margin: 33px 0 14px;
            @media #{$xs-layout} {
                margin: 15px 0 14px;
            }
        }
    }
    p {
        font-size: 15px;
        line-height: 30px;
        &.pro-dec-paragraph-1 {
            width: 70%;
            @media #{$xs-layout} {
                width: 100%;
            }
        }
        &.pro-dec-paragraph-2 {
            width: 65%;
            margin: 15px 0 0;
            @media #{$lg-layout} {
                width: 100%;
            }
            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$xs-layout} {
                width: 100%;
            }
        }
        &.pro-dec-paragraph-3 {
            width: 75%;
            margin: 15px 0 0;
            @media #{$xx-layout} {
                width: 100%;
            }
            @media #{$xl-layout} {
                width: 100%;
            }
            @media #{$lg-layout} {
                width: 100%;
            }
            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$xs-layout} {
                width: 100%;
            }
        }
    }
    .pro-details-price {
        display: flex;
        margin: 26px 0 25px;
        span {
            font-size: 30px;
            font-weight: 500;
            color: #3A3A3A;
            &.old-price {
                text-decoration: line-through;
                font-size: 21px;
                font-weight: 300;
                color: #777777;
                margin-left: 20px;
            }
        }
        &.pro-details-price-4 {
            margin: 0px 0 25px;
        }
    }
    .pro-details-color-wrap {
        margin-bottom: 25px;
        span {
            display: block;
            font-size: 13px;
            font-weight: 300;
            color: #777777;
            margin: 0 0 10px;
        }
    }
    .pro-details-size {
        margin-bottom: 25px;
        span {
            display: block;
            font-size: 13px;
            font-weight: 300;
            color: #777777;
            margin: 0 0 10px;
        }
        .pro-details-size-content {
            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                li {
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        font-size: 12px;
                        color: #828495;
                        border: 1px solid #E3E4E9;
                        line-height: 1;
                        width: 31px;
                        height: 31px;
                        text-align: center;
                        line-height: 29px;
                        &:hover {
                            background-color: $theme-color-black;
                            color: $white;
                            border: 1px solid $theme-color-black;
                        }
                    }
                }
            }
        }
    }
    .product-details-meta {
        margin: 20px 0 56px;
        @media #{$lg-layout} {
            margin: 20px 0 20px;
        }
        @media #{$md-layout} {
            margin: 20px 0 20px;
        }
        @media #{$xs-layout} {
            margin: 20px 0 20px;
        }
        ul {
            li {
                margin: 0 0 16px;
                &:last-child {
                    argin: 0 0 0px;
                }
                span {
                    display: inline-block;
                    color: #545454;
                    width: 85px;
                }
                a {
                    color: #1C1C1C;
                    &:hover {
                        color: $theme-color-dark-blue;
                    }
                }
            }
        }
    }
    .pro-details-action-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @media #{$xs-layout} {
            display: block;
        }
        &.pro-details-action-center {
            justify-content: center;
        }
        .pro-details-buy-now {
            margin-right: 15px;
            @media #{$md-layout} {
                margin-right: 10px;
            }
            @media #{$xs-layout} {
                margin-bottom: 20px;
            }
            a {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                color: #fff;
                line-height: 1;
                background-color: $theme-color-black;
                padding: 16px 50px 21px;
                @media #{$xl-layout} {
                    padding: 16px 30px 21px;
                }
                @media #{$lg-layout} {
                    padding: 16px 30px 21px;
                }
                @media #{$md-layout} {
                    padding: 16px 30px 21px;
                }
                &:hover {
                    background-color: $theme-color-dark-blue;
                }
            }
        }
        .pro-details-action {
            position: relative;
            > a {
                display: inline-block;
                font-size: 17px;
                line-height: 1;
                padding: 16px 18px 17px;
                color: $theme-color-black;
                border: 1px solid #EDEDED;
                margin-right: 15px;
                position: relative;
                @media #{$lg-layout} {
                    padding: 16px 15px 17px;
                }
                @media #{$md-layout} {
                    padding: 16px 10px 17px;
                    margin-right: 10px;
                }
                &:last-child {
                    margin-right: 0px;
                }
                &:hover {
                    background-color: $theme-color-dark-blue;
                    color: $white;
                }
            }
        }
    }
}

.pro-details-quality {
    span {
        display: block;
        font-size: 13px;
        font-weight: 300;
        color: #777777;
        margin: 0 0 10px;
    }
    .cart-plus-minus {
        display: inline-block;
        overflow: hidden;
        padding: 0;
        position: relative;
        .qtybutton {
            color: #8E8FA0;
            cursor: pointer;
            float: inherit;
            font-size: 18px;
            line-height: 20px;
            margin: 0;
            position: absolute;
            text-align: center;
            transition: all 0.3s ease 0s;
            top: 0;
            &.dec {
                height: 31px;
                width: 31px;
                line-height: 31px;
                border: 1px solid #E3E4E9;
            }
            &.inc {
                height: 31px;
                width: 31px;
                line-height: 31px;
                border: 1px solid #E3E4E9;
                right: 0px;
            }
        }
        input.cart-plus-minus-box {
            background: transparent none repeat scroll 0 0;
            border: medium none;
            color: #3A3A3A;
            float: left;
            font-size: 14px;
            font-weight: 500;
            height: 31px;
            margin: 0;
            padding: 0;
            text-align: center;
            width: 92px;
            &.plus-minus-width-inc {
                width: 120px;
            }
        }
    }
}

.pro-details-color-content {
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
            margin-right: 5px;
            display: inline-block;
            &:last-child {
                margin-right: 0;
            }
            a {
                width: 31px;
                height: 31px;
                font-size: 0;
                display: inline-block;
                border: 1px solid #E3E4E9;
                position: relative;
                &.active::before {
                    color: #fff;
                    content: "\eed8";
                    font-family: IcoFont;
                    font-size: 20px;
                    position: absolute;
                    z-index: 9;
                    left: 50%;
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                }
                &.white {
                    background-color: #fff;
                }
                &.azalea {
                    background-color: #FBBBBB;
                }
                &.dolly {
                    background-color: #F3E66D;
                }
                &.peach-orange {
                    background-color: #FFCC8A;
                }
                &.mona-lisa {
                    background-color: #FB9696;
                }
                &.cupid {
                    background-color: #F5A8DE;
                }
                &.one {
                    background-color: #D0CCF8;
                }
                &.two {
                    background-color: #B6E3F6;
                }
                &.three {
                    background-color: #D1D0AE;
                }
                &.four {
                    background-color: #BED4A5;
                }
                &.five {
                    background-color: #C4BB6C; 
                }
                &.six {
                    background-color: #A0D1B7;
                }
                &.seven {
                    background-color: #E1A8A8;
                }
                &.eight {
                    background-color: #B8BBD4;
                }
                &.nine {
                    background-color: #A8A6B4;
                }
                &.ten {
                    background-color: #5D6061;
                }
            }
        }
    }
    @media #{$xs-layout} {
        &.mt-50 {
            margin-top: 20px;
        }
    }
    &.sidebar-widget-color {
        margin-left: 18px;
        &.color-mrg-2 {
            margin-left: 0;
            margin-right: 45px;
        }
        ul {
            li {
                a {
                    width: 22px;
                    height: 22px;  
                }
            }
        }
        @media #{$xs-layout} {
            &.mt-45 {
                margin-top: 25px;
            }
            &.mt-50 {
                margin-top: 30px;
            }
        }
    }
}

.product-dec-social {
    position: absolute;
    bottom: -33px;
    right: -7px;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease 0s;
    @media #{$xs-layout} {
        left: 0;
        right: auto;
    }
    a {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        font-size: 12px;
        &.facebook {
            background-color: #5678bf;
        }
        &.twitter {
            background-color: #73c2fd;
        }
        &.instagram {
            background-color: #e4405f;
        }
        &.pinterest {
            background-color: #c32026;
        }
    }
    &.show {
        visibility: visible;
        opacity: 1;
        right: 15px;
    }
}

.product-details-2-img {
    position: relative;
    padding-top: 90px;
    display: inline-block !important;
    > img {
        position: relative;
        z-index: 5;
        max-width: 100%;
    }
    > a {
        > img {
            position: relative;
            z-index: 5;
        }
    }
    &:before {
        position: absolute;
        content: "";
        width: 432px;
        height: 432px;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(235,232,228,1) 99%);
        @media #{$lg-layout} {
            width: 332px;
            height: 332px;
        }
        @media #{$xs-layout} {
            top: 40px;
            width: 250px;
            height: 250px;
        }
        @media #{$sm-layout} {
            top: 40px;
            width: 350px;
            height: 350px;
        }
    }
}

.product-details-2-img-wrap {
    position: relative;
}

.product-details-tab-large {
    position: relative;
    @media #{$xs-layout} {
        &.pt-40 {
            padding-top: 0px;
        }
    }
}

.product-details-shape {
    position: absolute;
    top: 30%;
    left: 22%;
    animation: playbutton-effect 4s linear infinite;
    animation-direction: normal;
    animation-delay: 2s;
    animation-direction: alternate;
    z-index: 9;
    &.pro-dec-shape1 {
        left: -11%;
    }
    &.pro-dec-shape3 {
        left: 14%;
    }
    @media #{$xx-layout} {
        left: 11%;
        &.pro-dec-shape3 {
            left: 7%;
        }
        &.pro-dec-shape1 {
            left: -5%;
        }
    }
    @media #{$xl-layout} {
        left: 11%;
        &.pro-dec-shape3 {
            left: 7%;
        }
        &.pro-dec-shape1 {
            left: 5%;
        }
    }
    @media #{$lg-layout} {
        left: 8%;
        &.pro-dec-shape3 {
            left: 7%;
        }
        &.pro-dec-shape1 {
            left: 3%;
        }
    }
    @media #{$md-layout} {
        left: 8%;
        &.pro-dec-shape3 {
            left: 9%;
        }
        &.pro-dec-shape1 {
            left: 6%;
        }
    }
    @media #{$xs-layout} {
        left: 3%;
        &.pro-dec-shape3 {
            left: 6%;
        }
        &.pro-dec-shape1 {
            left: 3%;
        }
    }
    @media #{$sm-layout} {
        left: 7%;
        &.pro-dec-shape3 {
            left: 9%;
        }
        &.pro-dec-shape1 {
            left: 4%;
        }
    }
    img {
        width: 175px;
        @media #{$lg-layout} {
            width: 130px;
        }
        @media #{$md-layout} {
            width: 130px;
        }
        @media #{$xs-layout} {
            width: 75px;
        }
        @media #{$sm-layout} {
            width: 130px;
        }
    }
}
.product-details-mt {
    margin-top: 111px;
    @media #{$md-layout} {
        margin-top: 86px;
    }
    @media #{$xs-layout} {
        margin-top: 86px;
    }
}

.dec-review-topbar {
    border-bottom: 1px solid #F5F5F5;
    a {
        display: inline-block;
        font-size: 18px;
        color: #777777;
        padding: 0 30px 22px;
        margin: 0 14px 0 0;
        position: relative;
        &:last-child {
            margin: 0 0px 0 0;
        }
        @media #{$md-layout} {
            padding: 0 15px 22px;
            margin: 0 7px 0 0;
        }
        @media #{$xs-layout} {
            padding: 0 10px 10px;
            margin: 0 0px 10px 0;
            font-size: 16px;
        }
        @media #{$sm-layout} {
            padding: 0 7px 10px;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #333333;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
        }
        &.active {
            color: #333;
            font-weight: bold;
            &:before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    @media #{$xs-layout} {
        &.mb-65 {
            margin-bottom: 35px;
        }
    }
    &.dec-review-topbar-center {
        display: flex;
        justify-content: center;
        border-bottom: none;
    }
}

.dec-review-bottom {
    .description-wrap {
        p {
            font-size: 16px;
            line-height: 30px;
            margin: 0 0 30px;
            @media #{$xs-layout} {
                font-size: 14px;
                margin: 0 0 20px;
            }
            &:last-child {
                margin: 0 0 0px;
            }
        }
    }
    .specification-wrap {
        table {
            width: 100%;
            tbody {
                border: 1px solid #f0f0f0;
                width: 100%;
                tr {
                    border-bottom: 1px solid #f0f0f0;
                    td {
                        border-right: 1px solid #f0f0f0;
                        font-size: 14px;
                        color: #333333;
                        padding: 17px 30px;
                        @media #{$lg-layout} {
                            padding: 17px 20px;
                        }
                        @media #{$md-layout} {
                            padding: 17px 20px;
                        }
                        @media #{$xs-layout} {
                            padding: 17px 15px;
                        }
                    }
                    td.width1 {
                        width: 300px;
                        @media #{$lg-layout} {
                            width: 200px
                        }
                        @media #{$xs-layout} {
                            width: 100px
                        }
                    }
                }
            }
        }
    }
    .review-wrapper {
        h2 {
            font-size: 25px;
            margin: 0 0 37px;
            @media #{$lg-layout} {
                font-size: 20px;
            }
            @media #{$xs-layout} {
                font-size: 18px;
                margin: 0 0 18px;
            }
        }
    }
    .single-review {
        margin: 0 0 60px;
        padding: 50px 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        @media #{$lg-layout} {
            padding: 50px 20px;
        }
        @media #{$xs-layout} {
            padding: 30px;
            display: block;
            margin: 0 0 30px;
        }
        .review-img {
            flex: 0 0 60px;
        }
        .review-content {
            margin-left: 20px;
            @media #{$xs-layout} {
                margin-left: 0px;
            }
            .review-top-wrap {
                display: flex;
                justify-content: space-between;
                margin: 0 0 33px;
                @media #{$xs-layout} {
                    display: block;
                    margin: 10px 0 10px;
                }
                .review-name {
                    h5 {
                        font-size: 12px;
                        margin: 0;
                        color: #535353;
                        span {
                            font-weight: bold;
                            font-size: 14px;
                            color: #262626;
                        }
                    }
                }
                .review-rating {
                    line-height: 1;
                    @media #{$xs-layout} {
                        margin: 10px 0 0px;
                    }
                    i {
                        font-size: 14px;
                        color: #d5d6d8;
                        &.yellow {
                            color: #f5b223;
                        }
                    }
                }
            }
            p {
                font-size: 16px;
                color: #535353;
                line-height: 30px;
                margin: 0;
                @media #{$xs-layout} {
                    font-size: 15px;
                }
            }
        }
        @media #{$xl-layout} {
            &.pro-details-9-single-review {
                padding: 50px 12px;
            }
        }
    }

    .ratting-form-wrapper {
        > span {
            display: block;
            line-height: 1;
        }
        p {
            font-size: 12px;
            margin: 9px 0 0;
            color: #535353;
            span {
                color: red;
            }
        }
        .ratting-form {
            margin: 30px 0 0;
            .star-box-wrap {
                display: flex;
                flex-wrap: wrap;
                margin: 16px 0 29px;
                .single-ratting-star {
                    margin-right: 17px;
                    display: flex;
                    a {
                        color: #535353;
                    }
                    &:hover i {
                        color: #f5b223;
                    }
                }
            }
            .rating-form-style {
                label {
                    margin: 0 0 5px;
                    color: #535353;
                    span {
                        color: red;
                    }
                }
                input , textarea {
                    background: transparent;
                    border: 1px solid #CDCDCD;
                    height: 50px;
                    padding: 5px 10px;
                    &:focus {
                        border: 1px solid #262626;
                    }
                }
                textarea {
                    height: 150px;
                }
            }
            .form-submit {
                margin-top: 20px;
                input {
                    padding: 10px 35px;
                    line-height: 30px;
                    color: #fff;
                    border-width: 0;
                    font-weight: 600;
                    display: inline-block;
                    width: auto;
                    height: auto;
                    letter-spacing: 2px;
                    font-size: 12px;
                    text-transform: uppercase;
                    background: #262626;
                    &:hover {
                        background: $theme-color-dark-blue;
                    }
                }
            }
        }
    }
}

.product-details-content-3 {
    padding: 120px 30px 0 145px;
    @media #{$xx-layout} {
        padding: 80px 30px 0 100px;
    }
    @media #{$xl-layout} {
        padding: 80px 30px 0 100px;
    }
    @media #{$lg-layout} {
        padding: 70px 30px 0 30px;
    }
    @media #{$md-layout} {
        padding: 30px 30px 30px 30px;
    }
    @media #{$xs-layout} {
        padding: 10px 15px 0px 15px;
    }
    @media #{$sm-layout} {
        padding: 30px 30px 0px 30px;
    }
}

.product-details-3-img-slider {
    img {
        display: inline-block;
    }
}

.dot-style-8 {
    .slick-dots {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 22px;
        left: 22%;
        @media #{$xs-layout} {
            left: 7%;
        }
        li {
            margin: 0 3px;
            button {
                border: none;
                padding: 0;
                background-color: #E5E5E5;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                font-size: 0px;
                transition: all .3s ease 0s;
            }
            &.slick-active {
                button {
                    width: 34px;
                    border-radius: 50px;
                }
            }
        }
    }
}

.product-details-content-4 {
    margin-left: 45px;
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
        margin-top: 30px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
    }
}
.product-details-top-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 95px;
    @media #{$lg-layout} {
        display: block;
        margin-bottom: 50px;
    }
    @media #{$md-layout} {
        display: block;
        margin-bottom: 50px;
    }
    @media #{$xs-layout} {
        display: block;
        margin-bottom: 30px;
    }
}

.product-dec-left {
    width: 85%;
    float: left;
    padding-right: 26px;
    @media #{$lg-layout} {
        width: 83%;
        padding-right: 20px;
    }
    @media #{$xs-layout} {
        width: 75%;
        padding-right: 20px;
    }
}
.product-dec-right {
    width: 15%;
    float: left;
    @media #{$lg-layout} {
        width: 17%;
    }
    @media #{$xs-layout} {
        width: 25%;
    }
}

.product-big-img-style {
    .easyzoom-style {
        .easyzoom {
            display: block;
            > a {
                transition: none; 
                img {
                    width: 100%;
                    transition: none;
                } 
            }
        }
    }
}
.product-small-img-style {
    .slick-list {
        .product-dec-small {
            margin-bottom: 20px;
            img {
                cursor: pointer;
            }
        }
    }
}

.product-details-slider-5-active {
    .slick-list {
        padding: 0 184px;
        @media #{$md-layout} {
            padding: 0 120px;
        }
        @media #{$xs-layout} {
            padding: 0 50px;
        }
        .single-pro-details-slider-5 {
            margin: 0px 15px 0px 15px;
            img {
                width: 100%;
            }
        }
    }
    @media #{$xs-layout} {
        &.pb-100 {
            padding-bottom: 20px;
        }
    }
}

.description-review-wrapper {
    .pro-dec-accordion {
        padding: 0 0 43px;
        &:last-child {
            padding: 0 0 0px;
        }
        .panel-heading {
            h4 {
                margin: 0;
                font-size: 18px;
                a {
                    display: block;
                    position: relative;
                    text-transform: capitalize;
                    padding: 0px 0 0px 30px;
                    color: #333333;
                    font-weight: bold;
                    &::after {
                        content: "\ef9a";
                        color: #262626;
                        font-family: IcoFont;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 13px;
                        font-weight: normal;
                        color: #D1A868;
                    }
                    &.collapsed {
                        color: #777777;
                        font-weight: 400;
                        &:hover {
                            color: $theme-color-dark-blue;
                        }
                        &::after {
                            content: "\efc2";
                            color: #484848;
                        }
                    }
                }
            }
        }
        .panel-body {
            padding: 34px 0 0px 30px;
        }
    }
    &.product-details-5-wrap {
        margin: 0 0 0 37px;
        @media #{$lg-layout} {
            margin: 0 0 0 0px;
        }
        @media #{$md-layout} {
            margin: 50px 0 0 0px;
        }
        @media #{$xs-layout} {
            margin: 50px 0 0 0px;
        }
    }
}

.product-details-content-5 {
	border-right: 1px solid #E6E6E6;
    @media #{$md-layout} {
        border: none;
    }
    @media #{$xs-layout} {
        border: none;
    }
    @media #{$sm-layout} {
        margin-top: 20px;
    }
}

.product-details-content-6 {
    margin: 0 0 0 100px;
    @media #{$xl-layout} {
        margin: 0 0 0 70px;
    }
    @media #{$lg-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 30px 0 0 0px;
    }
}
.pro-details-6-img {
    margin-right: -30px;
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    img {
        width: 100%;
    }
}

.pro-details-7-img {
    img {
        width: 100%;
    }
    @media #{$xs-layout} {
        &.mt-75 {
            margin-top: 15px;
        }
    }
}

.product-details-content-7 {
    box-shadow: 0 10px 20px rgba(3, 9, 25, 0.04);
    padding: 135px 60px 120px 70px;
    margin-right: 15px;
    margin-top: -80px;
    position: relative;
    z-index: 9;
    background-color: #fff;
    @media #{$lg-layout} {
        padding: 135px 20px 120px 20px;
    }
    @media #{$md-layout} {
        margin-top: -50px;
        margin-right: 0px;
        padding: 100px 20px 100px 20px;
    }
    @media #{$xs-layout} {
        margin-top: -10px;
        margin-right: 0px;
        padding: 60px 15px 60px 15px;
    }
}
.product-details-content-8 {
    box-shadow: 0 10px 20px rgba(3, 9, 25, 0.04);
    padding: 112px 100px 120px 100px;
    margin-top: -80px;
    position: relative;
    z-index: 9;
    background-color: #fff;
    @media #{$md-layout} {
        margin-top: -50px;
        padding: 112px 20px 120px 20px;
    }
    @media #{$xs-layout} {
        margin-top: -10px;
        padding: 60px 15px 48px 15px;
    }
}

.product-details-7-wrap {
    margin: 80px 0 0 40px;
    @media #{$xx-layout} {
        margin: 80px 0 0 0px;
    }
    @media #{$xl-layout} {
        margin: 80px 0 0 0px;
    }
    @media #{$lg-layout} {
        margin: 80px 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 80px 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 50px 0 0 0px;
    }
}

.product-details-meta-2 {
    margin: 22px 0 80px 100px;
    @media #{$md-layout} {
        margin: 22px 0 50px 0px;
    }
    @media #{$xs-layout} {
        margin: 22px 0 27px 0px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        @media #{$md-layout} {
            justify-content: center;
        }
        @media #{$xs-layout} {
            display: block;
        }
        li {
            margin-right: 85px;
            &:last-child {
               margin-right: 0px; 
            }
            span {
                display: inline-block;
                color: #545454;
                width: 85px;
            }
            a {
                color: #1C1C1C;
                &:hover {
                    color: $theme-color-dark-blue;
                }
            }
        }
    }
}
.pro-details-9-img {
    img {
        width: 100%;
    }
}
.pro-details-9-2-img {
    margin-left: -10px;
    img {
        width: 100%;
    }
}

.product-details-content-9 {
    margin: 0 0 0 100px;
    @media #{$xx-layout} {
        margin: 0 0 0 50px;
    }
    @media #{$xl-layout} {
        margin: 0 0 0 50px;
    }
    @media #{$lg-layout} {
        margin: 30px 0 0 0px;
    }
    @media #{$md-layout} {
        margin: 30px 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 30px 0 0 0px;
    }
}

.description-review-wrapper-9 {
    margin-right: 105px;
    @media #{$xx-layout} {
        margin-right: 50px;
    }
    @media #{$xl-layout} {
        margin-right: 50px;
    }
    @media #{$lg-layout} {
        margin-right: 0px;
        margin-top: 35px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
        margin-top: 35px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
        margin-top: 35px;
    }
}
.pro-details-9-3-img {
    img {
        width: 100%;
    }
}
.product-details-9-middle {
    padding-top: 90px;
    padding-bottom: 90px;
    @media #{$xs-layout} {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.pro-details-img-zoom {
    overflow: hidden;
    img {
        transition: all .5s ease 0s;
        transform: scale(1);
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.main-product-details-content {
    box-shadow: 0 10px 20px rgba(3, 9, 25, 0.04);
    background-color: #fff;
    padding: 68px 60px 103px 60px;
    margin-top: 67px;
    margin-left: 20px;
    display: block;
    @media #{$lg-layout} {
        padding: 68px 30px 103px 30px;
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 37px;
        padding: 48px 15px 50px 15px;
        box-shadow: 0 10px 20px rgba(3, 9, 25, 0.06);
    }
    @media #{$sm-layout} {
        margin-top: 17px;
    }
}

.product-details-bg {
	background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 88.2%, #fff 0%);
    @media #{$lg-layout} {
        background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 91.2%, #fff 0%);
    }
    @media #{$md-layout} {
        background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 44.5%, #fff 0%);
    }
    @media #{$xs-layout} {
        background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 42.6%, #fff 0%);
    }
    @media #{$sm-layout} {
        background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 43.9%, #fff 0%);
    }
}
.product-details-tab-small {
    flex-wrap: wrap;
    margin-top: 37px;
    @media #{$lg-layout} {
        margin-top: 17px;
    }
    a {
        display: block;
        margin-right: 20px;
        @media #{$lg-layout} {
            margin-right: 10px;
        }
        @media #{$xs-layout} {
            margin-right: 10px;
            margin-bottom: 10px;
        }
        img {
            width: 100%;
        }
    }
}

.pro-details-9-4-img {
    img {
        max-width: 100%;
    }
}

@media #{$lg-layout} {
    .product-details-area {
        &.pt-120 {
            padding-top: 80px;
        }
    }
}
@media #{$md-layout} {
    .product-details-content-mrg {
        margin-top: 40px;
    }
    .product-details-area {
        &.pt-40 {
            padding-top: 0;
        }
        &.pt-50 {
            padding-top: 0;
        }
        &.pt-120 {
            padding-top: 80px;
        }
        &.pb-95 {
            padding-bottom: 50px;
        }
    }
}
@media #{$xs-layout} {
    .product-details-content-mrg {
        margin-top: 30px;
    }
    .product-details-area {
        &.pt-40 {
            padding-top: 0;
        }
        &.pt-50 {
            padding-top: 0;
        }
        &.pt-120 {
            padding-top: 30px;
        }
        &.pb-95 {
            padding-bottom: 50px;
        }
    }
}





