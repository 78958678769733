/*---------- 3. Slider style ------------*/

.slider-area {
    position: relative;
    @media #{$md-layout} {
        &.mr-15 {
            margin-right: 0px;
        }
    }
    @media #{$xs-layout} {
        &.mr-15 {
            margin-right: 0px;
        }
    }
}
.slider-active-1 {
    z-index: 9;
}
.slider-height-1 {
    height: 780px;
    @media #{$lg-layout} {
        height: 650px;
    }
    @media #{$md-layout} {
        height: 650px;
    }
    @media #{$xs-layout} {
        height: auto;
    }
}
.slider-height-2 {
    height: 662px;
    @media #{$lg-layout} {
        height: 600px;
    }
    @media #{$md-layout} {
        height: 450px;
    }
    @media #{$xs-layout} {
        height: 500px;
    }
    @media #{$sm-layout} {
        height: 400px;
    }
}
.slider-height-3 {
    height: 846px;
    @media #{$lg-layout} {
        height: 600px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 500px;
    }
}
.slider-height-4 {
    height: 830px;
    @media #{$xx-layout} {
        height: 700px;
    }
    @media #{$xl-layout} {
        height: 700px;
    }
    @media #{$lg-layout} {
        height: 700px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 480px;
    }
}

.slider-height-5 {
    height: 765px;
    @media #{$xx-layout} {
        height: 700px;
    }
    @media #{$xl-layout} {
        height: 700px;
    }
    @media #{$lg-layout} {
        height: 700px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 520px;
    }
}
.slider-height-6 {
    height: 990px;
    @media #{$xx-layout} {
        height: 715px;
    }
    @media #{$xl-layout} {
        height: 715px;
    }
    @media #{$lg-layout} {
        height: 600px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 650px;
    }
    @media #{$sm-layout} {
        height: 450px;
    }
}

.slider-height-7 {
    height: 846px;
    @media #{$lg-layout} {
        height: 800px;
    }
    @media #{$md-layout} {
        height: 700px;
    }
    @media #{$xs-layout} {
        height: 500px;
    }
}
.slider-height-8 {
    height: 640px;
    @media #{$lg-layout} {
        height: 500px;
    }
    @media #{$md-layout} {
        height: 500px;
    }
    @media #{$xs-layout} {
        height: auto;
    }
}

.slider-height-9 {
    height: 1010px;
    @media #{$xx-layout} {
        height: 715px;
    }
    @media #{$xl-layout} {
        height: 715px;
    }
    @media #{$lg-layout} {
        height: 700px;
    }
    @media #{$md-layout} {
        height: 600px;
    }
    @media #{$xs-layout} {
        height: 480px;
    }
    @media #{$sm-layout} {
        height: 450px;
    }
}

.slider-height-10 {
    height: 710px;
    @media #{$lg-layout} {
        height: 700px;
    }
    @media #{$md-layout} {
        height: 700px;
    }
    @media #{$xs-layout} {
        height: 500px;
    }
}

.slider-height-11 {
    height: 750px;
    @media #{$xx-layout} {
        height: 650px;
    }
    @media #{$xl-layout} {
        height: 650px;
    }
    @media #{$lg-layout} {
        height: 550px;
    }
    @media #{$md-layout} {
        height: 450px;
    }
    @media #{$xs-layout} {
        height: 600px;
    }
    @media #{$sm-layout} {
        height: 460px;
    }
}
.slider-height-12 {
    height: 726px;
    @media #{$xx-layout} {
        height: 650px;
    }
    @media #{$xl-layout} {
        height: 650px;
    }
    @media #{$lg-layout} {
        height: 550px;
    }
    @media #{$md-layout} {
        height: 450px;
    }
    @media #{$xs-layout} {
        height: 500px;
    }
    @media #{$sm-layout} {
        height: 400px;
    }
}
.slider-height-13 {
    height: 673px;
    @media #{$xx-layout} {
        height: 520px;
    }
    @media #{$xl-layout} {
        height: 473px;
    }
    @media #{$lg-layout} {
        height: 413px;
    }
    @media #{$md-layout} {
        height: 500px;
    }
    @media #{$xs-layout} {
        height: 500px;
        background-position: 70%;
    }
    @media #{$sm-layout} {
        height: 400px;
    }
}
.slider-height-14 {
    height: 800px;
    @media #{$xx-layout} {
        height: 700px;
    }
    @media #{$xl-layout} {
        height: 650px;
    }
    @media #{$lg-layout} {
        height: 550px;
    }
    @media #{$md-layout} {
        height: 500px;
    }
    @media #{$xs-layout} {
        height: auto;
        padding: 120px 0 230px;
    }
    @media #{$sm-layout} {
        height: 420px;
        padding: 0;
    }
}

.single-slider-ptb-1 {
    padding-top: 102px;
    @media #{$xs-layout} {
        padding-top: 80px;
        padding-bottom: 70px;
    }
}
.slider-bg-color-1 {
    background: linear-gradient(to right, #FCF6EC, #FCF6EC 43.666667%, #fff 0%);
}
.slider-content-mrg-1 {
    padding-top: 45px;
}

.slider-content-1 {
    position: relative;
    z-index: 9;
    @media #{$xs-layout} {
        text-align: center;
    }
    @media #{$sm-layout} {
        text-align: left;
    }
    h1 {
        font-size: 68px;
        font-weight: bold;
        color: #151C0F;
        line-height: 94px;
        margin: 0;
        @media #{$lg-layout} {
            font-size: 55px;
            line-height: 65px;
        }
        @media #{$md-layout} {
            font-size: 36px;
            line-height: 50px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            line-height: 40px;
        }
        @media #{$sm-layout} {
            font-size: 25px;
            line-height: 37px;
        }
    }
    p {
        font-size: 15px;
        line-height: 30px;
        margin: 20px 0 47px;
        width: 67%;
        &.width-inc {
            width: 78%;
            margin: 20px 0 36px;
            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$xs-layout} {
                width: 100%;
                margin: 10px 0 20px;
            }
        }
        @media #{$md-layout} {
            width: 100%;
            margin: 15px 0 30px;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 10px 0 20px;
        }
    }
}

.single-slider-pt-2 {
    padding-top: 236px;
    @media #{$lg-layout} {
        padding-top: 150px;
    }
    @media #{$md-layout} {
        padding-top: 150px;
    }
    @media #{$xs-layout} {
        padding-top: 150px;
    }
}
.slider-content-2 {
    h1 {
        font-size: 145px;
        font-weight: 800;
        color: #06103A;
        line-height: 110px;
        position: relative;
        z-index: 5;
        @media #{$xl-layout} {
            font-size: 125px;
        }
        @media #{$lg-layout} {
            font-size: 100px;
        }
        @media #{$md-layout} {
            font-size: 100px;
        }
        @media #{$xs-layout} {
            font-size: 70px;
            line-height: 65px;
        }
        span {
            color: $theme-color-yellow;
        }
    }
    h2 {
        font-size: 145px;
        font-weight: 800;
        color: $theme-color-yellow;
        line-height: 130px;
        margin: 27px 0 0px 362px;
        position: relative;
        z-index: 5;
        @media #{$xl-layout} {
            font-size: 125px;
        }
        @media #{$lg-layout} {
            font-size: 100px;
            margin: 27px 0 0px 200px;
        }
        @media #{$md-layout} {
            font-size: 100px;
            margin: 27px 0 0px 200px;
        }
        @media #{$xs-layout} {
            font-size: 70px;
            line-height: 65px;
            margin: 27px 0 0px 60px;
        }
        span {
            color: #06103A;
        }
    }
    .slider-single-img-3 {
        position: absolute;
        bottom: -70%;
        left: 3%;
        @media #{$lg-layout} {
            bottom: -45%;
            left: 0%;
        }
        @media #{$md-layout} {
            bottom: -35%;
            left: 50%;
            transform: translateX(-50%);
        }
        @media #{$xs-layout} {
            bottom: -75%;
            left: 50%;
            transform: translateX(-50%);
            a {
                img {
                    width: 300px;
                    max-width: none;
                }
            }
        }
        @media #{$sm-layout} {
            a {
                img {
                    width: 350px;
                    max-width: none;
                }
            }
        }
    }
    @media #{$md-layout} {
        text-align: center;
    }
    @media #{$xs-layout} {
        text-align: center;
    }
}

.slider-single-img {
    img {
        max-width: 100%;
    }
    @media #{$xs-layout} {
        text-align: center;
    }
}
.slider-single-img-2 {
    margin-left: 44px;
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 25px;
    }
    a {
        display: block;
        img {
            max-width: none;
            @media #{$lg-layout} {
                max-width: 100%;
            }
            @media #{$md-layout} {
                max-width: 100%;
            }
            @media #{$xs-layout} {
                max-width: 100%;
            }
        }
    }
}

.slider-sin-img-mrg1 {
    margin: 0 17px 0 30px;
    @media #{$lg-layout} {
        margin: 0;
    }
    @media #{$md-layout} {
        margin: 0;
    }
    @media #{$xs-layout} {
        margin: 30px 20px 0;
    }
}

.slider-shape-electric {
	position: absolute;
	left: 0;
	top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    img {
        width: 331px;
        @media #{$lg-layout} {
            width: 270px;
        }
        @media #{$md-layout} {
            width: 231px;
        }
        @media #{$xs-layout} {
            width: 131px;
        }
    }
}
.slider-shape-electric2 {
	position: absolute;
	right: 0;
	top: 10%;
    z-index: 1;
    img {
        width: 72px;
    }
}

.nav-style-1 {
    .slider-icon {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        display: inline-block;
        color: #0A1039;
        font-size: 45px;
        top: 50%;
        transition: all 0.4s ease-in-out 0s;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        &.slider-icon-next {
            right: 0%;
            left: auto;
        }
        &.slider-icon-prev {
            left: 0%;
            right: auto;
        }
        &:hover {
            &:hover {
                color: $theme-color-dark-blue
            }
        }
    }
    &:hover {
        .slider-icon {
            visibility: visible;
            opacity: 1;
            &.slider-icon-next {
                right: 2%;
            }
            &.slider-icon-prev {
                left: 2%;
            }
        }
    }
}

.dot-style-1 {
    ul {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        li {
            margin: 5px;
            button {
                padding: 0;
                font-size: 0;
                border: 2px solid transparent;
                background-color: #0A1039;
                width: 18px;
                height: 18px;
                border-radius: 100%;
            }
            &.slick-active {
                button {
                    border: 2px solid #0A1039;
                    background-color: transparent;
                }
            }
        }
    }
    &.dot-style-position2 {
        ul {
            
        }
    }
}

.single-slider {
    .slider-content * {
        animation-duration: 1.3s;
        animation-fill-mode: both;
    }
}


.single-slider.slick-active {
    .slider-animated-1 {
        h1 {
            animation-delay: 1.3s;
            animation-name: fadeInLeft;
        }
        h2 {
            animation-delay: 1.6s;
            animation-name: fadeInRight;
        }
        h3 {
            animation-delay: 1.8s;
            animation-name: fadeInLeft;
        }
        p {
            animation-delay: 1.6s;
            animation-name: fadeInLeft;
        }
        a {
            animation-delay: 1.9s;
            animation-name: fadeInLeft;
        }
        img {
            animation-delay: 1.6s;
            animation-name: fadeInRight;
        }
        .slider-single-img-3 {
            img {
                animation-delay: 1.6s;
                animation-name: fadeInUp;
            }
        }
        .slider-oganic-img-1 {
            img {
                animation-delay: 1.9s;
                animation-name: fadeInUp;
            }
        }
        .slider-oganic-img-2 {
            img {
                animation-delay: 1.6s;
                animation-name: zoomIn;
            }
        }
        .slider-kids-img-content {
            img {
                animation-delay: 1.0s;
                animation-name: fadeInUp;
            }
            h3 {
                animation-delay: 1.5s;
                animation-name: fadeInLeft;
            }
            h4 {
                animation-delay: 1.8s;
                animation-name: fadeInRight;
            }
        }
        .slider-flower-wrap {
            img {
                animation-delay: 0.5s;
                animation-name: fadeInDown;
            }
            h2 {
                animation-delay: 0.5s;
                animation-name: fadeInUp;
            }
        }
        &.slider-single-img-8 {
            img {
                animation-delay: 0.5s;
                animation-name: fadeInDown;
            }
        }
        &.slider-single-img-9 {
            img {
                animation-delay: 1.7s;
                animation-name: fadeInUp;
            }
        }
        &.slider-content-9 {
            &::before {
                transition: all 2s ease 0s;
                transform: scale(1);
            }
        }
        &.slider-content-10 {
            img {
                animation-delay: 0.8s;
                animation-name: fadeInUp;
            }
        }
        .fashion-3-slider-img2 {
            img {
                animation-delay: 1.3s;
                animation-name: zoomIn;
            }
        }
        .fashion-3-slider-img3 {
            img {
                animation-delay: .9s;
                animation-name: zoomIn;
            }
        }
        .fashion-3-slider-img4 {
            img {
                animation-delay: .5s;
                animation-name: zoomIn;
            }
        }
        &.slider-single-img-10 {
            > img {
                animation-delay: .5s;
                animation-name: fadeInRight;
            }
            .slider-baby-img {
                > img {
                    animation-delay: .5s;
                    animation-name: fadeInLeft;
                }
            }
            .slider-trolley-img {
                > img {
                    animation-delay: .5s;
                    animation-name: fadeInUp;
                }
            }
        }
        .slider-single-img-11 {
            img {
                animation-delay: 1.0s;
                animation-name: fadeInUp;
            }
        }
        .fashion-2-slider-img1-1 , 
        .fashion-2-slider-img2-1 {
            img {
                animation-delay: 1.5s;
                animation-name: zoomIn;
            }
        }
        .fashion-2-slider-img1-2 , 
        .fashion-2-slider-img2-2 {
            img {
                animation-delay: 1.9s;
                animation-name: zoomIn;
            }
        }
        .fashion-2-slider-img1-3 {
            img {
                animation-delay: 2.2s;
                animation-name: zoomIn;
            }
        }
        .fashion-2-slider-img1-4 {
            img {
                animation-delay: 2.5s;
                animation-name: zoomIn;
            }
        }
    }
}

@media #{$md-layout} {
    &.mt-140 {
        margin-top: 0px;
    }
}

.slider-mt-1 {
    margin-top: 140px;
    @media #{$lg-layout} {
        margin-top: 211px;
    }
    @media #{$md-layout} {
        margin-top: 91px;
    }
    @media #{$xs-layout} {
        margin-top: 91px;
    }
}
.slider-mt-2 {
    margin-top: 102px;
    @media #{$lg-layout} {
        margin-top: 177px;
    }
    @media #{$md-layout} {
        margin-top: 91px;
    }
    @media #{$xs-layout} {
        margin-top: 91px;
    }
}

.slider-mt-3 {
    margin-top: 135px;
    @media #{$lg-layout} {
        margin-top: 211px;
    }
    @media #{$md-layout} {
        margin-top: 91px;
    }
    @media #{$xs-layout} {
        margin-top: 91px;
    }
}
.slider-mt-4 {
    margin-top: 135px;
    @media #{$md-layout} {
        margin-top: 91px;
    }
    @media #{$xs-layout} {
        margin-top: 91px;
    }
}
.slider-mt-5 {
    margin-top: 147px;
    @media #{$md-layout} {
        margin-top: 91px;
    }
    @media #{$xs-layout} {
        margin-top: 91px;
    }
}
.slider-mt-6 {
    margin-top: 91px;
}

.slider-mt-7 , .breadcrumb-mt {
    margin-top: 102px;
    @media #{$lg-layout} {
        margin-top: 174px;
    }
    @media #{$md-layout} {
        margin-top: 86px;
    }
    @media #{$xs-layout} {
        margin-top: 86px;
    }
}

.single-slider-pt-3 {
    padding-top: 160px;
    @media #{$lg-layout} {
        padding-top: 200px;
        background-position: 66%;
    }
    @media #{$md-layout} {
        padding-top: 130px;
        background-position: 73%;
    }
    @media #{$xs-layout} {
        padding-top: 100px;
        background-position: 73%;
    }
}

.slider-content-3 {
    h1 {
        font-size: 68px;
        line-height: 90px;
        margin: 0;
        @media #{$xl-layout} {
            font-size: 55px;
            line-height: 80px;
        }
        @media #{$lg-layout} {
            font-size: 45px;
            line-height: 65px;
        }
        @media #{$md-layout} {
            font-size: 45px;
            line-height: 65px;
        }
        @media #{$xs-layout} {
            font-size: 35px;
            line-height: 45px;
        }
        span {
            font-weight: bold;
        }
    }
    p {
        font-size: 15px;
        line-height: 30px;
        width: 30%;
        margin: 40px 0 50px;
        @media #{$xx-layout} {
            margin: 20px 0 30px;
        }
        @media #{$xl-layout} {
            margin: 10px 0 30px;
        }
        @media #{$lg-layout} {
            width: 50%;
            margin: 10px 0 25px;
        }
        @media #{$md-layout} {
            width: 50%;
            margin: 10px 0 25px;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 10px 0 25px;
        }
    }
}

.dot-style-2 {
    ul {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: -14%;
        left: 19%;
        z-index: 9;
        @media #{$xx-layout} {
            left: 13%;
        }
        @media #{$xl-layout} {
            left: 15px;
        }
        @media #{$lg-layout} {
            left: 3%;
        }
        @media #{$md-layout} {
            left: 3%;
        }
        @media #{$xs-layout} {
            left: 15px;
            bottom: -5%;
        }
        @media #{$sm-layout} {
            left: 30px;
            bottom: -10%;
        }
        li {
            margin-right: 15px;
            @media #{$xs-layout} {
                margin-right: 5px;
            }
            button {
                padding: 0;
                font-size: 0;
                border: 2px solid transparent;
                background-color: transparent;
            }
        }
    }
}

.dot-style-3 {
    ul {
        li {
            margin-top: 15px;
            display: block;
            @media #{$lg-layout} {
                margin-top: 10px;
            }
            @media #{$xs-layout} {
                margin-top: 10px;
            }
            &:first-child {
                margin-top: 0;
            }
            button {
                padding: 0;
                font-size: 20px;
                color: #BCBDC8;
                border: none;
                background-color: transparent;
                position: relative;
                transition: all .3s;
                &:before {
                    content: "0";
                    display: inline-block;
                    font-size: 20px;
                }
                &::after {
                    position: absolute;
                    transition: all .3s;
                    z-index: 1;
                    width: 86px;
                    height: 3px;
                    background: #0A1039;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 42px;
                    content: "";
                    opacity: 0;
                    @media #{$xs-layout} {
                        width: 40px;
                    }
                }
                &:hover {
                    color: #0A1039;
                }
            }
            &.slick-active {
                button {
                    color: #0A1039;
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &.dot-style-3-position {
        ul {
            position: absolute;
            bottom: -19%;
            right: 0%;
            z-index: 9;
            @media #{$lg-layout} {
                bottom: -34%;
            }
            @media #{$xs-layout} {
                bottom: -13%;
            }
        }
    }
    &.dot-style-3-position-2 {
        ul {
            position: absolute;
            top: -60.5%;
            right: 0%;
            z-index: 9;
            @media #{$xs-layout} {
                top: auto;
                bottom: -10%;
            }
        }
    }
}

.hero-slider-content {
    position: relative;
    z-index: 99;
    h1 {
        font-size: 68px;
        font-weight: bold;
        color: $white;
        margin: 0 0 27px;
        @media #{$lg-layout} {
            font-size: 50px;
        }
        @media #{$md-layout} {
            font-size: 45px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    p {
        color: $white;
        font-size: 15px;
        line-height: 30px;
        margin: 0 auto;
        width: 30%;
        @media #{$lg-layout} {
            width: 40%;
        }
        @media #{$md-layout} {
            width: 50%;
        }
        @media #{$xs-layout} {
            width: 90%;
        }
        @media #{$sm-layout} {
            width: 80%;
        }
    }
}

.mouse-scroll-area {
    position: relative;
    padding-bottom: 115px;
    @media #{$md-layout} {
        padding-bottom: 40px;
    }
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
    .book-shape-1 {
        position: absolute;
        top: -3% !important;
        left: 22% !important;
        z-index: 1;
        &.layer {
            img {
                width: 293px;
                @media #{$xs-layout} {
                    width: 150px;
                }
            }
        }
    }
    .book-shape-2 {
        position: absolute;
        top: 24% !important;
        left: 70% !important;
        z-index:1;
        @media #{$xs-layout} {
            top: 10% !important;
            left: 40% !important;
        }
        &.layer {
            img {
                width: 85px;
            }
        }
    }    
    .book-shape-3 {
        position: absolute;
        top: 14% !important;
        left: 88% !important;
        z-index:1;
        &.layer {
            img {
                width: 233px;
            }
        }
    }    
    .book-shape-4 {
        position: absolute;
        top: 66% !important;
        left: 76% !important;
        z-index:1;
        @media #{$lg-layout} {
            top: 75% !important;
            left: 65% !important;
        }
        @media #{$md-layout} {
            top: 70% !important;
            left: 74% !important;
        }
        @media #{$xs-layout} {
            top: 92% !important;
        }
        @media #{$sm-layout} {
            top: 80% !important;
            left: 63% !important;
        }
        &.layer {
            img {
                width: 340px;
                @media #{$lg-layout} {
                    width: 240px;
                }
                @media #{$md-layout} {
                    width: 200px;
                }
                @media #{$xs-layout} {
                    width: 140px;
                }
            }
        }
    }   
    .book-shape-5 {
        position: absolute;
        top: 60% !important;
        left: 18% !important;
        z-index:1;
        @media #{$lg-layout} {
            top: 68% !important;
            left: 25% !important;
        }
        @media #{$md-layout} {
            top: 70% !important;
        }
        @media #{$xs-layout} {
            top: 70% !important;
            left: 31% !important;
        }
        &.layer {
            img {
                width: 289px;
                @media #{$lg-layout} {
                    width: 200px;
                }
                @media #{$md-layout} {
                    width: 180px;
                }
                @media #{$xs-layout} {
                    width: 100px;
                }
            }
        }
    } 
    .book-shape-6 {
        position: absolute;
        top: 54% !important;
        left: 0% !important;
        z-index:1;
        @media #{$xs-layout} {
            top: 74% !important;
        }
        &.layer {
            img {
                width: 176px;
                @media #{$md-layout} {
                    width: 100px;
                }
                @media #{$xs-layout} {
                    width: 70px;
                }
            }
        }
    }    
}    

.slider-cake-fixed-img {
	position: absolute;
	left: 0;
	top: 53%;
	z-index: 9;
    @media #{$xx-layout} {
        top: 43%;
    }
    @media #{$xl-layout} {
        top: 74%;
    }
    @media #{$lg-layout} {
        top: 76%;
    }
    @media #{$md-layout} {
        top: 76%;
    }
    @media #{$xs-layout} {
        top: 80%;
    }
    img {
        width: 178px;
        @media #{$xx-layout} {
            width: 70px;
        }
        @media #{$lg-layout} {
            width: 120px;
        }
        @media #{$md-layout} {
            width: 120px;
        }
        @media #{$xs-layout} {
            width: 80px;
        }
    }
}

.slider-content-4 {
    position: relative;
    z-index: 999;
    @media #{$xs-layout} {
        text-align: center;
    }
    @media #{$sm-layout} {
        text-align: left;
    }
    h3 {
        font-size: 48px;
        font-family: $rochester;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 35px;
        }
    }
    h1 {
        font-size: 95px;
        font-weight: 600;
        font-family: $rochester;
        margin: 10px 0 28px;
        line-height: 1;
        color: $theme-color-chocolate;
        @media #{$lg-layout} {
            font-size: 70px;
        }
        @media #{$md-layout} {
            font-size: 60px;
        }
        @media #{$xs-layout} {
            font-size: 40px;
            margin: 10px 0 20px;
        }
    }
}

.slider-single-img-4 {
    @media #{$xs-layout} {
        margin: 10px 0 0px;
    }
    a {
        display: block;
        img {
            max-width: none;
            @media #{$xx-layout} {
                max-width: 100%;
            }
            @media #{$xl-layout} {
                max-width: 100%;
            }
            @media #{$lg-layout} {
                max-width: 100%;
            }
            @media #{$md-layout} {
                max-width: 100%;
            }
            @media #{$xs-layout} {
                max-width: 100%;
            }
        }
    }
}

.slider-blue-overly {
    position: relative;
    &:before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #E7F2E8;
        opacity: .78;
        z-index: 9;
        
    }
}

.slider-content-5 {
    position: relative;
    h1 {
        position: relative;
        z-index: 9;
        font-size: 128px;
        font-family: $vandella;
        color: #172419;
        top: -85px;
        left: -84px;
        @media #{$lg-layout} {
            font-size: 90px;
            left: -68px;
        }
        @media #{$md-layout} {
            font-size: 90px;
            left: -30px;
        }
        @media #{$xs-layout} {
            font-size: 45px;
            left: 0px;
            top: -50px;
        }
        span {
            color: $theme-color-green-2;
            margin: 0 150px;
            display: inline-block;
            @media #{$md-layout} {
                margin: 0 70px;
            }
            @media #{$xs-layout} {
                margin: 0 0px;
            }
        }
    }
    .slider-oganic-img-1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 5;
    }
    .slider-oganic-img-2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 2;
    }
}

.slider-oganic-img-1 {
    img {
        width: 714px;
        max-width: none;
        @media #{$lg-layout} {
            width: 470px;
        }
        @media #{$md-layout} {
            width: 470px;
        }
        @media #{$xs-layout} {
            width: 214px;
        }
    }
}

.slider-oganic-img-2 {
    img {
        width: 510px;
        max-width: none;
        @media #{$lg-layout} {
            width: 410px;
        }
        @media #{$md-layout} {
            width: 410px;
        }
        @media #{$xs-layout} {
            width: 250px;
        }
    }
}

.oganic-bg-img {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	right: 0;
    img {
        width: 100%;
        max-width: none;
    }
}

.slider-bg-color-2 {
    background: linear-gradient(to right, #F0FDF5, #F0FDF5 68%, #F7F7F7 0%);
}
.single-slider-ptb-2 {
    padding-top: 22px;
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$xs-layout} {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @media #{$sm-layout} {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.slider-sin-img-mrg2 {
	margin: 0 -85px 0 0px;
	float: right;
    @media #{$xx-layout} {
        margin: 0 -40px 0 0px;
    }
    @media #{$xl-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$xs-layout} {
        margin: 30px 0px 0 0px;
    }
}

.slider-kids-img-content {
    position: relative;
    display: inline-block;
    h3 {
        font-size: 49px;
        font-family: $chewy;
        color: #090961;
        margin: 0;
        position: absolute;
        left: 0;
        top: 72px;
        z-index: 9;
        @media #{$lg-layout} {
            left: -40px;
            top: 50px;
        }
        @media #{$md-layout} {
            left: -40px;
            top: 50px;
        }
        @media #{$xs-layout} {
            left: -20px;
            top: 0;
            font-size: 25px
        }
    }
    h4 {
        font-size: 49px;
        font-family: $chewy;
        color: #090961;
        margin: 0;
        position: absolute;
        right: -65px;
        bottom: 118px;
        z-index: 9;
        @media #{$lg-layout} {
            bottom: 85px;
        }
        @media #{$md-layout} {
            bottom: 85px;
        }
        @media #{$xs-layout} {
            right: 0;
            bottom: -12px;
            font-size: 25px
        }
    }
    img {
        width: 755px;
        @media #{$lg-layout} {
            width: 555px;
        }
        @media #{$md-layout} {
            width: 555px;
        }
        @media #{$xs-layout} {
            width: 215px;
        }
    }
}

.kids-slider-area {
    position: relative;
    &::before {
        background-image: url(../../assets/images/icon-img/kids-slider.png);
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0px;
        background-repeat: repeat;
        display: block;
        width: 100%;
        height: 20px;
        z-index: 9;
    }
    @media #{$xs-layout} {
        padding: 120px 0 100px;
    }
}

.slider-flower-wrap {
    img {
        width: 845px;
        display: inline-block;
        @media #{$xx-layout} {
            width: 750px;
        }
        @media #{$xl-layout} {
            width: 700px;
        }
        @media #{$lg-layout} {
            width: 600px;
        }
        @media #{$md-layout} {
            width: 500px;
        }
        @media #{$xs-layout} {
            width: 230px;
        }
        @media #{$sm-layout} {
            width: 270px;
        }
    }
    h2 {
        background: -webkit-linear-gradient(0deg, #EF1884, #BC3B3B 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 124px;
        font-family: $vandella;
        margin-top: -50px;
        margin-bottom: 0;
        @media #{$xx-layout} {
            font-size: 115px;
            margin-top: -20px;
        }
        @media #{$xl-layout} {
            font-size: 100px;
            margin-top: -28px;
        }
        @media #{$lg-layout} {
            font-size: 85px;
            margin-top: -10px;
        }
        @media #{$md-layout} {
            font-size: 70px;
            margin-top: -10px;
        }
        @media #{$xs-layout} {
            font-size: 50px;
            margin-top: 10px;
        }
    }
}

.mouse-scroll-area-2 {
    position: absolute;
    top: 0% !important;
    left: 0 !important;
    z-index: 9 !important;
    width: 100% !important;
    height: 100% !important;
    .flower-shape-1 {
        position: absolute;
        top: 27% !important;
        left: 0% !important;
        z-index: 9;
        img {
            max-width: 100%;
        }
        @media #{$lg-layout} {
            left: -12% !important;
        }
        @media #{$md-layout} {
            img {
                width: 180px !important;
            }
        }
        @media #{$xs-layout} {
            top: 42% !important;
            img {
                width: 80px !important;
            }
        }
        @media #{$sm-layout} {
            img {
                width: 100px !important;
            }
        }
    }
    .flower-shape-2 {
        position: absolute;
        top: 27% !important;
        left: 81% !important;
        z-index: 9;
        @media #{$lg-layout} {
            left: 70% !important;
        }
        @media #{$xs-layout} {
            top: 42% !important;
        }
        img {
            max-width: 100%;
        }
    }
}

.slider-single-img-5 {
	position: relative;
	z-index: 9;
    margin: 0 0 -23px 24px;
    @media #{$md-layout} {
        margin: 0 0 0px 0px;
    }
    @media #{$xs-layout} {
        margin: 20px 0 -23px 0px;
        text-align: center;
    }
    img {
        max-width: 100%;
    }
}

.kids-3-bg-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    img {
        width: 100%;
    }
}
.pet-food-bg-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    img {
        width: 100%;
    }
}

.slider-content-6 {
    padding-top: 55px;
    @media #{$xs-layout} {
        padding-top: 0px;
        text-align: center;
    }
    @media #{$sm-layout} {
        text-align: left;
        padding-top: 20px;
    }
    h3 {
        font-size: 58px;
        color: #23914C;
        margin: 0 0 0px;
        line-height: 1;
        @media #{$xs-layout} {
            font-size: 45px;
        }
    }
    h1 {
        font-size: 70px;
        color: #1086EE;
        margin: 30px 0 4px;
        font-weight: bold;
        line-height: 1;
        @media #{$md-layout} {
            font-size: 57px;
            margin: 20px 0 4px;
        }
        @media #{$xs-layout} {
            font-size: 42px;
            margin: 15px 0 4px;
        }
    }
    h2 {
        font-size: 58px;
        color: #FE58F3;
        font-family: $jellybelly;
        margin: 0;
        line-height: 1;
        @media #{$md-layout} {
            font-size: 50px;
        }
        @media #{$xs-layout} {
            font-size: 38px;
        }
    }
}

.kids-3-single-slider {
    position: relative;
    &::before {
        background-image: url(../../assets/images/icon-img/kids-3-rectangle.png);
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: -9px;
        background-repeat: repeat;
        display: block;
        width: 100%;
        height: 18px;
        z-index: 9;
    }
    @media #{$xs-layout} {
        &.pt-100 {
            padding-top: 50px;
        }
    }
}


.slider-single-img-6 {
    img {
        width: 100%;
    }
}
.slider-single-img-7 {
    margin-left: -50px;
    position: relative;
    z-index: 2;
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        text-align: center;
    }
    img {
        width: 100%;
    }
}

.slider-content-7 {
    background-color: #AFA2A7;
    margin-right: -135px;
    position: relative;
    z-index: 9;
    padding: 63px 50px 63px;
    @media #{$lg-layout} {
        padding: 40px 30px 40px;
    }
    @media #{$md-layout} {
        padding: 30px 30px 30px;
    }
    @media #{$xs-layout} {
        padding: 30px 30px 30px;
        margin-right: 0px;
    }
    @media #{$sm-layout} {
        padding: 50px 30px 50px;
        margin-right: 0px;
    }
    h2 {
        font-size: 57px;
        font-weight: bold;
        color: $white;
        margin: 0 0 7px;
        @media #{$xx-layout} {
            font-size: 50px;
        }
        @media #{$xl-layout} {
            font-size: 45px;
        }
        @media #{$lg-layout} {
            font-size: 40px;
        }
        @media #{$md-layout} {
            font-size: 30px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    h1 {
        font-size: 132px;
        font-weight: bold;
        color: $white;
        margin: 0 0 0px;
        @media #{$xx-layout} {
            font-size: 98px;
        }
        @media #{$xl-layout} {
            font-size: 90px;
        }
        @media #{$lg-layout} {
            font-size: 80px;
        }
        @media #{$md-layout} {
            font-size: 50px;
        }
        @media #{$xs-layout} {
            font-size: 50px;
        }
    }
}


.slider-content-8 {
    position: relative;
    z-index: 9;
    @media #{$xs-layout} {
        margin-bottom: 30px;
        text-align: center;
    }
    @media #{$sm-layout} {
        text-align: left;
    }
    h1 {
        color: #8E5208;
        font-size: 68px;
        font-family: $pacifico;
        margin: 0;
        @media #{$lg-layout} {
            font-size: 55px;
        }
        @media #{$md-layout} {
            font-size: 38px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
        }
        @media #{$sm-layout} {
            font-size: 29px;
        }
    }
    p {
        font-size: 15px;
        line-height: 30px;
        margin: 14px 0 55px;
        width: 83%;
        @media #{$lg-layout} {
            margin: 25px 0 35px;
        }
        @media #{$md-layout} {
            margin: 20px 0 20px;
            width: 100%;
        }
        @media #{$xs-layout} {
            margin: 20px 0 20px;
            font-size: 14px;
            width: 100%;
        }
    }
}

.slider-content-9 {
    margin-left: -86px;
    margin-top: -60px;
    position: relative;
    @media #{$xx-layout} {
        margin-left: 0px;
        margin-top: 0px;
    }
    @media #{$xl-layout} {
        margin-left: 0px;
        margin-top: 0px;
    }
    @media #{$lg-layout} {
        margin-left: 0px;
        margin-top: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
        margin-top: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 70px;
    }
    @media #{$sm-layout} {
        margin-bottom: 0px;
    }
    &::before {
        position: absolute;
        left: 65px;
        top: -80px;
        width: 360px;
        height: 335px;
        border: 4px solid $theme-color-red;
        content: "";
        z-index: 9;
        transform: scale(0);
        @media #{$xx-layout} {
            left: 50px;
        }
        @media #{$xl-layout} {
            left: 50px;
            height: 300px;
            top: -73px;
        }
        @media #{$lg-layout} {
            left: 50px;
            height: 300px;
            top: -73px;
        }
        @media #{$md-layout} {
            left: 50px;
            height: 220px;
            top: -50px;
            width: 280px;
        }
        @media #{$xs-layout} {
            left: 42px;
            height: 130px;
            top: -26px;
            width: 176px;
            right: 0;
            margin: 0 auto;
        }
        @media #{$sm-layout} {
            left: 40px;
        }
    }
    h1 {
        font-size: 80px;
        font-weight: 900;
        margin: 0 0 26px;
        line-height: 60px;
        font-style: italic;
        letter-spacing: 3px;
        @media #{$xx-layout} {
            font-size: 53px;
        }
        @media #{$xl-layout} {
            font-size: 50px;
            margin: 0 0 15px;
        }
        @media #{$lg-layout} {
            font-size: 50px;
            margin: 0 0 15px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            margin: 0 0 15px;
            line-height: 40px;
        }
        @media #{$xs-layout} {
            font-size: 32px;
            margin: 0 0 12px;
            line-height: 30px;
        }
    }
    h2 {
        font-size: 139px;
        font-weight: 900;
        margin: 0 0 0px 80px;
        color: $theme-color-red;
        font-style: italic;
        letter-spacing: 3px;
        line-height: 100px;
        @media #{$xx-layout} {
            font-size: 100px;
            line-height: 80px;
            margin: 0 0 0px 0px;
        }
        @media #{$xl-layout} {
            font-size: 85px;
            line-height: 80px;
            margin: 0 0 0px 0px;
        }
        @media #{$lg-layout} {
            font-size: 85px;
            line-height: 80px;
            margin: 0 0 0px 0px;
        }
        @media #{$md-layout} {
            font-size: 53px;
            line-height: 65px;
            margin: 0 0 0px 0px;
        }
        @media #{$xs-layout} {
            font-size: 45px;
            line-height: 40px;
            margin: 0 0 0px 0px;
        }
    }
}

.slider-single-img-8 {
    margin: 0 -160px 0px 40px;
    @media #{$xx-layout} {
        margin: 0 0px 0px 40px;
    }
    @media #{$xl-layout} {
        margin: 0 0px 0px 40px;
    }
    @media #{$lg-layout} {
        margin: 0 0px 0px 40px;
    }
    @media #{$md-layout} {
        margin: 0 0px 0px 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0px 0px 0px;
    }
    img {
        max-width: 100%;
    }
}

.slider-content-10 {
    position: relative;
    h1 {
        position: absolute;
        left: 26%;
        top: 40%;
        color: $white;
        font-weight: bold;
        font-size: 160px;
        line-height: 120px;
        margin: 0;
        z-index: 9;
        @media #{$xx-layout} {
            left: 17%;
        }
        @media #{$xl-layout} {
            font-size: 130px;
            left: 17%;
        }
        @media #{$lg-layout} {
            font-size: 100px;
            left: 15%;
        }
        @media #{$md-layout} {
            font-size: 80px;
            left: 9%;
        }
        @media #{$xs-layout} {
            font-size: 42px;
            left: 6%;
        }
        @media #{$sm-layout} {
            font-size: 50px;
        }
    }
    h2 {
        position: absolute;
        right: 25%;
        top: 40%;
        color: $white;
        font-weight: bold;
        font-size: 160px;
        line-height: 120px;
        margin: 0;
        z-index: 2;
        @media #{$xx-layout} {
            right: 14%;
        }
        @media #{$xl-layout} {
            font-size: 130px;
            right: 16%;
        }
        @media #{$lg-layout} {
            font-size: 100px;
            right: 15%;
        }
        @media #{$md-layout} {
            font-size: 80px;
            right: 12%;
        }
        @media #{$xs-layout} {
            font-size: 42px;
            right: 6%;
            z-index: 9;
        }
        @media #{$sm-layout} {
            font-size: 50px;
            right: 13%;
        }
    }
    img {
        display: inline-block;
        position: relative;
        z-index: 5;
        max-width: 100%;
    }
}
.slider-100vh {
    height: 100vh;
    @media #{$md-layout} {
        height: auto;
        padding-top: 50px;
    }
    @media #{$xs-layout} {
        height: auto;
        padding-top: 30px;
    }
}

.slider-single-img-9 {
    position: relative;
    z-index: 9;
    @media #{$xs-layout} {
        text-align: center;
    }
    img {
        width: 386px;
        @media #{$xx-layout} {
            width: 280px;
        }
        @media #{$xl-layout} {
            width: 280px;
        }
        @media #{$lg-layout} {
            width: 250px;
        }
        @media #{$md-layout} {
            width: 280px;
        }
        @media #{$xs-layout} {
            width: 150px;
            display: inline-block;
        }
    }
}

.single-slider-fashion3 {
    position: relative;
    @media #{$xs-layout} {
        padding-top: 100px;
    }
    .fashion-3-slider-img2 {
        position: absolute;
        right: 18%;
        top: 8%;
        @media #{$xs-layout} {
            right: auto;
            top: auto;
            bottom: 17%;
            left: 50%;
            transform: translateX(-50%);
        }
        @media #{$sm-layout} {
            bottom: 19%;
            left: auto;
            right: 8%;
            transform: inherit;
        }
        img {
            width: 593px;
            @media #{$xx-layout} {
                width: 400px;
            }
            @media #{$xl-layout} {
                width: 360px;
            }
            @media #{$lg-layout} {
                width: 270px;
            }
            @media #{$md-layout} {
                width: 270px;
            }
            @media #{$xs-layout} {
                width: 170px;
            }
            @media #{$sm-layout} {
                width: 220px;
            }
        }
    }
    .fashion-3-slider-img3 {
        position: absolute;
        right: -18px;
        top: 24%;
        @media #{$xs-layout} {
            top: 0%;
        }
        img {
            width: 334px;
            @media #{$xx-layout} {
                width: 270px;
            }
            @media #{$xl-layout} {
                width: 200px;
            }
            @media #{$lg-layout} {
                width: 150px;
            }
            @media #{$md-layout} {
                width: 150px;
            }
            @media #{$xs-layout} {
                width: 150px;
            }
        }
    }
    .fashion-3-slider-img4 {
        position: absolute;
        right: 49%;
        top: 5%;
        @media #{$xs-layout} {
            top: 0%;
            right: auto;
            left: 15px;
        }
        img {
            width: 111px;
            @media #{$lg-layout} {
                width: 80px;
            }
            @media #{$md-layout} {
                width: 80px;
            }
            @media #{$xs-layout} {
                width: 80px;
            }
        }
    }
}

.slider-content-11 {
    position: relative;
    z-index: 9;
    padding: 0 0 0 58px;
    @media #{$lg-layout} {
        padding: 0 0 0 30px;
    }
    @media #{$md-layout} {
        padding: 0 0 0 30px;
    }
    @media #{$xs-layout} {
        padding: 0 0 30px 15px;
    }
    h1 {
        font-size: 68px;
        line-height: 90px;
        color: #151C0F;
        margin: 0;
        @media #{$xx-layout} {
            font-size: 50px;
            line-height: 70px;
        }
        @media #{$xl-layout} {
            font-size: 40px;
            line-height: 60px;
        }
        @media #{$lg-layout} {
            font-size: 40px;
            line-height: 60px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            line-height: 60px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            line-height: 45px;
        }
        span {
            font-weight: bold;
        }
    }
    p {
        font-size: 18px;
        line-height: 36px;
        width: 51%;
        margin: 40px 0 60px;
        @media #{$xx-layout} {
            width: 80%;
            margin: 20px 0 40px;
        }
        @media #{$xl-layout} {
            width: 100%;
            margin: 20px 0 40px;
        }
        @media #{$lg-layout} {
            width: 100%;
            margin: 20px 0 40px;
            font-size: 16px;
        }
        @media #{$md-layout} {
            width: 100%;
            margin: 20px 0 40px;
            font-size: 16px;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 5px 0 20px;
            font-size: 14px;
            line-height: 30px
        }
    }
}

.social-icon-2 {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    @media #{$xs-layout} {
        left: 5px;
    }
    a {
        display: block;
        margin-bottom: 40px;
        color: #CFCFCF;
        font-size: 16px;
        @media #{$xs-layout} {
            font-size: 14px;
            margin-bottom: 20px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            color: #0A1039;
        }
    }
}

.login-cart-position {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    @media #{$xs-layout} {
        right: 8px;
    }
    .same-style-2 {
        margin: 0 0 38px;
        &:last-child {
            margin: 0 0 0px;
        }
        a {
            font-size: 22px;
            color: #D8D8D8;
            &:hover {
                color: #0A1039;
            }
            @media #{$md-layout} {
                &.cart-active {
                    display: none;
                }
            }
            @media #{$xs-layout} {
                font-size: 16px;
                &.cart-active {
                    display: none;
                }
            }
        }
    }
}
.slider-fashion-4-plr {
    .container-fluid {
        padding: 0 50px;
        @media #{$xs-layout} {
            padding: 0 30px;
        }
    }
}

.sidebar-slider-area {
    margin-left: 285px;
    height: 100vh;
    @media #{$xx-layout} {
        margin-left: 230px;
    }
    @media #{$xl-layout} {
        margin-left: 220px;
    }
    @media #{$lg-layout} {
        margin-left: 180px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
        height: auto;
        margin-top: 90px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        height: auto;
        margin-top: 72px;
    }
}
.sidebar-slider-area-2 {
    margin-left: 65px;
    @media #{$md-layout} {
        margin-left: 0px;
        margin-top: 90px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-top: 72px;
    }
}

.kid-2-bg-shape {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    img {
        width: 100%;
        max-width: none;
    }
}

.kids-2-slider {
    .single-slider {
        margin-bottom: 170px;
        @media #{$lg-layout} {
            margin-bottom: 130px;
        }
        @media #{$md-layout} {
            margin-bottom: 85px;
        }
        @media #{$xs-layout} {
            margin-bottom: 50px;
        }
    }
}

.slider-single-img-10 {
    position: relative;
    float: right;
    z-index: 9;
    @media #{$xs-layout} {
        float: inherit;
        text-align: center;
    }
    img {
        width: 501px;
        max-width: 100%;
        @media #{$xl-layout} {
            width: 400px;
        }
        @media #{$lg-layout} {
            width: 370px;
        }
        @media #{$md-layout} {
            width: 300px;
        }
        @media #{$xs-layout} {
            width: 200px;
            display: inline-block;
        }
    }
    .slider-baby-img {
        position: absolute;
        left: -32%;
        top: 60px;
        @media #{$xs-layout} {
            left: 50%;
            transform: translateX(-50%);
        }
        img {
            width: 273px;
            max-width: 100%;
            @media #{$xl-layout} {
                width: 220px;
            }
            @media #{$lg-layout} {
                width: 200px;
            }
            @media #{$md-layout} {
                width: 200px;
            }
            @media #{$xs-layout} {
                width: 170px;
            }
        }
    } 
    .slider-trolley-img {
        position: absolute;
        left: -76%;
        top: 43%;
        @media #{$xx-layout} {
            left: -73%;
        }
        @media #{$xs-layout} {
            left: 50%;
            top: 105%;
            transform: translateX(-50%);
        }
        img {
            width: 624px;
            max-width: 100%;
            @media #{$xx-layout} {
                width: 570px;
            }
            @media #{$xl-layout} {
                width: 500px;
            }
            @media #{$lg-layout} {
                width: 450px;
            }
            @media #{$md-layout} {
                width: 370px;
                left: -68%;
            }
            @media #{$xs-layout} {
                width: 370px;
            }
        }
    } 
}

.slider-content-12 {
    @media #{$xx-layout} {
        padding-left: 55px;
    }
    @media #{$lg-layout} {
        padding-left: 30px;
    }
    h1 {
        font-size: 68px;
        line-height: 94px;
        margin: 0 0 17px;
        @media #{$xx-layout} {
            font-size: 60px;
            line-height: 70px;
        }
        @media #{$xl-layout} {
            font-size: 45px;
            line-height: 55px;
        }
        @media #{$lg-layout} {
            font-size: 45px;
            line-height: 55px;
        }
        @media #{$md-layout} {
            font-size: 45px;
            line-height: 55px;
        }
        @media #{$xs-layout} {
            font-size: 35px;
            line-height: 45px;
        }
        span {
            &.bold {
                font-weight: bold;
            }
            &.blue {
                font-weight: bold;
                color: $theme-color-blue;
            }
        }
    }
    p {
        font-size: 15px;
        line-height: 30px;
        width: 30%;
        @media #{$xx-layout} {
            width: 50%;
        }
        @media #{$xl-layout} {
            width: 50%;
        }
        @media #{$lg-layout} {
            width: 50%;
        }
        @media #{$md-layout} {
            width: 50%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
        @media #{$sm-layout} {
            width: 70%;
        }
    }
}

.slider-content-13 {
    margin-left: -80px;
    position: relative;
    z-index: 9;
    @media #{$xx-layout} {
        margin-left: 0px;
    }
    @media #{$xl-layout} {
        margin-left: 0px;
    }
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
        margin-bottom: 20px;
        text-align: center;
    }
    @media #{$sm-layout} {
        margin-bottom: 0px;
        text-align: left;
    }
    h1 {
        color: $white;
        font-size: 58px;
        line-height: 98px;
        font-family: $pacifico;
        margin: 0;
        @media #{$lg-layout} {
            font-size: 45px;
            line-height: 70px;
        }
        @media #{$md-layout} {
            font-size: 40px;
            line-height: 65px;
        }
        @media #{$xs-layout} {
            font-size: 40px;
            line-height: 55px;
        }
    }
}
.kids-2-slider-shape-2 {
    position: absolute;
    left: 0;
    top: 21%;
    img {
        width: 31px;
        animation: playbutton-effect 4s linear infinite;
        animation-direction: normal;
        animation-delay: 2s;
        animation-direction: alternate;
    }
}
.kids-2-slider-shape-1 {
	position: absolute;
	content: "";
	left: 19%;
    bottom: 78px;
	width: 59px;
	height: 59px;
	border-radius: 100%;
	z-index: 3;
    background-color: #FFF388;
    animation: playbutton-effect 4s linear infinite;
    animation-direction: normal;
    animation-delay: 2s;
    animation-direction: alternate;
    @media #{$xl-layout} {
        bottom: 50px;
    }
    @media #{$lg-layout} {
        bottom: 35px;
    }
    @media #{$md-layout} {
        bottom: 30px;
    }
    @media #{$xs-layout} {
        left: 8%;
    }
}

.kids-2-slider-shape-3 {
	position: absolute;
	content: "";
	left: 37%;
    top: 35%;
	width: 0;
    height: 0;
    transform: rotate(45deg);
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 22px solid #4ADCD7;
	z-index: 3;
    animation: playbutton-effect 4s linear infinite;
    animation-direction: normal;
    animation-delay: 2s;
    animation-direction: alternate;
}
.kids-2-slider-shape-4 {
	position: absolute;
	right: -1px;
    top: 0;
    z-index: 3;
    img {
        width: 448px;
    }
}
.kids-2-slider-shape-5 {
	position: absolute;
	right: 0%;
    bottom: 0;
    z-index: 3;
    img {
        width: 297px;
        @media #{$xs-layout} {
            width: 97px;
        }
    }
}
.kids-2-slider-shape-6 {
	position: absolute;
	content: "";
	right: 18%;
    top: 2%;
	width: 64px;
	height: 64px;
	border-radius: 100%;
	z-index: 3;
    background: linear-gradient(135deg, rgba(0,234,255,1) 0%, rgba(15,226,255,1) 6%, rgba(56,205,255,1) 18%, rgba(121,172,255,1) 70%, rgba(210,126,255,1) 100%, rgba(248,108,255,1) 100%);
    animation: playbutton-effect 4s linear infinite;
    animation-direction: normal;
    animation-delay: 2s;
    animation-direction: alternate;
    @media #{$xx-layout} {
        right: 26%;
    }
    @media #{$xl-layout} {
        right: 26%;
    }
    @media #{$lg-layout} {
        right: 36%;
        top: 3%;
    }
    @media #{$md-layout} {
        right: 36%;
        top: 4%;
    }
    @media #{$xs-layout} {
        right: 36%;
        top: 3%;
    }
}

.dot-style-4 {
    .slick-dots {
        position: absolute;
        left: 30.7%;
        bottom: 20px;
        @media #{$lg-layout} {
            margin-bottom: 8px;
            bottom: 10px;
        }
        @media #{$md-layout} {
            margin-bottom: -10px;
        }
        @media #{$xs-layout} {
            left: 30px;
            top: 38%;
            bottom: auto;
        }
        li {
            button {
                font-size: 20px;
                color: #777777;
                transition: all .3s;
                position: relative;
                margin-bottom: 18px;
                border: none;
                padding: 0;
                background-color: transparent;
                @media #{$lg-layout} {
                    margin-bottom: 8px;
                }
                @media #{$md-layout} {
                    font-size: 15px;
                    margin-bottom: 8px;
                }
                @media #{$xs-layout} {
                    font-size: 15px;
                    margin-bottom: 8px;
                }
                &::after {
                    position: absolute;
                    transition: all .3s;
                    z-index: 1;
                    width: 30px;
                    height: 2px;
                    background-color: $theme-color-dark-blue;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 32px;
                    content: "";
                    opacity: 0;
                }
                &::before {
                    content: "0";
                    display: inline-block;
                    font-size: 20px;
                    @media #{$md-layout} {
                        font-size: 15px;
                    }
                    @media #{$xs-layout} {
                        font-size: 15px;
                    }
                }
            }
            &.slick-active {
                button {
                    color: $theme-color-dark-blue;
                    &::after {
                        opacity: 1;
                        width: 87px;
                    }
                }
            }
            &:last-child {
                button {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.dot-style-6-position-1 {
    .slick-dots {
        position: absolute;
        left: 9%;
        bottom: 46px;
        @media #{$xx-layout} {
            left: 7%;
        }
        @media #{$xl-layout} {
            left: 2%;
        }
        @media #{$lg-layout} {
            left: 6%;
            bottom: 30px;
        }
        @media #{$md-layout} {
            left: 5%;
        }
        @media #{$xs-layout} {
            left: 5%;
        }
    }
}
.dot-style-6-position-2 {
    .slick-dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 80px;
        @media #{$xs-layout} {
            left: 15px;
            transform: none;
            bottom: 20px;
        }
        @media #{$sm-layout} {
            left: 40px;
        }
    }
}

.dot-style-6 {
    .slick-dots {
        display: flex;
        flex-wrap: wrap;
        li {
            margin-right: 36px;
            &:last-child {
                margin-right: 0;
            }
            button {
                font-size: 20px;
                color: #777777;
                transition: all .3s;
                position: relative;
                border: none;
                padding: 0;
                background-color: transparent;
                @media #{$md-layout} {
                    font-size: 15px;
                }
                @media #{$xs-layout} {
                    font-size: 15px;
                }
                &::after {
                    position: absolute;
                    transition: all .3s;
                    z-index: 1;
                    width: 30px;
                    height: 2px;
                    background-color: $theme-color-dark-blue;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 37px;
                    content: "";
                    opacity: 0;
                }
                &::before {
                    content: "0";
                    display: inline-block;
                    font-size: 20px;
                    @media #{$md-layout} {
                        font-size: 15px;
                    }
                    @media #{$xs-layout} {
                        font-size: 15px;
                    }
                }
            }
            &.slick-active {
                margin-right: 125px;
                button {
                    color: $theme-color-dark-blue;
                    &::after {
                        opacity: 1;
                        width: 87px;
                    }
                }
            }
            &:last-child {
                button {
                    margin-bottom: 0px;
                }
            }
        }
    }
}


.fashion2-slider-bg1 {
    background: linear-gradient(to right, #F2F5F8, #F2F5F8 44%, #fff 0%);
}
.fashion2-slider-bg2 {
    background: linear-gradient(to right, #FFF0E6, #FFF0E6 44%, #fff 0%);
}
.fashion2-slider-bg3 {
    background: linear-gradient(to left, #FFF0F9, #FFF0F9 30%, #fff 0%);
}

.single-slider-fashion2 {
    padding-top: 30px;
    .fashion-2-slider-img1-1 {
        position: absolute;
        left: 34%;
        bottom: 3.5%;
        @media #{$xx-layout} {
            left: 29%;
        }
        @media #{$xl-layout} {
            left: 25%;
        }
        @media #{$lg-layout} {
            left: 20%;
        }
        @media #{$md-layout} {
            left: 20%;
        }
        @media #{$xs-layout} {
            left: 4%;
            bottom: 12.5%;
        }
        @media #{$sm-layout} {
            left: 40%;
        }
        img {
            width: 129px;
            @media #{$md-layout} {
                width: 100px;
            }
            @media #{$xs-layout} {
                width: 70px;
            }
        }
    }
    .fashion-2-slider-img1-2 {
        position: absolute;
        right: .7%;
        bottom: 3.5%;
        @media #{$xs-layout} {
            right: 4%;
            bottom: 12.5%;
        }
        img {
            width: 129px;
            @media #{$md-layout} {
                width: 100px;
            }
            @media #{$xs-layout} {
                width: 70px;
            }
        }
    }
    .fashion-2-slider-img1-3 {
        position: absolute;
        left: 39.5%;
        top: 31%;
        img {
            width: 175px;
            @media #{$md-layout} {
                width: 130px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
    .fashion-2-slider-img1-4 {
        position: absolute;
        right: 2.3%;
        top: 11%;
        img {
            width: 111px;
            @media #{$md-layout} {
                width: 80px;
            }
            @media #{$xs-layout} {
                width: 70px;
                top: 2%;
            }
        }
    }
    .fashion-2-slider-img2-1 {
        position: absolute;
        left: 38%;
        top: 7%;
        img {
            width: 175px;
            @media #{$md-layout} {
                width: 150px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
    .fashion-2-slider-img2-2 {
        position: absolute;
        right: 3.5%;
        top: 5%;
        img {
            width: 149px;
            @media #{$md-layout} {
                width: 100px;
            }
            @media #{$xs-layout} {
                width: 70px;
            }
        }
    }
    .fashion-2-slider-img3-1 {
        position: absolute;
        left: 38%;
        top: 7%;
        img {
            width: 175px;
            @media #{$md-layout} {
                width: 150px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
}

.slider-single-img-11 {
    position: relative;
    z-index: 9;
    img {
        max-width: 100%;
    }
    @media #{$xs-layout} {
        text-align: center;
    }
}

.slider-content-14 {
    margin: -30px 0 0 0px;
    position: relative;
    z-index: 9;
    @media #{$xs-layout} {
        text-align: center;
        padding-top: 60px;
        padding-bottom: 30px;
    }
    @media #{$sm-layout} {
        text-align: left;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    h1 {
        font-size: 68px;
        font-weight: bold;
        margin: 0; 
        @media #{$lg-layout} {
            font-size: 60px;
        }
        @media #{$md-layout} {
            font-size: 50px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
        }
    }
    h2 {
        font-size: 117px;
        font-family: $vandella;
        color: #D3854C;
        margin: 33px 0 0px;
        line-height: 127px;
        @media #{$lg-layout} {
            font-size: 90px;
            margin: 10px 0 0px;
            line-height: 100px;
        }
        @media #{$md-layout} {
            font-size: 80px;
            margin: 10px 0 0px;
            line-height: 90px;
        }
        @media #{$xs-layout} {
            font-size: 70px;
            margin: 20px 0 0px;
            line-height: 70px;
        }
    }
    h3 {
        font-size: 57px;
        font-weight: 300;
        margin: 0;
        line-height: 1;
        @media #{$xs-layout} {
            font-size: 50px;
        }
    }
}

.fashion2-logo-position {
    position: absolute;
    left: 45px;
    top: 30px;
    @media #{$xl-layout} {
        left: 15px;
    }
    @media #{$lg-layout} {
        left: 15px;
    }
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
}

.furniture-slider-shape-1 {
    position: absolute;
    content: "";
    left: 11%;
    bottom: 23%;
    z-index: 9;
    @media #{$xx-layout} {
        bottom: 15%;
    }
    @media #{$xl-layout} {
        bottom: 15%;
    }
    @media #{$lg-layout} {
        bottom: 15%;
    }
    @media #{$xs-layout} {
        left: 5%;
        bottom: 0%;
    }
    img {
        width: 30px;
        animation: playbutton-effect 4s linear infinite;
        animation-direction: normal;
        animation-delay: 2s;
        animation-direction: alternate;
    }
}

.furniture-slider-shape-2 {
    position: absolute;
    content: "";
    left: 29%;
    top: 16%;
    z-index: 9;
    img {
        width: 173px;
        animation: playbutton-effect 4s linear infinite;
        animation-direction: normal;
        animation-delay: 2s;
        animation-direction: alternate;
        @media #{$xx-layout} {
            width: 100px;
            top: 20%;
        }
        @media #{$xl-layout} {
            width: 100px;
            top: 20%;
        }
        @media #{$lg-layout} {
            width: 100px;
            top: 20%;
        }
        @media #{$md-layout} {
            width: 100px;
            top: 20%;
        }
        @media #{$xs-layout} {
            width: 100px;
            top: 20%;
        }
    }
}

.furniture-slider-shape-3 {
    position: absolute;
    content: "";
    width: 59px;
    height: 59px;
    background-color: #FFF388;
    border-radius: 100%;
    left: 0%;
    bottom: 17%;
    z-index: 9;
    animation: playbutton-effect 4s linear infinite;
    animation-direction: normal;
    animation-delay: 2s;
    animation-direction: alternate;
    @media #{$xx-layout} {
        left: 2%;
        bottom: 10%;
    }
    @media #{$xl-layout} {
        left: 2%;
        bottom: 10%;
    }
    @media #{$md-layout} {
        left: 2%;
        bottom: 8%;
    }
    @media #{$xs-layout} {
        left: 50%;
        bottom: 35%;
    }
}
.furniture-slider-shape-4 {
	position: absolute;
	content: "";
	left: 31.3%;
	top: 26%;
	width: 0;
	height: 0;
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
	border-bottom: 22px solid #4ADCD7;
	z-index: 9;
	transform: rotate(45deg);
    animation: playbutton-effect 4s linear infinite;
    animation-direction: normal;
    animation-delay: 2s;
    animation-direction: alternate;
    @media #{$xl-layout} {
        top: 20%;
    }
}

.img-max-width {
    .slick-dots {
        li {
            button {
                img {
                    max-width: 100%;
                }
            }
        }
    }
}









