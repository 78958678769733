/*---------- 31. Others style ------------*/

.empty-cart-content {
    img {
        max-width: 100%;
    }
    h3 {
        font-size: 24px;
        text-transform: uppercase;
        margin: 50px 0 41px;
        color: #262626;
        @media #{$xs-layout}{
            font-size: 17px;
            margin: 30px 0 31px;
        }
    }
    .empty-cart-btn {
        a {
            display: inline-block;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            background-color: #262626;
            padding: 18px 32px;
            &:hover {
                background-color: $theme-color-dark-blue;
            }
        }
    }
}

.error-content {
    h1 {
        font-size: 200px;
        color: #fff;
        margin: 0;
        line-height: 150px;
        font-weight: 600;
        @media #{$md-layout} {
            font-size: 180px;
            line-height: 135px;
        }
        @media #{$xs-layout} {
            font-size: 120px;
            line-height: 120px;
        }
        @media #{$sm-layout} {
            font-size: 150px;
            line-height: 150px;
        }
    }
    h3 {
        font-size: 64px;
        color: #fff;
        margin: 40px 0 70px;
        font-family: $vandella;
        @media #{$xl-layout} {
            font-size: 55px;
        }
        @media #{$lg-layout} {
            font-size: 55px;
        }
        @media #{$md-layout} {
            font-size: 55px;
        }
        @media #{$xs-layout} {
            font-size: 30px;
            margin: 10px 0 30px;
        }
        @media #{$sm-layout} {
            font-size: 40px;
            margin: 10px 0 30px;
        }
    }
}

.error-btn {
    a {
        display: inline-block;
        color: #000;
        line-height: 1;
        text-transform: uppercase;
        background-color: #fff;
        padding: 18px 30px;
        z-index: 1;
        position: relative;
        &:hover {
            color: $white;
        }
    }
}

.single-store {
    h3 {
        font-size: 24px;
        font-weight: 700;
        text-transform: capitalize;
        line-height: 28px;
        margin-bottom: 20px;
        @media #{$xs-layout} {
            font-size: 20px;
            margin-bottom: 12px;
        }
    }
    ul {
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                
            }
        }
    }
    @media #{$md-layout} {
        &.mb-50 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}

@media #{$xs-layout} {
    .store-area {
        &.pt-120 {
            padding-top: 60px;
        }
        &.pb-70 {
            padding-bottom: 30px;
        }
    }
}







