/*---------- Header style------------*/

.header-small-device {
    display: none;
    &.header-small-ptb {
        padding: 22px 0;
    }
    @media #{$md-layout} {
        display: block;
    }
    @media #{$xs-layout} {
        display: block;
    }
}

.header-large-device {
    display: block;
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
}

.header-ptb-1 {
    padding: 22px 0;
    @media #{$xs-layout} {
        padding: 15px 0;
    }
}
.header-ptb-2 {
    padding: 17px 0;
    @media #{$xs-layout} {
        padding: 15px 0;
    }
}
.header-ptb-3 {
    padding: 30px 0 0;
    @media #{$md-layout} {
        padding: 0px 0 0;
    }
    @media #{$xs-layout} {
        padding: 0px 0 0;
    }
}
.header-ptb-4 {
    padding: 32px 0 32px;
    @media #{$md-layout} {
        padding: 22px 0;
    }
    @media #{$xs-layout} {
        padding: 22px 0;
    }
}

.logo {
    a {
        display: inline-block;
    }
}

.logo-position {
    position: relative;
    a { 
        img {
            &.sticky-logo {
                position: absolute;
                left: 0;
                top: 0;
                max-width: 100%;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

.stick {
    .logo-position {
        a {
            img {
                &.sticky-logo {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    max-width: 100%;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        a {
            img {
                &.normal-logo {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
}


.header-action-wrap {
    &.header-action-flex {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &.header-action-flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.header-action-mrg-1 {
        .same-style {
            margin-left: 55px;
            @media #{$xs-layout} {
                margin-left: 35px;
            }
        }
    }
    &.header-action-mrg-2 {
        .same-style {
            margin-left: 35px;
            @media #{$xs-layout} {
                margin-left: 35px;
            }
        }
    }
    &.header-action-mrg-3 {
        .same-style {
            margin-bottom: 37px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .same-style {
        a {
            font-size: 20px;
            color: #0A1039;
            &:hover {
                color: #444E7A;
            }
        }
        &.same-style-white {
            a {
                color: #fff;
                &:hover {
                    color: #ddd;
                }
            }
        }
        &.same-style-gray {
            a {
                color: #AFAFAF;
                &:hover {
                    color: #444E7A;
                }
            }
        }
    }
    .header-info {
        button {
            position: relative;
            width: 20px;
            height: 20px;
            line-height: 1;
            vertical-align: middle;
            padding: 0;
            background-color: transparent;
            border: none;
            span {
                background-color:#0A1039;
                border-radius: 2px;
                left: 0;
                display: block;
                position: absolute;
                transition: .4s;
                height: 2px;
                &:first-child {
                    width: 12px;
                    top: 0;
                }
                &:nth-child(2) {
                    width: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:last-child {
                    width: 19px;
                    top: 17px;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        width: 20px;
                    }
                    &:nth-child(2) {
                        width: 19px;
                    }
                    &:last-child {
                        width: 9px;
                    }
                }
            }
        }
        &.header-info-white {
            button {
                span {
                    background-color:#fff;
                }
            }
        }
        &.header-info-gray {
            button {
                span {
                    background-color:#AFAFAF;
                }
                &:hover {
                    span {
                        background-color: $theme-color-green-3;
                    }
                }
            }
        }
    }
}

.stick {
    .header-action-wrap {
        .same-style {
            &.same-style-white {
                a {
                    color: #0A1039;
                    &:hover {
                        color: #444E7A;
                    }
                }
            }
            &.pet-food-cart {
                &.same-style-white {
                    a {
                        color: #fff;
                        &:hover {
                            color: $theme-color-dark-blue;
                        }
                    }
                }
            }
        }
        .header-info {
            &.header-info-white {
                button {
                    span {
                        background-color:#0A1039;
                    }
                }
            }
        }
    }
    .header-action-white-bg {
        &::before {
            display: none;
        }
    }
}



.sticky-bar {
    &.stick {
        animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
        background-color: $white;
        border-bottom: 0 solid #4a90e2;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        left: 0; 
        transition: all .3s ease 0s;
    }
    &.pet-food-header {
        &.stick {
            background-color: $theme-color-brown;
        }
    }
}


.header-top-ptb-1 {
	padding: 4px 0px 5px;
}
.header-contact-number {
    span {
        color: #232953;
        font-size: 13px;
        font-weight: 300;
    }
    &.header-contact-number-white {
        span {
            color: #fff;
        }
    }
}

.header-top-right {
    .language-wrap {
        position: relative;
        a {
            display: block;
            img {
                max-width: 100%;
            }
        }
        .language-dropdown {
            background: #fff none repeat scroll 0 0;
            box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
            left: 0;
            padding: 8px 12px 10px;
            position: absolute;
            text-align: left;
            top: 120%;
            width: 80px;
            z-index: 9;
            display: none;
            ul {
                li {
                    padding: 0 0 3px;
                    &:last-child {
                        padding: 0 0 0px;
                    }
                    a {
                        color: #232953;
                        font-size: 13px;
                        font-weight: 300;
                        text-transform: capitalize;
                        &:hover {
                            color: $theme-color-dark-blue;
                        }
                    }
                }
            }
        }
    }
    .login-reg {
        ul {
            display: flex;
            li {
                position: relative;
                margin-right: 40px;
                &:before {
                    position: absolute;
                    content: "";
                    right: -20px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #232953;
                    width: 1px;
                    height: 13px;
                }
                &:last-child {
                    margin-right: 0;
                    &:before {
                        display: none;
                    }
                }
                a {
                    font-weight: 300;
                    color: #232953;
                    font-size: 13px;
                    &:hover {
                        color: $theme-color-dark-blue;
                    }
                }
            }
        }
        &.login-reg-white {
            ul {
                li {
                    &:before {
                        background-color: #ddd;
                    }
                    &:last-child {
                        margin-right: 0;
                        &:before {
                            display: none;
                        }
                    }
                    a {
                        color: #fff;
                        &:hover {
                            color: #ddd;
                        }
                    }
                }
            }
        }
    }
    &.header-top-flex {
        display: flex;
        justify-content: flex-end;
    }
}

.main-menu {
    > nav {
        > ul {
            > li {
                display: inline-block;
                position: relative;
                > a {
                    font-size: 16px;
                    color: #060A27;
                    display: inline-block;
                }
                &:hover > a {
                    color: $theme-color-dark-blue;
                }
                > ul {
                    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
                    background-color: #262626;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
                    opacity: 0;
                    visibility: hidden;
                    margin-top: 20px;
                    z-index: 9999;
                    @media #{$xl-layout}{
                        left: -10px;
                    }
                    @media #{$lg-layout}{
                        left: -40px;
                    }
                    &.sub-menu-width {
                        width: 220px;
                        padding: 39px 0 43px;
                        @media #{$xl-layout}{
                            width: 180px;
                        }
                        @media #{$lg-layout}{
                            width: 180px;
                        }
                        li {
                            display: block;
                            padding: 0 15px 14px 30px;
                            position: relative;
                            text-align: left;
                            &:last-child {
                                padding-bottom: 0;
                            }
                            a {
                                display: block;
                                color: #D0D0D0;
                                font-size: 13px;
                                font-weight: 300;
                                i {
                                    float: right;
                                    font-size: 12px;
                                    color: #999;
                                    font-weight: 600;
                                    position: relative;
                                    top: 6px;
                                }
                                &:hover {
                                    color: $white;
                                    text-decoration: underline;
                                }
                            }
                            ul.lavel-menu {
                                padding: 36px 0px 40px;
                                width: 220px;
                                transition: all 250ms ease-out;
                                box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
                                background-color: #262626;
                                position: absolute;
                                top: -17px;
                                left: 100%;
                                margin: 0;
                                visibility: hidden;
                                opacity: 0;
                                @media #{$lg-layout} {
                                    width: 180px;
                                }
                            }
                            &:hover > ul.lavel-menu {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                    &.mega-menu-width1 {
                        width: 675px;
                    }
                    &.mega-menu-width2 {
                        width: 1170px;
                        @media #{$xl-layout}{
                            width: 1100px;
                        }
                        @media #{$lg-layout}{
                            width: 960px;
                        }
                    }
                    &.menu-negative-mrg1 {
                        left: -160px;
                        @media #{$xx-layout}{
                            left: -137px;
                        }
                        @media #{$xl-layout}{
                            left: -126px;
                        }
                        @media #{$lg-layout}{
                            left: -185px;
                        }
                    }
                    &.menu-negative-mrg2 {
                        left: -240px;
                        @media #{$xx-layout}{
                            left: -208px;
                        }
                        @media #{$xl-layout}{
                            left: -198px;
                        }
                        @media #{$lg-layout}{
                            left: -534px;
                        }
                    }
                    &.menu-negative-mrg3 {
                        left: -400px;
                        @media #{$xx-layout}{
                            left: -408px;
                        }
                        @media #{$xl-layout}{
                            left: -372px;
                        }
                        @media #{$lg-layout}{
                            left: -287px;
                        }
                    }
                    &.menu-negative-mrg4 {
                        left: -600px;
                        @media #{$xx-layout}{
                            left: -710px;
                        }
                        @media #{$xl-layout}{
                            left: -680px;
                        }
                        @media #{$lg-layout}{
                            left: -495px;
                        }
                    }
                    &.menu-negative-mrg5 {
                        left: -160px;
                        @media #{$xx-layout}{
                            left: -150px;
                        }
                        @media #{$xl-layout}{
                            left: -250px;
                        }
                        @media #{$lg-layout}{
                            left: -185px;
                        }
                    }
                    &.mega-menu-style-1 {
                        padding: 40px 10px 33px 40px;
                        > li {
                            text-align: left;
                            &.mega-menu-sub-width33 {
                                width: 33.333%;
                            }
                            &.mega-menu-sub-width25 {
                                width: 25%;
                            }
                            &.mega-menu-sub-width20 {
                                width: 20%;
                            }
                            &.mega-menu-sub-width40 {
                                width: 40%;
                            }
                            padding-right: 30px;
                            float: left;
                            display: block;
                            a {
                                &.menu-title {
                                    color: #fff;
                                    font-size: 14px;
                                    padding: 0px 0px 12px 0px;
                                    position: relative;
                                    &:before {
                                        position: absolute;
                                        content: "";
                                        width: 25px;
                                        height: 2px;
                                        background: rgba(255, 255, 255, 0.2);
                                        bottom: -2px;
                                    }
                                }
                            }
                            > ul {
                                margin: 28px 0 0;
                                li {
                                    display: block;
                                    a {
                                        color: #D0D0D0;
                                        font-size: 13px;
                                        display: block;
                                        padding: 8px 0px;
                                        &:hover {
                                            color: $white;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                            > .banner-menu-content-wrap {
                                position: relative;
                                &.default-overlay::before {
                                    background-color: #000;
                                    opacity: .2;
                                    z-index: 1;
                                    pointer-events: none;
                                }
                                a {
                                    img {
                                        width: 100%;
                                    }
                                }
                                .banner-menu-content {
                                    position: absolute;
                                    left: 41px;
                                    bottom: 35px;
                                    z-index: 9;
                                    h2 {
                                        color: #fff;
                                        font-size: 32px;
                                        font-weight: bold;
                                        margin: 0;
                                        letter-spacing: 0px;
                                        line-height: 45px;
                                        transition: all .3s ease 0s;
                                        @media #{$lg-layout} {
                                            font-size: 35px;
                                            line-height: 50px;
                                        }
                                    }
                                }
                                &:hover {
                                    &.default-overlay::before {
                                        background-color: #000;
                                        opacity: .4;
                                        z-index: 1;
                                        pointer-events: none;
                                    }
                                    .banner-menu-content {
                                        h2 {
                                            letter-spacing: 1px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover > ul {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0px;
                }
            }
        }
    }
    &.main-menu-white {
        > nav {
            > ul {
                > li {
                    > a {
                        color: $white;
                    }
                    &:hover > a {
                        color: $theme-color-dark-blue;
                    }
                }
            }
        }
    }
    &.menu-lh-1 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 102px;
                    }
                }
            }
        }
    }
    &.menu-lh-2 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 75px;
                    }
                }
            }
        }
    }
    &.main-menu-padding-1 {
        > nav {
            > ul {
                > li {
                    padding: 0 30px 0 0;
                    @media #{$xx-layout} {
                        padding: 0 20px 0 0;
                    }
                    @media #{$xl-layout} {
                        padding: 0 20px 0 0;
                    }
                    &:last-child {
                        padding: 0 0px 0 0;
                    }
                }
            }
        }
    }
    &.menu-mrg-1 {
        margin-left: 63px;
        @media #{$xx-layout} {
            margin-left: 40px;
        }
        @media #{$xl-layout} {
            margin-left: 30px;
        }
    }
    &.menu-mrg-2 {
        margin-right: 135px;
        @media #{$xx-layout} {
            margin-right: 40px;
        }
        @media #{$xl-layout} {
            margin-right: 30px;
        }
        @media #{$lg-layout} {
            margin-right: 20px;
        }
    }
    &.menu-mrg-3 {
        margin-right: 35px;
    }
}

.categories-dropdown {
    .nice-select-style-1 {
        border-radius: 5px 0px 0px 5px;
        border: solid 1px #F3F3F3;
        font-family: $poppins;
        font-size: 12px;
        font-weight: 500;
        height: 45px;
        line-height: 45px;
        padding-left: 20px;
        padding-right: 20px;
        min-width: 140px;
        color: #232953;
        ul {
            width: 100%;
        }
    }
}

.search-style-2 {
    form {
        .form-search-2 {
            position: relative;
            input {
                background-color:transparent;
                border: 1px solid#F3F3F3;
                height: 45px;
                border-radius: 0 5px 5px 0px;
                font-weight: 300;
                color:#B3B5C2;
                font-size: 14px;
                width: 625px;
                padding: 2px 60px 2px 25px;
                &.width-dec {
                    width: 495px;
                    @media #{$xx-layout} {
                        width: 300px;
                    }
                    @media #{$xl-layout} {
                        width: 300px;
                        padding: 2px 60px 2px 15px;
                    }
                    @media #{$lg-layout} {
                        width: 260px;
                        padding: 2px 60px 2px 15px;
                    }
                }
                @media #{$xx-layout} {
                    width: 300px;
                }
                @media #{$xl-layout} {
                    width: 260px;
                    padding: 2px 60px 2px 15px;
                }
                &::-moz-input-placeholder {
                    color:#B3B5C2;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color:#B3B5C2;
                    opacity: 1;
                }
            }
            button {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                padding: 0;
                background-color: transparent;
                font-size: 20px;
                color: #081348;
            }
        }
    }
    &.search-2-bg-white {
        form {
            .form-search-2 {
                position: relative;
                input {
                    background-color: $white;
                }
            }
        }
    }
}

.search-style-5 {
    form {
        .form-search-5 {
            position: relative;
            input {
                background-color: $white;
                border: solid 1px #F3F3F3;
                height: 45px;
                border-radius: 0 5px 5px 0px;
                font-weight: 300;
                color:#B3B5C2;
                font-size: 14px;
                width: 495px;
                padding: 2px 60px 2px 25px;
                @media #{$xx-layout} {
                    width: 300px;
                }
                @media #{$xl-layout} {
                    width: 260px;
                    padding: 2px 60px 2px 15px;
                }
                &::-moz-input-placeholder {
                    color:#B3B5C2;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color:#B3B5C2;
                    opacity: 1;
                }
            }
            button {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                padding: 0;
                background-color: transparent;
                font-size: 20px;
                color: #081348;
            }
            &.width-dec-lg {
                input {
                    width: 280px;
                }
            }
        }
    }
}




.categories-dropdown {
    .nice-select::after {
        margin-top: -1px;
        border-bottom: 2px solid #232953;
        border-right: 2px solid #232953;
    }
}

.header-bottom-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media #{$lg-layout} {
        display: block;
    }
    .logo-menu-wrap {
        display: flex;
        align-items: center;
        flex: 0 0 50%;
        max-width: 50%;
        @media #{$xx-layout} {
            flex: 0 0 60%;
            max-width: 60%;
        }
        @media #{$xl-layout} {
            flex: 0 0 60%;
            max-width: 60%;
        }
        @media #{$lg-layout} {
            flex: 0 0 100%;
            max-width: 100%;
            justify-content: space-between;
        }
    }
    .header-action-width {
        flex: 0 0 50%;
        max-width: 50%;
        @media #{$xx-layout} {
            flex: 0 0 40%;
            max-width: 40%;
        }
        @media #{$xl-layout} {
            flex: 0 0 40%;
            max-width: 40%;
        }
        @media #{$lg-layout} {
            justify-content: center;
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 30px;
        }
    }
}


.main-wrapper {
    .body-overlay {
        background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active {
        .body-overlay {
            opacity: 1;
            visibility: visible;
        }
    }
} 

.main-wrapper-2 {
    .body-overlay-2 {
        background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active-2 {
        .body-overlay-2 {
            opacity: 1;
            visibility: visible;
        }
    }
} 

.main-wrapper-3 {
    .body-overlay-3 {
        background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active-3 {
        .body-overlay-3 {
            opacity: 1;
            visibility: visible;
        }
    }
} 


.sidebar-cart-active {
	position: fixed;
	top: 0;
	width: 420px;
	padding: 104px 50px 50px;
	min-height: 100vh;
	bottom: 0;
	z-index: 9999;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 250ms ease-in;
	-moz-transition: all 250ms ease-in;
	transform: translate(200px,0);
	box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    background-color: #fff;
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    @media #{$md-layout} {
        width: 350px;
        padding: 104px 30px 50px;
    }
    @media #{$xs-layout} {
        padding: 60px 15px 50px;
        width: 280px;
    }
    .sidebar-cart-all {
        .cart-close {
            position: absolute;
            top: 48px;
            left: 44px;
            font-size: 35px;
            line-height: 30px;
            transition: all 250ms ease-out;
            @media #{$md-layout} {
                left: 30px;
                font-size: 25px;
            }
            @media #{$xs-layout} {
                top: 20px;
                left: 17px;
                font-size: 23px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color-dark-blue;
            }
        }
        .cart-content {
            > h3 {
                font-size: 18px;
                font-weight: 500;
                margin: 0 0 30px;
                color: #393939;
            }
            ul {
                margin: 0 -30px 0 0;
                padding: 0 30px 0 0;
                max-height: 310px;
                max-height: calc(100vh - 430px);
                overflow: auto;
                @media #{$md-layout} {
                    max-height: calc(100vh - 365px);
                }
                @media #{$xs-layout} {
                    max-height: calc(100vh - 360px);
                }
                li {
                    display: flex;
                    margin-bottom: 25px;
                    .cart-img {
                        flex: 0 0 75px;
                        a {
                            img {
                                width: 100%;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                    .cart-title {
                        margin-left: 20px;
                        h4 {
                            font-size: 14px;
                            margin: 0 0 8px;
                            a {
                                color: #333333;
                                &:hover {
                                    color: $theme-color-dark-blue;
                                }
                            }
                        }
                        span {
                            font-size: 14px;
                            color: #262626;
                        }
                    }
                    .cart-delete {
                        display: flex;
                        flex-grow: 100;
                        justify-content: flex-end;
                        a {
                            display: block;
                            font-size: 16px;
                            height: 20px;
                            line-height: 17px;
                            overflow: hidden;
                            width: 20px;
                            text-align: right;
                        }
                    }
                }
            }
            .cart-total {
                display: block;
                width: 100%;
                margin: 18px 0 43px;
                h4 {
                    font-size: 15px;
                    margin: 0;
                    span {
                        font-size: 18px;
                        color: #2F2F2F;
                        float: right;
                    }
                }
            }
            .cart-checkout-btn {
                display: block;
                width: 100%;
                a {
                    width: 100%;
                    display: block;
                    margin: 10px 0 0;
                    text-align: center;
                    line-height: 20px;
                    padding: 19px 20px 20px;
                    background-color: #333;
                    color: #fff;
                    text-transform: capitalize;
                    font-size: 14px;
                    &:hover {
                        background-color: $theme-color-dark-blue;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}


.header-search-3 {
    position: relative;
    .search-toggle {
        z-index: 99;
        i {
            display: block;
            &.s-close {
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .s-close {
            opacity: 0;
        }
        &.open {
            .s-close {
                opacity: 1;
            }
            .s-open {
                opacity: 0;
            }
        }
    }
}

.search-wrap-3 {
    background-color: transparent;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 340px;
    z-index: -99;
    padding-bottom: 1px;
    margin-right: 10px;
    form {
        position: relative;
        overflow: hidden;
        input {
            background-color: #fff;
            border: 1px solid #E2DCDC;
            color: #0A1039;
            line-height: 30px;
            padding: 2px 60px 2px 20px;
            width: 100%;
            &::-moz-input-placeholder {
                color: #0A1039;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #0A1039;
                opacity: 1;
            }
        }
        button.button-search {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            border: none;
            background-color: transparent;
            height: 100%;
            border-left: 1px solid #E2DCDC;
            padding: 0 15px;
        }
    }
    &.open {
        opacity: 1;
        right: 100%;
        visibility: visible;
        z-index: 99;
    }
}

.clickalbe-sidebar-wrapper-style-1 {
	position: fixed;
	top: 0;
	width: 400px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 400ms ease-out;
	transform: translate(200px,0);
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
    z-index: 9999;
    @media #{$md-layout} {
        width: 330px;
    }
    @media #{$xs-layout} {
        width: 290px;
    }
    &.clickalbe-menu-sidebar-left {
        left: 0;
        transform: translate(-200px,0);
    }
    &.sidebar-visible {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    &.mobilemenu-visible {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    & .clickalbe-sidebar-wrap {
        padding: 150px 50px 20px;
        height: 100%;
        @media #{$md-layout} {
            padding: 100px 30px 20px;
        }
        @media #{$xs-layout} {
            padding: 70px 20px 20px;
        }
        .sidebar-close {
            position: absolute;
            top: 80px;
            left: 45px;
            font-size: 30px;
            line-height: 30px;
            transition: all 250ms ease-out;
            color: #0A1039;
            @media #{$md-layout} {
                left: 27px;
                top: 30px;
            }
            @media #{$xs-layout} {
                top: 20px;
                left: 17px;
                font-size: 23px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color-black;
            }
        }
        .sidebar-content-100-percent {
            height: 100%;
            overflow-y: auto;
            padding-right: 10px;
        }
        .clickable-mainmenu-style1 {
            > nav {
                > ul {
                    > li {
                        display: block;
                        padding-bottom: 40px;
                        line-height: 1;
                        @media #{$md-layout} {
                            padding-bottom: 22px;
                        }
                        @media #{$xs-layout} {
                            padding-bottom: 22px;
                        }
                        &:last-child {
                            padding-bottom: 0;
                        }
                        > a {
                            font-size: 18px;
                            display: block;
                            text-transform: capitalize;
                            color: #0A1039;
                            @media #{$md-layout} {
                                font-size: 16px;
                            }
                            @media #{$xs-layout} {
                                font-size: 16px;
                            }
                            i {
                                float: right;
                                top: 3px;
                                position: relative;
                                color: #0A1039;
                                transition: all .3s ease 0s;
                                font-size: 14px;
                                @media #{$xs-layout} {
                                    top: 2px;
                                    font-size: 12px;
                                }
                            }
                        }
                        &:hover > a {
                            color: $theme-color-black;
                        }
                        &:hover > a i {
                            color: $theme-color-black;
                        }
                        ul {
                            padding: 38px 0 0px 25px;
                            li {
                                padding-bottom: 22px;
                                @media #{$xs-layout} {
                                    padding-bottom: 18px;
                                }
                                &:last-child {
                                    padding-bottom: 0;
                                }
                                a {
                                    color: #0A1039;
                                    font-size: 14px;
                                    display: block;
                                    
                                    i {
                                        float: right;
                                        top: -1px;
                                        position: relative;
                                        @media #{$xs-layout} {
                                            top: 2px;
                                            font-size: 12px;
                                        }
                                    }
                                }
                                &:hover > a {
                                    color: $theme-color-black;
                                }
                                &:hover > a i {
                                    color: $theme-color-black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-menu-content-area {
    .mobile-search {
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        margin-bottom: 29px;
        padding-bottom: 30px;
        form {
            position: relative;
            input {
                background-color: #fff;
                border: 1px solid #E2DCDC;
                color: #0A1039;
                border-radius: 0px;
                height: 45px;
                padding: 0 60px 0 15px;
                width: 100%;
                font-size: 14px;
            }
            button {
                background-color: transparent;
                border-color: #E2DCDC;
                border-image: none;
                border-radius: 5px 0 0 5px;
                border-style: none none none solid;
                border-width: medium medium medium 1px;
                color: #0A1039;
                font-size: 15px;
                height: 100%;
                padding: 0 15px 0 14px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: all 0.3s ease 0s;
                @media #{$xs-layout} {
                    font-size: 16px;
                }
                &:hover{
                    color: $theme-color-black;
                }
                i {
                    margin-top: 5px;
                    display: inline-block;
                }
            }
        }
    }
    .mobile-curr-lang-wrap {
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
        margin-bottom: 31px;
        padding-bottom: 37px;
        border-top: 1px solid rgba(0, 0, 0, 0.09);
        padding-top: 25px;
        margin-top: 33px;
        .single-mobile-curr-lang {
            position: relative;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                font-size: 15px;
                display: block;
                color: #0A1039;
                i {
                    float: right;
                    font-size: 17px;
                    position: relative;
                    top: 7px;
                }
            }
            .lang-curr-dropdown {
                margin-top: 5px;
                display: none;
                background-color: rgba(255, 255, 255, 0.05);
                box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
                padding: 22px 30px 26px;
                width: 100%;
                z-index: 11;
                ul {
                    li {
                        padding-bottom: 10px;
                        &:last-child {
                            padding-bottom: 0px;
                        }
                        a {
                            font-size: 14px;
                            font-weight: 400;
                            color: #0A1039;
                            &:hover {
                                color: $theme-color-black;
                            }
                        }
                    }
                }
            }
            &:hover > a {
                color: $theme-color-black;
            }
            @media #{$xs-layout} {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .aside-contact-info {
        margin: 30px 0 30px;
        ul {
            li {
                color: #0A1039;
                display: flex;
                margin: 0 0 23px;
                transition: all .3s ease 0s;
                &:last-child {
                    margin: 0 0 0px;
                }
                &:hover {
                    color: $theme-color-black;
                }
                i {
                    font-size: 22px;
                    margin: 1px 16px 0px 0;
                }
            }
        }
    }
}


.search-style-1 {
    form {
        .form-search-1 {
            position: relative;
            input {
                background-color: transparent;
                width: 450px;
                font-weight: 300;
                color: #B3B5C2;
                border: none;
                height: 42px;
                padding: 2px 40px 2px 0;
                border-bottom: 1px solid #D9DFE6;
                @media #{$xx-layout} {
                    width: 350px;
                }
                @media #{$xl-layout} {
                    width: 280px;
                }
            }
            button {
                padding: 0;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                font-size: 20px;
                color: #081348;
                transition: all .3s ease 0s;
                background-color: transparent;
                &:hover {
                    color: #444E7A;
                }
            }
        }
    }
}


.search-style-6 {
    form {
        .form-search-6 {
            position: relative;
            input {
                background-color: #F2F8F3;
                height: 45px;
                border: none;
                border-radius: 50px;
                color: #B3B5C2;
                font-weight: 300;
                padding: 2px 50px 2px 33px;
                &.input-bg-gray {
                    background-color: #F7F7F7;
                }
                &::-moz-input-placeholder {
                    color:#B3B5C2;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color:#B3B5C2;
                    opacity: 1;
                }
            }
            button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                border: none;
                padding: 0;
                color: #939393;
                font-size: 20px;
                &:hover {
                    color: $theme-color-dark-blue;
                }
            }
        }
    }
}

.search-style-7 {
    form {
        .form-search-7 {
            position: relative;
            input {
                background-color: #fff;
                height: 40px;
                border: none;
                border-radius: 5px;
                color: #B3B5C2;
                font-weight: 300;
                padding: 2px 50px 2px 33px;
                width: 494px;
                @media #{$xx-layout} {
                    width: 400px;
                }
                @media #{$xl-layout} {
                    width: 350px;
                }
                @media #{$lg-layout} {
                    width: 200px;
                    padding: 2px 50px 2px 10px;
                }
                &::-moz-input-placeholder {
                    color:#B3B5C2;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color:#B3B5C2;
                    opacity: 1;
                }
            }
            button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                border: none;
                padding: 0;
                color: #081348;
                font-size: 20px;
                background-color: transparent;
                &:hover {
                    color: $theme-color-brown;
                }
            }
        }
    }
    @media #{$lg-layout} {
        &.ml-75 {
            margin-left: 15px;
        }
    }
}

.header-action-white-bg {
    position: relative;
    &::before {
        position: absolute;
        right: -32px;
        top: -37px;
        content: "";
        height: 91px;
        width: 338px;
        background-color: #fff;
        @media #{$xl-layout} {
            right: -15px;
        }
        @media #{$lg-layout} {
            right: -30px;
        }
    }
    .same-style {
        z-index: 1;
    }
}

.pet-food-header {
    &::before {
        background-image: url(../../assets/images/icon-img/pet-food-header-shape.png);
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0px;
        background-repeat: repeat;
        display: block;
        width: 100%;
        height: 6px;
        z-index: 9;
    }
    @media #{$md-layout} {
        &::before {
            display: none;
        }
    }
    @media #{$xs-layout} {
        &::before {
            display: none;
        }
    }
}

.logo-menu-wrap-2 {
	display: flex;
	align-items: center;
}
.header-slider-wrap {
	display: block;
	width: 100%;
}

.header-sidebar-area {
	width: 285px;
    height: 100vh;
    float: left;
	padding: 30px 0px 60px 48px;
    @media #{$xx-layout} {
        width: 230px;
        padding: 30px 0px 60px 30px;
    }
    @media #{$xl-layout} {
        width: 220px;
        padding: 30px 0px 60px 30px;
    }
    @media #{$lg-layout} {
        width: 180px;
        padding: 30px 0px 60px 20px;
    }
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
}

.header-sidebar-area-2 {
    width: 65px;
    height: 780px;
    float: left;
	padding: 40px 24px 40px 24px;
    overflow-y: auto;
    @media #{$xl-layout} {
        height: 771px;
    }
    @media #{$lg-layout} {
        height: 625px;
    }
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
    .header-sidebar-wrap-2 {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }
}



.main-menu-2 {
    > nav {
        > ul {
            > li {
                padding-bottom: 36px;
                position: relative;
                @media #{$xx-layout} {
                    padding-bottom: 25px;
                }
                @media #{$xl-layout} {
                    padding-bottom: 25px;
                }
                @media #{$lg-layout} {
                    padding-bottom: 25px;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                > a {
                    display: block;
                    color: #777777;
                    font-size: 16px;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: "";
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 4px;
                        height: 0px;
                        background-color: #151C0F;
                        z-index: 2;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .5s ease 0s;
                    }
                }
                &:hover > a {
                    color: #333;
                    font-weight: bold;
                    &:before {
                        height: 32px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
                > ul {
                    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
                    background-color: #262626;
                    position: absolute;
                    top: -5px;
                    left: 100%;
                    transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
                    opacity: 0;
                    visibility: hidden;
                    margin-top: 20px;
                    z-index: 9999;
                    &.sub-menu-width {
                        width: 220px;
                        padding: 39px 0 43px;
                        li {
                            display: block;
                            padding: 0 15px 14px 30px;
                            position: relative;
                            text-align: left;
                            &:last-child {
                                padding-bottom: 0;
                            }
                            a {
                                display: block;
                                color: #D0D0D0;
                                font-size: 13px;
                                font-weight: 300;
                                i {
                                    float: right;
                                    font-size: 12px;
                                    color: #999;
                                    font-weight: 600;
                                    position: relative;
                                    top: 6px;
                                }
                                &:hover {
                                    color: $white;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    &.mega-menu-width1 {
                        width: 675px;
                    }
                    &.mega-menu-width2 {
                        width: 1170px;
                        @media #{$xx-layout}{
                            width: 1050px;
                        }
                        @media #{$xl-layout}{
                            width: 950px;
                        }
                        @media #{$lg-layout}{
                            width: 790px;
                        }
                    }
                    &.mega-menu-style-2 {
                        padding: 40px 10px 33px 40px;
                        > li {
                            text-align: left;
                            &.mega-menu-sub-width33 {
                                width: 33.333%;
                            }
                            &.mega-menu-sub-width25 {
                                width: 25%;
                            }
                            &.mega-menu-sub-width20 {
                                width: 20%;
                            }
                            &.mega-menu-sub-width40 {
                                width: 40%;
                            }
                            padding-right: 30px;
                            float: left;
                            display: block;
                            a {
                                &.menu-title {
                                    color: #fff;
                                    font-size: 14px;
                                    padding: 0px 0px 12px 0px;
                                    position: relative;
                                    &:before {
                                        position: absolute;
                                        content: "";
                                        width: 25px;
                                        height: 2px;
                                        background: rgba(255, 255, 255, 0.2);
                                        bottom: -2px;
                                    }
                                }
                            }
                            > ul {
                                margin: 28px 0 0;
                                li {
                                    display: block;
                                    a {
                                        color: #D0D0D0;
                                        font-size: 13px;
                                        display: block;
                                        padding: 8px 0px;
                                        &:hover {
                                            color: $white;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                            > .banner-menu-content-wrap {
                                position: relative;
                                &.default-overlay::before {
                                    background-color: #000;
                                    opacity: .2;
                                    z-index: 1;
                                    pointer-events: none;
                                }
                                a {
                                    img {
                                        width: 100%;
                                    }
                                }
                                .banner-menu-content {
                                    position: absolute;
                                    left: 41px;
                                    bottom: 35px;
                                    z-index: 9;
                                    h2 {
                                        color: #fff;
                                        font-size: 32px;
                                        font-weight: bold;
                                        margin: 0;
                                        letter-spacing: 0px;
                                        line-height: 45px;
                                        transition: all .3s ease 0s;
                                        @media #{$lg-layout} {
                                            font-size: 35px;
                                            line-height: 50px;
                                        }
                                    }
                                }
                                &:hover {
                                    &.default-overlay::before {
                                        background-color: #000;
                                        opacity: .4;
                                        z-index: 1;
                                        pointer-events: none;
                                    }
                                    .banner-menu-content {
                                        h2 {
                                            letter-spacing: 1px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover > ul {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0px;
                }
            }
        }
    }
    &.sidebar-menu-padding {
        padding: 90px 0 265px 0px;
        @media #{$xx-layout} {
            padding: 90px 0 100px 0px;
        }
        @media #{$xl-layout} {
            padding: 90px 0 100px 0px;
        }
        @media #{$lg-layout} {
            padding: 90px 0 90px 0px;
        }
    }
}

.header-social-icon {
    a {
        font-size: 16px;
        color: #DDDDDD;
        margin-bottom: 55px;
        display: block;
        @media #{$lg-layout} {
            margin-bottom: 25px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            color: $theme-color-dark-blue;
        }
    }
}


.search-content-wrap {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	background-color: rgba(0,0,0,.9);
	text-align: center;
	transition: all ease-in-out .25s;
	z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    &.search-visible {
        visibility: visible;
	    opacity: 1;
        & .megashop-search-content {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
        }
    }
    > .megashop-search-close {
        font-size: 40px;
        display: block;
        position: absolute;
        top: 20%;
        right: 20%;
        line-height: 40px;
        height: 40px;
        transition: all .3s;
        color: rgba(255,255,255,.8);
        &:hover {
            transform: rotate(90deg);
        }
        i {
            &:hover {
                color: rgba(255,255,255,1);
            }
        }
        
    }
    & .megashop-search-content {
        display: inline-block;
        max-width: 90%;
        text-align: center;
        font-size: 18px;
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out .3s;
        transform: scale(.9);
        p{
           font-size: 18px;
            margin: 0 0 15px;
            text-transform: uppercase;
            color: rgba(255,255,255,.8);
            font-weight: 400;
        }
        .megashop-search-form {
            position: relative;
            input {
                width: 800px;
                background-color: transparent;
                box-shadow: 0 3px 0 0 rgba(255,255,255,.1);
                border: 0;
                text-align: center;
                font-size: 30px;
                padding: 34px 60px 34px 36px;
                color: rgba(255,255,255,.8);
                transition: all .3s ease-out;
                font-weight: 400;
                max-width: 100%;
                height: auto;
            }
            .megashop-button-search {
                position: absolute;
                top: 43px;
                display: block;
                right: 5px;
                border: 0;
                background: 0 0;
                padding: 0;
                color: rgba(255,255,255,.8);
                font-size: 28px;
                height: 30px;
                &:hover {
                    color: rgba(255,255,255,1);
                }
            }
        }
    }
}

.header-bg-1 {
    background: linear-gradient(to right, #FBFBFB, #FBFBFB 50%, #fff 0%);
}













