/*-----------------------------------------------------------------------------------
    
    Template Name: Dking - Multipurpose eCommerce HTML Template
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Coupon style
    5. Banner style
    6. Product style
    7. Animation style
    8. Deal product style
    9. Section title style
    10. Categories style
    11. Services style
    12. Brand logo style
    13. Blog style 
    14. Footer style
    15. Subscribe style
    16. Product details style
    17. Instagram style
    18. Testimonial style
    19. About style
    20. Sidebar style
    21. Shop style
    22. Breadcrumb style
    23. Contact style
    24. Cart style
    25. Login register style
    26. My account style
    27. Checkout style
    28. Order complete style
    29. Compare style
    30. Order tracking style
    31. Others style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Bubblegum+Sans|Rochester|Chewy|Montserrat:300,400,500,600,700,800,900|Pacifico|Poppins:300,400,500,600,700,800,900|Work+Sans:300,400,500,600,700,800,900&display=swap");
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333333;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
  font-weight: 400;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #777;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-130 {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-130 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .mt-130 {
    margin-top: 60px;
  }
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-175 {
  margin-top: 175px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-37 {
  margin-bottom: 37px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-160 {
  margin-bottom: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-160 {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-160 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .mb-160 {
    margin-bottom: 60px;
  }
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-180 {
  margin-bottom: 180px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-130 {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-130 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-130 {
    padding-top: 30px;
  }
}

.pt-135 {
  padding-top: 135px;
}

.pt-165 {
  padding-top: 165px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-165 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-165 {
    padding-top: 45px;
  }
}

.pt-140 {
  padding-top: 140px;
}

@media only screen and (max-width: 767px) {
  .pt-140 {
    padding-top: 55px;
  }
}

.pt-145 {
  padding-top: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-145 {
    padding-top: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-145 {
    padding-top: 35px;
  }
}

.pt-150 {
  padding-top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-150 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-150 {
    padding-top: 60px;
  }
}

.pt-155 {
  padding-top: 155px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-155 {
    padding-top: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-155 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-155 {
    padding-top: 55px;
  }
}

.pt-160 {
  padding-top: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-160 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-160 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-160 {
    padding-top: 60px;
  }
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-180 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-180 {
    padding-top: 50px;
  }
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

@media only screen and (max-width: 767px) {
  .pt-190 {
    padding-top: 80px;
  }
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

@media only screen and (max-width: 767px) {
  .pt-200 {
    padding-top: 70px;
  }
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-250 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-250 {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-250 {
    padding-top: 60px;
  }
}

.pt-255 {
  padding-top: 255px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-255 {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-255 {
    padding-top: 80px;
  }
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-340 {
  padding-top: 340px;
}

.pt-350 {
  padding-top: 350px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-350 {
    padding-top: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-350 {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-350 {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pt-350 {
    padding-top: 120px;
  }
}

.pt-365 {
  padding-top: 365px;
}

.pt-360 {
  padding-top: 360px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-360 {
    padding-top: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-360 {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-360 {
    padding-top: 100px;
  }
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

.pt-400 {
  padding-top: 400px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 20px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-125 {
    padding-bottom: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-125 {
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-125 {
    padding-bottom: 25px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-130 {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-130 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-130 {
    padding-bottom: 30px;
  }
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-135 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 60px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

@media only screen and (max-width: 767px) {
  .pb-140 {
    padding-bottom: 60px;
  }
}

.pb-145 {
  padding-bottom: 145px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-145 {
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-145 {
    padding-bottom: 20px;
  }
}

.pb-150 {
  padding-bottom: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-150 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-150 {
    padding-bottom: 50px;
  }
}

.pb-155 {
  padding-bottom: 155px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-155 {
    padding-bottom: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-155 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-155 {
    padding-bottom: 55px;
  }
}

.pb-160 {
  padding-bottom: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-160 {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-160 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-160 {
    padding-bottom: 60px;
  }
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-180 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-180 {
    padding-bottom: 50px;
  }
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-250 {
  padding-bottom: 250px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-250 {
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-250 {
    padding-bottom: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-250 {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pb-250 {
    padding-bottom: 100px;
  }
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pb-315 {
  padding-bottom: 315px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-315 {
    padding-bottom: 215px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-315 {
    padding-bottom: 120px;
  }
}

/*************************
    Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

.custom-d-flex {
  display: flex !important;
}

.custom-align-item-center {
  align-items: center;
}

.custom-align-item-end {
  align-items: flex-end;
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-md-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-5.custom-col-xs-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5.custom-col-sm-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.custom-col-5-2 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5-2.custom-col-md-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5-2 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .custom-col-5-2.custom-col-xs-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .custom-col-5-2.custom-col-sm-33 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.custom-col-8 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-8 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #262626;
  color: #fff;
  right: 50px;
  bottom: 53px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
    right: 20px;
  }
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 22px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

.height-100vh {
  height: 100vh;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

/*--
    Background Color
------------------------------------------*/
.bg-white {
  background-color: #fff;
}

.bg-gray {
  background-color: #FAFAFA;
}

.bg-gray-2 {
  background-color: #FBFBFB;
}

.bg-gray-3 {
  background-color: #F3F3F3;
}

.bg-gray-4 {
  background-color: #EFEFEF;
}

.bg-gray-5 {
  background-color: #F8F8F8;
}

.bg-gray-6 {
  background-color: #F9F9F9;
}

.bg-gray-7 {
  background-color: #F6F6F6;
}

.bg-gray-8 {
  background-color: #FAFBFC;
}

.bg-gray-9 {
  background-color: #F7F7F7;
}

.bg-brown {
  background-color: #FCF9F2;
}

.gradient-bg-1 {
  background: linear-gradient(180deg, #12126a 0%, #360fbb 100%);
}

.gradient-bg-2 {
  background: linear-gradient(0deg, #fff8dd 0%, white 100%);
}

.gradient-bg-3 {
  background: linear-gradient(270deg, #b7f0c0 0%, white 100%);
}

.gradient-bg-4 {
  background: linear-gradient(193deg, white 0%, #e8eeff 100%);
}

.gradient-bg-5 {
  background: linear-gradient(90deg, #e8ffdc 0%, #fffbe9 100%);
}

.gradient-bg-6 {
  background: linear-gradient(184deg, #fcf9f2 0%, #fce4d3 100%);
}

.gradient-bg-7 {
  background: linear-gradient(0deg, #f86cff 0%, #00bdff 100%);
}

.bg-light-green {
  background-color: #F5FFF7;
}

.bg-light-green-2 {
  background-color: #F2F8F3;
}

.bg-light-green-3 {
  background-color: #DDEDD2;
}

.bg-light-yellow {
  background-color: #FFF9F0;
}

.bg-light-orange {
  background-color: #FCF5ED;
}

.bg-light-pink {
  background-color: #FFF8F8;
}

.bg-blue {
  background-color: #453FEE;
}

.bg-blue-2 {
  background-color: #5D58FE;
}

.bg-theme-color-black {
  background-color: #333333;
}

.bg-theme-color-yellow {
  background-color: #EBA866;
}

.bg-theme-color-green-3 {
  background-color: #62BC77;
}

.bg-theme-color-pink {
  background-color: #FF437D;
}

.bg-theme-color-brown {
  background-color: #CF7F39;
}

.border-top-1 {
  border-top: 1px solid #FAFAFA;
}

.border-top-2 {
  border-top: 1px solid #EEEEEE;
}

.border-top-3 {
  border-top: 1px solid #5853E8;
}

.border-top-4 {
  border-top: 1px solid #F2F2F2;
}

.border-top-5 {
  border-top: 1px solid #F1F1F1;
}

.default-overlay {
  position: relative;
}

.default-overlay:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .4;
    z-index: 9;
  }
}

@media only screen and (max-width: 767px) {
  .res-black-overly-xs {
    position: relative;
  }
  .res-black-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
    opacity: .5;
    z-index: 9;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

.jarallax-img {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-padding-1 .container-fluid {
  padding: 0 55px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

.section-padding-3 .container-fluid {
  padding: 0 0px 0 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-3 .container-fluid {
    padding: 0 0px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-3 .container-fluid {
    padding: 0 0px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-3 .container-fluid {
    padding: 0 0px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-3 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

.section-padding-4 .container-fluid {
  padding: 0 0px 0 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-4 .container-fluid {
    padding: 0 0px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-4 .container-fluid {
    padding: 0 0px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-4 .container-fluid {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-4 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

.section-padding-5 .container-fluid {
  padding: 0 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-5 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-5 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-5 .container-fluid {
    padding: 0 30px;
  }
}

.section-padding-6 .container-fluid {
  padding: 0 0px 0 366px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-6 .container-fluid {
    padding: 0 0px 0 97px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-6 .container-fluid {
    padding: 0 0px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-6 .container-fluid {
    padding: 0 0px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-6 .container-fluid {
    padding: 0 0px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-6 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

.section-padding-7 .container-fluid {
  padding: 0 366px 0 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-7 .container-fluid {
    padding: 0 97px 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-7 .container-fluid {
    padding: 0 30px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-7 .container-fluid {
    padding: 0 40px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-7 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

.section-padding-8 .container-fluid {
  padding: 0 0px 0 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-8 .container-fluid {
    padding: 0 0px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-8 .container-fluid {
    padding: 0 0px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-8 .container-fluid {
    padding: 0 0px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-8 .container-fluid {
    padding: 0 0px 0 0px;
  }
}

.section-padding-9 .container-fluid {
  padding: 0 32px 0 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-9 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-9 .container-fluid {
    padding: 0 30px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-9 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

.section-padding-10 .container-fluid {
  padding: 0 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-10 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-10 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-10 .container-fluid {
    padding: 0 30px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-10 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

.section-padding-11 .container-fluid {
  padding: 0 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-11 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-11 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-11 .container-fluid {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-11 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

.section-padding-12 .container-fluid {
  padding: 0 90px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-12 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-12 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-12 .container-fluid {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-12 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

.section-padding-13 .container-fluid {
  padding: 0 0px 0 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-13 .container-fluid {
    padding: 0 0px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-13 .container-fluid {
    padding: 0 0px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-13 .container-fluid {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-13 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-13 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

.section-padding-14 .container-fluid {
  padding: 0 366px 0 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-14 .container-fluid {
    padding: 0 55px 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-14 .container-fluid {
    padding: 0 15px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-14 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-14 .container-fluid {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-14 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-14 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

.section-padding-15 .container-fluid {
  padding: 0 0px 0 366px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-15 .container-fluid {
    padding: 0 0px 0 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-15 .container-fluid {
    padding: 0 0px 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-15 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-15 .container-fluid {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-15 .container-fluid {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-15 .container-fluid {
    padding: 0 30px 0 30px;
  }
}

.btn-hover a::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0A1039;
  transform: scaleY(0);
  transform-origin: 50%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.btn-hover a:hover::before {
  transform: scaleY(1);
}

@media only screen and (max-width: 767px) {
  .xs-mb-30 {
    margin-bottom: 30px;
  }
}

.btn-style-1 a {
  display: inline-block;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  position: relative;
  z-index: 2;
}

.btn-style-1 a.btn-border-1 {
  border: 1px solid #3A3A3A;
}

.btn-style-1 a.btn-border-1:hover {
  border: 1px solid #0A1039;
}

.btn-style-1 a.btn-ptb-1 {
  padding: 18px 50px 19px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-ptb-1 {
    padding: 14px 31px 15px;
  }
}

.btn-style-1 a.btn-ptb-2 {
  padding: 19px 30px 21px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-1 a.btn-ptb-2 {
    padding: 19px 24px 21px;
  }
}

.btn-style-1 a.btn-ptb-3 {
  padding: 19px 43px 21px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-1 a.btn-ptb-3 {
    padding: 19px 25px 21px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-ptb-3 {
    padding: 19px 25px 21px;
  }
}

.btn-style-1 a.btn-1-bg-1 {
  background-color: #F3F3F3;
}

.btn-style-1 a.btn-ptb-4 {
  padding: 17px 50px 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-style-1 a.btn-ptb-4 {
    padding: 19px 25px 21px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-1 a.btn-ptb-4 {
    padding: 19px 25px 21px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-ptb-4 {
    padding: 19px 25px 21px;
  }
}

.btn-style-1 a:hover {
  color: #fff;
}

.btn-style-2 a {
  display: inline-block;
  color: #222D39;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  position: relative;
  z-index: 2;
}

.btn-style-2 a.btn-ptb-1 {
  padding: 18px 36px 19px;
}

@media only screen and (max-width: 767px) {
  .btn-style-2 a.btn-ptb-1 {
    padding: 14px 31px 15px;
  }
}

.btn-style-2 a.btn-ptb-2-white-bg {
  background-color: #ffffff;
}

.btn-style-2 a:hover {
  color: #fff;
}

.btn-style-3 a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1;
  padding: 20px 48px 20px;
  z-index: 2;
  position: relative;
  background-color: #EBA866;
}

.btn-style-3 a.btn3-bg-green {
  background-color: #97D694;
}

.btn-style-3 a.btn3-bg-chocolate {
  background-color: #885643;
}

.btn-style-3 a.btn3-bg-green-2 {
  background-color: #359043;
}

.btn-style-3 a.btn3-bg-green-3 {
  background-color: #62BC77;
}

.btn-style-3 a.btn3-bg-brown {
  background-color: #CF8A35;
}

.btn-style-3 a.btn3-bg-white {
  background-color: #ffffff;
}

.btn-style-3 a.btn3-text-blue {
  color: #222D39;
}

.btn-style-3 a.btn3-text-blue:hover {
  color: #ffffff;
}

.btn-style-3 a.btn3-text-black {
  color: #262836;
}

.btn-style-3 a.btn3-text-black:hover {
  color: #ffffff;
}

.btn-style-3 a i {
  position: relative;
  font-weight: bold;
  font-size: 20px;
  top: 2px;
  margin-left: 9px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a {
    font-size: 14px;
  }
}

.btn-style-3 a.btn3-ptb-1 {
  padding: 20px 48px 20px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn3-ptb-1 {
    padding: 15px 35px 15px;
  }
}

.btn-style-3 a.btn3-ptb-2 {
  padding: 20px 35px 20px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn3-ptb-2 {
    padding: 15px 35px 15px;
  }
}

.btn-style-3 a.btn3-ptb-3 {
  padding: 18px 22px 20px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn3-ptb-3 {
    padding: 15px 22px 15px;
  }
}

.btn-style-3 a.btn3-ptb-4 {
  padding: 13px 35px 14px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .btn-style-3 a.btn3-ptb-4 {
    padding: 13px 30px 14px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn3-ptb-4 {
    padding: 15px 22px 15px;
  }
}

.btn-style-3 a.btn3-ptb-5 {
  padding: 20px 42px 20px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn3-ptb-5 {
    padding: 15px 35px 15px;
  }
}

.btn-style-3 a.btn3-ptb-6 {
  padding: 18px 34px 20px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn3-ptb-6 {
    padding: 15px 35px 15px;
  }
}

.btn-style-3 a.btn3-ptb-7 {
  padding: 20px 57px 20px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn3-ptb-7 {
    padding: 15px 35px 15px;
  }
}

.btn-style-3 a.btn3-ptb-8 {
  padding: 13px 30px 14px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a.btn3-ptb-8 {
    padding: 15px 22px 15px;
  }
}

.padding-25-row-col .row {
  margin-right: -12.5px;
  margin-left: -12.5px;
}

.padding-25-row-col .row div[class^="col-"] {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.padding-100-row-col .row {
  margin-right: -50px;
  margin-left: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-100-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-100-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-100-row-col .row {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-100-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-100-row-col .row div[class^="col-"] {
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-100-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-100-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-100-row-col .row div[class^="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-100-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-40-row-col .row {
  margin-right: -20px;
  margin-left: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-40-row-col .row {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-40-row-col .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-40-row-col .row div[class^="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-40-row-col .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-3-row-col .row {
  margin-right: -1.5px;
  margin-left: -1.5px;
}

.padding-3-row-col .row div[class^="col-"] {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-16-row-col .row {
  margin-right: -8px;
  margin-left: -8px;
}

.padding-16-row-col .row div[class^="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}

.padding-20-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

.padding-20-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

/* img bounce */
@-webkit-keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* img bounce 2 */
@-webkit-keyframes bounce-img-2 {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes bounce-img-2 {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  50% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

/* img zoom */
@keyframes playbutton-effect {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

/*---------- Header style------------*/
.header-small-device {
  display: none;
}

.header-small-device.header-small-ptb {
  padding: 22px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-device {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-small-device {
    display: block;
  }
}

.header-large-device {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-large-device {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-large-device {
    display: none;
  }
}

.header-ptb-1 {
  padding: 22px 0;
}

@media only screen and (max-width: 767px) {
  .header-ptb-1 {
    padding: 15px 0;
  }
}

.header-ptb-2 {
  padding: 17px 0;
}

@media only screen and (max-width: 767px) {
  .header-ptb-2 {
    padding: 15px 0;
  }
}

.header-ptb-3 {
  padding: 30px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-ptb-3 {
    padding: 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-ptb-3 {
    padding: 0px 0 0;
  }
}

.header-ptb-4 {
  padding: 32px 0 32px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-ptb-4 {
    padding: 22px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-ptb-4 {
    padding: 22px 0;
  }
}

.logo a {
  display: inline-block;
}

.logo-position {
  position: relative;
}

.logo-position a img.sticky-logo {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
}

.stick .logo-position a img.sticky-logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  opacity: 1;
  visibility: visible;
}

.stick .logo-position a img.normal-logo {
  opacity: 0;
  visibility: hidden;
}

.header-action-wrap.header-action-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-action-wrap.header-action-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-action-wrap.header-action-mrg-1 .same-style {
  margin-left: 55px;
}

@media only screen and (max-width: 767px) {
  .header-action-wrap.header-action-mrg-1 .same-style {
    margin-left: 35px;
  }
}

.header-action-wrap.header-action-mrg-2 .same-style {
  margin-left: 35px;
}

@media only screen and (max-width: 767px) {
  .header-action-wrap.header-action-mrg-2 .same-style {
    margin-left: 35px;
  }
}

.header-action-wrap.header-action-mrg-3 .same-style {
  margin-bottom: 37px;
}

.header-action-wrap.header-action-mrg-3 .same-style:last-child {
  margin-bottom: 0px;
}

.header-action-wrap .same-style a {
  font-size: 20px;
  color: #0A1039;
}

.header-action-wrap .same-style a:hover {
  color: #444E7A;
}

.header-action-wrap .same-style.same-style-white a {
  color: #fff;
}

.header-action-wrap .same-style.same-style-white a:hover {
  color: #ddd;
}

.header-action-wrap .same-style.same-style-gray a {
  color: #AFAFAF;
}

.header-action-wrap .same-style.same-style-gray a:hover {
  color: #444E7A;
}

.header-action-wrap .header-info button {
  position: relative;
  width: 20px;
  height: 20px;
  line-height: 1;
  vertical-align: middle;
  padding: 0;
  background-color: transparent;
  border: none;
}

.header-action-wrap .header-info button span {
  background-color: #0A1039;
  border-radius: 2px;
  left: 0;
  display: block;
  position: absolute;
  transition: .4s;
  height: 2px;
}

.header-action-wrap .header-info button span:first-child {
  width: 12px;
  top: 0;
}

.header-action-wrap .header-info button span:nth-child(2) {
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.header-action-wrap .header-info button span:last-child {
  width: 19px;
  top: 17px;
}

.header-action-wrap .header-info button:hover span:first-child {
  width: 20px;
}

.header-action-wrap .header-info button:hover span:nth-child(2) {
  width: 19px;
}

.header-action-wrap .header-info button:hover span:last-child {
  width: 9px;
}

.header-action-wrap .header-info.header-info-white button span {
  background-color: #fff;
}

.header-action-wrap .header-info.header-info-gray button span {
  background-color: #AFAFAF;
}

.header-action-wrap .header-info.header-info-gray button:hover span {
  background-color: #62BC77;
}

.stick .header-action-wrap .same-style.same-style-white a {
  color: #0A1039;
}

.stick .header-action-wrap .same-style.same-style-white a:hover {
  color: #444E7A;
}

.stick .header-action-wrap .same-style.pet-food-cart.same-style-white a {
  color: #fff;
}

.stick .header-action-wrap .same-style.pet-food-cart.same-style-white a:hover {
  color: #0A1039;
}

.stick .header-action-wrap .header-info.header-info-white button span {
  background-color: #0A1039;
}

.stick .header-action-white-bg::before {
  display: none;
}

.sticky-bar.stick {
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  background-color: #ffffff;
  border-bottom: 0 solid #4a90e2;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  transition: all .3s ease 0s;
}

.sticky-bar.pet-food-header.stick {
  background-color: #CF7F39;
}

.header-top-ptb-1 {
  padding: 4px 0px 5px;
}

.header-contact-number span {
  color: #232953;
  font-size: 13px;
  font-weight: 300;
}

.header-contact-number.header-contact-number-white span {
  color: #fff;
}

.header-top-right .language-wrap {
  position: relative;
}

.header-top-right .language-wrap a {
  display: block;
}

.header-top-right .language-wrap a img {
  max-width: 100%;
}

.header-top-right .language-wrap .language-dropdown {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
  left: 0;
  padding: 8px 12px 10px;
  position: absolute;
  text-align: left;
  top: 120%;
  width: 80px;
  z-index: 9;
  display: none;
}

.header-top-right .language-wrap .language-dropdown ul li {
  padding: 0 0 3px;
}

.header-top-right .language-wrap .language-dropdown ul li:last-child {
  padding: 0 0 0px;
}

.header-top-right .language-wrap .language-dropdown ul li a {
  color: #232953;
  font-size: 13px;
  font-weight: 300;
  text-transform: capitalize;
}

.header-top-right .language-wrap .language-dropdown ul li a:hover {
  color: #0A1039;
}

.header-top-right .login-reg ul {
  display: flex;
}

.header-top-right .login-reg ul li {
  position: relative;
  margin-right: 40px;
}

.header-top-right .login-reg ul li:before {
  position: absolute;
  content: "";
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #232953;
  width: 1px;
  height: 13px;
}

.header-top-right .login-reg ul li:last-child {
  margin-right: 0;
}

.header-top-right .login-reg ul li:last-child:before {
  display: none;
}

.header-top-right .login-reg ul li a {
  font-weight: 300;
  color: #232953;
  font-size: 13px;
}

.header-top-right .login-reg ul li a:hover {
  color: #0A1039;
}

.header-top-right .login-reg.login-reg-white ul li:before {
  background-color: #ddd;
}

.header-top-right .login-reg.login-reg-white ul li:last-child {
  margin-right: 0;
}

.header-top-right .login-reg.login-reg-white ul li:last-child:before {
  display: none;
}

.header-top-right .login-reg.login-reg-white ul li a {
  color: #fff;
}

.header-top-right .login-reg.login-reg-white ul li a:hover {
  color: #ddd;
}

.header-top-right.header-top-flex {
  display: flex;
  justify-content: flex-end;
}

.main-menu > nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-menu > nav > ul > li > a {
  font-size: 16px;
  color: #060A27;
  display: inline-block;
}

.main-menu > nav > ul > li:hover > a {
  color: #0A1039;
}

.main-menu > nav > ul > li > ul {
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  position: absolute;
  top: 100%;
  left: 0;
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  z-index: 9999;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul {
    left: -10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul {
    left: -40px;
  }
}

.main-menu > nav > ul > li > ul.sub-menu-width {
  width: 220px;
  padding: 39px 0 43px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.sub-menu-width {
    width: 180px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.sub-menu-width {
    width: 180px;
  }
}

.main-menu > nav > ul > li > ul.sub-menu-width li {
  display: block;
  padding: 0 15px 14px 30px;
  position: relative;
  text-align: left;
}

.main-menu > nav > ul > li > ul.sub-menu-width li:last-child {
  padding-bottom: 0;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a {
  display: block;
  color: #D0D0D0;
  font-size: 13px;
  font-weight: 300;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a i {
  float: right;
  font-size: 12px;
  color: #999;
  font-weight: 600;
  position: relative;
  top: 6px;
}

.main-menu > nav > ul > li > ul.sub-menu-width li a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
  padding: 36px 0px 40px;
  width: 220px;
  transition: all 250ms ease-out;
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  position: absolute;
  top: -17px;
  left: 100%;
  margin: 0;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.sub-menu-width li ul.lavel-menu {
    width: 180px;
  }
}

.main-menu > nav > ul > li > ul.sub-menu-width li:hover > ul.lavel-menu {
  visibility: visible;
  opacity: 1;
}

.main-menu > nav > ul > li > ul.mega-menu-width1 {
  width: 675px;
}

.main-menu > nav > ul > li > ul.mega-menu-width2 {
  width: 1170px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 {
    width: 1100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-width2 {
    width: 960px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg1 {
  left: -160px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -137px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -126px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg1 {
    left: -185px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg2 {
  left: -240px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -208px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -198px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg2 {
    left: -534px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg3 {
  left: -400px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -408px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -372px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg3 {
    left: -287px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg4 {
  left: -600px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    left: -710px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    left: -680px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg4 {
    left: -495px;
  }
}

.main-menu > nav > ul > li > ul.menu-negative-mrg5 {
  left: -160px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.menu-negative-mrg5 {
    left: -185px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 {
  padding: 40px 10px 33px 40px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li {
  text-align: left;
  padding-right: 30px;
  float: left;
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li.mega-menu-sub-width33 {
  width: 33.333%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li.mega-menu-sub-width25 {
  width: 25%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li.mega-menu-sub-width20 {
  width: 20%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li.mega-menu-sub-width40 {
  width: 40%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li a.menu-title {
  color: #fff;
  font-size: 14px;
  padding: 0px 0px 12px 0px;
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li a.menu-title:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  bottom: -2px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul {
  margin: 28px 0 0;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li {
  display: block;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li a {
  color: #D0D0D0;
  font-size: 13px;
  display: block;
  padding: 8px 0px;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > ul li a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > .banner-menu-content-wrap {
  position: relative;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > .banner-menu-content-wrap.default-overlay::before {
  background-color: #000;
  opacity: .2;
  z-index: 1;
  pointer-events: none;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > .banner-menu-content-wrap a img {
  width: 100%;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > .banner-menu-content-wrap .banner-menu-content {
  position: absolute;
  left: 41px;
  bottom: 35px;
  z-index: 9;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > .banner-menu-content-wrap .banner-menu-content h2 {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0px;
  line-height: 45px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style-1 > li > .banner-menu-content-wrap .banner-menu-content h2 {
    font-size: 35px;
    line-height: 50px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > .banner-menu-content-wrap:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.main-menu > nav > ul > li > ul.mega-menu-style-1 > li > .banner-menu-content-wrap:hover .banner-menu-content h2 {
  letter-spacing: 1px;
}

.main-menu > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu.main-menu-white > nav > ul > li > a {
  color: #ffffff;
}

.main-menu.main-menu-white > nav > ul > li:hover > a {
  color: #0A1039;
}

.main-menu.menu-lh-1 > nav > ul > li > a {
  line-height: 102px;
}

.main-menu.menu-lh-2 > nav > ul > li > a {
  line-height: 75px;
}

.main-menu.main-menu-padding-1 > nav > ul > li {
  padding: 0 30px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 20px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 20px 0 0;
  }
}

.main-menu.main-menu-padding-1 > nav > ul > li:last-child {
  padding: 0 0px 0 0;
}

.main-menu.menu-mrg-1 {
  margin-left: 63px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-mrg-1 {
    margin-left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-mrg-1 {
    margin-left: 30px;
  }
}

.main-menu.menu-mrg-2 {
  margin-right: 135px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-mrg-2 {
    margin-right: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-mrg-2 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-mrg-2 {
    margin-right: 20px;
  }
}

.main-menu.menu-mrg-3 {
  margin-right: 35px;
}

.categories-dropdown .nice-select-style-1 {
  border-radius: 5px 0px 0px 5px;
  border: solid 1px #F3F3F3;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 45px;
  line-height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 140px;
  color: #232953;
}

.categories-dropdown .nice-select-style-1 ul {
  width: 100%;
}

.search-style-2 form .form-search-2 {
  position: relative;
}

.search-style-2 form .form-search-2 input {
  background-color: transparent;
  border: 1px solid #F3F3F3;
  height: 45px;
  border-radius: 0 5px 5px 0px;
  font-weight: 300;
  color: #B3B5C2;
  font-size: 14px;
  width: 625px;
  padding: 2px 60px 2px 25px;
}

.search-style-2 form .form-search-2 input.width-dec {
  width: 495px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .search-style-2 form .form-search-2 input.width-dec {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .search-style-2 form .form-search-2 input.width-dec {
    width: 300px;
    padding: 2px 60px 2px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-style-2 form .form-search-2 input.width-dec {
    width: 260px;
    padding: 2px 60px 2px 15px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .search-style-2 form .form-search-2 input {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .search-style-2 form .form-search-2 input {
    width: 260px;
    padding: 2px 60px 2px 15px;
  }
}

.search-style-2 form .form-search-2 input::-moz-input-placeholder {
  color: #B3B5C2;
  opacity: 1;
}

.search-style-2 form .form-search-2 input::-webkit-input-placeholder {
  color: #B3B5C2;
  opacity: 1;
}

.search-style-2 form .form-search-2 button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 20px;
  color: #081348;
}

.search-style-2.search-2-bg-white form .form-search-2 {
  position: relative;
}

.search-style-2.search-2-bg-white form .form-search-2 input {
  background-color: #ffffff;
}

.search-style-5 form .form-search-5 {
  position: relative;
}

.search-style-5 form .form-search-5 input {
  background-color: #ffffff;
  border: solid 1px #F3F3F3;
  height: 45px;
  border-radius: 0 5px 5px 0px;
  font-weight: 300;
  color: #B3B5C2;
  font-size: 14px;
  width: 495px;
  padding: 2px 60px 2px 25px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .search-style-5 form .form-search-5 input {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .search-style-5 form .form-search-5 input {
    width: 260px;
    padding: 2px 60px 2px 15px;
  }
}

.search-style-5 form .form-search-5 input::-moz-input-placeholder {
  color: #B3B5C2;
  opacity: 1;
}

.search-style-5 form .form-search-5 input::-webkit-input-placeholder {
  color: #B3B5C2;
  opacity: 1;
}

.search-style-5 form .form-search-5 button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 20px;
  color: #081348;
}

.search-style-5 form .form-search-5.width-dec-lg input {
  width: 280px;
}

.categories-dropdown .nice-select::after {
  margin-top: -1px;
  border-bottom: 2px solid #232953;
  border-right: 2px solid #232953;
}

.header-bottom-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bottom-flex {
    display: block;
  }
}

.header-bottom-flex .logo-menu-wrap {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  max-width: 50%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-bottom-flex .logo-menu-wrap {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-bottom-flex .logo-menu-wrap {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bottom-flex .logo-menu-wrap {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
  }
}

.header-bottom-flex .header-action-width {
  flex: 0 0 50%;
  max-width: 50%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-bottom-flex .header-action-width {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-bottom-flex .header-action-width {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-bottom-flex .header-action-width {
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.main-wrapper .body-overlay {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: 1;
  visibility: visible;
}

.main-wrapper-2 .body-overlay-2 {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper-2.overlay-active-2 .body-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.main-wrapper-3 .body-overlay-3 {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper-3.overlay-active-3 .body-overlay-3 {
  opacity: 1;
  visibility: visible;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  -moz-transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 104px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 60px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 48px;
  left: 44px;
  font-size: 35px;
  line-height: 30px;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    left: 30px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 20px;
    left: 17px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  transform: rotate(90deg);
  color: #0A1039;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 30px;
  color: #393939;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 25px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 75px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 14px;
  margin: 0 0 8px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a {
  color: #333333;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a:hover {
  color: #0A1039;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 14px;
  color: #262626;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-size: 16px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  text-align: right;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 18px 0 43px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 15px;
  margin: 0;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 18px;
  color: #2F2F2F;
  float: right;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
  line-height: 20px;
  padding: 19px 20px 20px;
  background-color: #333;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:hover {
  background-color: #0A1039;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.header-search-3 {
  position: relative;
}

.header-search-3 .search-toggle {
  z-index: 99;
}

.header-search-3 .search-toggle i {
  display: block;
}

.header-search-3 .search-toggle i.s-close {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.header-search-3 .search-toggle .s-close {
  opacity: 0;
}

.header-search-3 .search-toggle.open .s-close {
  opacity: 1;
}

.header-search-3 .search-toggle.open .s-open {
  opacity: 0;
}

.search-wrap-3 {
  background-color: transparent;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 340px;
  z-index: -99;
  padding-bottom: 1px;
  margin-right: 10px;
}

.search-wrap-3 form {
  position: relative;
  overflow: hidden;
}

.search-wrap-3 form input {
  background-color: #fff;
  border: 1px solid #E2DCDC;
  color: #0A1039;
  line-height: 30px;
  padding: 2px 60px 2px 20px;
  width: 100%;
}

.search-wrap-3 form input::-moz-input-placeholder {
  color: #0A1039;
  opacity: 1;
}

.search-wrap-3 form input::-webkit-input-placeholder {
  color: #0A1039;
  opacity: 1;
}

.search-wrap-3 form button.button-search {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: none;
  background-color: transparent;
  height: 100%;
  border-left: 1px solid #E2DCDC;
  padding: 0 15px;
}

.search-wrap-3.open {
  opacity: 1;
  right: 100%;
  visibility: visible;
  z-index: 99;
}

.clickalbe-sidebar-wrapper-style-1 {
  position: fixed;
  top: 0;
  width: 400px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(200px, 0);
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 {
    width: 330px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 {
    width: 290px;
  }
}

.clickalbe-sidebar-wrapper-style-1.clickalbe-menu-sidebar-left {
  left: 0;
  transform: translate(-200px, 0);
}

.clickalbe-sidebar-wrapper-style-1.sidebar-visible {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.clickalbe-sidebar-wrapper-style-1.mobilemenu-visible {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap {
  padding: 150px 50px 20px;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap {
    padding: 100px 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap {
    padding: 70px 20px 20px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-close {
  position: absolute;
  top: 80px;
  left: 45px;
  font-size: 30px;
  line-height: 30px;
  transition: all 250ms ease-out;
  color: #0A1039;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-close {
    left: 27px;
    top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-close {
    top: 20px;
    left: 17px;
    font-size: 23px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-close:hover {
  transform: rotate(90deg);
  color: #333333;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .sidebar-content-100-percent {
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li {
  display: block;
  padding-bottom: 40px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li {
    padding-bottom: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li {
    padding-bottom: 22px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li:last-child {
  padding-bottom: 0;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a {
  font-size: 18px;
  display: block;
  text-transform: capitalize;
  color: #0A1039;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a {
    font-size: 16px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a i {
  float: right;
  top: 3px;
  position: relative;
  color: #0A1039;
  transition: all .3s ease 0s;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li > a i {
    top: 2px;
    font-size: 12px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li:hover > a {
  color: #333333;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li:hover > a i {
  color: #333333;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul {
  padding: 38px 0 0px 25px;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li {
  padding-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li {
    padding-bottom: 18px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li:last-child {
  padding-bottom: 0;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li a {
  color: #0A1039;
  font-size: 14px;
  display: block;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li a i {
  float: right;
  top: -1px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li a i {
    top: 2px;
    font-size: 12px;
  }
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li:hover > a {
  color: #333333;
}

.clickalbe-sidebar-wrapper-style-1 .clickalbe-sidebar-wrap .clickable-mainmenu-style1 > nav > ul > li ul li:hover > a i {
  color: #333333;
}

.mobile-menu-content-area .mobile-search {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  margin-bottom: 29px;
  padding-bottom: 30px;
}

.mobile-menu-content-area .mobile-search form {
  position: relative;
}

.mobile-menu-content-area .mobile-search form input {
  background-color: #fff;
  border: 1px solid #E2DCDC;
  color: #0A1039;
  border-radius: 0px;
  height: 45px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
}

.mobile-menu-content-area .mobile-search form button {
  background-color: transparent;
  border-color: #E2DCDC;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #0A1039;
  font-size: 15px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-content-area .mobile-search form button {
    font-size: 16px;
  }
}

.mobile-menu-content-area .mobile-search form button:hover {
  color: #333333;
}

.mobile-menu-content-area .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-menu-content-area .mobile-curr-lang-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  margin-bottom: 31px;
  padding-bottom: 37px;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  padding-top: 25px;
  margin-top: 33px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 15px;
  display: block;
  color: #0A1039;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 17px;
  position: relative;
  top: 7px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  font-weight: 400;
  color: #0A1039;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: #333333;
}

.mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: #333333;
}

@media only screen and (max-width: 767px) {
  .mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .mobile-menu-content-area .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}

.mobile-menu-content-area .aside-contact-info {
  margin: 30px 0 30px;
}

.mobile-menu-content-area .aside-contact-info ul li {
  color: #0A1039;
  display: flex;
  margin: 0 0 23px;
  transition: all .3s ease 0s;
}

.mobile-menu-content-area .aside-contact-info ul li:last-child {
  margin: 0 0 0px;
}

.mobile-menu-content-area .aside-contact-info ul li:hover {
  color: #333333;
}

.mobile-menu-content-area .aside-contact-info ul li i {
  font-size: 22px;
  margin: 1px 16px 0px 0;
}

.search-style-1 form .form-search-1 {
  position: relative;
}

.search-style-1 form .form-search-1 input {
  background-color: transparent;
  width: 450px;
  font-weight: 300;
  color: #B3B5C2;
  border: none;
  height: 42px;
  padding: 2px 40px 2px 0;
  border-bottom: 1px solid #D9DFE6;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .search-style-1 form .form-search-1 input {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .search-style-1 form .form-search-1 input {
    width: 280px;
  }
}

.search-style-1 form .form-search-1 button {
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  font-size: 20px;
  color: #081348;
  transition: all .3s ease 0s;
  background-color: transparent;
}

.search-style-1 form .form-search-1 button:hover {
  color: #444E7A;
}

.search-style-6 form .form-search-6 {
  position: relative;
}

.search-style-6 form .form-search-6 input {
  background-color: #F2F8F3;
  height: 45px;
  border: none;
  border-radius: 50px;
  color: #B3B5C2;
  font-weight: 300;
  padding: 2px 50px 2px 33px;
}

.search-style-6 form .form-search-6 input.input-bg-gray {
  background-color: #F7F7F7;
}

.search-style-6 form .form-search-6 input::-moz-input-placeholder {
  color: #B3B5C2;
  opacity: 1;
}

.search-style-6 form .form-search-6 input::-webkit-input-placeholder {
  color: #B3B5C2;
  opacity: 1;
}

.search-style-6 form .form-search-6 button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border: none;
  padding: 0;
  color: #939393;
  font-size: 20px;
}

.search-style-6 form .form-search-6 button:hover {
  color: #0A1039;
}

.search-style-7 form .form-search-7 {
  position: relative;
}

.search-style-7 form .form-search-7 input {
  background-color: #fff;
  height: 40px;
  border: none;
  border-radius: 5px;
  color: #B3B5C2;
  font-weight: 300;
  padding: 2px 50px 2px 33px;
  width: 494px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .search-style-7 form .form-search-7 input {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .search-style-7 form .form-search-7 input {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-style-7 form .form-search-7 input {
    width: 200px;
    padding: 2px 50px 2px 10px;
  }
}

.search-style-7 form .form-search-7 input::-moz-input-placeholder {
  color: #B3B5C2;
  opacity: 1;
}

.search-style-7 form .form-search-7 input::-webkit-input-placeholder {
  color: #B3B5C2;
  opacity: 1;
}

.search-style-7 form .form-search-7 button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border: none;
  padding: 0;
  color: #081348;
  font-size: 20px;
  background-color: transparent;
}

.search-style-7 form .form-search-7 button:hover {
  color: #CF7F39;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-style-7.ml-75 {
    margin-left: 15px;
  }
}

.header-action-white-bg {
  position: relative;
}

.header-action-white-bg::before {
  position: absolute;
  right: -32px;
  top: -37px;
  content: "";
  height: 91px;
  width: 338px;
  background-color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-action-white-bg::before {
    right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action-white-bg::before {
    right: -30px;
  }
}

.header-action-white-bg .same-style {
  z-index: 1;
}

.pet-food-header::before {
  background-image: url(../../assets/images/icon-img/pet-food-header-shape.png);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0px;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 6px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-food-header::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .pet-food-header::before {
    display: none;
  }
}

.logo-menu-wrap-2 {
  display: flex;
  align-items: center;
}

.header-slider-wrap {
  display: block;
  width: 100%;
}

.header-sidebar-area {
  width: 285px;
  height: 100vh;
  float: left;
  padding: 30px 0px 60px 48px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-sidebar-area {
    width: 230px;
    padding: 30px 0px 60px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-area {
    width: 220px;
    padding: 30px 0px 60px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-area {
    width: 180px;
    padding: 30px 0px 60px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-sidebar-area {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-sidebar-area {
    display: none;
  }
}

.header-sidebar-area-2 {
  width: 65px;
  height: 780px;
  float: left;
  padding: 40px 24px 40px 24px;
  overflow-y: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-sidebar-area-2 {
    height: 771px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sidebar-area-2 {
    height: 625px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-sidebar-area-2 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-sidebar-area-2 {
    display: none;
  }
}

.header-sidebar-area-2 .header-sidebar-wrap-2 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.main-menu-2 > nav > ul > li {
  padding-bottom: 36px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li {
    padding-bottom: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li {
    padding-bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li {
    padding-bottom: 25px;
  }
}

.main-menu-2 > nav > ul > li:last-child {
  padding-bottom: 0;
}

.main-menu-2 > nav > ul > li > a {
  display: block;
  color: #777777;
  font-size: 16px;
  position: relative;
}

.main-menu-2 > nav > ul > li > a:before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 0px;
  background-color: #151C0F;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease 0s;
}

.main-menu-2 > nav > ul > li:hover > a {
  color: #333;
  font-weight: bold;
}

.main-menu-2 > nav > ul > li:hover > a:before {
  height: 32px;
  opacity: 1;
  visibility: visible;
}

.main-menu-2 > nav > ul > li > ul {
  box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
  background-color: #262626;
  position: absolute;
  top: -5px;
  left: 100%;
  transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  z-index: 9999;
}

.main-menu-2 > nav > ul > li > ul.sub-menu-width {
  width: 220px;
  padding: 39px 0 43px;
}

.main-menu-2 > nav > ul > li > ul.sub-menu-width li {
  display: block;
  padding: 0 15px 14px 30px;
  position: relative;
  text-align: left;
}

.main-menu-2 > nav > ul > li > ul.sub-menu-width li:last-child {
  padding-bottom: 0;
}

.main-menu-2 > nav > ul > li > ul.sub-menu-width li a {
  display: block;
  color: #D0D0D0;
  font-size: 13px;
  font-weight: 300;
}

.main-menu-2 > nav > ul > li > ul.sub-menu-width li a i {
  float: right;
  font-size: 12px;
  color: #999;
  font-weight: 600;
  position: relative;
  top: 6px;
}

.main-menu-2 > nav > ul > li > ul.sub-menu-width li a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-width1 {
  width: 675px;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-width2 {
  width: 1170px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 {
    width: 1050px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 {
    width: 950px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-width2 {
    width: 790px;
  }
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 {
  padding: 40px 10px 33px 40px;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li {
  text-align: left;
  padding-right: 30px;
  float: left;
  display: block;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width33 {
  width: 33.333%;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width25 {
  width: 25%;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width20 {
  width: 20%;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li.mega-menu-sub-width40 {
  width: 40%;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li a.menu-title {
  color: #fff;
  font-size: 14px;
  padding: 0px 0px 12px 0px;
  position: relative;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li a.menu-title:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  bottom: -2px;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > ul {
  margin: 28px 0 0;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > ul li {
  display: block;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > ul li a {
  color: #D0D0D0;
  font-size: 13px;
  display: block;
  padding: 8px 0px;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > ul li a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > .banner-menu-content-wrap {
  position: relative;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > .banner-menu-content-wrap.default-overlay::before {
  background-color: #000;
  opacity: .2;
  z-index: 1;
  pointer-events: none;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > .banner-menu-content-wrap a img {
  width: 100%;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > .banner-menu-content-wrap .banner-menu-content {
  position: absolute;
  left: 41px;
  bottom: 35px;
  z-index: 9;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > .banner-menu-content-wrap .banner-menu-content h2 {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0px;
  line-height: 45px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > .banner-menu-content-wrap .banner-menu-content h2 {
    font-size: 35px;
    line-height: 50px;
  }
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > .banner-menu-content-wrap:hover.default-overlay::before {
  background-color: #000;
  opacity: .4;
  z-index: 1;
  pointer-events: none;
}

.main-menu-2 > nav > ul > li > ul.mega-menu-style-2 > li > .banner-menu-content-wrap:hover .banner-menu-content h2 {
  letter-spacing: 1px;
}

.main-menu-2 > nav > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu-2.sidebar-menu-padding {
  padding: 90px 0 265px 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu-2.sidebar-menu-padding {
    padding: 90px 0 100px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu-2.sidebar-menu-padding {
    padding: 90px 0 100px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu-2.sidebar-menu-padding {
    padding: 90px 0 90px 0px;
  }
}

.header-social-icon a {
  font-size: 16px;
  color: #DDDDDD;
  margin-bottom: 55px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-social-icon a {
    margin-bottom: 25px;
  }
}

.header-social-icon a:last-child {
  margin-bottom: 0;
}

.header-social-icon a:hover {
  color: #0A1039;
}

.search-content-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  transition: all ease-in-out .25s;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-content-wrap.search-visible {
  visibility: visible;
  opacity: 1;
}

.search-content-wrap.search-visible .megashop-search-content {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-content-wrap > .megashop-search-close {
  font-size: 40px;
  display: block;
  position: absolute;
  top: 20%;
  right: 20%;
  line-height: 40px;
  height: 40px;
  transition: all .3s;
  color: rgba(255, 255, 255, 0.8);
}

.search-content-wrap > .megashop-search-close:hover {
  transform: rotate(90deg);
}

.search-content-wrap > .megashop-search-close i:hover {
  color: white;
}

.search-content-wrap .megashop-search-content {
  display: inline-block;
  max-width: 90%;
  text-align: center;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out .3s;
  transform: scale(0.9);
}

.search-content-wrap .megashop-search-content p {
  font-size: 18px;
  margin: 0 0 15px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
}

.search-content-wrap .megashop-search-content .megashop-search-form {
  position: relative;
}

.search-content-wrap .megashop-search-content .megashop-search-form input {
  width: 800px;
  background-color: transparent;
  box-shadow: 0 3px 0 0 rgba(255, 255, 255, 0.1);
  border: 0;
  text-align: center;
  font-size: 30px;
  padding: 34px 60px 34px 36px;
  color: rgba(255, 255, 255, 0.8);
  transition: all .3s ease-out;
  font-weight: 400;
  max-width: 100%;
  height: auto;
}

.search-content-wrap .megashop-search-content .megashop-search-form .megashop-button-search {
  position: absolute;
  top: 43px;
  display: block;
  right: 5px;
  border: 0;
  background: 0 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 28px;
  height: 30px;
}

.search-content-wrap .megashop-search-content .megashop-search-form .megashop-button-search:hover {
  color: white;
}

.header-bg-1 {
  background: linear-gradient(to right, #FBFBFB, #FBFBFB 50%, #fff 0%);
}

/*---------- 3. Slider style ------------*/
.slider-area {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area.mr-15 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area.mr-15 {
    margin-right: 0px;
  }
}

.slider-active-1 {
  z-index: 9;
}

.slider-height-1 {
  height: 780px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-1 {
    height: 650px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 650px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: auto;
  }
}

.slider-height-2 {
  height: 662px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: 500px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-2 {
    height: 400px;
  }
}

.slider-height-3 {
  height: 846px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-3 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-3 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-3 {
    height: 500px;
  }
}

.slider-height-4 {
  height: 830px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-4 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-4 {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-4 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-4 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-4 {
    height: 480px;
  }
}

.slider-height-5 {
  height: 765px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-5 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-5 {
    height: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-5 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-5 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-5 {
    height: 520px;
  }
}

.slider-height-6 {
  height: 990px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-6 {
    height: 715px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-6 {
    height: 715px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-6 {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-6 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-6 {
    height: 650px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-6 {
    height: 450px;
  }
}

.slider-height-7 {
  height: 846px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-7 {
    height: 800px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-7 {
    height: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-7 {
    height: 500px;
  }
}

.slider-height-8 {
  height: 640px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-8 {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-8 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-8 {
    height: auto;
  }
}

.slider-height-9 {
  height: 1010px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-9 {
    height: 715px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-9 {
    height: 715px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-9 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-9 {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-9 {
    height: 480px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-9 {
    height: 450px;
  }
}

.slider-height-10 {
  height: 710px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-10 {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-10 {
    height: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-10 {
    height: 500px;
  }
}

.slider-height-11 {
  height: 750px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-11 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-11 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-11 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-11 {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-11 {
    height: 600px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-11 {
    height: 460px;
  }
}

.slider-height-12 {
  height: 726px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-12 {
    height: 650px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-12 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-12 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-12 {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-12 {
    height: 500px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-12 {
    height: 400px;
  }
}

.slider-height-13 {
  height: 673px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-13 {
    height: 520px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-13 {
    height: 473px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-13 {
    height: 413px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-13 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-13 {
    height: 500px;
    background-position: 70%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-13 {
    height: 400px;
  }
}

.slider-height-14 {
  height: 800px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-14 {
    height: 700px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-14 {
    height: 650px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-14 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-14 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-14 {
    height: auto;
    padding: 120px 0 230px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-14 {
    height: 420px;
    padding: 0;
  }
}

.single-slider-ptb-1 {
  padding-top: 102px;
}

@media only screen and (max-width: 767px) {
  .single-slider-ptb-1 {
    padding-top: 80px;
    padding-bottom: 70px;
  }
}

.slider-bg-color-1 {
  background: linear-gradient(to right, #FCF6EC, #FCF6EC 43.66667%, #fff 0%);
}

.slider-content-mrg-1 {
  padding-top: 45px;
}

.slider-content-1 {
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .slider-content-1 {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-1 {
    text-align: left;
  }
}

.slider-content-1 h1 {
  font-size: 68px;
  font-weight: bold;
  color: #151C0F;
  line-height: 94px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-1 h1 {
    font-size: 55px;
    line-height: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 h1 {
    font-size: 36px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-1 h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-1 h1 {
    font-size: 25px;
    line-height: 37px;
  }
}

.slider-content-1 p {
  font-size: 15px;
  line-height: 30px;
  margin: 20px 0 47px;
  width: 67%;
}

.slider-content-1 p.width-inc {
  width: 78%;
  margin: 20px 0 36px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 p.width-inc {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-1 p.width-inc {
    width: 100%;
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 p {
    width: 100%;
    margin: 15px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-1 p {
    width: 100%;
    margin: 10px 0 20px;
  }
}

.single-slider-pt-2 {
  padding-top: 236px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-pt-2 {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-pt-2 {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-pt-2 {
    padding-top: 150px;
  }
}

.slider-content-2 h1 {
  font-size: 145px;
  font-weight: 800;
  color: #06103A;
  line-height: 110px;
  position: relative;
  z-index: 5;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-2 h1 {
    font-size: 125px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 h1 {
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h1 {
    font-size: 70px;
    line-height: 65px;
  }
}

.slider-content-2 h1 span {
  color: #EBA866;
}

.slider-content-2 h2 {
  font-size: 145px;
  font-weight: 800;
  color: #EBA866;
  line-height: 130px;
  margin: 27px 0 0px 362px;
  position: relative;
  z-index: 5;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-2 h2 {
    font-size: 125px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 h2 {
    font-size: 100px;
    margin: 27px 0 0px 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 h2 {
    font-size: 100px;
    margin: 27px 0 0px 200px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 h2 {
    font-size: 70px;
    line-height: 65px;
    margin: 27px 0 0px 60px;
  }
}

.slider-content-2 h2 span {
  color: #06103A;
}

.slider-content-2 .slider-single-img-3 {
  position: absolute;
  bottom: -70%;
  left: 3%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 .slider-single-img-3 {
    bottom: -45%;
    left: 0%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 .slider-single-img-3 {
    bottom: -35%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 .slider-single-img-3 {
    bottom: -75%;
    left: 50%;
    transform: translateX(-50%);
  }
  .slider-content-2 .slider-single-img-3 a img {
    width: 300px;
    max-width: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 .slider-single-img-3 a img {
    width: 350px;
    max-width: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-2 {
    text-align: center;
  }
}

.slider-single-img img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .slider-single-img {
    text-align: center;
  }
}

.slider-single-img-2 {
  margin-left: 44px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-img-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-2 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-2 {
    margin-left: 0px;
    margin-top: 25px;
  }
}

.slider-single-img-2 a {
  display: block;
}

.slider-single-img-2 a img {
  max-width: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-img-2 a img {
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-2 a img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-2 a img {
    max-width: 100%;
  }
}

.slider-sin-img-mrg1 {
  margin: 0 17px 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-sin-img-mrg1 {
    margin: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-sin-img-mrg1 {
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider-sin-img-mrg1 {
    margin: 30px 20px 0;
  }
}

.slider-shape-electric {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.slider-shape-electric img {
  width: 331px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-shape-electric img {
    width: 270px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-shape-electric img {
    width: 231px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-shape-electric img {
    width: 131px;
  }
}

.slider-shape-electric2 {
  position: absolute;
  right: 0;
  top: 10%;
  z-index: 1;
}

.slider-shape-electric2 img {
  width: 72px;
}

.nav-style-1 .slider-icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  display: inline-block;
  color: #0A1039;
  font-size: 45px;
  top: 50%;
  transition: all 0.4s ease-in-out 0s;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
}

.nav-style-1 .slider-icon.slider-icon-next {
  right: 0%;
  left: auto;
}

.nav-style-1 .slider-icon.slider-icon-prev {
  left: 0%;
  right: auto;
}

.nav-style-1 .slider-icon:hover:hover {
  color: #0A1039;
}

.nav-style-1:hover .slider-icon {
  visibility: visible;
  opacity: 1;
}

.nav-style-1:hover .slider-icon.slider-icon-next {
  right: 2%;
}

.nav-style-1:hover .slider-icon.slider-icon-prev {
  left: 2%;
}

.dot-style-1 ul {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.dot-style-1 ul li {
  margin: 5px;
}

.dot-style-1 ul li button {
  padding: 0;
  font-size: 0;
  border: 2px solid transparent;
  background-color: #0A1039;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}

.dot-style-1 ul li.slick-active button {
  border: 2px solid #0A1039;
  background-color: transparent;
}

.single-slider .slider-content * {
  animation-duration: 1.3s;
  animation-fill-mode: both;
}

.single-slider.slick-active .slider-animated-1 h1 {
  animation-delay: 1.3s;
  animation-name: fadeInLeft;
}

.single-slider.slick-active .slider-animated-1 h2 {
  animation-delay: 1.6s;
  animation-name: fadeInRight;
}

.single-slider.slick-active .slider-animated-1 h3 {
  animation-delay: 1.8s;
  animation-name: fadeInLeft;
}

.single-slider.slick-active .slider-animated-1 p {
  animation-delay: 1.6s;
  animation-name: fadeInLeft;
}

.single-slider.slick-active .slider-animated-1 a {
  animation-delay: 1.9s;
  animation-name: fadeInLeft;
}

.single-slider.slick-active .slider-animated-1 img {
  animation-delay: 1.6s;
  animation-name: fadeInRight;
}

.single-slider.slick-active .slider-animated-1 .slider-single-img-3 img {
  animation-delay: 1.6s;
  animation-name: fadeInUp;
}

.single-slider.slick-active .slider-animated-1 .slider-oganic-img-1 img {
  animation-delay: 1.9s;
  animation-name: fadeInUp;
}

.single-slider.slick-active .slider-animated-1 .slider-oganic-img-2 img {
  animation-delay: 1.6s;
  animation-name: zoomIn;
}

.single-slider.slick-active .slider-animated-1 .slider-kids-img-content img {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.single-slider.slick-active .slider-animated-1 .slider-kids-img-content h3 {
  animation-delay: 1.5s;
  animation-name: fadeInLeft;
}

.single-slider.slick-active .slider-animated-1 .slider-kids-img-content h4 {
  animation-delay: 1.8s;
  animation-name: fadeInRight;
}

.single-slider.slick-active .slider-animated-1 .slider-flower-wrap img {
  animation-delay: 0.5s;
  animation-name: fadeInDown;
}

.single-slider.slick-active .slider-animated-1 .slider-flower-wrap h2 {
  animation-delay: 0.5s;
  animation-name: fadeInUp;
}

.single-slider.slick-active .slider-animated-1.slider-single-img-8 img {
  animation-delay: 0.5s;
  animation-name: fadeInDown;
}

.single-slider.slick-active .slider-animated-1.slider-single-img-9 img {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.single-slider.slick-active .slider-animated-1.slider-content-9::before {
  transition: all 2s ease 0s;
  transform: scale(1);
}

.single-slider.slick-active .slider-animated-1.slider-content-10 img {
  animation-delay: 0.8s;
  animation-name: fadeInUp;
}

.single-slider.slick-active .slider-animated-1 .fashion-3-slider-img2 img {
  animation-delay: 1.3s;
  animation-name: zoomIn;
}

.single-slider.slick-active .slider-animated-1 .fashion-3-slider-img3 img {
  animation-delay: .9s;
  animation-name: zoomIn;
}

.single-slider.slick-active .slider-animated-1 .fashion-3-slider-img4 img {
  animation-delay: .5s;
  animation-name: zoomIn;
}

.single-slider.slick-active .slider-animated-1.slider-single-img-10 > img {
  animation-delay: .5s;
  animation-name: fadeInRight;
}

.single-slider.slick-active .slider-animated-1.slider-single-img-10 .slider-baby-img > img {
  animation-delay: .5s;
  animation-name: fadeInLeft;
}

.single-slider.slick-active .slider-animated-1.slider-single-img-10 .slider-trolley-img > img {
  animation-delay: .5s;
  animation-name: fadeInUp;
}

.single-slider.slick-active .slider-animated-1 .slider-single-img-11 img {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.single-slider.slick-active .slider-animated-1 .fashion-2-slider-img1-1 img,
.single-slider.slick-active .slider-animated-1 .fashion-2-slider-img2-1 img {
  animation-delay: 1.5s;
  animation-name: zoomIn;
}

.single-slider.slick-active .slider-animated-1 .fashion-2-slider-img1-2 img,
.single-slider.slick-active .slider-animated-1 .fashion-2-slider-img2-2 img {
  animation-delay: 1.9s;
  animation-name: zoomIn;
}

.single-slider.slick-active .slider-animated-1 .fashion-2-slider-img1-3 img {
  animation-delay: 2.2s;
  animation-name: zoomIn;
}

.single-slider.slick-active .slider-animated-1 .fashion-2-slider-img1-4 img {
  animation-delay: 2.5s;
  animation-name: zoomIn;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-140 {
    margin-top: 0px;
  }
}

.slider-mt-1 {
  margin-top: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-mt-1 {
    margin-top: 211px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-mt-1 {
    margin-top: 91px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-mt-1 {
    margin-top: 91px;
  }
}

.slider-mt-2 {
  margin-top: 102px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-mt-2 {
    margin-top: 177px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-mt-2 {
    margin-top: 91px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-mt-2 {
    margin-top: 91px;
  }
}

.slider-mt-3 {
  margin-top: 135px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-mt-3 {
    margin-top: 211px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-mt-3 {
    margin-top: 91px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-mt-3 {
    margin-top: 91px;
  }
}

.slider-mt-4 {
  margin-top: 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-mt-4 {
    margin-top: 91px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-mt-4 {
    margin-top: 91px;
  }
}

.slider-mt-5 {
  margin-top: 147px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-mt-5 {
    margin-top: 91px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-mt-5 {
    margin-top: 91px;
  }
}

.slider-mt-6 {
  margin-top: 91px;
}

.slider-mt-7, .breadcrumb-mt {
  margin-top: 102px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-mt-7, .breadcrumb-mt {
    margin-top: 174px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-mt-7, .breadcrumb-mt {
    margin-top: 86px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-mt-7, .breadcrumb-mt {
    margin-top: 86px;
  }
}

.single-slider-pt-3 {
  padding-top: 160px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-pt-3 {
    padding-top: 200px;
    background-position: 66%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-pt-3 {
    padding-top: 130px;
    background-position: 73%;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-pt-3 {
    padding-top: 100px;
    background-position: 73%;
  }
}

.slider-content-3 h1 {
  font-size: 68px;
  line-height: 90px;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-3 h1 {
    font-size: 55px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-3 h1 {
    font-size: 45px;
    line-height: 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-3 h1 {
    font-size: 45px;
    line-height: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-3 h1 {
    font-size: 35px;
    line-height: 45px;
  }
}

.slider-content-3 h1 span {
  font-weight: bold;
}

.slider-content-3 p {
  font-size: 15px;
  line-height: 30px;
  width: 30%;
  margin: 40px 0 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-3 p {
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-3 p {
    margin: 10px 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-3 p {
    width: 50%;
    margin: 10px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-3 p {
    width: 50%;
    margin: 10px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-3 p {
    width: 100%;
    margin: 10px 0 25px;
  }
}

.dot-style-2 ul {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -14%;
  left: 19%;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .dot-style-2 ul {
    left: 13%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .dot-style-2 ul {
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-2 ul {
    left: 3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-2 ul {
    left: 3%;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-2 ul {
    left: 15px;
    bottom: -5%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dot-style-2 ul {
    left: 30px;
    bottom: -10%;
  }
}

.dot-style-2 ul li {
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .dot-style-2 ul li {
    margin-right: 5px;
  }
}

.dot-style-2 ul li button {
  padding: 0;
  font-size: 0;
  border: 2px solid transparent;
  background-color: transparent;
}

.dot-style-3 ul li {
  margin-top: 15px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-3 ul li {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-3 ul li {
    margin-top: 10px;
  }
}

.dot-style-3 ul li:first-child {
  margin-top: 0;
}

.dot-style-3 ul li button {
  padding: 0;
  font-size: 20px;
  color: #BCBDC8;
  border: none;
  background-color: transparent;
  position: relative;
  transition: all .3s;
}

.dot-style-3 ul li button:before {
  content: "0";
  display: inline-block;
  font-size: 20px;
}

.dot-style-3 ul li button::after {
  position: absolute;
  transition: all .3s;
  z-index: 1;
  width: 86px;
  height: 3px;
  background: #0A1039;
  top: 50%;
  transform: translateY(-50%);
  right: 42px;
  content: "";
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .dot-style-3 ul li button::after {
    width: 40px;
  }
}

.dot-style-3 ul li button:hover {
  color: #0A1039;
}

.dot-style-3 ul li.slick-active button {
  color: #0A1039;
}

.dot-style-3 ul li.slick-active button::after {
  opacity: 1;
}

.dot-style-3.dot-style-3-position ul {
  position: absolute;
  bottom: -19%;
  right: 0%;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-3.dot-style-3-position ul {
    bottom: -34%;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-3.dot-style-3-position ul {
    bottom: -13%;
  }
}

.dot-style-3.dot-style-3-position-2 ul {
  position: absolute;
  top: -60.5%;
  right: 0%;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .dot-style-3.dot-style-3-position-2 ul {
    top: auto;
    bottom: -10%;
  }
}

.hero-slider-content {
  position: relative;
  z-index: 99;
}

.hero-slider-content h1 {
  font-size: 68px;
  font-weight: bold;
  color: #ffffff;
  margin: 0 0 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content h1 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content h1 {
    font-size: 30px;
  }
}

.hero-slider-content p {
  color: #ffffff;
  font-size: 15px;
  line-height: 30px;
  margin: 0 auto;
  width: 30%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content p {
    width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content p {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content p {
    width: 90%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content p {
    width: 80%;
  }
}

.mouse-scroll-area {
  position: relative;
  padding-bottom: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area {
    padding-bottom: 50px;
  }
}

.mouse-scroll-area .book-shape-1 {
  position: absolute;
  top: -3% !important;
  left: 22% !important;
  z-index: 1;
}

.mouse-scroll-area .book-shape-1.layer img {
  width: 293px;
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .book-shape-1.layer img {
    width: 150px;
  }
}

.mouse-scroll-area .book-shape-2 {
  position: absolute;
  top: 24% !important;
  left: 70% !important;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .book-shape-2 {
    top: 10% !important;
    left: 40% !important;
  }
}

.mouse-scroll-area .book-shape-2.layer img {
  width: 85px;
}

.mouse-scroll-area .book-shape-3 {
  position: absolute;
  top: 14% !important;
  left: 88% !important;
  z-index: 1;
}

.mouse-scroll-area .book-shape-3.layer img {
  width: 233px;
}

.mouse-scroll-area .book-shape-4 {
  position: absolute;
  top: 66% !important;
  left: 76% !important;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .book-shape-4 {
    top: 75% !important;
    left: 65% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .book-shape-4 {
    top: 70% !important;
    left: 74% !important;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .book-shape-4 {
    top: 92% !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mouse-scroll-area .book-shape-4 {
    top: 80% !important;
    left: 63% !important;
  }
}

.mouse-scroll-area .book-shape-4.layer img {
  width: 340px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .book-shape-4.layer img {
    width: 240px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .book-shape-4.layer img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .book-shape-4.layer img {
    width: 140px;
  }
}

.mouse-scroll-area .book-shape-5 {
  position: absolute;
  top: 60% !important;
  left: 18% !important;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .book-shape-5 {
    top: 68% !important;
    left: 25% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .book-shape-5 {
    top: 70% !important;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .book-shape-5 {
    top: 70% !important;
    left: 31% !important;
  }
}

.mouse-scroll-area .book-shape-5.layer img {
  width: 289px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area .book-shape-5.layer img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .book-shape-5.layer img {
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .book-shape-5.layer img {
    width: 100px;
  }
}

.mouse-scroll-area .book-shape-6 {
  position: absolute;
  top: 54% !important;
  left: 0% !important;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .book-shape-6 {
    top: 74% !important;
  }
}

.mouse-scroll-area .book-shape-6.layer img {
  width: 176px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area .book-shape-6.layer img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area .book-shape-6.layer img {
    width: 70px;
  }
}

.slider-cake-fixed-img {
  position: absolute;
  left: 0;
  top: 53%;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-cake-fixed-img {
    top: 43%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-cake-fixed-img {
    top: 74%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-cake-fixed-img {
    top: 76%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-cake-fixed-img {
    top: 76%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-cake-fixed-img {
    top: 80%;
  }
}

.slider-cake-fixed-img img {
  width: 178px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-cake-fixed-img img {
    width: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-cake-fixed-img img {
    width: 120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-cake-fixed-img img {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-cake-fixed-img img {
    width: 80px;
  }
}

.slider-content-4 {
  position: relative;
  z-index: 999;
}

@media only screen and (max-width: 767px) {
  .slider-content-4 {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-4 {
    text-align: left;
  }
}

.slider-content-4 h3 {
  font-size: 48px;
  font-family: "Rochester", cursive;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .slider-content-4 h3 {
    font-size: 35px;
  }
}

.slider-content-4 h1 {
  font-size: 95px;
  font-weight: 600;
  font-family: "Rochester", cursive;
  margin: 10px 0 28px;
  line-height: 1;
  color: #885643;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-4 h1 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-4 h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-4 h1 {
    font-size: 40px;
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-4 {
    margin: 10px 0 0px;
  }
}

.slider-single-img-4 a {
  display: block;
}

.slider-single-img-4 a img {
  max-width: none;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-single-img-4 a img {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-single-img-4 a img {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-img-4 a img {
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-4 a img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-4 a img {
    max-width: 100%;
  }
}

.slider-blue-overly {
  position: relative;
}

.slider-blue-overly:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #E7F2E8;
  opacity: .78;
  z-index: 9;
}

.slider-content-5 {
  position: relative;
}

.slider-content-5 h1 {
  position: relative;
  z-index: 9;
  font-size: 128px;
  font-family: "Vandella";
  color: #172419;
  top: -85px;
  left: -84px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-5 h1 {
    font-size: 90px;
    left: -68px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-5 h1 {
    font-size: 90px;
    left: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-5 h1 {
    font-size: 45px;
    left: 0px;
    top: -50px;
  }
}

.slider-content-5 h1 span {
  color: #359043;
  margin: 0 150px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-5 h1 span {
    margin: 0 70px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-5 h1 span {
    margin: 0 0px;
  }
}

.slider-content-5 .slider-oganic-img-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 5;
}

.slider-content-5 .slider-oganic-img-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
}

.slider-oganic-img-1 img {
  width: 714px;
  max-width: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-oganic-img-1 img {
    width: 470px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-oganic-img-1 img {
    width: 470px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-oganic-img-1 img {
    width: 214px;
  }
}

.slider-oganic-img-2 img {
  width: 510px;
  max-width: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-oganic-img-2 img {
    width: 410px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-oganic-img-2 img {
    width: 410px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-oganic-img-2 img {
    width: 250px;
  }
}

.oganic-bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
}

.oganic-bg-img img {
  width: 100%;
  max-width: none;
}

.slider-bg-color-2 {
  background: linear-gradient(to right, #F0FDF5, #F0FDF5 68%, #F7F7F7 0%);
}

.single-slider-ptb-2 {
  padding-top: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-ptb-2 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-ptb-2 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-slider-ptb-2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.slider-sin-img-mrg2 {
  margin: 0 -85px 0 0px;
  float: right;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-sin-img-mrg2 {
    margin: 0 -40px 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-sin-img-mrg2 {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-sin-img-mrg2 {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-sin-img-mrg2 {
    margin: 0 0px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-sin-img-mrg2 {
    margin: 30px 0px 0 0px;
  }
}

.slider-kids-img-content {
  position: relative;
  display: inline-block;
}

.slider-kids-img-content h3 {
  font-size: 49px;
  font-family: "Chewy";
  color: #090961;
  margin: 0;
  position: absolute;
  left: 0;
  top: 72px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-kids-img-content h3 {
    left: -40px;
    top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-kids-img-content h3 {
    left: -40px;
    top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-kids-img-content h3 {
    left: -20px;
    top: 0;
    font-size: 25px;
  }
}

.slider-kids-img-content h4 {
  font-size: 49px;
  font-family: "Chewy";
  color: #090961;
  margin: 0;
  position: absolute;
  right: -65px;
  bottom: 118px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-kids-img-content h4 {
    bottom: 85px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-kids-img-content h4 {
    bottom: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-kids-img-content h4 {
    right: 0;
    bottom: -12px;
    font-size: 25px;
  }
}

.slider-kids-img-content img {
  width: 755px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-kids-img-content img {
    width: 555px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-kids-img-content img {
    width: 555px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-kids-img-content img {
    width: 215px;
  }
}

.kids-slider-area {
  position: relative;
}

.kids-slider-area::before {
  background-image: url(../../assets/images/icon-img/kids-slider.png);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0px;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 20px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .kids-slider-area {
    padding: 120px 0 100px;
  }
}

.slider-flower-wrap img {
  width: 845px;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-flower-wrap img {
    width: 750px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-flower-wrap img {
    width: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-flower-wrap img {
    width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-flower-wrap img {
    width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-flower-wrap img {
    width: 230px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-flower-wrap img {
    width: 270px;
  }
}

.slider-flower-wrap h2 {
  background: -webkit-linear-gradient(0deg, #EF1884, #BC3B3B 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 124px;
  font-family: "Vandella";
  margin-top: -50px;
  margin-bottom: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-flower-wrap h2 {
    font-size: 115px;
    margin-top: -20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-flower-wrap h2 {
    font-size: 100px;
    margin-top: -28px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-flower-wrap h2 {
    font-size: 85px;
    margin-top: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-flower-wrap h2 {
    font-size: 70px;
    margin-top: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-flower-wrap h2 {
    font-size: 50px;
    margin-top: 10px;
  }
}

.mouse-scroll-area-2 {
  position: absolute;
  top: 0% !important;
  left: 0 !important;
  z-index: 9 !important;
  width: 100% !important;
  height: 100% !important;
}

.mouse-scroll-area-2 .flower-shape-1 {
  position: absolute;
  top: 27% !important;
  left: 0% !important;
  z-index: 9;
}

.mouse-scroll-area-2 .flower-shape-1 img {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area-2 .flower-shape-1 {
    left: -12% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mouse-scroll-area-2 .flower-shape-1 img {
    width: 180px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area-2 .flower-shape-1 {
    top: 42% !important;
  }
  .mouse-scroll-area-2 .flower-shape-1 img {
    width: 80px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mouse-scroll-area-2 .flower-shape-1 img {
    width: 100px !important;
  }
}

.mouse-scroll-area-2 .flower-shape-2 {
  position: absolute;
  top: 27% !important;
  left: 81% !important;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mouse-scroll-area-2 .flower-shape-2 {
    left: 70% !important;
  }
}

@media only screen and (max-width: 767px) {
  .mouse-scroll-area-2 .flower-shape-2 {
    top: 42% !important;
  }
}

.mouse-scroll-area-2 .flower-shape-2 img {
  max-width: 100%;
}

.slider-single-img-5 {
  position: relative;
  z-index: 9;
  margin: 0 0 -23px 24px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-5 {
    margin: 0 0 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-5 {
    margin: 20px 0 -23px 0px;
    text-align: center;
  }
}

.slider-single-img-5 img {
  max-width: 100%;
}

.kids-3-bg-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.kids-3-bg-img img {
  width: 100%;
}

.pet-food-bg-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.pet-food-bg-img img {
  width: 100%;
}

.slider-content-6 {
  padding-top: 55px;
}

@media only screen and (max-width: 767px) {
  .slider-content-6 {
    padding-top: 0px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-6 {
    text-align: left;
    padding-top: 20px;
  }
}

.slider-content-6 h3 {
  font-size: 58px;
  color: #23914C;
  margin: 0 0 0px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .slider-content-6 h3 {
    font-size: 45px;
  }
}

.slider-content-6 h1 {
  font-size: 70px;
  color: #1086EE;
  margin: 30px 0 4px;
  font-weight: bold;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-6 h1 {
    font-size: 57px;
    margin: 20px 0 4px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-6 h1 {
    font-size: 42px;
    margin: 15px 0 4px;
  }
}

.slider-content-6 h2 {
  font-size: 58px;
  color: #FE58F3;
  font-family: "JellyBelly Font";
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-6 h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-6 h2 {
    font-size: 38px;
  }
}

.kids-3-single-slider {
  position: relative;
}

.kids-3-single-slider::before {
  background-image: url(../../assets/images/icon-img/kids-3-rectangle.png);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: -9px;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 18px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .kids-3-single-slider.pt-100 {
    padding-top: 50px;
  }
}

.slider-single-img-6 img {
  width: 100%;
}

.slider-single-img-7 {
  margin-left: -50px;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-7 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-7 {
    margin-left: 0px;
    text-align: center;
  }
}

.slider-single-img-7 img {
  width: 100%;
}

.slider-content-7 {
  background-color: #AFA2A7;
  margin-right: -135px;
  position: relative;
  z-index: 9;
  padding: 63px 50px 63px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-7 {
    padding: 40px 30px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-7 {
    padding: 30px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-7 {
    padding: 30px 30px 30px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-7 {
    padding: 50px 30px 50px;
    margin-right: 0px;
  }
}

.slider-content-7 h2 {
  font-size: 57px;
  font-weight: bold;
  color: #ffffff;
  margin: 0 0 7px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-7 h2 {
    font-size: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-7 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-7 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-7 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-7 h2 {
    font-size: 30px;
  }
}

.slider-content-7 h1 {
  font-size: 132px;
  font-weight: bold;
  color: #ffffff;
  margin: 0 0 0px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-7 h1 {
    font-size: 98px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-7 h1 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-7 h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-7 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-7 h1 {
    font-size: 50px;
  }
}

.slider-content-8 {
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .slider-content-8 {
    margin-bottom: 30px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-8 {
    text-align: left;
  }
}

.slider-content-8 h1 {
  color: #8E5208;
  font-size: 68px;
  font-family: "Pacifico";
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-8 h1 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-8 h1 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-8 h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-8 h1 {
    font-size: 29px;
  }
}

.slider-content-8 p {
  font-size: 15px;
  line-height: 30px;
  margin: 14px 0 55px;
  width: 83%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-8 p {
    margin: 25px 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-8 p {
    margin: 20px 0 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-8 p {
    margin: 20px 0 20px;
    font-size: 14px;
    width: 100%;
  }
}

.slider-content-9 {
  margin-left: -86px;
  margin-top: -60px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-9 {
    margin-left: 0px;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-9 {
    margin-left: 0px;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-9 {
    margin-left: 0px;
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-9 {
    margin-left: 0px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-9 {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-9 {
    margin-bottom: 0px;
  }
}

.slider-content-9::before {
  position: absolute;
  left: 65px;
  top: -80px;
  width: 360px;
  height: 335px;
  border: 4px solid #D02F3B;
  content: "";
  z-index: 9;
  transform: scale(0);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-9::before {
    left: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-9::before {
    left: 50px;
    height: 300px;
    top: -73px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-9::before {
    left: 50px;
    height: 300px;
    top: -73px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-9::before {
    left: 50px;
    height: 220px;
    top: -50px;
    width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-9::before {
    left: 42px;
    height: 130px;
    top: -26px;
    width: 176px;
    right: 0;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-9::before {
    left: 40px;
  }
}

.slider-content-9 h1 {
  font-size: 80px;
  font-weight: 900;
  margin: 0 0 26px;
  line-height: 60px;
  font-style: italic;
  letter-spacing: 3px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-9 h1 {
    font-size: 53px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-9 h1 {
    font-size: 50px;
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-9 h1 {
    font-size: 50px;
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-9 h1 {
    font-size: 40px;
    margin: 0 0 15px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-9 h1 {
    font-size: 32px;
    margin: 0 0 12px;
    line-height: 30px;
  }
}

.slider-content-9 h2 {
  font-size: 139px;
  font-weight: 900;
  margin: 0 0 0px 80px;
  color: #D02F3B;
  font-style: italic;
  letter-spacing: 3px;
  line-height: 100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-9 h2 {
    font-size: 100px;
    line-height: 80px;
    margin: 0 0 0px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-9 h2 {
    font-size: 85px;
    line-height: 80px;
    margin: 0 0 0px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-9 h2 {
    font-size: 85px;
    line-height: 80px;
    margin: 0 0 0px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-9 h2 {
    font-size: 53px;
    line-height: 65px;
    margin: 0 0 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-9 h2 {
    font-size: 45px;
    line-height: 40px;
    margin: 0 0 0px 0px;
  }
}

.slider-single-img-8 {
  margin: 0 -160px 0px 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-single-img-8 {
    margin: 0 0px 0px 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-single-img-8 {
    margin: 0 0px 0px 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-img-8 {
    margin: 0 0px 0px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-8 {
    margin: 0 0px 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-8 {
    margin: 0 0px 0px 0px;
  }
}

.slider-single-img-8 img {
  max-width: 100%;
}

.slider-content-10 {
  position: relative;
}

.slider-content-10 h1 {
  position: absolute;
  left: 26%;
  top: 40%;
  color: #ffffff;
  font-weight: bold;
  font-size: 160px;
  line-height: 120px;
  margin: 0;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-10 h1 {
    left: 17%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-10 h1 {
    font-size: 130px;
    left: 17%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-10 h1 {
    font-size: 100px;
    left: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-10 h1 {
    font-size: 80px;
    left: 9%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-10 h1 {
    font-size: 42px;
    left: 6%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-10 h1 {
    font-size: 50px;
  }
}

.slider-content-10 h2 {
  position: absolute;
  right: 25%;
  top: 40%;
  color: #ffffff;
  font-weight: bold;
  font-size: 160px;
  line-height: 120px;
  margin: 0;
  z-index: 2;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-10 h2 {
    right: 14%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-10 h2 {
    font-size: 130px;
    right: 16%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-10 h2 {
    font-size: 100px;
    right: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-10 h2 {
    font-size: 80px;
    right: 12%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-10 h2 {
    font-size: 42px;
    right: 6%;
    z-index: 9;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-10 h2 {
    font-size: 50px;
    right: 13%;
  }
}

.slider-content-10 img {
  display: inline-block;
  position: relative;
  z-index: 5;
  max-width: 100%;
}

.slider-100vh {
  height: 100vh;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-100vh {
    height: auto;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-100vh {
    height: auto;
    padding-top: 30px;
  }
}

.slider-single-img-9 {
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .slider-single-img-9 {
    text-align: center;
  }
}

.slider-single-img-9 img {
  width: 386px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-single-img-9 img {
    width: 280px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-single-img-9 img {
    width: 280px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-img-9 img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-9 img {
    width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-9 img {
    width: 150px;
    display: inline-block;
  }
}

.single-slider-fashion3 {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion3 {
    padding-top: 100px;
  }
}

.single-slider-fashion3 .fashion-3-slider-img2 {
  position: absolute;
  right: 18%;
  top: 8%;
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion3 .fashion-3-slider-img2 {
    right: auto;
    top: auto;
    bottom: 17%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-slider-fashion3 .fashion-3-slider-img2 {
    bottom: 19%;
    left: auto;
    right: 8%;
    transform: inherit;
  }
}

.single-slider-fashion3 .fashion-3-slider-img2 img {
  width: 593px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-slider-fashion3 .fashion-3-slider-img2 img {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-slider-fashion3 .fashion-3-slider-img2 img {
    width: 360px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-fashion3 .fashion-3-slider-img2 img {
    width: 270px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion3 .fashion-3-slider-img2 img {
    width: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion3 .fashion-3-slider-img2 img {
    width: 170px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-slider-fashion3 .fashion-3-slider-img2 img {
    width: 220px;
  }
}

.single-slider-fashion3 .fashion-3-slider-img3 {
  position: absolute;
  right: -18px;
  top: 24%;
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion3 .fashion-3-slider-img3 {
    top: 0%;
  }
}

.single-slider-fashion3 .fashion-3-slider-img3 img {
  width: 334px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-slider-fashion3 .fashion-3-slider-img3 img {
    width: 270px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-slider-fashion3 .fashion-3-slider-img3 img {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-fashion3 .fashion-3-slider-img3 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion3 .fashion-3-slider-img3 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion3 .fashion-3-slider-img3 img {
    width: 150px;
  }
}

.single-slider-fashion3 .fashion-3-slider-img4 {
  position: absolute;
  right: 49%;
  top: 5%;
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion3 .fashion-3-slider-img4 {
    top: 0%;
    right: auto;
    left: 15px;
  }
}

.single-slider-fashion3 .fashion-3-slider-img4 img {
  width: 111px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-fashion3 .fashion-3-slider-img4 img {
    width: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion3 .fashion-3-slider-img4 img {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion3 .fashion-3-slider-img4 img {
    width: 80px;
  }
}

.slider-content-11 {
  position: relative;
  z-index: 9;
  padding: 0 0 0 58px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-11 {
    padding: 0 0 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-11 {
    padding: 0 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-11 {
    padding: 0 0 30px 15px;
  }
}

.slider-content-11 h1 {
  font-size: 68px;
  line-height: 90px;
  color: #151C0F;
  margin: 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-11 h1 {
    font-size: 50px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-11 h1 {
    font-size: 40px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-11 h1 {
    font-size: 40px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-11 h1 {
    font-size: 40px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-11 h1 {
    font-size: 30px;
    line-height: 45px;
  }
}

.slider-content-11 h1 span {
  font-weight: bold;
}

.slider-content-11 p {
  font-size: 18px;
  line-height: 36px;
  width: 51%;
  margin: 40px 0 60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-11 p {
    width: 80%;
    margin: 20px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-11 p {
    width: 100%;
    margin: 20px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-11 p {
    width: 100%;
    margin: 20px 0 40px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-11 p {
    width: 100%;
    margin: 20px 0 40px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-11 p {
    width: 100%;
    margin: 5px 0 20px;
    font-size: 14px;
    line-height: 30px;
  }
}

.social-icon-2 {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .social-icon-2 {
    left: 5px;
  }
}

.social-icon-2 a {
  display: block;
  margin-bottom: 40px;
  color: #CFCFCF;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .social-icon-2 a {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.social-icon-2 a:last-child {
  margin-bottom: 0;
}

.social-icon-2 a:hover {
  color: #0A1039;
}

.login-cart-position {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .login-cart-position {
    right: 8px;
  }
}

.login-cart-position .same-style-2 {
  margin: 0 0 38px;
}

.login-cart-position .same-style-2:last-child {
  margin: 0 0 0px;
}

.login-cart-position .same-style-2 a {
  font-size: 22px;
  color: #D8D8D8;
}

.login-cart-position .same-style-2 a:hover {
  color: #0A1039;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-cart-position .same-style-2 a.cart-active {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .login-cart-position .same-style-2 a {
    font-size: 16px;
  }
  .login-cart-position .same-style-2 a.cart-active {
    display: none;
  }
}

.slider-fashion-4-plr .container-fluid {
  padding: 0 50px;
}

@media only screen and (max-width: 767px) {
  .slider-fashion-4-plr .container-fluid {
    padding: 0 30px;
  }
}

.sidebar-slider-area {
  margin-left: 285px;
  height: 100vh;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-slider-area {
    margin-left: 230px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-slider-area {
    margin-left: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-slider-area {
    margin-left: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-slider-area {
    margin-left: 0px;
    height: auto;
    margin-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-slider-area {
    margin-left: 0px;
    height: auto;
    margin-top: 72px;
  }
}

.sidebar-slider-area-2 {
  margin-left: 65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-slider-area-2 {
    margin-left: 0px;
    margin-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-slider-area-2 {
    margin-left: 0px;
    margin-top: 72px;
  }
}

.kid-2-bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.kid-2-bg-shape img {
  width: 100%;
  max-width: none;
}

.kids-2-slider .single-slider {
  margin-bottom: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-2-slider .single-slider {
    margin-bottom: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-2-slider .single-slider {
    margin-bottom: 85px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-2-slider .single-slider {
    margin-bottom: 50px;
  }
}

.slider-single-img-10 {
  position: relative;
  float: right;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .slider-single-img-10 {
    float: inherit;
    text-align: center;
  }
}

.slider-single-img-10 img {
  width: 501px;
  max-width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-single-img-10 img {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-img-10 img {
    width: 370px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-10 img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-10 img {
    width: 200px;
    display: inline-block;
  }
}

.slider-single-img-10 .slider-baby-img {
  position: absolute;
  left: -32%;
  top: 60px;
}

@media only screen and (max-width: 767px) {
  .slider-single-img-10 .slider-baby-img {
    left: 50%;
    transform: translateX(-50%);
  }
}

.slider-single-img-10 .slider-baby-img img {
  width: 273px;
  max-width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-single-img-10 .slider-baby-img img {
    width: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-img-10 .slider-baby-img img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-10 .slider-baby-img img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-10 .slider-baby-img img {
    width: 170px;
  }
}

.slider-single-img-10 .slider-trolley-img {
  position: absolute;
  left: -76%;
  top: 43%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-single-img-10 .slider-trolley-img {
    left: -73%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-10 .slider-trolley-img {
    left: 50%;
    top: 105%;
    transform: translateX(-50%);
  }
}

.slider-single-img-10 .slider-trolley-img img {
  width: 624px;
  max-width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-single-img-10 .slider-trolley-img img {
    width: 570px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-single-img-10 .slider-trolley-img img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-single-img-10 .slider-trolley-img img {
    width: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-single-img-10 .slider-trolley-img img {
    width: 370px;
    left: -68%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-single-img-10 .slider-trolley-img img {
    width: 370px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-12 {
    padding-left: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-12 {
    padding-left: 30px;
  }
}

.slider-content-12 h1 {
  font-size: 68px;
  line-height: 94px;
  margin: 0 0 17px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-12 h1 {
    font-size: 60px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-12 h1 {
    font-size: 45px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-12 h1 {
    font-size: 45px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-12 h1 {
    font-size: 45px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-12 h1 {
    font-size: 35px;
    line-height: 45px;
  }
}

.slider-content-12 h1 span.bold {
  font-weight: bold;
}

.slider-content-12 h1 span.blue {
  font-weight: bold;
  color: #1EA9D3;
}

.slider-content-12 p {
  font-size: 15px;
  line-height: 30px;
  width: 30%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-12 p {
    width: 50%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-12 p {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-12 p {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-12 p {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-12 p {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-12 p {
    width: 70%;
  }
}

.slider-content-13 {
  margin-left: -80px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-13 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-13 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-13 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-13 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-13 {
    margin-left: 0px;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-13 {
    margin-bottom: 0px;
    text-align: left;
  }
}

.slider-content-13 h1 {
  color: #ffffff;
  font-size: 58px;
  line-height: 98px;
  font-family: "Pacifico";
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-13 h1 {
    font-size: 45px;
    line-height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-13 h1 {
    font-size: 40px;
    line-height: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-13 h1 {
    font-size: 40px;
    line-height: 55px;
  }
}

.kids-2-slider-shape-2 {
  position: absolute;
  left: 0;
  top: 21%;
}

.kids-2-slider-shape-2 img {
  width: 31px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

.kids-2-slider-shape-1 {
  position: absolute;
  content: "";
  left: 19%;
  bottom: 78px;
  width: 59px;
  height: 59px;
  border-radius: 100%;
  z-index: 3;
  background-color: #FFF388;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-2-slider-shape-1 {
    bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-2-slider-shape-1 {
    bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-2-slider-shape-1 {
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-2-slider-shape-1 {
    left: 8%;
  }
}

.kids-2-slider-shape-3 {
  position: absolute;
  content: "";
  left: 37%;
  top: 35%;
  width: 0;
  height: 0;
  transform: rotate(45deg);
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 22px solid #4ADCD7;
  z-index: 3;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

.kids-2-slider-shape-4 {
  position: absolute;
  right: -1px;
  top: 0;
  z-index: 3;
}

.kids-2-slider-shape-4 img {
  width: 448px;
}

.kids-2-slider-shape-5 {
  position: absolute;
  right: 0%;
  bottom: 0;
  z-index: 3;
}

.kids-2-slider-shape-5 img {
  width: 297px;
}

@media only screen and (max-width: 767px) {
  .kids-2-slider-shape-5 img {
    width: 97px;
  }
}

.kids-2-slider-shape-6 {
  position: absolute;
  content: "";
  right: 18%;
  top: 2%;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  z-index: 3;
  background: linear-gradient(135deg, #00eaff 0%, #0fe2ff 6%, #38cdff 18%, #79acff 70%, #d27eff 100%, #f86cff 100%);
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-2-slider-shape-6 {
    right: 26%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-2-slider-shape-6 {
    right: 26%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-2-slider-shape-6 {
    right: 36%;
    top: 3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-2-slider-shape-6 {
    right: 36%;
    top: 4%;
  }
}

@media only screen and (max-width: 767px) {
  .kids-2-slider-shape-6 {
    right: 36%;
    top: 3%;
  }
}

.dot-style-4 .slick-dots {
  position: absolute;
  left: 30.7%;
  bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-4 .slick-dots {
    margin-bottom: 8px;
    bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-4 .slick-dots {
    margin-bottom: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-4 .slick-dots {
    left: 30px;
    top: 38%;
    bottom: auto;
  }
}

.dot-style-4 .slick-dots li button {
  font-size: 20px;
  color: #777777;
  transition: all .3s;
  position: relative;
  margin-bottom: 18px;
  border: none;
  padding: 0;
  background-color: transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-4 .slick-dots li button {
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-4 .slick-dots li button {
    font-size: 15px;
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-4 .slick-dots li button {
    font-size: 15px;
    margin-bottom: 8px;
  }
}

.dot-style-4 .slick-dots li button::after {
  position: absolute;
  transition: all .3s;
  z-index: 1;
  width: 30px;
  height: 2px;
  background-color: #0A1039;
  top: 50%;
  transform: translateY(-50%);
  left: 32px;
  content: "";
  opacity: 0;
}

.dot-style-4 .slick-dots li button::before {
  content: "0";
  display: inline-block;
  font-size: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-4 .slick-dots li button::before {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-4 .slick-dots li button::before {
    font-size: 15px;
  }
}

.dot-style-4 .slick-dots li.slick-active button {
  color: #0A1039;
}

.dot-style-4 .slick-dots li.slick-active button::after {
  opacity: 1;
  width: 87px;
}

.dot-style-4 .slick-dots li:last-child button {
  margin-bottom: 0px;
}

.dot-style-6-position-1 .slick-dots {
  position: absolute;
  left: 9%;
  bottom: 46px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .dot-style-6-position-1 .slick-dots {
    left: 7%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .dot-style-6-position-1 .slick-dots {
    left: 2%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-6-position-1 .slick-dots {
    left: 6%;
    bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-6-position-1 .slick-dots {
    left: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-6-position-1 .slick-dots {
    left: 5%;
  }
}

.dot-style-6-position-2 .slick-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .dot-style-6-position-2 .slick-dots {
    left: 15px;
    transform: none;
    bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dot-style-6-position-2 .slick-dots {
    left: 40px;
  }
}

.dot-style-6 .slick-dots {
  display: flex;
  flex-wrap: wrap;
}

.dot-style-6 .slick-dots li {
  margin-right: 36px;
}

.dot-style-6 .slick-dots li:last-child {
  margin-right: 0;
}

.dot-style-6 .slick-dots li button {
  font-size: 20px;
  color: #777777;
  transition: all .3s;
  position: relative;
  border: none;
  padding: 0;
  background-color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-6 .slick-dots li button {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-6 .slick-dots li button {
    font-size: 15px;
  }
}

.dot-style-6 .slick-dots li button::after {
  position: absolute;
  transition: all .3s;
  z-index: 1;
  width: 30px;
  height: 2px;
  background-color: #0A1039;
  top: 50%;
  transform: translateY(-50%);
  left: 37px;
  content: "";
  opacity: 0;
}

.dot-style-6 .slick-dots li button::before {
  content: "0";
  display: inline-block;
  font-size: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-6 .slick-dots li button::before {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-6 .slick-dots li button::before {
    font-size: 15px;
  }
}

.dot-style-6 .slick-dots li.slick-active {
  margin-right: 125px;
}

.dot-style-6 .slick-dots li.slick-active button {
  color: #0A1039;
}

.dot-style-6 .slick-dots li.slick-active button::after {
  opacity: 1;
  width: 87px;
}

.dot-style-6 .slick-dots li:last-child button {
  margin-bottom: 0px;
}

.fashion2-slider-bg1 {
  background: linear-gradient(to right, #F2F5F8, #F2F5F8 44%, #fff 0%);
}

.fashion2-slider-bg2 {
  background: linear-gradient(to right, #FFF0E6, #FFF0E6 44%, #fff 0%);
}

.fashion2-slider-bg3 {
  background: linear-gradient(to left, #FFF0F9, #FFF0F9 30%, #fff 0%);
}

.single-slider-fashion2 {
  padding-top: 30px;
}

.single-slider-fashion2 .fashion-2-slider-img1-1 {
  position: absolute;
  left: 34%;
  bottom: 3.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-slider-fashion2 .fashion-2-slider-img1-1 {
    left: 29%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-slider-fashion2 .fashion-2-slider-img1-1 {
    left: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-fashion2 .fashion-2-slider-img1-1 {
    left: 20%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion2 .fashion-2-slider-img1-1 {
    left: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img1-1 {
    left: 4%;
    bottom: 12.5%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img1-1 {
    left: 40%;
  }
}

.single-slider-fashion2 .fashion-2-slider-img1-1 img {
  width: 129px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion2 .fashion-2-slider-img1-1 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img1-1 img {
    width: 70px;
  }
}

.single-slider-fashion2 .fashion-2-slider-img1-2 {
  position: absolute;
  right: .7%;
  bottom: 3.5%;
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img1-2 {
    right: 4%;
    bottom: 12.5%;
  }
}

.single-slider-fashion2 .fashion-2-slider-img1-2 img {
  width: 129px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion2 .fashion-2-slider-img1-2 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img1-2 img {
    width: 70px;
  }
}

.single-slider-fashion2 .fashion-2-slider-img1-3 {
  position: absolute;
  left: 39.5%;
  top: 31%;
}

.single-slider-fashion2 .fashion-2-slider-img1-3 img {
  width: 175px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion2 .fashion-2-slider-img1-3 img {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img1-3 img {
    width: 100px;
  }
}

.single-slider-fashion2 .fashion-2-slider-img1-4 {
  position: absolute;
  right: 2.3%;
  top: 11%;
}

.single-slider-fashion2 .fashion-2-slider-img1-4 img {
  width: 111px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion2 .fashion-2-slider-img1-4 img {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img1-4 img {
    width: 70px;
    top: 2%;
  }
}

.single-slider-fashion2 .fashion-2-slider-img2-1 {
  position: absolute;
  left: 38%;
  top: 7%;
}

.single-slider-fashion2 .fashion-2-slider-img2-1 img {
  width: 175px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion2 .fashion-2-slider-img2-1 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img2-1 img {
    width: 100px;
  }
}

.single-slider-fashion2 .fashion-2-slider-img2-2 {
  position: absolute;
  right: 3.5%;
  top: 5%;
}

.single-slider-fashion2 .fashion-2-slider-img2-2 img {
  width: 149px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion2 .fashion-2-slider-img2-2 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img2-2 img {
    width: 70px;
  }
}

.single-slider-fashion2 .fashion-2-slider-img3-1 {
  position: absolute;
  left: 38%;
  top: 7%;
}

.single-slider-fashion2 .fashion-2-slider-img3-1 img {
  width: 175px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-fashion2 .fashion-2-slider-img3-1 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-fashion2 .fashion-2-slider-img3-1 img {
    width: 100px;
  }
}

.slider-single-img-11 {
  position: relative;
  z-index: 9;
}

.slider-single-img-11 img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .slider-single-img-11 {
    text-align: center;
  }
}

.slider-content-14 {
  margin: -30px 0 0 0px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .slider-content-14 {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-14 {
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.slider-content-14 h1 {
  font-size: 68px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-14 h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-14 h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-14 h1 {
    font-size: 30px;
  }
}

.slider-content-14 h2 {
  font-size: 117px;
  font-family: "Vandella";
  color: #D3854C;
  margin: 33px 0 0px;
  line-height: 127px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-14 h2 {
    font-size: 90px;
    margin: 10px 0 0px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-14 h2 {
    font-size: 80px;
    margin: 10px 0 0px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content-14 h2 {
    font-size: 70px;
    margin: 20px 0 0px;
    line-height: 70px;
  }
}

.slider-content-14 h3 {
  font-size: 57px;
  font-weight: 300;
  margin: 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .slider-content-14 h3 {
    font-size: 50px;
  }
}

.fashion2-logo-position {
  position: absolute;
  left: 45px;
  top: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fashion2-logo-position {
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion2-logo-position {
    left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fashion2-logo-position {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .fashion2-logo-position {
    display: none;
  }
}

.furniture-slider-shape-1 {
  position: absolute;
  content: "";
  left: 11%;
  bottom: 23%;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .furniture-slider-shape-1 {
    bottom: 15%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-slider-shape-1 {
    bottom: 15%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-slider-shape-1 {
    bottom: 15%;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-slider-shape-1 {
    left: 5%;
    bottom: 0%;
  }
}

.furniture-slider-shape-1 img {
  width: 30px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

.furniture-slider-shape-2 {
  position: absolute;
  content: "";
  left: 29%;
  top: 16%;
  z-index: 9;
}

.furniture-slider-shape-2 img {
  width: 173px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .furniture-slider-shape-2 img {
    width: 100px;
    top: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-slider-shape-2 img {
    width: 100px;
    top: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-slider-shape-2 img {
    width: 100px;
    top: 20%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-slider-shape-2 img {
    width: 100px;
    top: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-slider-shape-2 img {
    width: 100px;
    top: 20%;
  }
}

.furniture-slider-shape-3 {
  position: absolute;
  content: "";
  width: 59px;
  height: 59px;
  background-color: #FFF388;
  border-radius: 100%;
  left: 0%;
  bottom: 17%;
  z-index: 9;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .furniture-slider-shape-3 {
    left: 2%;
    bottom: 10%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-slider-shape-3 {
    left: 2%;
    bottom: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-slider-shape-3 {
    left: 2%;
    bottom: 8%;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-slider-shape-3 {
    left: 50%;
    bottom: 35%;
  }
}

.furniture-slider-shape-4 {
  position: absolute;
  content: "";
  left: 31.3%;
  top: 26%;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 22px solid #4ADCD7;
  z-index: 9;
  transform: rotate(45deg);
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .furniture-slider-shape-4 {
    top: 20%;
  }
}

.img-max-width .slick-dots li button img {
  max-width: 100%;
}

/*---------- 4. Coupon style ------------*/
.single-coupon-wrap {
  background-color: #F4F4F4;
  padding: 8px 0px;
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}

.single-coupon-wrap::before {
  background-image: url(../../assets/images/icon-img/dot.png);
  position: absolute;
  content: "";
  left: 36%;
  top: 0px;
  background-repeat: repeat-y;
  display: block;
  width: 7px;
  height: 100%;
}

.single-coupon-wrap .single-coupon-left {
  background-color: #fff;
  padding: 37px 5px 31px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.single-coupon-wrap .single-coupon-left h3 {
  font-size: 25px;
  font-weight: 400;
  line-height: 33px;
  margin: 0;
}

.single-coupon-wrap .single-coupon-left h3 span {
  font-weight: bold;
}

.single-coupon-wrap .single-coupon-right {
  background-color: #fff;
  padding: 33px 5px;
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.single-coupon-wrap .single-coupon-right p {
  line-height: 19px;
}

.single-coupon-wrap .single-coupon-right h2 {
  color: #232953;
  font-weight: bold;
  margin: 10px 0 0;
  font-size: 20px;
}

.single-coupon-wrap .coupon-width-1 {
  flex: 0 0 37%;
  max-width: 37%;
  padding-left: 9px;
  padding-right: 9px;
}

.single-coupon-wrap .coupon-width-2 {
  flex: 0 0 63%;
  max-width: 63%;
  padding-right: 9px;
  padding-left: 9px;
}

/*---------- 5. Banner style ------------*/
.banner-area {
  position: relative;
}

.banner-area .banner-img-position {
  position: absolute;
  left: 33%;
  bottom: 4%;
}

.banner-area .banner-img-position img {
  width: 459px;
}

@media only screen and (max-width: 767px) {
  .banner-area .banner-img-position img {
    width: 259px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-area .banner-img-position {
    left: 23%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-area .banner-img-position {
    left: 17%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area .banner-img-position {
    left: 8%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area .banner-img-position {
    left: 8%;
  }
}

.banner-wrap {
  position: relative;
  overflow: hidden;
}

.banner-wrap.banner-bg-color-azure {
  background-color: #E8FCFF;
}

.banner-wrap.banner-bg-color-honeydew {
  background-color: #EDFFF0;
}

.banner-wrap.banner-mrg-left-1 {
  margin-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap.banner-mrg-left-1 {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.banner-mrg-left-1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-mrg-left-1 {
    margin-left: 0px;
  }
}

.banner-wrap.banner-mrg-right-1 {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap.banner-mrg-right-1 {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.banner-mrg-right-1 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-mrg-right-1 {
    margin-right: 0px;
  }
}

.banner-wrap .banner-img a {
  display: block;
}

.banner-wrap .banner-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.banner-wrap .banner-img.banner-img-overflow {
  overflow: hidden;
}

.banner-wrap .banner-position-1 {
  position: absolute;
  right: 42px;
  top: 87px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-1 {
    right: 20px;
    top: 92px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    right: 30px;
  }
}

.banner-wrap .banner-position-2 {
  position: absolute;
  right: 50px;
  bottom: 70px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-2 {
    right: 20px;
    bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-2 {
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-2 {
    right: 30px;
  }
}

.banner-wrap .banner-position-3 {
  position: absolute;
  top: 52px;
  left: 200px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-3 {
    left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-3 {
    top: 52px;
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-3 {
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-3 {
    left: 30px;
  }
}

.banner-wrap .banner-position-4 {
  position: absolute;
  top: 52px;
  left: 320px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-4 {
    left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-4 {
    top: 50px;
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-4 {
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-4 {
    left: 30px;
  }
}

.banner-wrap .banner-position-5 {
  position: absolute;
  left: 90px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-5 {
    left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-5 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-5 {
    left: 15px;
  }
}

.banner-wrap .banner-position-6 {
  position: absolute;
  right: 90px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-6 {
    right: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-6 {
    right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-6 {
    right: 15px;
  }
}

.banner-wrap .banner-position-7 {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-7 {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-7 {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-7 {
    right: 20px;
  }
}

.banner-wrap .banner-position-8 {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-8 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-8 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-8 {
    left: 20px;
  }
}

.banner-wrap .banner-position-9 {
  position: absolute;
  left: 0px;
  right: 0;
  text-align: center;
  bottom: 53px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-9 {
    bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-9 {
    bottom: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-9 {
    bottom: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-9 {
    bottom: 44px;
  }
}

.banner-wrap .banner-position-10 {
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translateY(-50%);
}

.banner-wrap .banner-position-11 {
  position: absolute;
  left: 73px;
  bottom: 43px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-position-11 {
    bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-11 {
    left: 30px;
    bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-11 {
    bottom: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-11 {
    bottom: 48px;
  }
}

.banner-wrap .banner-position-13-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  text-align: center;
}

.banner-wrap .banner-position-13-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 55%;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-13-2 {
    left: 45%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-13-2 {
    left: 45%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-position-13-2 {
    left: 50%;
  }
}

.banner-wrap .banner-content-13 h4 {
  color: #F5691C;
  font-size: 70px;
  margin: 0 0 15px;
  font-family: "Vandella";
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-13 h4 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-13 h4 {
    font-size: 40px;
    margin: 0 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 h4 {
    font-size: 30px;
    margin: 0 0 8px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-13 h4 {
    font-size: 50px;
    margin: 0 0 20px;
  }
}

.banner-wrap .banner-content-13 p {
  color: #271003;
  font-size: 26px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-13 p {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-13 p {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 p {
    font-size: 15px;
    line-height: 1;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-13 p {
    font-size: 20px;
  }
}

.banner-wrap .b-content-14-position-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

.banner-wrap .b-content-14-position-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.banner-wrap .b-content-14-position-3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

.banner-wrap .banner-content-14 h2 {
  font-size: 58px;
  font-family: "JellyBelly Font";
  margin: 0 0 10px;
  line-height: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 46px;
    line-height: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 40px;
    line-height: 43px;
    margin: 0 0 3px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 45px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 50px;
  }
}

.banner-wrap .banner-content-14 h2.pink {
  color: #E21655;
}

.banner-wrap .banner-content-14 h2.orange {
  color: #D48817;
}

.banner-wrap .banner-content-14 h2.green {
  color: #00AE5A;
}

.banner-wrap .banner-content-14 h2.purple {
  color: #7E4FBB;
}

.banner-wrap .banner-content-14 h2.blue {
  color: #1E5DB2;
}

.banner-wrap .banner-content-14 p {
  color: #06103A;
  font-size: 20px;
  font-family: "Work Sans";
}

.banner-wrap .banner-content-15 {
  position: absolute;
  left: 55px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-15 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-15 {
    left: 30px;
  }
}

.banner-wrap .banner-content-15 h2 {
  font-size: 36px;
  font-weight: bold;
  margin: 0 0 10px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 26px;
  }
}

.banner-wrap .banner-content-15 h3 {
  font-size: 36px;
  font-weight: 300;
  margin: 0 0 0px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-15 h3 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-15 h3 {
    font-size: 26px;
  }
}

.banner-wrap .banner-content-16 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 34%;
}

.banner-wrap .banner-content-16 h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 0px;
  line-height: 30px;
}

.banner-wrap .banner-content-16 h3 a {
  color: #333333;
}

.banner-wrap .banner-content-16 h3 a:hover {
  color: #CF7F39;
}

.banner-wrap .banner-content-17 {
  position: absolute;
  left: 50%;
  top: 17%;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-17 {
    left: 43%;
  }
}

.banner-wrap .banner-content-17 h3 {
  font-size: 29px;
  margin: 0 0 0px;
  line-height: 56px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-17 h3 {
    font-size: 25px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-17 h3 {
    font-size: 22px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-17 h3 {
    font-size: 15px;
    line-height: 25px;
  }
}

.banner-wrap .banner-content-17 h3 a {
  color: #262836;
}

.banner-wrap .banner-content-17 h3 a:hover {
  color: #D02F3B;
}

.banner-wrap .banner-18-position-1 {
  position: absolute;
  left: 14%;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-18-position-1 {
    left: 7%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-18-position-1 {
    left: 6%;
  }
}

.banner-wrap .banner-18-position-2 {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-18-position-2 {
    right: 7%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-18-position-2 {
    right: 6%;
  }
}

.banner-wrap .banner-18-position-3 {
  position: absolute;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-18-position-3 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-18-position-3 {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-18-position-3 {
    left: 20px;
  }
}

.banner-wrap .banner-content-18 h2 {
  font-size: 29px;
  font-weight: 500;
  margin: 0;
}

.banner-wrap .banner-content-18 h2.bold {
  font-weight: bold;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-18 h2 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-18 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-18 h2 {
    font-size: 18px;
  }
}

.banner-wrap .banner-content-18 h3 {
  font-size: 60px;
  font-weight: bold;
  margin: 16px 0 52px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-18 h3 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-18 h3 {
    font-size: 40px;
    margin: 16px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-18 h3 {
    font-size: 30px;
    margin: 12px 0 10px;
  }
}

.banner-wrap .banner-content-18.banner-content-18-white h2 {
  color: #ffffff;
}

.banner-wrap .banner-content-18.banner-content-18-white h3 {
  color: #ffffff;
}

.banner-wrap .banner-content-19 {
  position: absolute;
  right: 24px;
  top: 110px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19 {
    right: 15px;
    top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-19 {
    right: 20px;
    top: 100px;
  }
}

.banner-wrap .banner-content-19 h2 {
  color: #ffffff;
  font-size: 56px;
  margin: 0 0 21px;
  line-height: 45px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19 h2 {
    font-size: 40px;
  }
}

.banner-wrap .banner-content-19 span {
  color: #ffffff;
  font-size: 20px;
  display: block;
  line-height: 1;
}

.banner-wrap .banner-content-19 h1 {
  color: #ffffff;
  font-size: 95px;
  margin: 5px 0 0;
  line-height: 70px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19 h1 {
    font-size: 60px;
  }
}

.banner-wrap .banner-20-position-1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 36px;
  text-align: center;
}

.banner-wrap .banner-20-position-2 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 36px;
  text-align: center;
}

.banner-wrap .banner-content-20 h3 {
  color: #ffffff;
  font-size: 30px;
  margin: 0 0 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-20 h3 {
    font-size: 26px;
  }
}

.banner-wrap .banner-content-20 span {
  color: #ffffff;
  font-size: 22px;
  display: block;
  line-height: 1;
}

.banner-wrap .banner-content-21 {
  position: absolute;
  top: 10%;
  right: 0;
  border: 5px solid #fff;
  border-right: none;
  padding: 18px 35px 10px 20px;
}

.banner-wrap .banner-content-21 h3 {
  font-size: 36px;
  font-weight: 300;
  line-height: 1;
  margin: 0 0 13px;
}

.banner-wrap .banner-content-21 h3 span {
  font-weight: bold;
}

.banner-wrap .banner-content-21 p {
  font-size: 18px;
  color: #151C0F;
  line-height: 1;
}

.banner-wrap .banner-offer-1 {
  width: 112px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/banner/banner-shape-electric.png);
}

.banner-wrap .banner-offer-1 h3 {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-offer-1 h3 {
    font-size: 18px;
    line-height: 22px;
  }
}

.banner-wrap .banner-content-1 h4 {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin: 0 0 11px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 h4 {
    font-size: 20px;
    margin: 0 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h4 {
    font-size: 20px;
    margin: 0 0 5px;
  }
}

.banner-wrap .banner-content-1 h4 a {
  color: #333;
}

.banner-wrap .banner-content-1 h4 a:hover {
  color: #232953;
}

.banner-wrap .banner-content-1 p {
  font-size: 18px;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 p {
    font-size: 16px;
    color: #333;
  }
}

.banner-wrap .banner-content-2 {
  padding: 0 0 0 87px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 {
    padding: 0 0 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 {
    padding: 0 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 {
    padding: 0 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 {
    padding: 30px 0 30px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 {
    padding: 30px 0 30px 30px;
  }
}

.banner-wrap .banner-content-2 h2 {
  font-size: 29px;
  font-weight: 500;
  line-height: 43px;
  margin: 0 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 25px;
    line-height: 32px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 25px;
    line-height: 32px;
    margin: 0 0 15px;
  }
}

.banner-wrap .banner-content-3-wrap {
  position: absolute;
  bottom: 33px;
  left: 0px;
  right: 0px;
  margin: 0 43px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 47px 60px 46px 50px;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-3-wrap {
    padding: 47px 40px 46px 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-3-wrap {
    margin: 0 20px;
    padding: 47px 30px 46px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3-wrap {
    margin: 0 20px;
    padding: 47px 30px 46px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3-wrap {
    margin: 0 20px;
    padding: 30px 20px 30px 20px;
  }
}

.banner-wrap .banner-content-3-wrap .banner-content-3 h3 {
  font-size: 29px;
  font-weight: 500;
  margin: 0 0 18px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3-wrap .banner-content-3 h3 {
    font-size: 25px;
    margin: 0 0 12px;
  }
}

.banner-wrap .banner-content-3-wrap .banner-content-3 h3 a {
  color: #333333;
}

.banner-wrap .banner-content-3-wrap .banner-content-3 h3 a:hover {
  color: #0A1039;
}

.banner-wrap .banner-content-3-wrap .banner-content-3 p {
  color: #777;
  font-size: 15px;
  margin: 0;
}

.banner-wrap .banner-content-3-wrap .banner-btn a {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3-wrap .banner-btn a {
    font-size: 16px;
  }
}

.banner-wrap .banner-content-3-wrap .banner-btn a i {
  color: #333333;
  font-size: 8px;
  display: inline-block;
  line-height: 1;
  position: relative;
  top: -2px;
  margin-left: 9px;
  transition: all .5s ease 0s;
}

.banner-wrap .banner-content-3-wrap .banner-btn a:hover {
  color: #0A1039;
}

.banner-wrap .banner-content-3-wrap .banner-btn a:hover i {
  margin-left: 15px;
  color: #0A1039;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3-wrap .banner-btn {
    margin-top: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3-wrap .banner-btn {
    margin-top: 8px;
  }
}

.banner-wrap .banner-content-4 h3 {
  font-size: 29px;
  font-weight: 500;
  margin: 0 0 0px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 h3 {
    font-size: 22px;
  }
}

.banner-wrap .banner-content-4 h3 a {
  color: #333333;
}

.banner-wrap .banner-content-4 h3 a:hover {
  color: #0A1039;
}

.banner-wrap .banner-content-4 p {
  color: #777;
  font-size: 15px;
  margin: 22px 0 65px;
  line-height: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-4 p {
    margin: 22px 0 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 p {
    margin: 15px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 p {
    line-height: 21px;
    font-size: 13px;
    margin: 5px 0 2px;
  }
}

.banner-wrap .banner-content-4 .banner-btn-2 a {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 .banner-btn-2 a {
    font-size: 14px;
  }
}

.banner-wrap .banner-content-4 .banner-btn-2 a i {
  color: #333333;
  font-size: 8px;
  display: inline-block;
  line-height: 1;
  position: relative;
  top: -2px;
  margin-left: 9px;
  transition: all .5s ease 0s;
}

.banner-wrap .banner-content-4 .banner-btn-2 a:hover {
  color: #0A1039;
}

.banner-wrap .banner-content-4 .banner-btn-2 a:hover i {
  margin-left: 15px;
  color: #0A1039;
}

.banner-wrap .banner-content-5 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 59px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 {
    bottom: 30px;
  }
}

.banner-wrap .banner-content-5 h3 {
  font-size: 36px;
  font-weight: 800;
  color: #D57939;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 h3 {
    font-size: 32px;
  }
}

.banner-wrap .banner-content-5 h3 a {
  color: #D57939;
}

.banner-wrap .banner-content-7 h3 {
  font-size: 29px;
  font-weight: 500;
  line-height: 43px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7 h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-7 h3 {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 h3 {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-7 h3 {
    font-size: 22px;
    line-height: 30px;
  }
}

.banner-wrap .banner-content-7 h3 a {
  color: #333;
}

.banner-wrap .banner-content-7 h3 a:hover {
  color: #666;
}

.banner-wrap .banner-content-7 p {
  font-size: 15px;
  line-height: 30px;
  margin: 12px 0 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7 p {
    margin: 5px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-7 p {
    font-size: 14px;
    line-height: 26px;
    margin: 5px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 p {
    font-size: 14px;
    line-height: 23px;
    margin: 5px 0 8px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-7 p {
    margin: 12px 0 19px;
  }
}

.banner-wrap .banner-content-7 .banner-btn-3 a {
  background-color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #222D39;
  line-height: 1;
  z-index: 2;
  position: relative;
  padding: 14px 55px 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-7 .banner-btn-3 a {
    padding: 14px 35px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 .banner-btn-3 a {
    padding: 10px 35px 11px;
  }
}

.banner-wrap .banner-content-7 .banner-btn-3 a:hover {
  color: #fff;
}

.banner-wrap .banner-content-8 h3 {
  font-size: 46px;
  font-family: "Pacifico";
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-8 h3 {
    font-size: 40px;
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 h3 {
    font-size: 40px;
    line-height: 1;
  }
}

.banner-wrap .banner-content-8 h3 a {
  color: #3C1709;
}

.banner-wrap .banner-content-8 h3 a:hover {
  color: #885643;
}

.banner-wrap .banner-content-8 span {
  font-size: 31px;
  font-weight: 500;
  color: #885643;
  display: block;
  line-height: 1;
  margin: 10px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-8 span {
    margin: 5px 0 0;
  }
}

.banner-wrap .banner-content-9 h3 {
  font-size: 36px;
  font-family: "Pacifico";
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-9 h3 {
    font-size: 26px;
  }
}

.banner-wrap .banner-content-9 h3 a {
  color: #3C1709;
}

.banner-wrap .banner-content-9 h3 a:hover {
  color: #885643;
}

.banner-wrap .banner-content-10 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 {
    top: 40%;
    left: 30%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-10 {
    top: 50%;
    left: 50%;
  }
}

.banner-wrap .banner-content-10 h2 {
  color: #ffffff;
  font-size: 89px;
  font-family: "Vandella";
  margin: 0 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 64px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 50px;
  }
}

.banner-wrap .banner-content-10 h3 {
  color: #ffffff;
  font-size: 33px;
  font-weight: 500;
  margin: 0 0 0px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-10 h3::before {
  content: url(../../assets/images/icon-img/organic-banner-shape-1.png);
  position: absolute;
  left: 0;
  top: -37px;
  bottom: auto;
}

.banner-wrap .banner-content-10 h3::after {
  content: url(../../assets/images/icon-img/organic-banner-shape-1.png);
  position: absolute;
  left: 0;
  bottom: -30px;
  top: auto;
}

.banner-wrap .banner-content-11 {
  position: absolute;
  left: 100px;
  top: 21%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-11 {
    left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 {
    left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 {
    left: 30px;
    top: 30%;
  }
}

.banner-wrap .banner-content-11 h2 {
  font-size: 40px;
  font-weight: bold;
  margin: 0 0 5px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-11 h2 {
    font-size: 40px;
  }
}

.banner-wrap .banner-content-11 h1 {
  font-size: 98px;
  font-weight: bold;
  margin: 0 0 0px;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-11 h1 {
    font-size: 40px;
  }
}

.banner-wrap .banner-content-11 h1 span {
  font-size: 79px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 h1 span {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 h1 span {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h1 span {
    font-size: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-11 h1 span {
    font-size: 40px;
  }
}

.banner-wrap .banner-content-12 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-12 {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 {
    right: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-12 {
    right: 50px;
  }
}

.banner-wrap .banner-content-12 h3 {
  font-weight: bold;
  font-size: 30px;
  font-family: "Work Sans";
  color: #06103A;
  margin: 0;
  line-height: 1;
}

.banner-wrap .banner-content-12 h2 {
  font-size: 58px;
  font-family: "Chewy";
  margin: 0 0 10px;
  line-height: 1;
}

.banner-wrap .banner-content-12 h2.pink {
  color: #E21655;
}

.banner-wrap .banner-content-12 h2.blue {
  color: #13B7EB;
}

.banner-wrap .banner-content-12 h2.green {
  color: #0EC256;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 58px;
  }
}

.banner-wrap .banner-content-12 span {
  color: #06103A;
  font-size: 20px;
  font-family: "Work Sans";
}

.banner-wrap .banner-content-wrap {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.banner-wrap .banner-content-wrap .banner-content-6 {
  display: inline-block;
  padding: 36px 0;
  border-top: 4px solid #ffffff;
  border-bottom: 4px solid #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-wrap .banner-content-6 {
    padding: 26px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-wrap .banner-content-6 {
    padding: 16px 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-wrap .banner-content-6 {
    padding: 16px 0;
  }
}

.banner-wrap .banner-content-wrap .banner-content-6 h2 {
  font-size: 46px;
  font-weight: 500;
  margin: 0 0 20px;
  line-height: 37px;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-wrap .banner-content-6 h2 {
    font-size: 36px;
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-wrap .banner-content-6 h2 {
    font-size: 32px;
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-wrap .banner-content-6 h2 {
    font-size: 28px;
    margin: 0 0 10px;
  }
}

.banner-wrap .banner-content-wrap .banner-content-6 h4 {
  font-size: 27px;
  font-weight: 500;
  margin: 0 0 0px;
  line-height: 25px;
  color: #ffffff;
}

.banner-wrap .banner-content-23-position1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-23-position1 {
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-23-position1 {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-23-position1 {
    right: 15px;
  }
}

.banner-wrap .banner-content-23-position2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-23-position2 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-23-position2 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-23-position2 {
    left: 15px;
  }
}

.banner-wrap .banner-content-23 h3 {
  color: #262836;
  font-size: 26px;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-23 h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-23 h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-23 h3 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-23 h2 {
  color: #262836;
  font-size: 50px;
  margin: 33px 0 71px;
  font-weight: 300;
  line-height: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-23 h2 {
    font-size: 40px;
    margin: 17px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-23 h2 {
    font-size: 40px;
    margin: 17px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-23 h2 {
    font-size: 40px;
    margin: 17px 0 10px;
  }
}

.banner-wrap .banner-content-23 h2 span {
  font-weight: bold;
}

.banner-wrap.banner-23-mrg-1 {
  margin-right: -30px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-23-mrg-1 {
    margin-right: 0px;
  }
}

.banner-wrap.banner-23-mrg-2 {
  margin-left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.banner-23-mrg-2 {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-23-mrg-2 {
    margin-left: 0px;
  }
}

.banner-wrap.banner-23-mrg-3 {
  margin-right: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.banner-23-mrg-3 {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-23-mrg-3 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.banner-width-flex-50 {
    flex: 0 0 50%;
  }
  .banner-wrap.mt-125 {
    margin-top: 50px;
  }
  .banner-wrap.mb-70 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap.mt-125 {
    margin-top: 0px;
  }
  .banner-wrap.mt-55 {
    margin-top: 0px;
  }
  .banner-wrap.mb-70 {
    margin-bottom: 30px;
  }
}

.banner-wrap:hover .banner-img a img {
  transform: scale(1.1);
}

.banner-categore-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 485px;
  background-color: #E09850;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-categore-wrap {
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-categore-wrap {
    width: 350px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-categore-wrap {
    width: 270px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-categore-wrap {
    width: 100%;
    position: static;
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .banner-categore-wrap {
    width: 100%;
    position: static;
    display: block;
  }
}

.banner-categore-wrap .handcraft-categore-list {
  padding: 70px 80px 70px 117px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-categore-wrap .handcraft-categore-list {
    padding: 70px 70px 70px 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-categore-wrap .handcraft-categore-list {
    padding: 70px 40px 70px 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-categore-wrap .handcraft-categore-list {
    padding: 70px 20px 70px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-categore-wrap .handcraft-categore-list {
    padding: 50px 30px 50px 30px;
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-categore-wrap .handcraft-categore-list {
    padding: 50px 30px 50px 30px;
  }
}

.banner-categore-wrap .handcraft-categore-list ul li {
  display: block;
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-categore-wrap .handcraft-categore-list ul li {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-categore-wrap .handcraft-categore-list ul li {
    margin-bottom: 25px;
  }
}

.banner-categore-wrap .handcraft-categore-list ul li:last-child {
  margin-bottom: 0;
}

.banner-categore-wrap .handcraft-categore-list ul li a {
  color: #ffffff;
  font-size: 18px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-categore-wrap .handcraft-categore-list ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-categore-wrap .handcraft-categore-list ul li a {
    font-size: 16px;
  }
}

.banner-categore-wrap .handcraft-categore-list ul li a i {
  font-size: 20px;
  position: relative;
  top: 2px;
  margin-left: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-categore-wrap .handcraft-categore-list ul li a i {
    font-size: 17px;
  }
}

.banner-categore-wrap .handcraft-categore-list ul li a:hover {
  font-weight: bold;
}

.banner-btn-4 {
  margin: 22px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-btn-4 {
    margin: 6px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-btn-4 {
    margin: 10px 0 0;
  }
}

.banner-btn-4 a {
  display: inline-block;
  color: #885643;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-btn-4 a {
    font-size: 14px;
  }
}

.banner-btn-4 a svg {
  margin-left: 22px;
}

.banner-btn-4 a svg.arrow-mrg-dec {
  margin-left: 13px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-btn-4 a svg {
    margin-left: 4px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-btn-4 a svg {
    margin-left: 15px;
  }
}

.banner-btn-4 a svg path {
  fill: #885643;
}

.banner-btn-4 a:hover {
  color: #3C1709;
}

.banner-btn-4 a:hover svg path {
  fill: #3C1709;
}

.banner-btn-4.white a {
  color: #ffffff;
}

.banner-btn-4.white a svg path {
  fill: #ffffff;
}

.banner-btn-4.white a:hover {
  color: #0A1039;
}

.banner-btn-4.white a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.green-2 a {
  color: #359043;
}

.banner-btn-4.green-2 a svg path {
  fill: #359043;
}

.banner-btn-4.green-2 a:hover {
  color: #0A1039;
}

.banner-btn-4.green-2 a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.green-3 a {
  color: #62BC77;
}

.banner-btn-4.green-3 a svg path {
  fill: #62BC77;
}

.banner-btn-4.green-3 a:hover {
  color: #0A1039;
}

.banner-btn-4.green-3 a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.pink a {
  color: #D60B5E;
}

.banner-btn-4.pink a svg path {
  fill: #D60B5E;
}

.banner-btn-4.pink a:hover {
  color: #0A1039;
}

.banner-btn-4.pink a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.black a {
  color: #333333;
  font-size: 16px;
}

.banner-btn-4.black a svg path {
  fill: #333333;
}

.banner-btn-4.black a:hover {
  color: #0A1039;
}

.banner-btn-4.black a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.black-2 a {
  color: #333333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-btn-4.black-2 a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.black-2 a {
    font-size: 16px;
  }
}

.banner-btn-4.black-2 a svg path {
  fill: #333333;
}

.banner-btn-4.black-2 a:hover {
  color: #0A1039;
}

.banner-btn-4.black-2 a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.banner-btn-4-kids-2 {
  margin: 70px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-btn-4.banner-btn-4-kids-2 {
    margin: 40px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-btn-4.banner-btn-4-kids-2 {
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.banner-btn-4-kids-2 {
    margin: 10px 0 0;
  }
}

.banner-btn-4.banner-btn-4-kids-2 a {
  color: #333333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-btn-4.banner-btn-4-kids-2 a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-btn-4.banner-btn-4-kids-2 a svg {
    margin-left: 10px;
  }
}

.banner-btn-4.banner-btn-4-kids-2 a svg path {
  fill: #624BE0;
}

.banner-btn-4.banner-btn-4-kids-2 a:hover {
  color: #0A1039;
}

.banner-btn-4.banner-btn-4-kids-2 a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.banner-btn-4-modify {
  margin: 11px 0 0;
}

.banner-btn-4.banner-btn-4-modify a {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
}

.banner-btn-4.banner-btn-4-modify a svg path {
  fill: #333333;
}

.banner-btn-4.banner-btn-4-modify a:hover {
  color: #0A1039;
}

.banner-btn-4.banner-btn-4-modify a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.banner-btn-4-modify.modify2-mt-65 {
  margin-top: 65px;
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.banner-btn-4-modify.modify2-mt-65 {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.banner-btn-4.banner-btn-4-right {
  float: right;
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.banner-btn-4-right {
    float: inherit;
    text-align: center;
  }
}

.banner-btn-4.banner-btn-4-mrg-none {
  margin: 0px 0 0;
}

.banner-btn-4.banner-btn-4-automobile {
  margin-top: 37px;
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.banner-btn-4-automobile {
    margin-top: 10px;
  }
}

.banner-btn-4.banner-btn-4-automobile a {
  color: #D02F3B;
}

.banner-btn-4.banner-btn-4-automobile a svg path {
  fill: #D02F3B;
}

.banner-btn-4.banner-btn-4-automobile a:hover {
  color: #0A1039;
}

.banner-btn-4.banner-btn-4-automobile a:hover svg path {
  fill: #0A1039;
}

.banner-btn-4.banner-btn-4-automobile-2 {
  margin-top: 121px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-btn-4.banner-btn-4-automobile-2 {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-btn-4.banner-btn-4-automobile-2 {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-btn-4.banner-btn-4-automobile-2 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.banner-btn-4-automobile-2 {
    margin-top: 15px;
  }
}

.banner-btn-4.banner-btn-4-automobile-2 a {
  color: #333333;
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.banner-btn-4-automobile-2 a {
    font-size: 14px;
  }
}

.banner-btn-4.banner-btn-4-automobile-2 a svg path {
  fill: #333333;
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.banner-btn-4-automobile-2 a svg {
    margin-left: 10px;
  }
}

.banner-btn-4.banner-btn-4-automobile-2 a:hover {
  color: #D02F3B;
}

.banner-btn-4.banner-btn-4-automobile-2 a:hover svg path {
  fill: #D02F3B;
}

.banner-btn-4.banner-btn-4-tree {
  margin: 42px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-btn-4.banner-btn-4-tree {
    margin: 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-btn-4.banner-btn-4-tree {
    margin: 0px 0 0;
  }
}

.flower-banner-wrap {
  position: relative;
  padding: 275px 0 263px;
  overflow: hidden;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .flower-banner-wrap {
    padding: 212px 0 203px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .flower-banner-wrap {
    padding: 192px 0 183px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flower-banner-wrap {
    padding: 172px 0 163px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-banner-wrap {
    padding: 172px 0 163px;
    margin-bottom: 30px;
  }
}

.flower-banner-wrap .flower-banner-img {
  position: absolute;
  left: 0;
  bottom: 5px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-banner-wrap .flower-banner-img {
    bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-banner-wrap .flower-banner-img {
    bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flower-banner-wrap .flower-banner-img {
    bottom: 40px;
  }
}

.flower-banner-wrap .flower-banner-img > a {
  display: block;
}

.flower-banner-wrap .flower-banner-img > a > img {
  width: 152px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-banner-wrap .flower-banner-img > a > img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-banner-wrap .flower-banner-img > a > img {
    width: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flower-banner-wrap .flower-banner-img > a > img {
    width: 120px;
  }
}

.flower-banner-wrap .flower-banner-content {
  float: right;
  display: block;
  margin-right: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .flower-banner-wrap .flower-banner-content {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-banner-wrap .flower-banner-content {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-banner-wrap .flower-banner-content {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flower-banner-wrap .flower-banner-content {
    margin-right: 50px;
  }
}

.flower-banner-wrap .flower-banner-content h2 {
  font-size: 60px;
  font-family: "Vandella";
  line-height: 80px;
  color: #518351;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flower-banner-wrap .flower-banner-content h2 {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-banner-wrap .flower-banner-content h2 {
    font-size: 40px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-banner-wrap .flower-banner-content h2 {
    font-size: 40px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flower-banner-wrap .flower-banner-content h2 {
    font-size: 50px;
    line-height: 70px;
  }
}

.kids-fashion-area {
  position: relative;
}

.kids-fashion-area .kids-fashion-shape-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.kids-fashion-area .kids-fashion-shape-top img {
  width: 100%;
}

.kids-fashion-area .kids-fashion-shape-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.kids-fashion-area .kids-fashion-shape-bottom img {
  width: 100%;
}

.kids-fashion-content h2 {
  font-size: 58px;
  font-family: "JellyBelly Font";
  margin: 0;
  color: #D6860C;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-fashion-content h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-fashion-content h2 {
    font-size: 32px;
  }
}

.kids-fashion-content h2 span.pink {
  color: #FF437D;
}

.kids-fashion-content h2 span.purple {
  color: #9758F6;
}

.kids-fashion-content p {
  font-size: 16px;
  font-weight: 300;
  color: #828495;
  margin: 40px 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-fashion-content p {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-fashion-content p {
    font-size: 14px;
    margin: 20px 0 20px;
  }
}

.kids-fashion-img {
  margin-left: -100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-fashion-img {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-fashion-img {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-fashion-img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .kids-fashion-img {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.kids-fashion-img img {
  width: 100%;
}

.banner-20-mt {
  margin-top: 76px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-20-mt {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-20-mt {
    margin-top: 0px;
  }
}

.mega-fashion1-banner-btn {
  background-color: #FBFBFB;
  padding: 55px 20px;
}

@media only screen and (max-width: 767px) {
  .mega-fashion1-banner-btn {
    padding: 35px 20px;
  }
}

.mega-fashion1-banner-btn.banner-btn-4 {
  margin: 15px 0 0;
}

.mega-fashion1-banner-btn.banner-btn-4 a svg {
  margin-left: 12px;
}

.mega-fashion-banner-bg1 {
  background-color: #F9F9F9;
  padding: 22px 20px 30px 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mega-fashion-banner-bg1 {
    padding: 30px 20px 30px 37px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mega-fashion-banner-bg1 {
    padding: 22px 20px 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .mega-fashion-banner-bg1 {
    padding: 22px 20px 30px 20px;
  }
}

.mega-fashion-banner-bg2 {
  background-color: #EBFDFF;
  padding: 22px 20px 30px 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mega-fashion-banner-bg2 {
    padding: 30px 20px 30px 37px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mega-fashion-banner-bg2 {
    padding: 22px 20px 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .mega-fashion-banner-bg2 {
    padding: 22px 20px 30px 20px;
  }
}

.banner-wrap-2 {
  position: relative;
}

.banner-wrap-2 .banner-img-2 {
  margin-left: -43px;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-2 .banner-img-2 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-2 .banner-img-2 {
    margin-left: 0px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-2 .banner-img-2 {
    margin-left: 0px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-2 .banner-img-2 {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.banner-wrap-2 .banner-img-2 a {
  display: block;
}

.banner-wrap-2 .banner-img-2 a img {
  transform: scale(1);
  transition: all .5s ease 0s;
  max-width: 100%;
}

.banner-wrap-2 .banner-img-2.banner-img-2-mrg1 {
  margin-bottom: -60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-2 .banner-img-2.banner-img-2-mrg1 {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-2 .banner-img-2.banner-img-2-mrg1 {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-2 .banner-img-2.banner-img-2-mrg1 {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-2 .banner-img-2.banner-img-2-mrg1 {
    margin-bottom: 0px;
  }
}

.banner-wrap-2 .banner-img-2.banner-img-2-mrg2 {
  margin-top: -52px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-2 .banner-img-2.banner-img-2-mrg2 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-2 .banner-img-2.banner-img-2-mrg2 {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-2 .banner-img-2.banner-img-2-mrg2 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-2 .banner-img-2.banner-img-2-mrg2 {
    margin-top: 30px;
  }
}

.banner-wrap-2 .banner-content-22 {
  margin-top: 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap-2 .banner-content-22 {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-2 .banner-content-22 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-2 .banner-content-22 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-2 .banner-content-22 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-2 .banner-content-22 {
    margin-top: 0px;
  }
}

.banner-wrap-2 .banner-content-22 h3 {
  font-size: 29px;
  font-weight: 500;
  line-height: 43px;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-2 .banner-content-22 h3 {
    font-size: 25px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-2 .banner-content-22 h3 {
    font-size: 22px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-2 .banner-content-22 h3 {
    font-size: 20px;
    line-height: 33px;
  }
}

.banner-wrap-2 .banner-content-22 p {
  width: 62%;
  font-size: 15px;
  line-height: 30px;
  margin: 20px 0 77px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap-2 .banner-content-22 p {
    width: 85%;
    margin: 20px 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap-2 .banner-content-22 p {
    width: 100%;
    margin: 10px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-2 .banner-content-22 p {
    width: 100%;
    margin: 10px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-2 .banner-content-22 p {
    width: 100%;
    margin: 5px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-2 .banner-content-22 p {
    width: 100%;
    margin: 10px 0 0px;
  }
}

.banner-wrap-2:hover .banner-img-2 a img {
  transform: scale(1.09);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .automobile-banner-area .banner-wrap.ml-15 {
    margin-left: 0px;
  }
  .automobile-banner-area .banner-wrap.mr-15 {
    margin-right: 0px;
  }
  .banner-wrap.ml-15 {
    margin-left: 0px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .automobile-banner-area .banner-wrap.ml-15 {
    margin-left: 0px;
  }
  .automobile-banner-area .banner-wrap.mr-15 {
    margin-right: 0px;
  }
  .banner-wrap.ml-15 {
    margin-left: 0px;
    margin-top: 30px;
  }
}

/*---------- 6. Product style ------------*/
.product-area {
  position: relative;
}

.product-area .organic-position-3 {
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
  position: absolute;
  top: 4.5%;
  right: 8%;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-area .organic-position-3 {
    top: 1.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-area .organic-position-3 {
    top: -1.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area .organic-position-3 {
    top: -1.5%;
  }
}

@media only screen and (max-width: 767px) {
  .product-area .organic-position-3 {
    top: -1.5%;
  }
}

.product-area .organic-position-3 img {
  width: 150px;
}

@media only screen and (max-width: 767px) {
  .product-area .organic-position-3 img {
    width: 100px;
  }
}

.product-area .organic-position-4 {
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
  position: absolute;
  top: 46%;
  left: 0%;
  z-index: 9;
}

.product-area .organic-position-4 img {
  width: 151px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-area .organic-position-4 img img {
    width: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-area .organic-position-4 {
    top: 50%;
  }
  .product-area .organic-position-4 img {
    width: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-area .organic-position-4 {
    top: 50%;
  }
  .product-area .organic-position-4 img {
    width: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area .organic-position-4 img {
    width: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area .organic-position-4 {
    top: 45%;
  }
  .product-area .organic-position-4 img {
    width: 40px;
  }
}

.product-area .organic-position-5 {
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
  position: absolute;
  top: 54%;
  left: 1%;
  z-index: 9;
}

.product-area .organic-position-5 img {
  width: 37px;
}

@media only screen and (max-width: 767px) {
  .product-area .organic-position-5 {
    left: 0%;
  }
  .product-area .organic-position-5 img {
    width: 20px;
  }
}

.product-area .organic-position-6 {
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
  position: absolute;
  bottom: 26%;
  right: 0%;
  z-index: 9;
}

.product-area .organic-position-6 img {
  width: 49px;
}

@media only screen and (max-width: 767px) {
  .product-area .organic-position-6 img {
    width: 30px;
  }
}

.product-area .organic-position-7 {
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
  position: absolute;
  bottom: 6%;
  right: 4%;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .product-area .organic-position-7 {
    bottom: 1%;
    right: 12%;
  }
}

.product-area .organic-position-7 img {
  width: 162px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area .organic-position-7 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area .organic-position-7 img {
    width: 100px;
  }
}

.product-tab-list-1 {
  display: flex;
  justify-content: center;
}

.product-tab-list-1 a {
  font-size: 18px;
  display: inline-block;
  color: #777;
  padding: 0 45px 20px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-1 a {
    padding: 0 30px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-1 a {
    padding: 0 25px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1 a {
    padding: 0 15px 12px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-1 a {
    padding: 0 10px 12px;
    margin-bottom: 10px;
  }
}

.product-tab-list-1 a.active {
  color: #333;
  font-weight: bold;
}

.product-tab-list-1 a.active:before {
  position: absolute;
  background-color: #333;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  width: 80px;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1 a.active:before {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-1.mb-90 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-1.mb-90 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1.mb-90 {
    margin-bottom: 15px;
  }
  .product-tab-list-1.mb-65 {
    margin-bottom: 15px;
  }
  .product-tab-list-1.mb-50 {
    margin-bottom: 20px;
  }
}

.product-tab-list-1.tab-list-1-pl a {
  padding: 0 0 20px 0px;
  margin-left: 90px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-tab-list-1.tab-list-1-pl a {
    margin-left: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-tab-list-1.tab-list-1-pl a {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-1.tab-list-1-pl a {
    margin-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-1.tab-list-1-pl a {
    margin-left: 18px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1.tab-list-1-pl a {
    margin-left: 18px;
    font-size: 15px;
    padding: 0 0 10px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-1.tab-list-1-pl a {
    font-size: 18px;
  }
}

.product-tab-list-1.tab-list-1-pl a:first-child {
  margin-left: 0px;
}

.product-tab-list-1.tab-list-1-pl a:before {
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-1.tab-list-1-pl a:before {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1.tab-list-1-pl a:before {
    width: 100%;
  }
}

.product-tab-list-1.tab-list-1-left {
  justify-content: left;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1.tab-list-1-left.mt-100 {
    margin-top: 30px;
  }
}

.product-tab-list-1.tab-list-1-right {
  justify-content: right;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1.tab-list-1-right {
    justify-content: center;
  }
}

.product-tab-list-1.tab-list-1-modify a {
  padding: 0 37px 13px;
}

.product-tab-list-1.tab-list-1-modify a.active:before {
  width: 71%;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-1.tab-list-1-modify a {
    padding: 0 17px 13px;
  }
}

.product-slider-active-1 .slick-list,
.product-slider-active-2 .slick-list,
.product-slider-active-4 .slick-list {
  margin-right: -15px;
  margin-left: -15px;
}

.product-slider-active-1 .slick-list .product-wrap-plr-1,
.product-slider-active-2 .slick-list .product-wrap-plr-1,
.product-slider-active-4 .slick-list .product-wrap-plr-1 {
  padding-left: 15px;
  padding-right: 15px;
}

.product-slider-active-3 .slick-list {
  margin-right: -15px;
  margin-left: -15px;
  padding-right: 220px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-slider-active-3 .slick-list {
    padding-right: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-slider-active-3 .slick-list {
    padding-right: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-slider-active-3 .slick-list {
    padding-right: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active-3 .slick-list {
    padding-right: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .product-slider-active-3 .slick-list {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-slider-active-3 .slick-list {
    padding-right: 160px;
  }
}

.product-slider-active-3 .slick-list .product-wrap-plr-1 {
  padding-left: 15px;
  padding-right: 15px;
}

.product-wrap {
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap.mb-100 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap.mb-100 {
    margin-bottom: 30px;
  }
  .product-wrap.mb-90 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap.mb-55 {
    margin-bottom: 40px;
  }
  .product-wrap.mb-50 {
    margin-bottom: 40px;
  }
  .product-wrap.mb-100 {
    margin-bottom: 40px;
  }
  .product-wrap.mb-90 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap.mb-55 {
    margin-bottom: 30px;
  }
  .product-wrap.mb-50 {
    margin-bottom: 30px;
  }
  .product-wrap.mb-100 {
    margin-bottom: 30px;
  }
  .product-wrap.mb-90 {
    margin-bottom: 30px;
  }
}

.product-wrap .product-img {
  overflow: hidden;
  position: relative;
}

.product-wrap .product-img a {
  display: block;
}

.product-wrap .product-img a img {
  width: 100%;
}

.product-wrap .product-img.product-img-zoom a img {
  transition: all .5s ease 0s;
  transform: scale(1);
}

.product-wrap .product-img .product-offer {
  position: absolute;
  left: 70px;
  top: 70px;
  width: 88px;
  height: 88px;
  line-height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #0CBDEE;
}

.product-wrap .product-img .product-offer h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: #ffffff;
  margin: 0;
}

.product-wrap .product-content h4 {
  font-size: 15px;
  margin: 0 0 9px;
}

.product-wrap .product-content h4 a {
  color: #333;
}

.product-wrap .product-content h4 a:hover {
  color: #0A1039;
}

.product-wrap .product-content.product-content-border {
  border: 1px solid #F2F2F2;
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.product-wrap .product-content.product-content-padding {
  padding: 35px 10px 30px;
}

.product-wrap .product-action-position-1 {
  position: absolute;
  background-color: #fff;
  border: 1px solid #F0F0F0;
  padding: 25px 10px 27px;
  left: 0;
  right: 0;
  bottom: -30px;
  transition: all .3s ease 0s;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-action-position-1 {
    padding: 20px 10px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-action-position-1 {
    padding: 15px 10px 17px;
  }
}

.product-wrap .product-action-2 {
  top: 60%;
  transform: translateY(-50%);
  left: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  transition: all 0.6s ease 0s;
}

.product-wrap .product-action-2 .product-action-2-style {
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 9px 17px 7px;
  border-radius: 50px;
}

.product-wrap .product-action-2 .product-action-2-style button {
  border: none;
  background-color: transparent;
  color: #B2B2B2;
  font-size: 20px;
  padding: 0 5px;
  position: relative;
  background-color: transparent;
  border: none;
}

.product-wrap .product-action-2 .product-action-2-style button:hover {
  color: #885643;
}

.product-wrap .product-action-2 .product-action-2-style button::before {
  background: #DFE3E8 none repeat scroll 0 0;
  content: "";
  height: 20px;
  position: absolute;
  right: -3px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

.product-wrap .product-action-2 .product-action-2-style button:last-child::before {
  display: none;
}

.product-wrap .product-action-2 .product-action-2-style.green-3-action-2 button {
  color: #B2B2B2;
}

.product-wrap .product-action-2 .product-action-2-style.green-3-action-2 button:hover {
  color: #62BC77;
}

.product-wrap .product-content-2 h4 {
  font-size: 14px;
  margin: 0 0 9px;
  font-weight: 300;
}

.product-wrap .product-content-2 h4 a {
  color: #232953;
}

.product-wrap .product-content-2 h4 a:hover {
  color: #333;
}

.product-wrap .product-action-wrap > .product-action-cart {
  margin: 18px 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-action-wrap > .product-action-cart {
    margin: 14px 0 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-action-wrap > .product-action-cart {
    margin: 14px 0 14px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-action-wrap > .product-action-cart {
    margin: 10px 0 12px;
  }
}

.product-wrap .product-action-wrap > .product-action-cart > button {
  border: none;
  padding: 7px 32px 7px;
  background-color: #333333;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  position: relative;
}

.product-wrap .product-action-wrap > .product-action-cart > button.padding-dec {
  padding: 6px 20px 7px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-action-wrap > .product-action-cart > button {
    padding: 6px 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-action-wrap > .product-action-cart > button {
    padding: 8px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-action-wrap > .product-action-cart > button {
    padding: 6px 22px;
  }
}

.product-wrap .product-action-wrap > .product-action-cart > button:hover {
  background-color: #0A1039;
}

.product-wrap .product-action-wrap > button {
  border: none;
  padding: 0;
  background-color: transparent;
  color: #B2B2B2;
  font-size: 20px;
}

.product-wrap .product-action-wrap > button:hover {
  color: #0A1039;
}

.product-wrap .product-action-wrap.product-action-wrap-modify > .product-action-cart {
  margin: 10px 0 10px;
}

.product-wrap .product-action-wrap.product-action-wrap-modify > .product-action-cart > button {
  padding: 3px 20px 5px;
}

.product-wrap.product-wrap-gray {
  background-color: #F8F8F8;
  padding: 52px 30px 45px 110px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-wrap.product-wrap-gray {
    padding: 52px 30px 45px 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap.product-wrap-gray {
    padding: 52px 30px 45px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap.product-wrap-gray {
    padding: 52px 20px 45px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap.product-wrap-gray {
    padding: 52px 20px 45px 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap.product-wrap-gray {
    padding: 52px 20px 45px 50px;
  }
}

.product-wrap .product-content-3 h4 {
  font-size: 19px;
  font-weight: 500;
  margin: 0px 0 12px;
}

.product-wrap .product-content-3 h4 a {
  color: #333;
}

.product-wrap .product-content-3 h4 a:hover {
  color: #666;
}

.product-wrap .product-content-3 .price-cart-wrap {
  display: flex;
}

.product-wrap .product-content-3 .price-cart-wrap .product-price-3 {
  margin-right: 45px;
}

.product-wrap .product-content-3 .price-cart-wrap .product-price-3 span {
  font-size: 24px;
  font-weight: bold;
}

.product-wrap .product-content-3 .price-cart-wrap .product-cart-3 a {
  font-size: 26px;
  color: #AFAFAF;
}

.product-wrap .product-content-3 .price-cart-wrap .product-cart-3 a:hover {
  color: #333;
}

.product-wrap .product-content-position-1 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 35px;
}

.product-wrap .product-content-position-2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
}

.product-wrap .product-content-4 h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-4 h4 {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-4 h4 {
    margin: 0 0 10px;
  }
}

.product-wrap .product-content-4 h4 a {
  color: #232953;
}

.product-wrap .product-content-4 h4 a:hover {
  color: #359043;
}

.product-wrap .product-content-5 h4 {
  font-size: 22px;
  font-family: "Pacifico";
  color: #62BC77;
  margin: 0;
  position: relative;
}

.product-wrap .product-content-5 h4::before {
  position: absolute;
  left: -135px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #62BC77;
  width: 108px;
  height: 1px;
  content: "";
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-5 h4::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-5 h4::before {
    display: block;
    width: 50px;
    left: -65px;
  }
}

.product-wrap .product-content-5 h3 {
  font-size: 29px;
  font-weight: 500;
  color: #333333;
  margin: 17px 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-5 h3 {
    margin: 12px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-5 h3 {
    margin: 8px 0 13px;
    font-size: 25px;
  }
}

.product-wrap .product-content-5 h3 a {
  color: #333333;
}

.product-wrap .product-content-5 h3 a:hover {
  color: #62BC77;
}

.product-wrap .product-content-5 span {
  font-size: 24px;
  font-weight: bold;
  font-family: "Montserrat";
  color: #333333;
}

.product-wrap .product-content-5.product-content-5-mrg {
  margin: 110px 0 0 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-wrap .product-content-5.product-content-5-mrg {
    margin: 40px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-wrap .product-content-5.product-content-5-mrg {
    margin: 50px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-wrap .product-content-5.product-content-5-mrg {
    margin: 20px 0 0 0px;
  }
}

.product-wrap .product-timer {
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 20px;
  opacity: 1;
  visibility: visible;
  transition: all .3s ease 0s;
}

.product-wrap.fashion2-product-mt {
  margin-top: -50px;
}

@media only screen and (max-width: 767px) {
  .product-wrap.fashion2-product-mt {
    margin-top: 0px;
  }
}

.product-wrap:hover .product-img-zoom a img {
  transform: scale(1.1);
}

.product-wrap:hover .product-action-position-1 {
  bottom: 0;
  visibility: visible;
  opacity: 1;
}

.product-wrap:hover .product-action-2 {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.product-wrap:hover .product-timer {
  opacity: 0;
  visibility: hidden;
  bottom: -20px;
}

.product-price span {
  font-size: 15px;
  font-weight: 500;
  color: #333333;
}

.product-price span.old-price {
  color: #777777;
  font-weight: 300;
  text-decoration: line-through;
  margin-left: 12px;
}

.product-price-2 span {
  font-size: 12px;
  font-weight: 300;
  color: #232953;
}

.product-price-2 span.old-price {
  font-weight: 300;
  text-decoration: line-through;
  margin-left: 12px;
}

.product-price-3 span {
  font-size: 16px;
  font-weight: 300;
  color: #232953;
}

.product-price-3 span.old-price {
  text-decoration: line-through;
  margin-left: 8px;
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: hastechZoomIn;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-content.mt-80 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-content.mt-80 {
    margin-top: 30px;
  }
}

.tab-content.jump-2 .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump-2 .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: hastechMoveLeftBig;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

.product-tab-list-2 a {
  display: block;
  width: 100%;
  font-size: 18px;
  color: #777777;
  margin-bottom: 59px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2 a {
    font-size: 16px;
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-2 a {
    margin-bottom: 35px;
  }
}

.product-tab-list-2 a:last-child {
  margin-bottom: 0px;
}

.product-tab-list-2 a.active {
  font-weight: bold;
  color: #333;
}

.product-tab-list-2 a.active svg path {
  fill: #333;
}

.product-tab-list-2 a svg {
  width: 32px;
  margin-right: 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-2 a svg {
    margin-right: 10px;
  }
}

.product-tab-list-2 a svg path {
  fill: #3A3A3A;
}

.product-tab-list-2 a:hover {
  color: #333;
}

.product-tab-list-2 a:hover svg path {
  fill: #333;
}

.product-tab-list-3 {
  display: flex;
}

.product-tab-list-3 a {
  display: inline-block;
  font-size: 18px;
  color: #777777;
  padding: 0 39px 24px;
  border-bottom: 2px solid transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3 a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3 a {
    padding: 0 10px 15px;
    margin-bottom: 15px;
  }
}

.product-tab-list-3 a:last-child {
  margin-bottom: 0px;
}

.product-tab-list-3 a.active {
  font-weight: bold;
  color: #EBA866;
  border-bottom: 2px solid #EBA866;
}

.product-tab-list-3 a:hover {
  font-weight: bold;
  color: #EBA866;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-3.mb-60 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-3.mb-60 {
    margin-bottom: 20px;
  }
  .product-tab-list-3.mb-80 {
    margin-bottom: 20px;
  }
}

.product-tab-list-3.product-tab-list-3-green a.active {
  color: #333333;
  border-bottom: 2px solid #97D694;
}

.product-tab-list-3.product-tab-list-3-green a:hover {
  color: #333333;
}

.product-tab-list-3.product-tab-list-3-black a.active {
  color: #333333;
  border-bottom: 2px solid #333333;
}

.product-tab-list-3.product-tab-list-3-black a:hover {
  color: #333333;
}

.pre-order-bg-color {
  background: linear-gradient(to right, #FFF9F0, #FFF9F0 40.66667%, #fff 0%);
}

@media only screen and (max-width: 767px) {
  .pre-order-bg-color {
    background: linear-gradient(to right, #FFF9F0, #FFF9F0 61.66667%, #fff 0%);
  }
}

.pre-order-img {
  margin-right: 55px;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pre-order-img {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pre-order-img {
    margin-right: 0px;
  }
}

.pre-order-img a {
  display: block;
}

.pre-order-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.pre-order-img:hover a img {
  transform: scale(1.03);
}

.pre-order-content h3 {
  color: #333333;
  font-size: 29px;
  font-weight: 500;
}

.pre-order-content h3 a {
  color: #333333;
}

.pre-order-content h4 {
  color: #777777;
  font-size: 16px;
  margin: 10px 0 20px;
}

.pre-order-content h4 > span {
  font-weight: bold;
  color: #EBA866;
  margin-right: 12px;
}

.pre-order-content p {
  font-size: 15px;
  line-height: 30px;
  margin: 0;
}

.pre-order-content > span {
  font-size: 24px;
  color: #232953;
  font-weight: 300;
  display: block;
  margin: 27px 0 46px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pre-order-content > span {
    margin: 15px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pre-order-content > span {
    margin: 15px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pre-order-content {
    padding-bottom: 30px;
  }
}

.pre-order-content.pre-order-green h4 {
  color: #232953;
  font-weight: 500;
}

.pre-order-content.pre-order-green h4 > span {
  color: #97D694;
  font-weight: bold;
}

.pre-order-content.pre-order-green p {
  width: 80%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pre-order-content.pre-order-green p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .pre-order-content.pre-order-green p {
    width: 100%;
  }
}

.pre-order-img-2 {
  margin: 0 0 0 120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pre-order-img-2 {
    margin: 0 0 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pre-order-img-2 {
    margin: 0 0 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pre-order-img-2 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pre-order-img-2 {
    margin: 0 0 0 0px;
  }
}

.pre-order-img-2 a {
  display: block;
}

.pre-order-img-2 a img {
  max-width: 100%;
}

.pre-order-img-3 {
  text-align: right;
}

.pre-order-img-3 a {
  display: block;
}

.pre-order-img-3 a img {
  max-width: 100%;
}

.sessional-bg-color {
  background: linear-gradient(to left, #F8ECE2, #F8ECE2 40.5%, #fff 0%);
  padding: 72px 0 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-bg-color {
    background: linear-gradient(to left, #F8ECE2, #F8ECE2 49.5%, #fff 0%);
  }
}

@media only screen and (max-width: 767px) {
  .sessional-bg-color {
    background: linear-gradient(to left, #F8ECE2, #F8ECE2 50%, #fff 0%);
    padding: 50px 0 25px;
  }
}

.sessional-content h2 {
  font-size: 29px;
  font-weight: 500;
  margin: 0;
  line-height: 1;
}

.sessional-content p {
  font-size: 15px;
  line-height: 30px;
  margin: 32px 0 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-content p {
    margin: 25px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-content p {
    margin: 12px 0 20px;
  }
}

.sessional-img-wrap {
  position: relative;
}

.sessional-img-wrap .sessional-img {
  float: right;
  margin-right: -30px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sessional-img-wrap .sessional-img {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-img-wrap .sessional-img {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-img-wrap .sessional-img {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-img-wrap .sessional-img {
    margin-right: 0px;
  }
}

.sessional-img-wrap .sessional-img .sessional-shap {
  position: absolute;
  top: 10%;
  left: 25%;
  width: 15px;
  height: 15px;
  background-color: #FFE600;
  transform: rotate(45deg);
  z-index: 9;
}

.sessional-img-wrap .sessional-img a {
  display: block;
}

.sessional-img-wrap .sessional-img a img {
  max-width: 100%;
}

.sessional-img-wrap .sessional-img-content {
  position: absolute;
  left: 12.7%;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-img-wrap .sessional-img-content {
    left: 0%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-img-wrap .sessional-img-content {
    left: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-img-wrap .sessional-img-content {
    left: 0%;
  }
}

.sessional-img-wrap .sessional-img-content h2 {
  font-size: 142px;
  font-weight: bold;
  line-height: 110px;
  color: #D18437;
  margin: 0 0 27px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-img-wrap .sessional-img-content h2 {
    font-size: 100px;
    line-height: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-img-wrap .sessional-img-content h2 {
    font-size: 80px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-img-wrap .sessional-img-content h2 {
    font-size: 80px;
    line-height: 75px;
  }
}

.sessional-img-wrap .sessional-img-content h3 {
  font-size: 63px;
  font-weight: bold;
  line-height: 54px;
  color: #06103A;
  margin: 0 0 0px;
  position: relative;
}

.sessional-img-wrap .sessional-img-content h3 span {
  margin-left: -33px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .sessional-img-wrap {
    margin-top: 20px;
  }
}

.cake-product-img {
  margin-right: 100px;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cake-product-img {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cake-product-img {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .cake-product-img {
    margin-right: 0px;
  }
}

.cake-product-img a {
  display: block;
}

.cake-product-img a img {
  width: 100%;
  transition: all .5s ease 0s;
  transform: rotate(0);
}

.cake-product-img:hover a img {
  transform: rotate(10deg);
}

@media only screen and (max-width: 767px) {
  .arrival-product-details {
    margin: 15px 0 0px;
  }
}

.arrival-product-details h4 {
  font-size: 22px;
  font-family: "Pacifico";
  color: #885643;
  margin: 0;
}

.arrival-product-details h3 {
  font-size: 29px;
  font-weight: 500;
  margin: 18px 0 26px;
}

@media only screen and (max-width: 767px) {
  .arrival-product-details h3 {
    margin: 10px 0 15px;
  }
}

.arrival-product-details h3 a {
  color: #333333;
}

.arrival-product-details h3 a:hover {
  color: #885643;
}

.arrival-product-details span {
  color: #0A1039;
  font-size: 30px;
  letter-spacing: 1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .arrival-product-details.ml-50 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .arrival-product-details.ml-50 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .arrival-product-details.ml-50 {
    margin-left: 0;
  }
}

.product-action-wrap-2 {
  display: flex;
  align-items: center;
  margin: 37px 0 0;
}

.product-action-wrap-2.treeplant-action-mrg-dec {
  margin: 30px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-action-wrap-2.treeplant-action-mrg-dec {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .product-action-wrap-2 {
    margin: 16px 0 0;
  }
}

.product-action-wrap-2 .product-action-wrap-2-right {
  margin-left: 23px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-action-wrap-2 .product-action-wrap-2-right {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-action-wrap-2 .product-action-wrap-2-right {
    margin-left: 15px;
  }
}

.product-action-wrap-2 .product-action-wrap-2-right > button {
  border: none;
  padding: 0;
  background-color: transparent;
  color: #B2B2B2;
  margin-right: 15px;
  font-size: 20px;
}

.product-action-wrap-2 .product-action-wrap-2-right > button:last-child {
  margin-right: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-action-wrap-2 .product-action-wrap-2-right > button {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-action-wrap-2 .product-action-wrap-2-right > button {
    margin-right: 10px;
  }
}

.product-action-wrap-2 .product-action-wrap-2-right > button.green-2:hover {
  color: #359043;
}

.product-action-wrap-2 .product-action-wrap-2-right > button.green-3:hover {
  color: #62BC77;
}

.product-action-wrap-2 .product-action-wrap-2-right > button.brown {
  margin-right: 8px;
}

.product-action-wrap-2 .product-action-wrap-2-right > button.brown:last-child {
  margin-right: 0px;
}

.product-action-wrap-2 .product-action-wrap-2-right > button.brown:hover {
  color: #CF7F39;
}

.product-action-wrap-2 .product-action-wrap-2-right > button:hover {
  color: #885643;
}

.organic-product-mt {
  margin-top: 30px;
}

.organic-product-shape-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.organic-product-shape-top img {
  width: 100%;
}

.organic-product-shape-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.organic-product-shape-bottom img {
  width: 100%;
}

.discount-content h3 {
  font-size: 65px;
  color: #172419;
  margin: 0;
  font-family: "Vandella";
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .discount-content h3 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .discount-content h3 {
    font-size: 45px;
  }
}

.discount-content h2 {
  font-size: 128px;
  color: #359043;
  font-family: "Vandella";
  margin: 10px 0px 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .discount-content h2 {
    font-size: 110px;
    margin: 10px 0px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .discount-content h2 {
    font-size: 90px;
    margin: 10px 0px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .discount-content h2 {
    font-size: 66px;
    margin: 10px 0px 30px;
  }
}

.discount-content.discount-content-flower h3 {
  color: #333;
}

.discount-content.discount-content-flower h2 {
  color: #D20769;
  margin: 0px 0px 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .discount-content.discount-content-flower h2 {
    margin: 10px 0px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .discount-content.discount-content-flower h2 {
    margin: 10px 0px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .discount-content.discount-content-flower h2 {
    margin: 10px 0px 20px;
  }
}

.discount-wrap {
  padding: 90px 0 42px 60px;
}

@media only screen and (max-width: 767px) {
  .discount-wrap {
    padding: 30px 0 30px 30px;
  }
}

.discount-wrap .discount-img {
  overflow: hidden;
}

.discount-wrap .discount-img a {
  display: block;
}

.discount-wrap .discount-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.discount-wrap > h3 {
  font-size: 36px;
  font-weight: 300;
  color: #fff;
  margin: 49px 0 0 -60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .discount-wrap > h3 {
    margin: 26px 0 0 -60px;
  }
}

@media only screen and (max-width: 767px) {
  .discount-wrap > h3 {
    margin: 26px 0 0 -30px;
  }
}

.discount-wrap > h3 span {
  font-weight: bold;
  line-height: 55px;
}

.discount-wrap:hover .discount-img a img {
  transform: scale(1.1);
}

.product-bundle-negative-mrg {
  margin-top: -95px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-bundle-negative-mrg {
    margin-top: -70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-bundle-negative-mrg {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-bundle-negative-mrg {
    margin-top: 0px;
  }
}

.product-tab-list-4 {
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-4 {
    justify-content: center;
    margin: 20px 0 0;
  }
}

.product-tab-list-4 a {
  color: #06103A;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
  padding: 19px 80px;
  background-color: #F3F3F3;
  margin-left: 10px;
  position: relative;
}

.product-tab-list-4 a:first-child {
  margin-left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-4 a {
    padding: 19px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-4 a {
    padding: 14px 30px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-4 a {
    padding: 12px 20px 15px;
  }
}

.product-tab-list-4 a::before {
  background-image: url(../../assets/images/icon-img/tab-shape.png);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0px;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 4px;
  z-index: 9;
}

.product-tab-list-4 a.active {
  background-color: #FF437D;
  color: #ffffff;
}

.flower-arrival-content {
  padding: 0 120px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .flower-arrival-content {
    padding: 0 80px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .flower-arrival-content {
    padding: 0 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .flower-arrival-content {
    padding: 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-arrival-content {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-arrival-content {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .flower-arrival-content {
    padding: 0 30px;
  }
}

.flower-arrival-content h3 {
  color: #D60B5E;
  font-size: 60px;
  font-family: "Vandella";
}

@media only screen and (max-width: 767px) {
  .flower-arrival-content h3 {
    font-size: 50px;
    margin: 0;
  }
}

.flower-arrival-content p {
  color: #777777;
  font-size: 15px;
  line-height: 30px;
  margin: 25px 0 35px;
  width: 85%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flower-arrival-content p {
    margin: 15px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .flower-arrival-content p {
    margin: 5px 0 15px;
    width: 100%;
  }
}

.grid-sizer {
  width: 25%;
}

.single-car-details {
  background-color: #ffffff;
  padding: 0 12px 65px;
  box-shadow: 0 5px 20px rgba(29, 8, 67, 0.03);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-car-details {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .single-car-details {
    margin-top: 60px;
  }
}

.single-car-details .single-car-form-wrap {
  background-color: #F9F9F9;
  padding: 35px 56px 120px 43px;
}

@media only screen and (max-width: 767px) {
  .single-car-details .single-car-form-wrap {
    padding: 35px 20px 120px 20px;
  }
}

.single-car-details .single-car-form-wrap .single-car-form {
  width: 100%;
  height: 50px;
}

.single-car-details .single-car-form-wrap .single-car-submit {
  text-align: center;
  margin-top: 38px;
}

.single-car-details .single-car-form-wrap .single-car-submit button {
  font-weight: bold;
  color: #fff;
  background-color: #D02F3B;
  border: none;
  padding: 9px 47px 10px;
}

.single-car-details .single-car-form-wrap .single-car-submit button:hover {
  background-color: #0A1039;
}

.single-car-details .single-car-img {
  margin-top: -60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-car-details .single-car-img {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .single-car-details .single-car-img {
    text-align: center;
  }
}

.single-car-details .single-car-img img {
  max-width: 100%;
}

.product-tab-list-5 a {
  display: block;
  color: #777777;
  font-size: 17px;
  line-height: 1;
  width: 100%;
  padding: 20px 20px 20px 50px;
  background-color: transparent;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-5 a {
    font-size: 14px;
    padding: 14px 20px 14px 50px;
  }
}

.product-tab-list-5 a:before {
  position: absolute;
  content: "";
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 11px;
  background-color: #707070;
  z-index: 2;
}

.product-tab-list-5 a.active {
  font-weight: bold;
  color: #333333;
  background-color: #FCEBEB;
}

.product-tab-list-5 a:hover {
  font-weight: bold;
  color: #333333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-tab-list-5.mb-50 {
    margin-bottom: 0px;
  }
}

.product-tab-list-6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-tab-list-6 a {
  font-size: 18px;
  color: #777777;
  display: inline-block;
  padding: 0 12px 18px;
  margin: 0 25px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-6 a {
    padding: 0 5px 10px;
    margin: 0 10px 10px;
    font-size: 16px;
  }
}

.product-tab-list-6 a:before {
  position: absolute;
  content: "";
  left: 0px;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #333333;
  z-index: 2;
}

.product-tab-list-6 a.active {
  font-weight: bold;
  color: #333333;
}

.product-tab-list-6 a.active:before {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .product-tab-list-6.mb-140 {
    margin-bottom: 20px;
  }
}

.product-tab-list-7 {
  display: flex;
  flex-wrap: wrap;
}

.product-tab-list-7 a {
  font-size: 18px;
  color: #707070;
  display: inline-block;
  padding: 0 14px 28px;
  margin: 0 31px;
  position: relative;
}

.product-tab-list-7 a:first-child {
  margin: 0 31px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-7 a {
    padding: 0 14px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-7 a {
    padding: 0 6px 10px;
    margin: 0 6px;
  }
  .product-tab-list-7 a:first-child {
    margin: 0 6px 0 0;
  }
}

.product-tab-list-7 a:before {
  position: absolute;
  content: "";
  left: 0px;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #333333;
  z-index: 2;
}

.product-tab-list-7 a.active {
  font-weight: bold;
  color: #333333;
}

.product-tab-list-7 a.active:before {
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-7.mb-60 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list-7.mb-60 {
    margin-bottom: 20px;
  }
}

.dot-style-5 ul {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  right: 17px;
  z-index: 9;
}

.dot-style-5 ul li {
  margin: 0px 5px;
}

@media only screen and (max-width: 767px) {
  .dot-style-5 ul li {
    margin: 0px 2px;
  }
}

.dot-style-5 ul li button {
  padding: 0;
  font-size: 0;
  background-color: #CBCBCB;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: none;
}

.dot-style-5 ul li.slick-active button {
  background-color: #404040;
}

.sessional-sale-content {
  padding: 250px 150px 165px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sessional-sale-content {
    padding: 220px 50px 165px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sessional-sale-content {
    padding: 220px 50px 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-sale-content {
    padding: 143px 30px 123px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-sale-content {
    padding: 80px 40px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-sale-content {
    padding: 60px 20px 60px;
  }
}

.sessional-sale-content h2 {
  font-size: 29px;
  font-weight: bold;
  color: #151C0F;
  margin: 0;
}

.sessional-sale-content p {
  font-size: 16px;
  line-height: 30px;
  width: 89%;
  margin: 48px 0 55px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sessional-sale-content p {
    margin: 30px 0 37px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-sale-content p {
    margin: 20px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-sale-content p {
    margin: 10px 0 20px;
    width: 100%;
    font-size: 14px;
  }
}

.sessional-sale-banner {
  background: linear-gradient(to top, #D9F9FF, #D9F9FF 83%, #fff 0%);
  position: relative;
  text-align: right;
  padding: 50px 230px 30px 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sessional-sale-banner {
    padding: 50px 50px 30px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sessional-sale-banner {
    padding: 50px 50px 30px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-sale-banner {
    padding: 50px 50px 30px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-sale-banner {
    padding: 100px 40px 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-sale-banner {
    padding: 80px 20px 30px 0;
  }
}

.sessional-sale-banner .sessional-sale-img {
  display: inline-block;
  position: relative;
}

.sessional-sale-banner .sessional-sale-img:after {
  width: 198px;
  height: 198px;
  background-color: #FBFBFB;
  border-radius: 100%;
  position: absolute;
  content: "";
  top: -50px;
  left: -26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-sale-banner .sessional-sale-img:after {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-sale-banner .sessional-sale-img:after {
    width: 150px;
    height: 150px;
    left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-img:after {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-img:after {
    width: 150px;
    height: 150px;
  }
}

.sessional-sale-banner .sessional-sale-img img {
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-sale-banner .sessional-sale-img img {
    width: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-img img {
    width: 120px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-img img {
    width: 220px;
  }
}

.sessional-sale-banner .sessional-sale-banner-content {
  position: absolute;
  left: 135px;
  top: 45%;
  text-align: left;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sessional-sale-banner .sessional-sale-banner-content {
    left: 95px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sessional-sale-banner .sessional-sale-banner-content {
    left: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-sale-banner .sessional-sale-banner-content {
    left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-sale-banner .sessional-sale-banner-content {
    left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-banner-content {
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.sessional-sale-banner .sessional-sale-banner-content h2 {
  color: #347AB5;
  font-size: 142px;
  font-weight: bold;
  margin: 0 0 10px;
  line-height: 110px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sessional-sale-banner .sessional-sale-banner-content h2 {
    font-size: 115px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sessional-sale-banner .sessional-sale-banner-content h2 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sessional-sale-banner .sessional-sale-banner-content h2 {
    font-size: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sessional-sale-banner .sessional-sale-banner-content h2 {
    font-size: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-banner-content h2 {
    font-size: 50px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-banner-content h2 {
    font-size: 100px;
    line-height: 100px;
  }
}

.sessional-sale-banner .sessional-sale-banner-content h3 {
  color: #151C0F;
  font-size: 68px;
  font-weight: bold;
  margin: 0 0 0px;
  line-height: 57px;
}

@media only screen and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-banner-content h3 {
    font-size: 45px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sessional-sale-banner .sessional-sale-banner-content h3 {
    font-size: 55px;
    line-height: 50px;
  }
}

.kids-2-bg-color {
  background: linear-gradient(to right, #E8FFFB, #E8FFFB 46.5%, #fff 0%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-2-bg-color {
    background: linear-gradient(to right, #E8FFFB, #E8FFFB 33%, #fff 0%);
  }
}

@media only screen and (max-width: 767px) {
  .kids-2-bg-color {
    background: linear-gradient(to right, #E8FFFB, #E8FFFB 100%, #fff 0%);
    padding: 60px 0 60px;
  }
}

.kids-chair-img {
  margin: 0 110px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-chair-img {
    margin: 20px 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .kids-chair-img {
    margin: 0 0px 15px 0;
  }
}

.kids-chair-img a {
  display: block;
}

.kids-chair-img a img {
  width: 100%;
}

.mega-fashion-pro-title h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 25px;
}

@media only screen and (max-width: 767px) {
  .mega-fashion-pro-title h4 {
    margin: 0 0 15px;
  }
}

.mega-fashion-pro-title h4 a {
  color: #333333;
}

.mega-fashion-pro-title h4 a:hover {
  color: #0A1039;
}

.trending-product-img {
  position: relative;
}

.trending-product-img a {
  display: block;
}

.trending-product-img a img {
  width: 100%;
}

.trending-product-img.trending-nrgative-mrg {
  margin-right: -30px;
}

@media only screen and (max-width: 767px) {
  .trending-product-img.trending-nrgative-mrg {
    margin-right: 0px;
  }
}

.trending-product-img .trending-product1-shape1 {
  position: absolute;
  top: -23px;
  left: 4%;
}

.trending-product-img .trending-product1-shape1 img {
  width: 47px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

.trending-product-img .trending-product1-shape2 {
  position: absolute;
  bottom: 12px;
  left: 25%;
}

.trending-product-img .trending-product1-shape2 img {
  width: 161px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

.trending-product-img .trending-product2-shape1 {
  position: absolute;
  top: 0;
  left: 16px;
}

.trending-product-img .trending-product2-shape1 img {
  width: 148px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (max-width: 767px) {
  .trending-product-img .trending-product2-shape1 img {
    width: 90px;
  }
}

.trending-product-img .trending-product2-shape2 {
  position: absolute;
  right: 93px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .trending-product-img .trending-product2-shape2 {
    right: 80px;
    bottom: 10px;
  }
}

.trending-product-img .trending-product2-shape2 img {
  width: 85px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (max-width: 767px) {
  .trending-product-img .trending-product2-shape2 img {
    width: 65px;
  }
}

.trending-product-img .trending-product3-shape2 {
  position: absolute;
  left: 15%;
  bottom: 3%;
}

.trending-product-img .trending-product3-shape2 img {
  width: 71px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (max-width: 767px) {
  .trending-product-img .trending-product3-shape2 img {
    width: 50px;
  }
}

.trending-product-img .trending-product3-shape1 {
  position: absolute;
  right: 70px;
  top: 10%;
}

@media only screen and (max-width: 767px) {
  .trending-product-img .trending-product3-shape1 {
    right: 30px;
  }
}

.trending-product-img .trending-product3-shape1 img {
  width: 107px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (max-width: 767px) {
  .trending-product-img .trending-product3-shape1 img {
    width: 80px;
  }
}

.trending-product-content h2 {
  color: #151C0F;
  font-size: 29px;
  font-weight: bold;
  margin: 0;
  line-height: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trending-product-content h2 {
    font-size: 19px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .trending-product-content h2 {
    font-size: 18px;
    line-height: 35px;
  }
}

.trending-product-content h2 a {
  color: #151C0F;
}

.trending-product-content h2 a:hover {
  color: #0A1039;
}

.trending-product-content p {
  font-size: 15px;
  line-height: 30px;
  margin: 16px 0 41px;
}

.trending-product-content p.peragraph-width {
  width: 89%;
}

@media only screen and (max-width: 767px) {
  .trending-product-content p.peragraph-width {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trending-product-content p {
    margin: 10px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .trending-product-content p {
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trending-product-content.mt-90 {
    margin-top: 0px;
  }
  .trending-product-content.ml-70 {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .trending-product-content.mt-90 {
    margin-top: 20px;
  }
  .trending-product-content.ml-70 {
    margin-left: 0px;
  }
}

.shop-list-wrap .product-list-img {
  overflow: hidden;
  position: relative;
}

.shop-list-wrap .product-list-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.shop-list-wrap .product-list-img .shop-list-quickview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translatex(-50%);
}

.shop-list-wrap .product-list-img .shop-list-quickview > button {
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #333;
  color: #fff;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all .3s ease 0s;
}

.shop-list-wrap .product-list-img .shop-list-quickview > button:hover {
  background-color: #0A1039;
}

.shop-list-wrap:hover .product-list-img a img {
  transform: scale(1.1);
}

.shop-list-wrap:hover .shop-list-quickview button {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.shop-list-content h3 {
  font-size: 20px;
  margin: 0;
}

.shop-list-content h3 a {
  color: #333333;
}

.shop-list-content h3 a:hover {
  color: #0A1039;
}

.shop-list-content .pro-list-price {
  margin: 15px 0 16px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-content .pro-list-price {
    margin: 15px 0 10px;
  }
}

.shop-list-content .pro-list-price span {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.shop-list-content .pro-list-price span.old-price {
  color: #777777;
  font-weight: 300;
  text-decoration: line-through;
  margin-left: 12px;
}

.shop-list-content p {
  font-size: 15px;
  line-height: 30px;
  margin: 0 0 25px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-content p {
    margin: 0 0 15px;
  }
}

.shop-list-content .product-list-action {
  display: flex;
  flex-wrap: wrap;
}

.shop-list-content .product-list-action > button {
  border: none;
  padding: 0;
  background-color: transparent;
  color: #B2B2B2;
  font-size: 20px;
  transition: all .3s ease 0s;
}

.shop-list-content .product-list-action > button:hover {
  color: #0A1039;
}

.shop-list-content .product-list-action .product-action-cart > button {
  border: none;
  padding: 7px 32px 7px;
  background-color: #333333;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  margin: 0 15px;
}

.shop-list-content .product-list-action .product-action-cart > button:hover {
  background-color: #0A1039;
}

@media only screen and (max-width: 767px) {
  .shop-list-content {
    margin-top: 20px;
  }
  .shop-list-content.ml-20 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-content {
    margin-top: 0px;
  }
  .shop-list-content.ml-20 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .organic-discount {
    background-position: 86%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-discount {
    background-position: 86%;
  }
  .product-area.pb-120 {
    padding-bottom: 60px;
  }
  .product-area.pb-60 {
    padding-bottom: 0px;
  }
  .product-area.pb-90 {
    padding-bottom: 30px;
  }
  .product-area.pb-110 {
    padding-bottom: 50px;
  }
  .product-area.pb-100 {
    padding-bottom: 40px;
  }
  .product-area.pt-125 {
    padding-top: 70px;
  }
  .product-area.pt-145 {
    padding-top: 80px;
  }
  .product-area.pb-145 {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-discount {
    background-position: 86%;
  }
  .product-area.pb-100 {
    padding-bottom: 40px;
  }
  .product-area.pb-90 {
    padding-bottom: 10px;
  }
  .product-area.pb-95 {
    padding-bottom: 30px;
  }
  .product-area.pt-125 {
    padding-top: 50px;
  }
  .product-area.pb-110 {
    padding-bottom: 40px;
  }
  .product-area.pt-135 {
    padding-top: 80px;
  }
  .flower-arrival-bg {
    background-position: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .organic-discount {
    background-position: 86%;
  }
  .product-area.pb-100 {
    padding-bottom: 20px;
  }
  .product-area.pb-115 {
    padding-bottom: 35px;
  }
  .product-area.pb-105 {
    padding-bottom: 25px;
  }
  .product-area.pb-95 {
    padding-bottom: 15px;
  }
  .product-area.pb-90 {
    padding-bottom: 15px;
  }
  .product-area.pt-135 {
    padding-top: 40px;
  }
  .product-area.pt-80 {
    padding-top: 30px;
  }
  .pt-125 {
    padding-top: 30px;
  }
  .pb-110 {
    padding-bottom: 30px;
  }
  .organic-discount.pb-100 {
    padding-bottom: 60px;
  }
}

.nice-select.nice-select-style-3 {
  border-radius: 0;
  font-size: 14px;
  height: 50px;
  font-weight: 400;
  line-height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  color: #342D0E;
  width: 100%;
  border: none;
  font-weight: 400;
}

.nice-select.nice-select-style-3 ul {
  width: 100%;
}

.nice-select.nice-select-style-3.nice-select-shop {
  border: 1px solid #EFEFEF;
  font-weight: 300;
  padding-left: 26px;
  color: #808080;
}

.nice-select.nice-select-style-3.cart-tax-select {
  height: 45px;
  line-height: 45px;
  font-size: 13px;
  color: #999999;
  background-color: #F6F6F6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nice-select.nice-select-style-3.cart-tax-select {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .nice-select.nice-select-style-3.cart-tax-select {
    margin-bottom: 20px;
  }
}

.nice-select::after {
  display: none;
}

.nice-select:before {
  position: absolute;
  content: "";
  right: 15px;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #0B1154;
  z-index: 2;
  transform: translateY(-50%);
}

.nice-select.open:before {
  border-bottom: 6px solid #0B1154;
  border-top: none;
}

/*-------- 7. Animation style ---------*/
@keyframes hastechFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hastechZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hastechZoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hastechMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveUpBig {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveDownBig {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveLeft {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveLeftBig {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveRight {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveRightBig {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechFallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes hastechFlipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes hastechFlipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes columnMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes edit-button-pulse {
  0% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0.6);
  }
  30% {
    box-shadow: 0 0 2px 10px rgba(183, 8, 78, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0);
  }
}

/*---------- 8. Deal product style ------------*/
.deal-area {
  overflow: hidden;
}

.deal-content h2 {
  font-size: 29px;
  font-weight: 500;
  margin: 0 0 33px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content h2 {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content h2 {
    margin: 0 0 15px;
  }
}

.deal-content p {
  font-size: 13px;
  color: #828495;
  font-weight: 300;
  margin: 0;
  line-height: 20px;
}

.deal-content .deal-rating {
  margin: 0px 0 15px;
}

.deal-content .deal-rating i {
  font-size: 10px;
  color: #EE9F39;
}

.deal-content .deal-price {
  margin: 0 0 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content .deal-price {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content .deal-price {
    margin: 0 0 20px;
  }
}

.deal-content .deal-price span {
  font-size: 20px;
  color: #262626;
}

.deal-content .deal-price span.old-price {
  color: #828495;
  margin-left: 22px;
  text-decoration: line-through;
}

@media only screen and (max-width: 767px) {
  .deal-content {
    text-align: center;
  }
}

.timer-style-1 div {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .timer-style-1 div {
    justify-content: center;
  }
}

.timer-style-1 div > span {
  text-align: center;
  border: 1px solid #EEEEEE;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 25px 14px;
  margin-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-1 div > span {
    padding: 16px 20px 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-1 div > span {
    padding: 16px 15px 14px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1 div > span {
    padding: 16px 18px 14px;
    margin-right: 7px;
    margin-bottom: 10px;
  }
}

.timer-style-1 div > span:last-child {
  margin-right: 0px;
}

.timer-style-1 div > span > span {
  font-size: 17px;
  font-weight: bold;
  color: #777777;
  display: block;
  line-height: 1;
}

.timer-style-1 div > span > p {
  font-size: 15px;
  font-weight: 300;
  color: #777777;
  margin: 4px 0 0;
}

.timer-style-1.timer-plr-inc div {
  justify-content: flex-start;
}

.timer-style-1.timer-plr-inc div > span {
  padding: 16px 20px 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-1.timer-plr-inc div > span {
    padding: 16px 10px 14px;
    margin-right: 6px;
  }
  .timer-style-1.timer-plr-inc div > span:last-child {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1.timer-plr-inc div > span {
    margin-bottom: 10px;
  }
}

.timer-style-1.timer-mb {
  margin: 0 0 38px;
}

@media only screen and (max-width: 767px) {
  .timer-style-1.timer-mb {
    margin: 0 0 20px;
  }
}

.timer-style-1.timer-style-1-center div {
  justify-content: center;
}

.timer-style-1.mega-fashion-timer > div > span {
  padding: 13px 15px 13px;
}

@media only screen and (max-width: 767px) {
  .timer-style-1.mega-fashion-timer > div > span {
    padding: 13px 8px 13px;
  }
}

.timer-style-1.mega-fashion-timer > div > span > p {
  line-height: 1;
  margin: 6px 0 0;
}

@media only screen and (max-width: 767px) {
  .timer-style-1.mega-fashion-timer.mb-40 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .timer-style-1.mt-135 {
    margin-top: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .timer-style-1.mt-135 {
    margin-top: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-1.mt-135 {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-1.mt-135 {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1.mt-135 {
    margin-top: 20px;
  }
  .timer-style-1.furniture-timer div > span {
    padding: 10px 8px 8px;
    margin-right: 4px;
    margin-bottom: 0;
  }
  .timer-style-1.furniture-timer div > span:last-child {
    margin-right: 0px;
  }
}

.timer-style-1.automobile-timer div > span {
  padding: 13px 15px 10px;
  margin-right: 15px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .timer-style-1.automobile-timer div > span {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .timer-style-1.automobile-timer div > span {
    margin-right: 8px;
    padding: 13px 11px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1.automobile-timer div > span {
    padding: 9px 7px 6px;
    margin-right: 6px;
  }
}

.timer-style-1.automobile-timer div > span:last-child {
  margin-right: 0;
}

.timer-style-1.automobile-timer div > span > span {
  font-size: 15px;
}

.timer-style-1.automobile-timer div > span > p {
  font-size: 13px;
  margin: 1px 0 0;
}

.deal-img {
  margin-left: -30px;
  margin-right: -50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-img {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-img {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-img {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-img {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-img {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 25px;
  }
}

.deal-img a {
  display: block;
}

.deal-img a img {
  width: 100%;
}

.organic-deal-img {
  margin-right: 145px;
  margin-top: -90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .organic-deal-img {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .organic-deal-img {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .organic-deal-img {
    margin-right: 0px;
    margin-top: -40px;
    margin-bottom: 30px;
  }
}

.deal-content-2 h4 {
  font-size: 22px;
  font-family: "Pacifico";
  color: #359043;
  margin: 0;
}

.deal-content-2 h3 {
  font-size: 36px;
  font-weight: 500;
  color: #06103A;
  margin: 10px 0 25px;
}

@media only screen and (max-width: 767px) {
  .deal-content-2 h3 {
    font-size: 30px;
    margin: 10px 0 20px;
  }
}

.deal-content-2 h3 a {
  color: #06103A;
}

.deal-content-2 h3 a:hover {
  color: #359043;
}

.deal-content-2 span {
  font-size: 30px;
  color: #06103A;
}

.timer-style-2 div {
  display: flex;
  flex-wrap: wrap;
}

.timer-style-2 div > span {
  text-align: center;
  background-color: #FAFAFA;
  border-radius: 8px;
  padding: 16px 25px 14px;
  margin-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-2 div > span {
    padding: 16px 20px 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-2 div > span {
    padding: 16px 15px 14px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-2 div > span {
    padding: 12px 12px 10px;
    margin-right: 8px;
  }
}

.timer-style-2 div > span:last-child {
  margin-right: 0px;
}

.timer-style-2 div > span > span {
  font-size: 15px;
  font-weight: bold;
  color: #232953;
  display: block;
  line-height: 1;
}

.timer-style-2 div > span > p {
  font-size: 13px;
  font-weight: 300;
  color: #A1A5C2;
  margin: 4px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-2.mt-70 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-2.mt-70 {
    margin-top: 30px;
  }
}

.deal-area-mrg-negative {
  margin-top: -30px;
}

.deal-content-area {
  margin-left: 30px;
  margin-top: 90px;
  padding: 60px 0 107px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-content-area {
    padding: 10px 0 92px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-content-area {
    padding: 10px 0 92px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-area {
    padding: 56px 0 56px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-area {
    margin-left: 0;
    margin-top: 30px;
    padding: 30px 30px 50px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-area {
    margin-left: 0;
    margin-top: 30px;
    padding: 20px 20px 50px 20px;
  }
}

.deal-img-2 {
  float: right;
}

@media only screen and (max-width: 767px) {
  .deal-img-2 {
    text-align: center;
    float: inherit;
  }
}

.deal-img-2 a {
  display: block;
}

.deal-img-2 a img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .deal-content-3 {
    text-align: center;
  }
}

.deal-content-3 h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: #333333;
}

.deal-content-3 h3 a {
  color: #333333;
}

.deal-content-3 h3 a:hover {
  color: #62BC77;
}

.deal-content-3 p {
  font-size: 15px;
  line-height: 30px;
  margin: 7px 0 8px;
}

.deal-content-3 .deal-rating-2 {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .deal-content-3 .deal-rating-2 {
    justify-content: center;
  }
}

.deal-content-3 .deal-rating-2 i {
  color: #FFB300;
  font-size: 12px;
}

.deal-content-3 .deal-price-3 {
  margin: 5px 0 0;
}

.deal-content-3 .deal-price-3 span {
  font-size: 20px;
  color: #262626;
  display: inline-block;
}

.deal-content-3 .deal-price-3 span.old-price {
  text-decoration: line-through;
  color: #828495;
  margin-left: 22px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-content-3.treeplant-deal {
    margin-left: -50px;
  }
}

.timer-style-3 div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.timer-style-3 div > span {
  text-align: center;
  border-radius: 8px;
  padding: 11px 20px 12px;
  margin-right: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .timer-style-3 div > span {
    padding: 11px 8px 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-3 div > span {
    padding: 11px 12px 12px;
    margin-right: 6px;
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-3 div > span {
    padding: 11px 12px 12px;
    margin-right: 6px;
    margin-bottom: 6px;
  }
}

.timer-style-3 div > span:last-child {
  margin-right: 0px;
}

.timer-style-3 div > span.day {
  background-color: #FFE4E4;
}

.timer-style-3 div > span.hour {
  background-color: #FEF3BB;
}

.timer-style-3 div > span.minutes {
  background-color: #DBFFAC;
}

.timer-style-3 div > span.second {
  background-color: #C0FCFC;
}

.timer-style-3 div > span > span {
  font-size: 15px;
  font-weight: bold;
  color: #232953;
  display: block;
  line-height: 1;
}

.timer-style-3 div > span > p {
  font-size: 13px;
  font-weight: 300;
  color: #A1A5C2;
  margin: 5px 0 0;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-3.mt-70 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-3.mt-70 {
    margin-top: 30px;
  }
}

.timer-style-4 div {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .timer-style-4 div {
    justify-content: center;
  }
}

.timer-style-4 div > span {
  text-align: center;
  margin: 0 36px 0 0;
}

@media only screen and (max-width: 767px) {
  .timer-style-4 div > span {
    margin: 0 25px 0 0;
  }
}

.timer-style-4 div > span:last-child {
  margin: 0 0px 0 0;
}

.timer-style-4 div > span > span {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  display: inline-block;
  background-color: #FCF9F2;
  border-radius: 100%;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  color: #D0952D;
}

.timer-style-4 div > span > span:before {
  position: absolute;
  left: -8px;
  top: -8px;
  width: 50px;
  height: 50px;
  border: 1px solid #D0952D;
  content: "";
  z-index: 9;
  border-radius: 100%;
}

.timer-style-4 div > span p {
  font-size: 13px;
  color: #3E3F48;
  margin: 10px 0 0;
}

@media only screen and (max-width: 767px) {
  .timer-style-4.mb-50 {
    margin-bottom: 30px;
  }
}

.deal-content-4-wrap {
  background-color: #FCF9F2;
  padding: 73px 20px 73px 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-4-wrap {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-4-wrap {
    margin-bottom: 30px;
    text-align: center;
    padding: 50px 15px 50px 15px;
  }
}

.deal-content-4-wrap .deal-img-3 a img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .deal-content-4-wrap .deal-content-4 {
    margin-top: 30px;
  }
}

.deal-content-4-wrap .deal-content-4 h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.deal-content-4-wrap .deal-content-4 h4 a {
  color: #333333;
}

.deal-content-4-wrap .deal-content-4 h4 a:hover {
  color: #CF7F39;
}

.deal-content-4-wrap .deal-content-4 .deal-rating-3 {
  display: flex;
  margin: 20px 0 36px;
}

@media only screen and (max-width: 767px) {
  .deal-content-4-wrap .deal-content-4 .deal-rating-3 {
    margin: 10px 0 10px;
    justify-content: center;
  }
}

.deal-content-4-wrap .deal-content-4 .deal-rating-3 i {
  color: #FFB300;
  font-size: 12px;
}

.deal-content-4-wrap .deal-content-4 .deal-price-4 {
  margin: 5px 0 0;
}

.deal-content-4-wrap .deal-content-4 .deal-price-4 span {
  font-size: 20px;
  color: #262626;
  display: inline-block;
}

.deal-content-4-wrap .deal-content-4 .deal-price-4 span.old-price {
  text-decoration: line-through;
  color: #777;
  margin-left: 22px;
}

.deal-content-4-wrap .deal-content-4 > p {
  font-size: 13px;
  font-weight: 300;
  margin: 50px 0 34px;
}

.offer-area {
  position: relative;
}

.pet-food-offer-content {
  padding: 345px 0 64px 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pet-food-offer-content {
    padding: 250px 0 64px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-food-offer-content {
    padding: 250px 0 64px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-food-offer-content {
    padding: 100px 15px 64px 15px;
  }
}

.pet-food-offer-content h2 {
  position: absolute;
  left: 30%;
  top: 16%;
  font-size: 49px;
  font-weight: bold;
  margin: 0;
  line-height: 38px;
  transform: rotate(-10deg);
  color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pet-food-offer-content h2 {
    left: 22%;
    top: 16%;
    transform: rotate(-10deg);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pet-food-offer-content h2 {
    left: 18%;
    top: 16%;
    transform: rotate(-10deg);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pet-food-offer-content h2 {
    left: 17%;
    top: 16%;
    transform: rotate(-10deg);
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-food-offer-content h2 {
    left: 10%;
    top: 13%;
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-food-offer-content h2 {
    font-size: 35px;
    position: static;
    transform: rotate(0deg);
    color: #333;
  }
}

.pet-food-offer-content h3 {
  position: absolute;
  left: 36%;
  top: 26%;
  font-family: "Vandella";
  font-size: 54px;
  color: #CF7F39;
  margin: 0;
  line-height: 1;
  transform: rotate(-12deg);
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .pet-food-offer-content h3 {
    left: 30%;
    top: 27%;
    transform: rotate(-16deg);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pet-food-offer-content h3 {
    left: 27%;
    top: 27%;
    transform: rotate(-16deg);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pet-food-offer-content h3 {
    left: 26%;
    top: 27%;
    font-size: 50px;
    transform: rotate(-16deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-food-offer-content h3 {
    left: 14%;
    top: 27%;
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-food-offer-content h3 {
    margin: 10px 0 21px;
    position: static;
    transform: rotate(0deg);
  }
}

.pet-food-offer-content p {
  color: #3E3F48;
  font-size: 16px;
  line-height: 30px;
  width: 53%;
  margin: 0 0 42px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pet-food-offer-content p {
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-food-offer-content p {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-food-offer-content p {
    width: 100%;
    margin: 0 0 20px;
  }
}

.offer-product-wrap {
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offer-product-wrap.mr-60 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offer-product-wrap {
    margin-bottom: 50px;
  }
  .offer-product-wrap.mr-60 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-product-wrap {
    margin-bottom: 50px;
  }
  .offer-product-wrap.mr-60 {
    margin-right: 0px;
  }
}

.offer-product-wrap .product-img {
  position: relative;
  overflow: hidden;
}

.offer-product-wrap .product-img a {
  display: block;
}

.offer-product-wrap .product-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s 3ase 0s;
}

.offer-product-wrap .product-img .product-timer-2 {
  position: absolute;
  right: 57px;
  bottom: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offer-product-wrap .product-img .product-timer-2 {
    right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-product-wrap .product-img .product-timer-2 {
    right: 0;
    left: 0;
    bottom: 15px;
  }
}

.offer-product-wrap .product-img .product-timer-3 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .offer-product-wrap .product-img .product-timer-3 {
    bottom: 20px;
  }
}

.offer-product-wrap .mega-fashion-pro-offer {
  position: absolute;
  left: 44px;
  bottom: -20px;
  background-color: #C9ADAD;
  padding: 40px 37px 42px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offer-product-wrap .mega-fashion-pro-offer {
    padding: 30px 20px 32px 20px;
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-product-wrap .mega-fashion-pro-offer {
    position: static;
  }
}

.offer-product-wrap .mega-fashion-pro-offer p {
  font-size: 19px;
  line-height: 29px;
  color: #fff;
}

.offer-product-wrap .mega-fashion-pro-offer h3 {
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  line-height: 47px;
  text-align: right;
  margin: 26px 0 9px;
}

.offer-product-wrap .mega-fashion-pro-offer span {
  font-size: 19px;
  color: #fff;
  text-align: right;
  display: block;
}

.offer-product-wrap .mega-fashion-pro-title2 {
  position: absolute;
  top: -20px;
  left: 60px;
  right: 60px;
  text-align: center;
  background-color: #F9FFF2;
  padding: 81px 10px 80px;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offer-product-wrap .mega-fashion-pro-title2 {
    padding: 51px 10px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-product-wrap .mega-fashion-pro-title2 {
    padding: 21px 10px 20px;
    left: 10px;
    right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .offer-product-wrap .mega-fashion-pro-title2 {
    padding: 51px 10px 50px;
  }
}

.offer-product-wrap .mega-fashion-pro-title2 h2 {
  font-size: 29px;
  font-weight: bold;
  margin: 0;
}

.offer-product-wrap:hover .product-img a img {
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area-padding {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 767px) {
  .deal-area-padding {
    padding: 60px 0;
  }
}

/*---------- 9. Section title style ------------*/
.section-categories-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.section-categories-wrap .section-title-width-1 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

@media only screen and (max-width: 767px) {
  .section-categories-wrap .section-title-width-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.section-categories-wrap .section-btn-width-1 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  text-align: right;
  margin-top: 34px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-categories-wrap .section-btn-width-1 {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-categories-wrap .section-btn-width-1 {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-categories-wrap .section-btn-width-1 {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .section-categories-wrap .section-btn-width-1 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
    margin-top: 15px;
  }
}

.section-categories-wrap .section-btn-width-1.furniture-categories-btn {
  margin-top: 70px;
}

@media only screen and (max-width: 767px) {
  .section-categories-wrap .section-btn-width-1.furniture-categories-btn {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-categories-wrap.mb-85 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-categories-wrap.mb-85 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-categories-wrap.mb-85 {
    margin-bottom: 30px;
  }
}

.section-title h2 {
  font-size: 29px;
  font-weight: 500;
  margin: 0 0 27px;
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 25px;
    margin: 0 0 15px;
  }
}

.section-title h2.bold {
  font-weight: bold;
  margin: 0 0 34px;
}

@media only screen and (max-width: 767px) {
  .section-title h2.bold {
    margin: 0 0 15px;
  }
}

.section-title p {
  font-size: 15px;
  line-height: 30px;
  width: 95%;
}

@media only screen and (max-width: 767px) {
  .section-title p {
    font-size: 14px;
    line-height: 26px;
    width: 100%;
  }
}

.section-title span {
  color: #828495;
  font-size: 16px;
  font-weight: 300;
}

.section-title.st-line-height h2 {
  line-height: 43px;
  margin: 0 0 16px;
}

@media only screen and (max-width: 767px) {
  .section-title.st-line-height h2 {
    font-size: 21px;
    line-height: 35px;
    margin: 0 0 10px;
  }
}

.section-title.st-peragraph-width p {
  width: 63%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.st-peragraph-width p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.st-peragraph-width p {
    width: 100%;
  }
}

.section-title.st-peragraph-width-2 p {
  width: 93%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.st-peragraph-width-2 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.st-peragraph-width-2 p {
    width: 100%;
  }
}

.section-title.st-peragraph-auto p {
  margin: 0 auto;
}

.section-title.st-light-blue h2 {
  color: #232953;
  font-size: 30px;
  line-height: 46px;
}

@media only screen and (max-width: 767px) {
  .section-title.st-light-blue h2 {
    font-size: 22px;
    line-height: 35px;
  }
}

.section-title.st-light-blue p {
  color: #828495;
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title.mb-85 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.mb-85 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.mb-85 {
    margin-bottom: 30px;
  }
  .section-title.mb-60 {
    margin-bottom: 30px;
  }
  .section-title.mb-65 {
    margin-bottom: 30px;
  }
  .section-title.mb-75 {
    margin-bottom: 30px;
  }
  .section-title.mb-100 {
    margin-bottom: 30px;
  }
  .section-title.mt-55 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title.mt-55 {
    margin-top: 55px;
  }
}

.section-title.section-title-white h2 {
  color: #fff;
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  .section-title.section-title-white h2 {
    font-size: 22px;
  }
}

.section-title.section-title-white p {
  color: #fff;
}

.section-title.section-title-white span {
  color: #fff;
}

.section-title-2 h2 {
  font-size: 29px;
  font-weight: 500;
  margin: 0;
}

.section-title-2 p.st-2-paragraph {
  font-size: 15px;
  line-height: 30px;
  width: 82%;
  margin: 25px 0 59px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 p.st-2-paragraph {
    margin: 20px 0 25px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 p.st-2-paragraph {
    margin: 20px 0 25px;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-2.mb-85 {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2.mb-85 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-85 {
    margin-bottom: 30px;
  }
}

.section-title-3 h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
  line-height: 46px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-3 h2 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-3 h2 {
    font-size: 25px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-3 h2 {
    font-size: 26px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    font-size: 25px;
    line-height: 40px;
  }
}

.section-title-3 p {
  font-size: 16px;
  line-height: 30px;
  width: 75%;
  margin: 20px 0 59px;
  color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-title-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-title-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-3 p {
    margin: 20px 0 25px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-3 p {
    margin: 20px 0 25px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-3 p {
    margin: 12px 0 22px;
    width: 100%;
  }
}

.section-title-4 span {
  font-size: 22px;
  font-family: "Pacifico";
  color: #885643;
}

.section-title-4 h2 {
  font-size: 29px;
  font-weight: bold;
  color: #151C0F;
  margin: 17px 0 0;
}

.section-title-5 h2 {
  font-size: 36px;
  color: #232953;
  font-weight: 500;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-5 h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-5.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-6 h2 {
  color: #151C0F;
  font-size: 29px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-6 h2 {
    font-size: 25px;
    text-align: center;
  }
}

.section-title-6 p {
  color: #828495;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  margin: 28px 0 0;
}

.section-title-6 p.pet-food {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: #777;
}

@media only screen and (max-width: 767px) {
  .section-title-6 p {
    font-size: 14px;
    margin: 15px 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6.mb-55 {
    margin-bottom: 30px;
  }
  .section-title-6.mb-65 {
    margin-bottom: 30px;
  }
}

.section-title-6.st-6-fashion h2 {
  color: #151C0F;
  line-height: 50px;
  width: 45%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6.st-6-fashion h2 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6.st-6-fashion h2 {
    width: 100%;
  }
}

.section-title-7 h2 {
  font-size: 29px;
  font-weight: bold;
  color: #151C0F;
  line-height: 50px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-7 h2 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7 h2 {
    font-size: 24px;
    line-height: 40px;
  }
}

.section-title-7 span {
  margin: 46px 0 0px;
  font-size: 16px;
  color: #828495;
  display: block;
}

.section-title-7 span.mrg-dec {
  margin: 23px 0 0px;
}

@media only screen and (max-width: 767px) {
  .section-title-7 span.mrg-dec {
    margin: 10px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7 span {
    margin: 10px 0 0px;
  }
}

.section-title-7 p {
  font-size: 15px;
  line-height: 30px;
  margin: 13px auto 0;
  width: 41%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7 p {
    width: 65%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7 p {
    width: 100%;
  }
}

.section-title-7 p.peragraph-width-2 {
  width: 64%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-7 p.peragraph-width-2 {
    width: 72%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7 p.peragraph-width-2 {
    width: 93%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7 p.peragraph-width-2 {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-7.mb-100 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-7.mb-80 {
    margin-bottom: 30px;
  }
  .section-title-7.mb-100 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-7.mb-60 {
    margin-bottom: 30px;
  }
  .section-title-7.mb-65 {
    margin-bottom: 30px;
  }
  .section-title-7.mb-110 {
    margin-bottom: 30px;
  }
  .section-title-7.mb-80 {
    margin-bottom: 30px;
  }
  .section-title-7.mb-70 {
    margin-bottom: 30px;
  }
  .section-title-7.mb-100 {
    margin-bottom: 30px;
  }
}

.section-title-8 h2 {
  font-size: 36px;
  font-weight: bold;
  line-height: 30px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2 {
    font-size: 27px;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-8.mb-65 {
    margin-bottom: 30px;
  }
  .section-title-8.mb-60 {
    margin-bottom: 30px;
  }
}

.section-title-9 h2 {
  font-size: 36px;
  font-weight: bold;
  line-height: 55px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-9 h2 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-9 h2 {
    font-size: 26px;
    line-height: 40px;
  }
}

.section-title-9 span {
  margin: 10px 0 0px;
  font-size: 16px;
  color: #828495;
  display: block;
}

@media only screen and (max-width: 767px) {
  .section-title-9 {
    text-align: center;
  }
}

.mega-fashion-instagram-title {
  margin: 85px 0 95px 85px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .mega-fashion-instagram-title {
    margin: 45px 0 45px 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .mega-fashion-instagram-title {
    margin: 20px 0 45px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mega-fashion-instagram-title {
    margin: 27px 0 27px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mega-fashion-instagram-title {
    margin: 0px 0 30px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .mega-fashion-instagram-title {
    margin: 0px 0 30px 0px;
  }
}

/*---------- 10. Categories style ------------*/
.single-categories {
  background-color: #ffffff;
  padding: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories {
    padding: 30px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-categories {
    padding: 30px 20px;
  }
}

.single-categories .single-categories-title {
  margin: 0 0 43px;
}

.single-categories .single-categories-title h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories .single-categories-title h4 {
    font-size: 16px;
  }
}

.single-categories .single-categories-title h4 svg {
  margin-right: 22px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories .single-categories-title h4 svg {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-categories .single-categories-title h4 svg {
    margin-right: 10px;
  }
}

.single-categories .single-categories-title h4 svg path {
  fill: #000;
}

.single-categories ul li {
  position: relative;
  padding-left: 30px;
  line-height: 1;
  display: block;
  margin-bottom: 35px;
}

.single-categories ul li:last-child {
  margin-bottom: 0;
}

.single-categories ul li:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #AFAFAF;
  width: 11px;
  height: 2px;
  content: "";
}

.single-categories ul li a {
  color: #777777;
  font-size: 16px;
}

.single-categories ul li a:hover {
  color: #0A1039;
}

.single-categories:hover .single-categories-title h4 svg {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.categories-brand-tab-list {
  display: flex;
  justify-content: center;
}

.categories-brand-tab-list a {
  font-size: 18px;
  color: #333333;
  font-weight: 400;
  padding: 0 40px 25px;
  border-bottom: 2px solid #333;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .categories-brand-tab-list a {
    padding: 0 20px 25px;
  }
}

.categories-brand-tab-list a.active {
  font-weight: bold;
}

.categories-brand-tab-list a.active:before {
  position: absolute;
  background-color: #333;
  height: 4px;
  bottom: -3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  width: 100%;
  border-radius: 5px;
}

.categories-brand-tab-list.brand-tab-list-pink a {
  font-weight: 500;
  border-bottom: 1px solid #E3E4E9;
}

.categories-brand-tab-list.brand-tab-list-pink a.active {
  font-weight: 500;
}

.categories-brand-tab-list.brand-tab-list-pink a.active:before {
  background-color: #FF437D;
}

@media only screen and (max-width: 767px) {
  .categories-brand-tab-list.mb-70 {
    margin-bottom: 50px;
  }
  .categories-brand-tab-list.mb-90 {
    margin-bottom: 40px;
  }
}

.single-categories-brand a {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #777;
  border: 1px solid #ECECEC;
  padding: 30px 20px 30px 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-brand a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-brand a {
    font-size: 16px;
  }
}

.single-categories-brand a svg {
  min-width: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-brand a svg {
    min-width: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories-brand a svg {
    min-width: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-brand a svg {
    min-width: 45px;
  }
}

.single-categories-brand a svg g {
  min-width: 12px;
}

.single-categories-brand a svg g path {
  fill: #777;
  transition: all .3s ease 0s;
}

.single-categories-brand a:hover {
  color: #0A1039;
}

.single-categories-brand a:hover svg path {
  fill: #0A1039;
}

.single-categories-brand.single-categories-padding a {
  padding: 30px 10px 30px 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-brand.single-categories-padding a {
    padding: 30px 10px 30px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories-brand.single-categories-padding a {
    padding: 30px 20px 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-brand.single-categories-padding a {
    padding: 30px 15px 30px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-categories-brand.single-categories-padding a {
    padding: 30px 20px 30px 20px;
  }
}

.single-categories-brand.categories-brand-center a {
  text-align: center;
}

.categoriesa-top {
  padding: 54px 60px 0 104px;
  margin: 0 60px;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categoriesa-top {
    margin: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categoriesa-top {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categoriesa-top {
    margin: 0 0px;
    padding: 54px 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .categoriesa-top {
    margin: 0 0px;
    padding: 40px 20px 40px 20px;
  }
}

.categories-search-wrap {
  display: flex;
  flex-wrap: wrap;
}

.categories-search-wrap .categories-dropdown-2 {
  flex: 0 0 17%;
  max-width: 17%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-search-wrap .categories-dropdown-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .categories-search-wrap .categories-dropdown-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.categories-search-wrap .categories-dropdown-2 .nice-select-style-2.nice-select {
  border: solid 1px #F3F3F3;
  font-size: 12px;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
}

.categories-search-wrap .categories-dropdown-2 .nice-select-style-2.nice-select::after {
  margin-top: -1px;
  right: 25px;
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
}

.categories-search-wrap .categories-dropdown-2 .nice-select-style-2 > ul {
  width: 100%;
}

.categories-search-wrap .search-wrap-4 {
  flex: 0 0 83%;
  max-width: 83%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-search-wrap .search-wrap-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .categories-search-wrap .search-wrap-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.categories-search-wrap .search-wrap-4 form .search-style-4 {
  position: relative;
}

.categories-search-wrap .search-wrap-4 form .search-style-4 input {
  background-color: #ffffff;
  height: 50px;
  border: solid 1px #F3F3F3;
  border-radius: 5px;
  color: #777777;
  font-size: 13px;
  padding: 2px 60px 2px 30px;
}

@media only screen and (max-width: 767px) {
  .categories-search-wrap .search-wrap-4 form .search-style-4 input {
    padding: 2px 60px 2px 25px;
  }
}

.categories-search-wrap .search-wrap-4 form .search-style-4 button {
  padding: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  font-size: 20px;
  color: #081348;
  transition: all .3s ease 0s;
  background-color: transparent;
}

.categories-search-wrap .search-wrap-4 form .search-style-4 button:hover {
  color: #97D694;
}

.categories-brand-tab-list-2 {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .categories-brand-tab-list-2 {
    margin-top: 20px;
  }
}

.categories-brand-tab-list-2 a {
  font-size: 16px;
  color: #777777;
  font-weight: 500;
  padding: 14px 26px 17px;
  position: relative;
  border-radius: 10px 10px 0 0px;
  display: inline-block;
  border-top: 5px solid transparent;
}

@media only screen and (max-width: 767px) {
  .categories-brand-tab-list-2 a {
    padding: 10px 15px 13px;
    font-size: 14px;
  }
}

.categories-brand-tab-list-2 a.active {
  background-color: #ffffff;
  color: #4AA036;
  border-top: 5px solid #87CF8C;
}

.single-categories-2 .categories-img-2 {
  overflow: hidden;
}

.single-categories-2 .categories-img-2 a {
  display: block;
}

.single-categories-2 .categories-img-2 a img {
  transform: scale(1);
  transition: all .5s ease 0s;
}

.single-categories-2 h4 {
  font-size: 16px;
  color: #777777;
  margin: 27px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-2 h4 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-2 h4 {
    font-size: 14px;
    margin: 17px 0 0;
  }
}

.single-categories-2 h4 a {
  color: #777777;
}

.single-categories-2 h4 a:hover {
  color: #97D694;
}

.single-categories-2:hover .categories-img-2 a img {
  transform: scale(1.08);
}

@media only screen and (max-width: 767px) {
  .single-categories-2.mb-70 {
    margin-bottom: 50px;
  }
}

.categories-btn a {
  display: inline-block;
  color: #222D39;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  background-color: #F5F5F5;
  padding: 19px 22px 22px;
  position: relative;
  z-index: 2;
}

.categories-btn a:hover {
  color: #ffffff;
}

.categori-slider-active .slick-list {
  margin-left: -25px;
  margin-right: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categori-slider-active .slick-list {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categori-slider-active .slick-list {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categori-slider-active .slick-list {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .categori-slider-active .slick-list {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.categori-slider-active .slick-list .categories-3-plr {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categori-slider-active .slick-list .categories-3-plr {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categori-slider-active .slick-list .categories-3-plr {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categori-slider-active .slick-list .categories-3-plr {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .categori-slider-active .slick-list .categories-3-plr {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.categori-slider-active .slick-list .categories-3-plr .single-categories-3 .categories-img-3 {
  overflow: hidden;
}

.categori-slider-active .slick-list .categories-3-plr .single-categories-3 .categories-img-3 img {
  transition: all .5s ease 0s;
  transform: scale(1);
}

.categori-slider-active .slick-list .categories-3-plr .single-categories-3 h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 18px 0 0;
}

.categori-slider-active .slick-list .categories-3-plr .single-categories-3 h4 a {
  color: #232953;
}

.categori-slider-active .slick-list .categories-3-plr .single-categories-3 h4 a:hover {
  color: #359043;
}

.categori-slider-active .slick-list .categories-3-plr .single-categories-3:hover .categories-img-3 img {
  transform: scale(1.1);
}

.categori-area {
  position: relative;
}

.categori-area .organic-position-1 {
  animation-iteration-count: infinite;
  animation-name: bounce-img-2;
  animation-duration: 2.5s;
  position: absolute;
  top: -100px;
  right: 20px;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categori-area .organic-position-1 {
    top: -60px;
  }
}

@media only screen and (max-width: 767px) {
  .categori-area .organic-position-1 {
    top: -30px;
  }
}

.categori-area .organic-position-1 img {
  width: 195px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categori-area .organic-position-1 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .categori-area .organic-position-1 img {
    width: 100px;
  }
}

.categori-area .organic-position-2 {
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: 9;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .categori-area .organic-position-2 {
    top: 35%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categori-area .organic-position-2 {
    top: 35%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categori-area .organic-position-2 {
    top: 35%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categori-area .organic-position-2 {
    top: 35%;
  }
}

@media only screen and (max-width: 767px) {
  .categori-area .organic-position-2 {
    top: 44%;
  }
}

.categori-area .organic-position-2 img {
  width: 207px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categori-area .organic-position-2 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categori-area .organic-position-2 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categori-area .organic-position-2 img {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .categori-area .organic-position-2 img {
    width: 50px;
  }
}

.categories-search-wrap-2 {
  background-color: #fff;
  padding: 30px 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
}

@media only screen and (max-width: 767px) {
  .categories-search-wrap-2 {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .categories-menu {
    margin-bottom: 10px;
    text-align: center;
  }
}

.categories-menu nav ul li {
  display: inline-block;
  margin: 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-menu nav ul li {
    margin: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .categories-menu nav ul li {
    margin: 0 5px;
  }
}

.categories-menu nav ul li a {
  font-size: 18px;
  font-weight: 500;
  color: #777777;
  display: inline-block;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .categories-menu nav ul li a {
    font-size: 15px;
  }
}

.categories-menu nav ul li a:hover {
  color: #62BC77;
}

.single-categories-4 {
  position: relative;
  overflow: hidden;
}

.single-categories-4 > a {
  display: block;
}

.single-categories-4 > a img {
  width: 100%;
  transition: all .5s ease 0s;
  transform: scale(1);
}

.single-categories-4 .categorie-content-4 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 32px;
}

.single-categories-4 .categorie-content-4 h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.single-categories-4 .categorie-content-4 h4 a {
  color: #777777;
}

.single-categories-4 .categorie-content-4 h4 a:hover {
  color: #FF437D;
}

.single-categories-4 .categorie-content-5 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 32px;
}

.single-categories-4 .categorie-content-5 h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-4 .categorie-content-5 h4 {
    font-size: 14px;
  }
}

.single-categories-4 .categorie-content-5 h4 a {
  color: #081348;
}

.single-categories-4 .categorie-content-5 h4 a:hover {
  color: #333333;
}

.single-categories-4:hover a img {
  transform: scale(1.1);
}

.single-categories-5 {
  border: 1px solid #F3F3F3;
  padding: 35px 10px;
}

.single-categories-5 .single-categories-5-img a {
  display: block;
}

.single-categories-5 .single-categories-5-img a svg path {
  fill: #D8D8D8;
  transition: all .3s ease 0s;
}

.single-categories-5 .categorie-content-6 h4 {
  font-size: 16px;
  margin: 17px 0 0;
  line-height: 1;
}

.single-categories-5 .categorie-content-6 h4 a {
  display: block;
  color: #333333;
}

.single-categories-5 .categorie-content-6 h4 a.color-light {
  color: #777;
}

.single-categories-5 .categorie-content-6 h4 a.color-light:hover {
  color: #0A1039;
}

.single-categories-5 .categorie-content-6 h4 a:hover {
  color: #0A1039;
}

.single-categories-5:hover .single-categories-5-img a svg path {
  fill: #0A1039;
}

.car-categories-wrap {
  margin: 75px 0 0 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .car-categories-wrap {
    margin: 75px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .car-categories-wrap {
    margin: 75px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .car-categories-wrap {
    margin: 60px 0 0 0px;
  }
}

.single-categories-6 {
  border: 1px solid #F3F3F3;
  padding: 25px 10px;
}

.single-categories-6 .single-categories-6-img a {
  display: block;
  transition: all .5s ease 0s;
}

.single-categories-6 h4 {
  font-size: 16px;
  margin: 15px 0 0;
  line-height: 1;
}

.single-categories-6 h4 a {
  color: #081348;
}

.single-categories-6 h4 a:hover {
  color: #D02F3B;
}

.single-categories-6:hover .single-categories-6-img a {
  animation: 900ms ease-in-out 0s normal none 1 running jello;
}

.fashion-categore-list-wrap {
  padding: 0 100px 100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fashion-categore-list-wrap {
    padding: 0 0px 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fashion-categore-list-wrap {
    padding: 0 0px 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion-categore-list-wrap {
    padding: 0 0px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fashion-categore-list-wrap {
    padding: 0 0px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-categore-list-wrap {
    padding: 0 0px 30px;
  }
}

.fashion-categore-list {
  margin-bottom: 83px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fashion-categore-list {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fashion-categore-list {
    margin-bottom: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion-categore-list {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fashion-categore-list {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-categore-list {
    margin-bottom: 15px;
  }
}

.fashion-categore-list ul li {
  display: block;
  margin-bottom: 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fashion-categore-list ul li {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fashion-categore-list ul li {
    margin-bottom: 31px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion-categore-list ul li {
    margin-bottom: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fashion-categore-list ul li {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-categore-list ul li {
    margin-bottom: 15px;
  }
}

.fashion-categore-list ul li:last-child {
  margin-bottom: 0px;
}

.fashion-categore-list ul li a {
  display: block;
  font-size: 18px;
  color: #777777;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion-categore-list ul li a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fashion-categore-list ul li a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-categore-list ul li a {
    font-size: 16px;
  }
}

.fashion-categore-list ul li a:hover {
  color: #0A1039;
}

.fashion-categore-list-2 {
  background-color: #FAFAFA;
  padding: 69px 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fashion-categore-list-2 {
    padding: 33px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fashion-categore-list-2 {
    padding: 33px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion-categore-list-2 {
    padding: 40px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fashion-categore-list-2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-categore-list-2 {
    margin-bottom: 30px;
    padding: 40px 20px;
  }
}

.fashion-categore-list-2 .fashion3-categori-title {
  margin: 0 0 56px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fashion-categore-list-2 .fashion3-categori-title {
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fashion-categore-list-2 .fashion3-categori-title {
    margin: 0 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion-categore-list-2 .fashion3-categori-title {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-categore-list-2 .fashion3-categori-title {
    margin: 0 0 20px;
  }
}

.fashion-categore-list-2 .fashion3-categori-title h3 {
  font-size: 18px;
  font-weight: bold;
}

.fashion-categore-list-2 ul li {
  margin-bottom: 42px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fashion-categore-list-2 ul li {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fashion-categore-list-2 ul li {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion-categore-list-2 ul li {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fashion-categore-list-2 ul li {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-categore-list-2 ul li {
    margin-bottom: 15px;
  }
}

.fashion-categore-list-2 ul li:last-child {
  margin-bottom: 0;
}

.fashion-categore-list-2 ul li a {
  color: #777777;
  font-size: 18px;
  display: block;
  padding-left: 55px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .fashion-categore-list-2 ul li a {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .fashion-categore-list-2 ul li a {
    font-size: 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fashion-categore-list-2 ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-categore-list-2 ul li a {
    font-size: 16px;
  }
}

.fashion-categore-list-2 ul li a::before {
  position: absolute;
  content: "";
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 20px;
  background-color: #D8D8D8;
  z-index: 2;
  transition: all .3s ease 0s;
}

.fashion-categore-list-2 ul li a:hover {
  color: #333333;
}

.fashion-categore-list-2 ul li a:hover::before {
  background-color: #333333;
}

.single-categories-7 {
  position: relative;
  overflow: hidden;
}

.single-categories-7 a {
  display: block;
}

.single-categories-7 a img {
  width: 100%;
  transform: scale(1);
  transition: all .3s ease 0s;
}

.single-categories-7 .categorie-content-7 {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 26px;
}

.single-categories-7 .categorie-content-7 h4 {
  font-size: 18px;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-7 .categorie-content-7 h4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-7 .categorie-content-7 h4 {
    font-size: 16px;
  }
}

.single-categories-7 .categorie-content-7 h4 a {
  color: #333333;
}

.single-categories-7 .categorie-content-7 h4 a:hover {
  color: #6459FF;
}

.single-categories-7.categories-7-mrg1 {
  margin-top: -3px;
}

@media only screen and (max-width: 767px) {
  .single-categories-7.categories-7-mrg1 {
    margin-top: 0px;
  }
}

.single-categories-7.categories-7-mrg2 {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .single-categories-7.categories-7-mrg2 {
    margin-top: 0px;
    margin-bottom: 40px;
  }
}

.single-categories-7.categories-7-mrg3 {
  margin-top: -43px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories-7.categories-7-mrg3 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-7.categories-7-mrg3 {
    margin-top: 0px;
  }
}

.single-categories-7.categories-7-mrg4 {
  margin-top: -92px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-7.categories-7-mrg4 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories-7.categories-7-mrg4 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-7.categories-7-mrg4 {
    margin-top: 0px;
  }
}

.single-categories-7.categories-7-mrg5 {
  margin-top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-7.categories-7-mrg5 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories-7.categories-7-mrg5 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-7.categories-7-mrg5 {
    margin-top: 0px;
  }
}

.single-categories-7.categories-7-mrg6 {
  margin-top: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-categories-7.categories-7-mrg6 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-categories-7.categories-7-mrg6 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-categories-7.categories-7-mrg6 {
    margin-top: 0px;
  }
}

.single-categories-7:hover a img {
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-area.pt-120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .categories-area.pt-120 {
    padding-top: 30px;
  }
}

/*---------- 11. Services style ------------*/
.service-bg-color {
  margin-top: -110px;
  background: linear-gradient(to right, #F7FFF9, #F7FFF9 22.5%, #fff 0%);
}

@media only screen and (max-width: 767px) {
  .service-bg-color {
    margin-top: -40px;
  }
}

.service-wrap {
  position: relative;
  z-index: 92;
}

.service-wrap svg {
  transition: all .5s ease 0s;
}

.service-wrap svg.service-yellow-color path {
  fill: #EBA866;
}

.service-wrap svg.service-yellow-color text tspan {
  fill: #EBA866;
}

.service-wrap h3 {
  font-size: 18px;
  font-weight: bold;
  color: #060A27;
  margin: 32px 0 17px;
}

@media only screen and (max-width: 767px) {
  .service-wrap h3 {
    margin: 20px 0 17px;
  }
}

.service-wrap p {
  font-size: 15px;
  line-height: 30px;
  width: 78%;
}

.service-wrap p.service-peragraph-2 {
  margin: 0 auto;
  width: 80%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap p.service-peragraph-2 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap p.service-peragraph-2 {
    width: 90%;
  }
}

.service-wrap p.service-peragraph-3 {
  margin: 0 auto;
  width: 50%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .service-wrap p.service-peragraph-3 {
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap p.service-peragraph-3 {
    width: 77%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap p.service-peragraph-3 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap p.service-peragraph-3 {
    width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap p.service-peragraph-3 {
    width: 86%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap p {
    width: 100%;
  }
}

.service-wrap.service-wrap-ptb-1 {
  padding: 28px 5px 40px;
}

.service-wrap.service-wrap-ptb-2 {
  padding: 28px 5px 56px;
}

.service-wrap.service-wrap-ptb-3 {
  padding: 28px 5px 43px;
}

.service-wrap.service-bg-color-1 {
  background-color: #FFF5F5;
}

.service-wrap.service-bg-color-2 {
  background-color: #F7FFF1;
}

.service-wrap.service-bg-color-3 {
  background-color: #E6FFFD;
}

.service-wrap.service-bg-color-4 {
  background-color: #F5F5FF;
}

.service-wrap:hover svg {
  animation: 900ms ease-in-out 0s normal none 1 running jello;
}

.service-wrap.service-wrap-modify h3 {
  font-size: 17px;
  margin: 21px 0 27px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap.service-wrap-modify h3 {
    margin: 21px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap.service-wrap-modify h3 {
    margin: 12px 0 12px;
  }
}

.service-wrap.service-wrap-modify p {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #828495;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap.service-wrap-modify p {
    width: 76%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap.service-wrap-modify p {
    color: #333;
  }
}

.service-wrap.service-wrap-modify-2 h3 {
  font-size: 17px;
  color: #333;
  margin: 21px 0 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap.service-wrap-modify-2 h3 {
    margin: 21px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap.service-wrap-modify-2 h3 {
    margin: 12px 0 12px;
  }
}

.service-wrap.service-automobile {
  padding: 60px 48px 55px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .service-wrap.service-automobile {
    padding: 60px 30px 55px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .service-wrap.service-automobile {
    padding: 40px 30px 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap.service-automobile {
    padding: 40px 20px 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap.service-automobile {
    padding: 20px 15px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap.service-automobile {
    padding: 20px 15px 15px;
  }
}

.service-wrap:hover.service-automobile {
  background-color: #FCEEEE;
}

@media only screen and (max-width: 767px) {
  .service-wrap.mb-65 {
    margin-bottom: 30px;
  }
  .service-wrap.mb-90 {
    margin-bottom: 40px;
  }
}

.services-banner-img {
  margin-right: -0px;
}

.services-banner-img img {
  width: 100%;
}

.service-border {
  position: relative;
}

.service-border:before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  border: 1px solid #E6E7E8;
}

.service-border:last-child:before {
  display: none;
}

.service-wrap-2 .service-wrap-2-content {
  padding: 80px 30px 76px 50px;
  position: relative;
}

.service-wrap-2 .service-wrap-2-content::before {
  background-image: url(../../assets/images/icon-img/service-shape.png);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0px;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 9px;
  z-index: 9;
}

.service-wrap-2 .service-wrap-2-content::after {
  background-image: url(../../assets/images/icon-img/service-shape-2.png);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0px;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 7px;
  z-index: 9;
}

.service-wrap-2 .service-wrap-2-content h3 {
  font-size: 17px;
  font-weight: bold;
  margin: 0 0 10px;
}

.service-wrap-2 .service-wrap-2-content p {
  font-size: 15px;
  line-height: 30px;
}

.service-wrap-2 .service-wrap-2-content.service2-bg-blue {
  background-color: #F2FCFF;
}

.service-wrap-2 .service-wrap-2-content.service2-bg-blue h3 {
  color: #5696FF;
}

.service-wrap-2 .service-wrap-2-content.service2-bg-green {
  background-color: #EBFFF7;
}

.service-wrap-2 .service-wrap-2-content.service2-bg-green h3 {
  color: #45D288;
}

.service-wrap-2 .service-wrap-2-content.service2-bg-pink {
  background-color: #FFF8FD;
}

.service-wrap-2 .service-wrap-2-content.service2-bg-pink h3 {
  color: #E46BC0;
}

.service-wrap-2 .service-wrap-2-content.service2-bg-orange {
  background-color: #FFFAF5;
}

.service-wrap-2 .service-wrap-2-content.service2-bg-orange h3 {
  color: #E39604;
}

@media only screen and (max-width: 767px) {
  .service-wrap-2 {
    margin-bottom: 50px;
  }
  .service-wrap-2 .service-wrap-2-img {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-wrap-2 {
    margin-bottom: 0px;
  }
  .service-wrap-2 .service-wrap-2-img {
    margin-bottom: 0px;
  }
}

.service-wrap-2:hover .service-wrap-2-img svg {
  animation: 900ms ease-in-out 0s normal none 1 running jello;
}

.service-wrap-3 {
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-3.mb-85 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-3.mb-85 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-3.mb-85 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .service-wrap-3.mb-85 {
    margin-bottom: 50px;
  }
}

.service-wrap-3 .service-img-3 {
  min-width: 50px;
  margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-3 .service-img-3 {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-3 .service-img-3 {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-3 .service-img-3 {
    margin-right: 20px;
  }
}

.service-wrap-3 .service-img-3 svg {
  transition: all .5s ease 0s;
}

.service-wrap-3 .service-content-3 h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 19px;
}

@media only screen and (max-width: 767px) {
  .service-wrap-3 .service-content-3 h3 {
    margin: 0 0 10px;
  }
}

.service-wrap-3 .service-content-3 p {
  font-size: 15px;
  line-height: 30px;
  width: 78%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-3 .service-content-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap-3 .service-content-3 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-3 .service-content-3 p {
    width: 100%;
  }
}

.service-wrap-3:hover .service-img-3 svg {
  animation: 900ms ease-in-out 0s normal none 1 running jello;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-area.pb-110 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .service-area.pb-110 {
    padding-bottom: 20px;
  }
  .service-area.pb-100 {
    padding-bottom: 30px;
  }
  .service-area.pb-50 {
    padding-bottom: 20px;
  }
  .service-area.pt-100 {
    padding-top: 60px;
  }
  .service-area.service-kids-bg {
    background-position: 34%;
  }
}

/*---------- 12. Brand logo style ------------*/
.brand-logo-active .single-brand-logo, .brand-logo-active-3 .single-brand-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo-active .single-brand-logo img, .brand-logo-active-3 .single-brand-logo img {
  transition: all .5s ease 0s;
  max-width: 100%;
}

.brand-logo-active .single-brand-logo:hover img, .brand-logo-active-3 .single-brand-logo:hover img {
  animation: 900ms ease-in-out 0s normal none 1 running swing;
}

.brand-logo-active-2 .slick-list, .brand-logo-active .slick-list, .brand-logo-active-3 .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.brand-logo-active-2 .slick-list .single-brand-plr, .brand-logo-active .slick-list .single-brand-plr, .brand-logo-active-3 .slick-list .single-brand-plr {
  padding-right: 15px;
  padding-left: 15px;
}

.brand-logo-active-2 .single-brand-logo img, .brand-logo-active .single-brand-logo img, .brand-logo-active-3 .single-brand-logo img {
  max-width: 100%;
}

.single-brand-logo-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.single-brand-logo-2 img {
  transition: all .5s ease 0s;
  width: 100%;
  transform: scale(1);
}

.single-brand-logo-2:hover img {
  transform: scale(1.1);
}

.single-brand-logo-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #FAFAFA;
}

.single-brand-logo-3 img {
  transition: all .5s ease 0s;
  width: 100%;
  transform: scale(1);
}

.single-brand-logo-3:hover {
  border: 1px solid #f1f1f1;
}

.single-brand-logo-3:hover img {
  transform: scale(1.1);
}

.fashion-3-brand-logo {
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .fashion-3-brand-logo {
    margin-right: 0px;
  }
}

.single-brand-logo-4 {
  display: flex;
  justify-content: center;
}

.single-brand-logo-4 img {
  transition: all .5s ease 0s;
  transform: scale(1);
}

.single-brand-logo-4:hover img {
  transform: scale(1.1);
}

.dot-style-7 .slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.dot-style-7 .slick-dots li {
  margin: 0 6px;
}

.dot-style-7 .slick-dots li button {
  border: none;
  padding: 0;
  background-color: #E5E5E5;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  font-size: 0px;
  transition: all .3s ease 0s;
}

.dot-style-7 .slick-dots li.slick-active button {
  width: 23px;
  border-radius: 50px;
}

/*---------- 13. Blog style  ------------*/
.blog-wrap .blog-img {
  overflow: hidden;
}

.blog-wrap .blog-img a {
  display: block;
}

.blog-wrap .blog-img a img {
  width: 100%;
  transform: scale(1) rotate(0deg);
  transition: all .5s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-img.mb-35 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-img.mb-35 {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img.mb-35 {
    margin-bottom: 15px;
  }
}

.blog-wrap .blog-content h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 11px;
  line-height: 27px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h3 {
    font-size: 16px;
  }
}

.blog-wrap .blog-content h3 a {
  color: #333;
}

.blog-wrap .blog-content h3 a:hover {
  color: #0A1039;
}

.blog-wrap .blog-content span {
  font-size: 12px;
  color: #928F8F;
}

.blog-wrap .blog-content span.mrg-top-inc {
  margin-top: 18px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content span.mrg-top-inc {
    margin-top: 7px;
  }
}

.blog-wrap .blog-content p {
  font-size: 15px;
  line-height: 30px;
  margin: 15px 0 0;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content p {
    margin: 7px 0 0;
  }
}

.blog-wrap:hover .blog-img a img {
  transform: scale(1.08) rotate(5deg);
}

.blog-details-content .blog-details-img {
  position: relative;
}

.blog-details-content .blog-details-img img {
  width: 100%;
}

.blog-details-content h3 {
  font-size: 28px;
  margin: 35px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-content h3 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content h3 {
    font-size: 19px;
    line-height: 30px;
    margin: 10px 0 0px;
  }
}

.blog-details-content .blog-meta-5 {
  padding: 17px 0 12px;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-meta-5 {
    padding: 10px 0 10px;
  }
}

.blog-details-content .blog-meta-5 ul li {
  font-size: 12px;
  color: #928F8F;
  display: inline-block;
  margin-right: 2px;
}

.blog-details-content p {
  font-size: 15px;
  line-height: 30px;
  margin: 0 0 18px;
}

.blog-details-content .blog-dec-midle-content {
  margin: 38px 0 0;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-dec-midle-content {
    margin: 20px 0 0;
  }
}

.blog-details-content .blog-dec-midle-content img {
  width: 100%;
}

.blog-details-content .blog-dec-midle-content h3 {
  font-size: 28px;
  margin: 35px 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-content .blog-dec-midle-content h3 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .blog-dec-midle-content h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-dec-midle-content h3 {
    font-size: 19px;
    line-height: 30px;
    margin: 10px 0 0px;
  }
}

.blog-details-content .blog-dec-midle-content p {
  font-size: 15px;
  line-height: 30px;
  margin: 0 0 18px;
}

.blog-details-tag-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 46px 0 20px;
  flex-wrap: wrap;
  padding-bottom: 8px;
  border-bottom: 1px solid #d8d8d8;
}

@media only screen and (max-width: 767px) {
  .blog-details-tag-social {
    margin: 25px 0 25px;
  }
}

.blog-details-tag-social .blog-details-tag ul {
  display: flex;
}

.blog-details-tag-social .blog-details-tag ul li {
  color: #2f2f2f;
  margin-right: 3px;
}

.blog-details-tag-social .blog-details-tag ul li a {
  color: #2f2f2f;
}

.blog-details-tag-social .blog-details-tag ul li a:hover {
  color: #0A1039;
}

@media only screen and (max-width: 767px) {
  .blog-details-tag-social .blog-details-social {
    margin-top: 10px;
  }
}

.blog-details-tag-social .blog-details-social ul {
  display: flex;
  align-items: center;
}

.blog-details-tag-social .blog-details-social ul li {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .blog-details-tag-social .blog-details-social ul li {
    margin-left: 4px;
  }
}

.blog-details-tag-social .blog-details-social ul li:first-child {
  margin-left: 0;
}

.blog-details-tag-social .blog-details-social ul li a {
  display: inline-block;
  color: #333;
  font-size: 14px;
}

.blog-details-tag-social .blog-details-social ul li a.facebook:hover {
  color: #3b5999;
}

.blog-details-tag-social .blog-details-social ul li a.twitter:hover {
  color: #55acee;
}

.blog-details-tag-social .blog-details-social ul li a.instagram:hover {
  color: #e4405f;
}

.blog-details-tag-social .blog-details-social ul li a.pinterest:hover {
  color: #b90f16;
}

.next-prev-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 17px;
  border-bottom: 1px solid #d8d8d8;
}

.next-prev-wrap .next-prev-conent > h4 {
  font-size: 16px;
  line-height: 28px;
  margin: 0 0 7px;
  color: #1d1d1d;
}

@media only screen and (max-width: 767px) {
  .next-prev-wrap .next-prev-conent > h4 {
    font-size: 13px;
    margin: 0 0 3px;
    line-height: 25px;
  }
}

.next-prev-wrap .next-prev-conent > h4 a {
  color: #1d1d1d;
}

.next-prev-wrap .next-prev-conent > h4 a:hover {
  color: #0A1039;
}

.next-prev-wrap .next-prev-conent > a {
  color: #989898;
  font-size: 14px;
}

.next-prev-wrap .next-prev-conent > a:hover {
  color: #0A1039;
}

.next-prev-wrap .next-prev-conent.prev-wrap {
  text-align: right;
}

.blog-comments-area {
  margin: 35px 0 50px;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area {
    margin: 30px 0 30px;
  }
}

.blog-comments-area h4 {
  font-size: 24px;
  color: #2f2f2f;
  margin: 0 0 35px;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area h4 {
    margin: 0 0 20px;
  }
}

.blog-comments-area .blog-comments-wrap {
  border: 1px solid #d1d1d1;
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel {
  padding: 50px 50px 50px;
  border-bottom: 1px solid #d1d1d1;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel {
    padding: 30px 20px 30px;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel:last-child {
  border-bottom: none;
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment {
    flex-wrap: wrap;
  }
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment.ml-80 {
    margin-left: 0;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img {
  flex: 0 0 94px;
  margin-right: 57px;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img {
    margin-bottom: 10px;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-img img {
  width: 100%;
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content p {
  font-size: 16px;
  color: #535353;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content p {
    margin-bottom: 10px;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply {
    display: flex;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply h5 {
  color: #989898;
  font-size: 14px;
  margin: 0 20px 0 0;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply h5 {
    line-height: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply h5 {
    line-height: 1;
  }
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply a {
  display: inline-block;
  line-height: 1;
  color: #2f2f2f;
}

.blog-comments-area .blog-comments-wrap .single-blog-bundel .single-blog-comment .blog-comment-content .comment-name-reply a:hover {
  color: #0A1039;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comment-form {
    margin-bottom: 50px;
  }
  .comment-form.mrg-none {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .comment-form {
    margin-bottom: 30px;
  }
  .comment-form.mrg-none {
    margin-bottom: 0;
  }
}

.comment-form > h3 {
  font-size: 24px;
  color: #2f2f2f;
  margin: 0 0 7px;
}

.comment-form p {
  font-size: 13px;
  color: #707070;
  margin: 0 0 45px;
}

@media only screen and (max-width: 767px) {
  .comment-form p {
    margin: 0 0 20px;
  }
}

.comment-form form .leave-form {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .comment-form form .leave-form {
    margin: 0 0 15px;
  }
}

.comment-form form .leave-form input {
  background-color: transparent;
  height: 60px;
  color: #2f2f2f;
  border: 1px solid #d1d1d1;
  padding: 2px 20px;
}

@media only screen and (max-width: 767px) {
  .comment-form form .leave-form input {
    height: 50px;
  }
}

.comment-form form .leave-form input:focus {
  border: 1px solid #262626;
}

.comment-form form .leave-form textarea {
  background-color: transparent;
  height: 150px;
  color: #2f2f2f;
  padding: 20px 20px 2px 20px;
  border: 1px solid #d1d1d1;
}

.comment-form form .leave-form textarea:focus {
  border: 1px solid #262626;
}

.comment-form form .text-submit input {
  height: auto;
  border: none;
  display: inline-block;
  padding: 15px 45px;
  font-weight: bold;
  background-color: #1d1d1d;
  color: #fff;
  width: auto;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
}

.comment-form form .text-submit input:hover {
  background-color: #0A1039;
}

.quote-content {
  background-color: #2f2f2f;
  padding: 50px 165px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .quote-content {
    padding: 50px 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .quote-content {
    padding: 50px 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .quote-content {
    padding: 50px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quote-content {
    padding: 40px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .quote-content {
    padding: 40px 15px;
  }
}

.quote-content h5 {
  font-size: 16px;
  color: #fff;
  margin: 0 0 20px;
  line-height: 32px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .quote-content h5 {
    font-size: 16px;
    margin: 0 0 10px;
  }
}

.quote-content .blog-name span {
  font-weight: 500;
  color: #fff;
  display: inline-block;
  position: relative;
}

.quote-content .blog-name span:before {
  position: absolute;
  left: -45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 32px;
  height: 2px;
  content: "";
}

.quote-content .blog-name span:after {
  position: absolute;
  right: -45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  width: 32px;
  height: 2px;
  content: "";
}

.quote-content.quote-content-mrg {
  margin: 40px 0 38px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-blog-wrap {
    margin-bottom: 50px;
  }
  .main-blog-wrap.mr-20 {
    margin-right: 0px;
  }
  .main-blog-wrap.ml-20 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-blog-wrap {
    margin-bottom: 50px;
  }
  .main-blog-wrap.mr-20 {
    margin-right: 0px;
  }
  .main-blog-wrap.ml-20 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrap.ml-20 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrap.ml-20 {
    margin-left: 0px;
  }
}

/*---------- 14. Footer style ------------*/
.footer-area {
  position: relative;
}

.footer-area.kids-footer {
  position: relative;
}

.footer-area.kids-footer::before {
  background-image: url(../../assets/images/icon-img/testimonial-shape-2.png);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0px;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 9px;
  z-index: 9;
}

.footer-area .organic-footer-shape-top {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .footer-area .organic-footer-shape-top {
    top: -10px;
  }
}

.footer-area .organic-footer-shape-top img {
  width: 100%;
}

.footer-widget h3.footer-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget h3.footer-title {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title {
    margin: 0 0 25px;
  }
}

.footer-widget h3.footer-title.footer-title-white {
  color: #ffffff;
}

.footer-widget h3.footer-title-2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget h3.footer-title-2 {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title-2 {
    margin: 0 0 25px;
  }
}

.footer-widget h3.footer-title-3 {
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  margin: 0 0 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget h3.footer-title-3 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget h3.footer-title-3 {
    margin: 0 0 30px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title-3 {
    margin: 0 0 25px;
    font-size: 20px;
  }
}

.footer-widget h3.footer-title-3.footer-title-3-black {
  color: #2B292D;
}

.footer-widget .footer-info-list ul li {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-info-list ul li {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-info-list ul li {
    margin-bottom: 17px;
  }
}

.footer-widget .footer-info-list ul li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-info-list ul li a {
  font-size: 16px;
  color: #777;
  font-weight: 300;
  display: block;
  line-height: 1;
}

.footer-widget .footer-info-list ul li a:hover {
  color: #0A1039;
}

.footer-widget .footer-info-list.info-list-mrg-dec ul li {
  margin-bottom: 26px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-info-list.info-list-mrg-dec ul li {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-info-list.info-list-mrg-dec ul li {
    margin-bottom: 17px;
  }
}

.footer-widget .footer-info-list.info-list-mrg-dec ul li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-info-list.info-list-mrg-dec ul li a {
  color: #828495;
}

.footer-widget .footer-info-list.info-list-mrg-dec ul li a:hover {
  color: #0A1039;
}

.footer-widget .footer-info-list.footer-info-list-white ul li a {
  color: #ffffff;
}

.footer-widget .footer-info-list.footer-info-list-white ul li a:hover {
  color: #0A1039;
}

.footer-widget .footer-info-list-2 ul li {
  margin-bottom: 26px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-info-list-2 ul li {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-info-list-2 ul li {
    margin-bottom: 17px;
  }
}

.footer-widget .footer-info-list-2 ul li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-info-list-2 ul li a {
  color: #828495;
  font-size: 16px;
  font-weight: 300;
  display: block;
  line-height: 1;
}

.footer-widget .footer-info-list-2 ul li a:hover {
  color: #0A1039;
}

.footer-widget .footer-info-list-3 ul li {
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-info-list-3 ul li {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-info-list-3 ul li {
    margin-bottom: 17px;
  }
}

.footer-widget .footer-info-list-3 ul li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-info-list-3 ul li a {
  color: #BEBCFE;
  font-size: 18px;
  display: block;
  line-height: 1;
}

.footer-widget .footer-info-list-3 ul li a:hover {
  color: #0A1039;
}

.footer-widget .footer-info-list-3.footer-info-list-3-black ul li a {
  color: #777;
}

.footer-widget .footer-info-list-3.footer-info-list-3-black ul li a:hover {
  color: #0A1039;
}

.footer-widget .footer-connect p {
  color: #828495;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 9px;
}

.footer-widget .footer-connect a {
  color: #828495;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 7px;
  display: block;
}

.footer-widget .footer-connect a:hover {
  color: #0A1039;
}

.app-google-store {
  margin: 32px 0 0;
}

.app-google-store a {
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .app-google-store a {
    margin-right: 2px;
  }
}

@media only screen and (max-width: 767px) {
  .app-google-store a {
    margin-right: 2px;
    margin-bottom: 5px;
  }
}

.app-google-store a:last-child {
  margin-right: 0;
}

.app-google-store a img {
  max-width: 100%;
}

.copyright p {
  color: #AFAFAF;
}

.copyright p a {
  color: #AFAFAF;
}

.copyright.copyright-ptb {
  padding: 23px 0 25px;
}

.footer-menu nav ul li {
  display: inline-block;
  margin-right: 17px;
}

.footer-menu nav ul li:last-child {
  margin-right: 0px;
}

.footer-menu nav ul li a {
  color: #2B292D;
  display: inline-block;
}

.footer-menu nav ul li a:hover {
  color: #0A1039;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu {
    text-align: center;
  }
}

.footer-menu.footer-menu-right {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footer-menu.footer-menu-right {
    text-align: center;
  }
}

.footer-menu.footer-menu-right-blue nav ul li a {
  color: #9E9E9E;
}

.footer-menu.footer-menu-right-blue nav ul li a:hover {
  color: #EBA866;
}

.footer-menu.footer-menu-right-white nav ul li a {
  color: #ffffff;
}

.footer-menu.footer-menu-right-white nav ul li a:hover {
  color: #0A1039;
}

.social-icon {
  display: flex;
  flex-wrap: wrap;
}

.social-icon a {
  display: inline-block;
  margin-left: 30px;
  color: #CFCFCF;
  font-size: 16px;
}

.social-icon a:first-child {
  margin-left: 0px;
}

.social-icon a:hover {
  color: #0A1039;
}

.social-icon.social-icon-right {
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-icon.social-icon-right {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .social-icon.social-icon-right {
    justify-content: center;
  }
}

.social-icon.social-icon-center {
  justify-content: center;
}

.social-icon.social-icon-center a {
  font-size: 17px;
}

.social-icon.social-icon-white a {
  color: #fff;
}

.social-icon.social-icon-white a:hover {
  color: #0A1039;
}

.social-icon.fashion-1-social a {
  margin-left: 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .social-icon.fashion-1-social a {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .social-icon.fashion-1-social a {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-icon.fashion-1-social a {
    margin-left: 10px;
    font-size: 14px;
  }
}

.social-icon.fashion-1-social a:first-child {
  margin-left: 0px;
}

.copyright-ptb-2 {
  padding: 35px 0;
}

.copyright-2 p {
  color: #9E9E9E;
}

.copyright-2 p a {
  color: #9E9E9E;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-2 {
    padding: 8px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .copyright-2 {
    padding: 10px 0;
    text-align: center;
  }
}

.copyright-2.copyright-2-white p {
  color: #ffffff;
}

.copyright-2.copyright-2-white p a {
  color: #ffffff;
}

.copyright-2.copyright-2-right p {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .copyright-2.copyright-2-right p {
    text-align: center;
  }
}

.footer-top-style-3 p {
  color: #828495;
  font-weight: 300;
  line-height: 28px;
  margin: 40px auto 37px;
  width: 35%;
  text-align: center;
}

.footer-top-style-3 p.white {
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-top-style-3 p {
    width: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-top-style-3 p {
    width: 60%;
    margin: 20px auto 17px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-style-3 p {
    width: 100%;
    margin: 20px auto 17px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-style-3.pb-75 {
    padding-bottom: 45px;
  }
}

.footer-menu-2 {
  margin-top: 57px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu-2 {
    margin-top: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu-2 {
    margin-top: 27px;
  }
}

.footer-menu-2 nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-menu-2 nav ul li {
  display: inline-block;
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .footer-menu-2 nav ul li {
    margin-right: 20px;
  }
}

.footer-menu-2 nav ul li:last-child {
  margin-right: 0px;
}

.footer-menu-2 nav ul li a {
  color: #828495;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}

.footer-menu-2 nav ul li a:hover {
  color: #EBA866;
}

.footer-menu-2.footer-menu-2-white nav ul li a {
  color: #ffffff;
}

.footer-menu-2.footer-menu-2-white nav ul li a:hover {
  color: #0A1039;
}

.copyright-ptb-3 {
  padding: 40px 0 43px;
}

@media only screen and (max-width: 767px) {
  .copyright-ptb-3 {
    padding: 20px 0 25px;
  }
}

.footer-bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.footer-bg-shape img {
  width: 100%;
}

.footer-about p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  margin: 30px 0 50px;
  width: 74%;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-about p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-about p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-about p {
    width: 100%;
    margin: 20px 0 20px;
  }
}

.footer-about.footer-about-black p {
  color: #777777;
  width: 77%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-about.footer-about-black p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-about.footer-about-black p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-top.pb-110 {
    padding-bottom: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area.pt-115 {
    padding-top: 80px;
  }
  .footer-top.pb-110 {
    padding-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area.pt-115 {
    padding-top: 50px;
  }
  .footer-area.pt-120 {
    padding-top: 60px;
  }
  .footer-top.pb-70 {
    padding-bottom: 20px;
  }
  .footer-top.pet-footer-top.pb-50 {
    padding-bottom: 10px;
  }
}

.pet-food-footer-1 {
  position: absolute;
  top: -40px;
  left: 0;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .pet-food-footer-1 {
    top: -10px;
  }
}

.pet-food-footer-1 img {
  width: 202px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pet-food-footer-1 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pet-food-footer-1 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-food-footer-1 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-food-footer-1 img {
    width: 60px;
  }
}

.pet-food-footer-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pet-food-footer-2 {
    top: -20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pet-food-footer-2 {
    top: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-food-footer-2 {
    top: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-food-footer-2 {
    top: -20px;
  }
}

.pet-food-footer-2 img {
  width: 207px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .pet-food-footer-2 img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pet-food-footer-2 img {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pet-food-footer-2 img {
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .pet-food-footer-2 img {
    width: 80px;
  }
}

.kids-2-footer-area {
  background-color: #5F3AF5;
  position: relative;
}

.kids-2-footer-area::before {
  background-image: url(../../assets/images/icon-img/kids-2-footer-shape.png);
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0px;
  background-repeat: repeat;
  display: block;
  width: 100%;
  height: 26px;
  z-index: 9;
}

/*---------- 15. Subscribe style ------------*/
.subscribe-area {
  position: relative;
}

.subscribe-area .kids-subscribe-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.subscribe-area .kids-subscribe-shape img {
  width: 100%;
}

.subscribe-wrap p {
  font-weight: 300;
  color: #828495;
  line-height: 21px;
  margin: 16px 0 0;
}

.subscribe-wrap p.white {
  color: #fff;
}

.subscribe-form .subscribe-form-style > .mc-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form .subscribe-form-style > .mc-form > input {
  color: #828495;
  font-weight: 300;
  border: none;
  height: 40px;
  padding: 2px 55px 2px 0;
  background-color: transparent;
  border-bottom: 1px solid #DCDCDC;
}

.subscribe-form .subscribe-form-style > .mc-form > input::-moz-input-placeholder {
  color: #828495;
  opacity: 1;
}

.subscribe-form .subscribe-form-style > .mc-form > input::-webkit-input-placeholder {
  color: #828495;
  opacity: 1;
}

.subscribe-form .subscribe-form-style > .mc-form .mc-news {
  display: none;
}

.subscribe-form .subscribe-form-style > .mc-form .clear {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 43px;
  height: 40px;
  border: none;
  padding: 0;
  z-index: 99;
  background: #262836 url("../../assets/images/icon-img/right-arrow.png") no-repeat scroll right 15px center;
}

.subscribe-form .subscribe-form-style > .mc-form .clear:hover {
  background: #0A1039 url("../../assets/images/icon-img/right-arrow.png") no-repeat scroll right 15px center;
}

.subscribe-form .subscribe-form-style > .mc-form .clear input {
  background-color: transparent;
  border: none;
  height: 40px;
}

.subscribe-form .subscribe-form-style > .mc-form .clear.yellow-arrow {
  background: #FFE600 url("../../assets/images/icon-img/right-arrow-black.png") no-repeat scroll right 15px center;
}

.subscribe-form .subscribe-form-style > .mc-form .clear.yellow-arrow:hover {
  background: #0A1039 url("../../assets/images/icon-img/right-arrow.png") no-repeat scroll right 15px center;
}

.subscribe-form .subscribe-form-style > .mc-form .clear.green-arrow {
  background: #359043 url("../../assets/images/icon-img/right-arrow.png") no-repeat scroll right 15px center;
}

.subscribe-form .subscribe-form-style > .mc-form .clear.green-arrow:hover {
  background: #0A1039 url("../../assets/images/icon-img/right-arrow.png") no-repeat scroll right 15px center;
}

.subscribe-form .subscribe-form-style > .mc-form .clear.brown-arrow {
  background: #CF7F39 url("../../assets/images/icon-img/right-arrow.png") no-repeat scroll right 15px center;
}

.subscribe-form .subscribe-form-style > .mc-form .clear.brown-arrow:hover {
  background: #333333 url("../../assets/images/icon-img/right-arrow.png") no-repeat scroll right 15px center;
}

.subscribe-form.subscribe-form-white .subscribe-form-style > .mc-form > input {
  color: #fff;
  border-bottom: 1px solid #DCDCDC;
}

.subscribe-form.subscribe-form-white .subscribe-form-style > .mc-form > input::-moz-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form.subscribe-form-white .subscribe-form-style > .mc-form > input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form.subscribe-form-white .subscribe-form-style > .mc-form .clear {
  background: #ffffff url("../../assets/images/icon-img/right-arrow-pink.png") no-repeat scroll right 15px center;
}

.subscribe-form.subscribe-form-white .subscribe-form-style > .mc-form .clear:hover {
  background: #0A1039 url("../../assets/images/icon-img/right-arrow.png") no-repeat scroll right 15px center;
}

.subscribe-form.subscribe-form-white .subscribe-form-style > .mc-form .clear input {
  background-color: transparent;
  border: none;
  height: 40px;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 {
  position: relative;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input {
  background-color: transparent;
  border: 1px solid #686D7B;
  height: 57px;
  font-weight: 300;
  color: #AFAFAF;
  padding: 2px 160px 2px 25px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input {
    padding: 2px 115px 2px 15px;
  }
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input::-moz-input-placeholder {
  color: #AFAFAF;
  opacity: 1;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input::-webkit-input-placeholder {
  color: #AFAFAF;
  opacity: 1;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-news-2 {
  display: none;
}

.subscribe-form-2 .subscribe-form-style-2 .clear-2 {
  position: absolute;
  top: 0;
  right: 0;
}

.subscribe-form-2 .subscribe-form-style-2 .clear-2 input {
  width: auto;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 2px 35px;
  background-color: #333333;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2 .subscribe-form-style-2 .clear-2 input {
    font-size: 15px;
    padding: 2px 15px;
  }
}

.subscribe-form-2 .subscribe-form-style-2 .clear-2 input::-moz-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form-2 .subscribe-form-style-2 .clear-2 input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form-2 .subscribe-form-style-2 .clear-2 input:hover {
  background-color: #0A1039;
}

.subscribe-form-2.subscribe-form-2-white .subscribe-form-style-2 .mc-form-2 input {
  border: 1px solid #fff;
  color: #fff;
}

.subscribe-form-2.subscribe-form-2-white .subscribe-form-style-2 .mc-form-2 input::-moz-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form-2.subscribe-form-2-white .subscribe-form-style-2 .mc-form-2 input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.subscribe-form-2.subscribe-form-2-white .subscribe-form-style-2 .clear-2 input {
  color: #62BC77;
  font-weight: bold;
  background-color: #ffffff;
  border: none;
}

.subscribe-form-2.subscribe-form-2-white .subscribe-form-style-2 .clear-2 input::-moz-input-placeholder {
  color: #62BC77;
  opacity: 1;
}

.subscribe-form-2.subscribe-form-2-white .subscribe-form-style-2 .clear-2 input::-webkit-input-placeholder {
  color: #62BC77;
  opacity: 1;
}

.subscribe-form-2.subscribe-form-2-white .subscribe-form-style-2 .clear-2 input.blue {
  color: #453FEE;
}

.subscribe-form-2.subscribe-form-2-white .subscribe-form-style-2 .clear-2 input:hover {
  background-color: #0A1039;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-2.subscribe-form-2-white {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2.subscribe-form-2-white {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2.mt-30 {
    margin-top: 0px;
  }
}

.subscribe-form-2.subscribe-form-2-yellow .subscribe-form-style-2 .mc-form-2 input {
  border: 1px solid #F8E5A1;
  color: #888EB8;
}

.subscribe-form-2.subscribe-form-2-yellow .subscribe-form-style-2 .mc-form-2 input::-moz-input-placeholder {
  color: #888EB8;
  opacity: 1;
}

.subscribe-form-2.subscribe-form-2-yellow .subscribe-form-style-2 .mc-form-2 input::-webkit-input-placeholder {
  color: #888EB8;
  opacity: 1;
}

.subscribe-form-2.subscribe-form-2-yellow .subscribe-form-style-2 .clear-2 input {
  background-color: #EBA866;
  color: #ffffff;
}

.subscribe-form-2.subscribe-form-2-yellow .subscribe-form-style-2 .clear-2 input::-moz-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-2.subscribe-form-2-yellow .subscribe-form-style-2 .clear-2 input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}

.subscribe-form-2.subscribe-form-2-yellow .subscribe-form-style-2 .clear-2 input:hover {
  background-color: #0A1039;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-area.pt-120 {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-area.pb-115 {
    padding-bottom: 60px;
  }
  .subscribe-area.pb-130 {
    padding-bottom: 60px;
  }
  .subscribe-area.pb-95 {
    padding-bottom: 60px;
  }
  .subscribe-area.pt-120 {
    padding-top: 60px;
  }
}

/*-------- 16. Product details style ---------*/
.modal-dialog {
  margin: 2% auto 8%;
  max-width: 960px;
  width: 960px;
  padding: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

.modal-dialog .modal-header .close {
  color: #333;
  cursor: pointer;
  opacity: 1;
}

.modal-dialog .modal-body {
  padding: 35px 15px;
}

.modal-dialog .quickview-big-img img {
  width: 100%;
}

.quickview-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .product-details-content {
    margin: 20px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content {
    margin: 0px 0 0;
  }
}

.product-details-content h2 {
  font-size: 29px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 25px;
  }
}

.product-details-content .product-ratting-review-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 14px;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-right: 35px;
  position: relative;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  right: -18px;
  background-color: #BCBCBC;
  height: 15px;
  width: 1px;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap .product-ratting {
  display: flex;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap .product-ratting i {
  font-size: 12px;
  color: #D1A868;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap .product-digit {
  margin-left: 18px;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap .product-digit span {
  font-size: 16px;
  color: #484848;
}

.product-details-content .product-ratting-review-wrap .product-review-order span {
  color: #BCBCBC;
  font-size: 16px;
  margin-right: 10px;
}

.product-details-content .product-ratting-review-wrap .product-review-order span:last-child {
  margin-right: 0;
}

.product-details-content .product-ratting-review-wrap.product-ratting-mrg-none {
  margin: 33px 0 14px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-ratting-review-wrap.product-ratting-mrg-none {
    margin: 15px 0 14px;
  }
}

.product-details-content p {
  font-size: 15px;
  line-height: 30px;
}

.product-details-content p.pro-dec-paragraph-1 {
  width: 70%;
}

@media only screen and (max-width: 767px) {
  .product-details-content p.pro-dec-paragraph-1 {
    width: 100%;
  }
}

.product-details-content p.pro-dec-paragraph-2 {
  width: 65%;
  margin: 15px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content p.pro-dec-paragraph-2 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content p.pro-dec-paragraph-2 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content p.pro-dec-paragraph-2 {
    width: 100%;
  }
}

.product-details-content p.pro-dec-paragraph-3 {
  width: 75%;
  margin: 15px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content p.pro-dec-paragraph-3 {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content p.pro-dec-paragraph-3 {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content p.pro-dec-paragraph-3 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content p.pro-dec-paragraph-3 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content p.pro-dec-paragraph-3 {
    width: 100%;
  }
}

.product-details-content .pro-details-price {
  display: flex;
  margin: 26px 0 25px;
}

.product-details-content .pro-details-price span {
  font-size: 30px;
  font-weight: 500;
  color: #3A3A3A;
}

.product-details-content .pro-details-price span.old-price {
  text-decoration: line-through;
  font-size: 21px;
  font-weight: 300;
  color: #777777;
  margin-left: 20px;
}

.product-details-content .pro-details-price.pro-details-price-4 {
  margin: 0px 0 25px;
}

.product-details-content .pro-details-color-wrap {
  margin-bottom: 25px;
}

.product-details-content .pro-details-color-wrap span {
  display: block;
  font-size: 13px;
  font-weight: 300;
  color: #777777;
  margin: 0 0 10px;
}

.product-details-content .pro-details-size {
  margin-bottom: 25px;
}

.product-details-content .pro-details-size span {
  display: block;
  font-size: 13px;
  font-weight: 300;
  color: #777777;
  margin: 0 0 10px;
}

.product-details-content .pro-details-size .pro-details-size-content ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-details-content .pro-details-size .pro-details-size-content ul li {
  margin-right: 5px;
}

.product-details-content .pro-details-size .pro-details-size-content ul li:last-child {
  margin-right: 0;
}

.product-details-content .pro-details-size .pro-details-size-content ul li a {
  display: inline-block;
  font-size: 12px;
  color: #828495;
  border: 1px solid #E3E4E9;
  line-height: 1;
  width: 31px;
  height: 31px;
  text-align: center;
  line-height: 29px;
}

.product-details-content .pro-details-size .pro-details-size-content ul li a:hover {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #333333;
}

.product-details-content .product-details-meta {
  margin: 20px 0 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-meta {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-details-meta {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-meta {
    margin: 20px 0 20px;
  }
}

.product-details-content .product-details-meta ul li {
  margin: 0 0 16px;
}

.product-details-content .product-details-meta ul li:last-child {
  argin: 0 0 0px;
}

.product-details-content .product-details-meta ul li span {
  display: inline-block;
  color: #545454;
  width: 85px;
}

.product-details-content .product-details-meta ul li a {
  color: #1C1C1C;
}

.product-details-content .product-details-meta ul li a:hover {
  color: #0A1039;
}

.product-details-content .pro-details-action-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-action-wrap {
    display: block;
  }
}

.product-details-content .pro-details-action-wrap.pro-details-action-center {
  justify-content: center;
}

.product-details-content .pro-details-action-wrap .pro-details-buy-now {
  margin-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-action-wrap .pro-details-buy-now {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-action-wrap .pro-details-buy-now {
    margin-bottom: 20px;
  }
}

.product-details-content .pro-details-action-wrap .pro-details-buy-now a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 1;
  background-color: #333333;
  padding: 16px 50px 21px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content .pro-details-action-wrap .pro-details-buy-now a {
    padding: 16px 30px 21px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-action-wrap .pro-details-buy-now a {
    padding: 16px 30px 21px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-action-wrap .pro-details-buy-now a {
    padding: 16px 30px 21px;
  }
}

.product-details-content .pro-details-action-wrap .pro-details-buy-now a:hover {
  background-color: #0A1039;
}

.product-details-content .pro-details-action-wrap .pro-details-action {
  position: relative;
}

.product-details-content .pro-details-action-wrap .pro-details-action > a {
  display: inline-block;
  font-size: 17px;
  line-height: 1;
  padding: 16px 18px 17px;
  color: #333333;
  border: 1px solid #EDEDED;
  margin-right: 15px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-action-wrap .pro-details-action > a {
    padding: 16px 15px 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-action-wrap .pro-details-action > a {
    padding: 16px 10px 17px;
    margin-right: 10px;
  }
}

.product-details-content .pro-details-action-wrap .pro-details-action > a:last-child {
  margin-right: 0px;
}

.product-details-content .pro-details-action-wrap .pro-details-action > a:hover {
  background-color: #0A1039;
  color: #ffffff;
}

.pro-details-quality span {
  display: block;
  font-size: 13px;
  font-weight: 300;
  color: #777777;
  margin: 0 0 10px;
}

.pro-details-quality .cart-plus-minus {
  display: inline-block;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.pro-details-quality .cart-plus-minus .qtybutton {
  color: #8E8FA0;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  top: 0;
}

.pro-details-quality .cart-plus-minus .qtybutton.dec {
  height: 31px;
  width: 31px;
  line-height: 31px;
  border: 1px solid #E3E4E9;
}

.pro-details-quality .cart-plus-minus .qtybutton.inc {
  height: 31px;
  width: 31px;
  line-height: 31px;
  border: 1px solid #E3E4E9;
  right: 0px;
}

.pro-details-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #3A3A3A;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 31px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 92px;
}

.pro-details-quality .cart-plus-minus input.cart-plus-minus-box.plus-minus-width-inc {
  width: 120px;
}

.pro-details-color-content ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pro-details-color-content ul li {
  margin-right: 5px;
  display: inline-block;
}

.pro-details-color-content ul li:last-child {
  margin-right: 0;
}

.pro-details-color-content ul li a {
  width: 31px;
  height: 31px;
  font-size: 0;
  display: inline-block;
  border: 1px solid #E3E4E9;
  position: relative;
}

.pro-details-color-content ul li a.active::before {
  color: #fff;
  content: "\eed8";
  font-family: IcoFont;
  font-size: 20px;
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pro-details-color-content ul li a.white {
  background-color: #fff;
}

.pro-details-color-content ul li a.azalea {
  background-color: #FBBBBB;
}

.pro-details-color-content ul li a.dolly {
  background-color: #F3E66D;
}

.pro-details-color-content ul li a.peach-orange {
  background-color: #FFCC8A;
}

.pro-details-color-content ul li a.mona-lisa {
  background-color: #FB9696;
}

.pro-details-color-content ul li a.cupid {
  background-color: #F5A8DE;
}

.pro-details-color-content ul li a.one {
  background-color: #D0CCF8;
}

.pro-details-color-content ul li a.two {
  background-color: #B6E3F6;
}

.pro-details-color-content ul li a.three {
  background-color: #D1D0AE;
}

.pro-details-color-content ul li a.four {
  background-color: #BED4A5;
}

.pro-details-color-content ul li a.five {
  background-color: #C4BB6C;
}

.pro-details-color-content ul li a.six {
  background-color: #A0D1B7;
}

.pro-details-color-content ul li a.seven {
  background-color: #E1A8A8;
}

.pro-details-color-content ul li a.eight {
  background-color: #B8BBD4;
}

.pro-details-color-content ul li a.nine {
  background-color: #A8A6B4;
}

.pro-details-color-content ul li a.ten {
  background-color: #5D6061;
}

@media only screen and (max-width: 767px) {
  .pro-details-color-content.mt-50 {
    margin-top: 20px;
  }
}

.pro-details-color-content.sidebar-widget-color {
  margin-left: 18px;
}

.pro-details-color-content.sidebar-widget-color.color-mrg-2 {
  margin-left: 0;
  margin-right: 45px;
}

.pro-details-color-content.sidebar-widget-color ul li a {
  width: 22px;
  height: 22px;
}

@media only screen and (max-width: 767px) {
  .pro-details-color-content.sidebar-widget-color.mt-45 {
    margin-top: 25px;
  }
  .pro-details-color-content.sidebar-widget-color.mt-50 {
    margin-top: 30px;
  }
}

.product-dec-social {
  position: absolute;
  bottom: -33px;
  right: -7px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .product-dec-social {
    left: 0;
    right: auto;
  }
}

.product-dec-social a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.product-dec-social a.facebook {
  background-color: #5678bf;
}

.product-dec-social a.twitter {
  background-color: #73c2fd;
}

.product-dec-social a.instagram {
  background-color: #e4405f;
}

.product-dec-social a.pinterest {
  background-color: #c32026;
}

.product-dec-social.show {
  visibility: visible;
  opacity: 1;
  right: 15px;
}

.product-details-2-img {
  position: relative;
  padding-top: 90px;
  display: inline-block !important;
}

.product-details-2-img > img {
  position: relative;
  z-index: 5;
  max-width: 100%;
}

.product-details-2-img > a > img {
  position: relative;
  z-index: 5;
}

.product-details-2-img:before {
  position: absolute;
  content: "";
  width: 432px;
  height: 432px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  background: linear-gradient(180deg, white 0%, #ebe8e4 99%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-2-img:before {
    width: 332px;
    height: 332px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-2-img:before {
    top: 40px;
    width: 250px;
    height: 250px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-2-img:before {
    top: 40px;
    width: 350px;
    height: 350px;
  }
}

.product-details-2-img-wrap {
  position: relative;
}

.product-details-tab-large {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .product-details-tab-large.pt-40 {
    padding-top: 0px;
  }
}

.product-details-shape {
  position: absolute;
  top: 30%;
  left: 22%;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
  z-index: 9;
}

.product-details-shape.pro-dec-shape1 {
  left: -11%;
}

.product-details-shape.pro-dec-shape3 {
  left: 14%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-shape {
    left: 11%;
  }
  .product-details-shape.pro-dec-shape3 {
    left: 7%;
  }
  .product-details-shape.pro-dec-shape1 {
    left: -5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-shape {
    left: 11%;
  }
  .product-details-shape.pro-dec-shape3 {
    left: 7%;
  }
  .product-details-shape.pro-dec-shape1 {
    left: 5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-shape {
    left: 8%;
  }
  .product-details-shape.pro-dec-shape3 {
    left: 7%;
  }
  .product-details-shape.pro-dec-shape1 {
    left: 3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-shape {
    left: 8%;
  }
  .product-details-shape.pro-dec-shape3 {
    left: 9%;
  }
  .product-details-shape.pro-dec-shape1 {
    left: 6%;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-shape {
    left: 3%;
  }
  .product-details-shape.pro-dec-shape3 {
    left: 6%;
  }
  .product-details-shape.pro-dec-shape1 {
    left: 3%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-shape {
    left: 7%;
  }
  .product-details-shape.pro-dec-shape3 {
    left: 9%;
  }
  .product-details-shape.pro-dec-shape1 {
    left: 4%;
  }
}

.product-details-shape img {
  width: 175px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-shape img {
    width: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-shape img {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-shape img {
    width: 75px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-shape img {
    width: 130px;
  }
}

.product-details-mt {
  margin-top: 111px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-mt {
    margin-top: 86px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-mt {
    margin-top: 86px;
  }
}

.dec-review-topbar {
  border-bottom: 1px solid #F5F5F5;
}

.dec-review-topbar a {
  display: inline-block;
  font-size: 18px;
  color: #777777;
  padding: 0 30px 22px;
  margin: 0 14px 0 0;
  position: relative;
}

.dec-review-topbar a:last-child {
  margin: 0 0px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dec-review-topbar a {
    padding: 0 15px 22px;
    margin: 0 7px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-topbar a {
    padding: 0 10px 10px;
    margin: 0 0px 10px 0;
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dec-review-topbar a {
    padding: 0 7px 10px;
  }
}

.dec-review-topbar a:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #333333;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}

.dec-review-topbar a.active {
  color: #333;
  font-weight: bold;
}

.dec-review-topbar a.active:before {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .dec-review-topbar.mb-65 {
    margin-bottom: 35px;
  }
}

.dec-review-topbar.dec-review-topbar-center {
  display: flex;
  justify-content: center;
  border-bottom: none;
}

.dec-review-bottom .description-wrap p {
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .description-wrap p {
    font-size: 14px;
    margin: 0 0 20px;
  }
}

.dec-review-bottom .description-wrap p:last-child {
  margin: 0 0 0px;
}

.dec-review-bottom .specification-wrap table {
  width: 100%;
}

.dec-review-bottom .specification-wrap table tbody {
  border: 1px solid #f0f0f0;
  width: 100%;
}

.dec-review-bottom .specification-wrap table tbody tr {
  border-bottom: 1px solid #f0f0f0;
}

.dec-review-bottom .specification-wrap table tbody tr td {
  border-right: 1px solid #f0f0f0;
  font-size: 14px;
  color: #333333;
  padding: 17px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dec-review-bottom .specification-wrap table tbody tr td {
    padding: 17px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dec-review-bottom .specification-wrap table tbody tr td {
    padding: 17px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .specification-wrap table tbody tr td {
    padding: 17px 15px;
  }
}

.dec-review-bottom .specification-wrap table tbody tr td.width1 {
  width: 300px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dec-review-bottom .specification-wrap table tbody tr td.width1 {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .specification-wrap table tbody tr td.width1 {
    width: 100px;
  }
}

.dec-review-bottom .review-wrapper h2 {
  font-size: 25px;
  margin: 0 0 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dec-review-bottom .review-wrapper h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .review-wrapper h2 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.dec-review-bottom .single-review {
  margin: 0 0 60px;
  padding: 50px 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dec-review-bottom .single-review {
    padding: 50px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review {
    padding: 30px;
    display: block;
    margin: 0 0 30px;
  }
}

.dec-review-bottom .single-review .review-img {
  flex: 0 0 60px;
}

.dec-review-bottom .single-review .review-content {
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review .review-content {
    margin-left: 0px;
  }
}

.dec-review-bottom .single-review .review-content .review-top-wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0 33px;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review .review-content .review-top-wrap {
    display: block;
    margin: 10px 0 10px;
  }
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-name h5 {
  font-size: 12px;
  margin: 0;
  color: #535353;
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-name h5 span {
  font-weight: bold;
  font-size: 14px;
  color: #262626;
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review .review-content .review-top-wrap .review-rating {
    margin: 10px 0 0px;
  }
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-rating i {
  font-size: 14px;
  color: #d5d6d8;
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-rating i.yellow {
  color: #f5b223;
}

.dec-review-bottom .single-review .review-content p {
  font-size: 16px;
  color: #535353;
  line-height: 30px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review .review-content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .dec-review-bottom .single-review.pro-details-9-single-review {
    padding: 50px 12px;
  }
}

.dec-review-bottom .ratting-form-wrapper > span {
  display: block;
  line-height: 1;
}

.dec-review-bottom .ratting-form-wrapper p {
  font-size: 12px;
  margin: 9px 0 0;
  color: #535353;
}

.dec-review-bottom .ratting-form-wrapper p span {
  color: red;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form {
  margin: 30px 0 0;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .star-box-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 29px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star {
  margin-right: 17px;
  display: flex;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star a {
  color: #535353;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star:hover i {
  color: #f5b223;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 5px;
  color: #535353;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style label span {
  color: red;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style input, .dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 50px;
  padding: 5px 10px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style input:focus, .dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style textarea:focus {
  border: 1px solid #262626;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .form-submit {
  margin-top: 20px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .form-submit input {
  padding: 10px 35px;
  line-height: 30px;
  color: #fff;
  border-width: 0;
  font-weight: 600;
  display: inline-block;
  width: auto;
  height: auto;
  letter-spacing: 2px;
  font-size: 12px;
  text-transform: uppercase;
  background: #262626;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #0A1039;
}

.product-details-content-3 {
  padding: 120px 30px 0 145px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content-3 {
    padding: 80px 30px 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content-3 {
    padding: 80px 30px 0 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content-3 {
    padding: 70px 30px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content-3 {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content-3 {
    padding: 10px 15px 0px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content-3 {
    padding: 30px 30px 0px 30px;
  }
}

.product-details-3-img-slider img {
  display: inline-block;
}

.dot-style-8 .slick-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 22px;
  left: 22%;
}

@media only screen and (max-width: 767px) {
  .dot-style-8 .slick-dots {
    left: 7%;
  }
}

.dot-style-8 .slick-dots li {
  margin: 0 3px;
}

.dot-style-8 .slick-dots li button {
  border: none;
  padding: 0;
  background-color: #E5E5E5;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  font-size: 0px;
  transition: all .3s ease 0s;
}

.dot-style-8 .slick-dots li.slick-active button {
  width: 34px;
  border-radius: 50px;
}

.product-details-content-4 {
  margin-left: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content-4 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content-4 {
    margin-left: 0px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content-4 {
    margin-left: 0px;
  }
}

.product-details-top-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 95px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-top-content {
    display: block;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-top-content {
    display: block;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-top-content {
    display: block;
    margin-bottom: 30px;
  }
}

.product-dec-left {
  width: 85%;
  float: left;
  padding-right: 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-left {
    width: 83%;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-left {
    width: 75%;
    padding-right: 20px;
  }
}

.product-dec-right {
  width: 15%;
  float: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-right {
    width: 17%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-right {
    width: 25%;
  }
}

.product-big-img-style .easyzoom-style .easyzoom {
  display: block;
}

.product-big-img-style .easyzoom-style .easyzoom > a {
  transition: none;
}

.product-big-img-style .easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.product-small-img-style .slick-list .product-dec-small {
  margin-bottom: 20px;
}

.product-small-img-style .slick-list .product-dec-small img {
  cursor: pointer;
}

.product-details-slider-5-active .slick-list {
  padding: 0 184px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-slider-5-active .slick-list {
    padding: 0 120px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-slider-5-active .slick-list {
    padding: 0 50px;
  }
}

.product-details-slider-5-active .slick-list .single-pro-details-slider-5 {
  margin: 0px 15px 0px 15px;
}

.product-details-slider-5-active .slick-list .single-pro-details-slider-5 img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .product-details-slider-5-active.pb-100 {
    padding-bottom: 20px;
  }
}

.description-review-wrapper .pro-dec-accordion {
  padding: 0 0 43px;
}

.description-review-wrapper .pro-dec-accordion:last-child {
  padding: 0 0 0px;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 {
  margin: 0;
  font-size: 18px;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a {
  display: block;
  position: relative;
  text-transform: capitalize;
  padding: 0px 0 0px 30px;
  color: #333333;
  font-weight: bold;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a::after {
  content: "\ef9a";
  color: #262626;
  font-family: IcoFont;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  font-weight: normal;
  color: #D1A868;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a.collapsed {
  color: #777777;
  font-weight: 400;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a.collapsed:hover {
  color: #0A1039;
}

.description-review-wrapper .pro-dec-accordion .panel-heading h4 a.collapsed::after {
  content: "\efc2";
  color: #484848;
}

.description-review-wrapper .pro-dec-accordion .panel-body {
  padding: 34px 0 0px 30px;
}

.description-review-wrapper.product-details-5-wrap {
  margin: 0 0 0 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-review-wrapper.product-details-5-wrap {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-wrapper.product-details-5-wrap {
    margin: 50px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper.product-details-5-wrap {
    margin: 50px 0 0 0px;
  }
}

.product-details-content-5 {
  border-right: 1px solid #E6E6E6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content-5 {
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content-5 {
    border: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content-5 {
    margin-top: 20px;
  }
}

.product-details-content-6 {
  margin: 0 0 0 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content-6 {
    margin: 0 0 0 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content-6 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content-6 {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content-6 {
    margin: 30px 0 0 0px;
  }
}

.pro-details-6-img {
  margin-right: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-details-6-img {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-6-img {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-details-6-img {
    margin-right: 0px;
  }
}

.pro-details-6-img img {
  width: 100%;
}

.pro-details-7-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .pro-details-7-img.mt-75 {
    margin-top: 15px;
  }
}

.product-details-content-7 {
  box-shadow: 0 10px 20px rgba(3, 9, 25, 0.04);
  padding: 135px 60px 120px 70px;
  margin-right: 15px;
  margin-top: -80px;
  position: relative;
  z-index: 9;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content-7 {
    padding: 135px 20px 120px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content-7 {
    margin-top: -50px;
    margin-right: 0px;
    padding: 100px 20px 100px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content-7 {
    margin-top: -10px;
    margin-right: 0px;
    padding: 60px 15px 60px 15px;
  }
}

.product-details-content-8 {
  box-shadow: 0 10px 20px rgba(3, 9, 25, 0.04);
  padding: 112px 100px 120px 100px;
  margin-top: -80px;
  position: relative;
  z-index: 9;
  background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content-8 {
    margin-top: -50px;
    padding: 112px 20px 120px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content-8 {
    margin-top: -10px;
    padding: 60px 15px 48px 15px;
  }
}

.product-details-7-wrap {
  margin: 80px 0 0 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-7-wrap {
    margin: 80px 0 0 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-7-wrap {
    margin: 80px 0 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-7-wrap {
    margin: 80px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-7-wrap {
    margin: 80px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-7-wrap {
    margin: 50px 0 0 0px;
  }
}

.product-details-meta-2 {
  margin: 22px 0 80px 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-meta-2 {
    margin: 22px 0 50px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-meta-2 {
    margin: 22px 0 27px 0px;
  }
}

.product-details-meta-2 ul {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-meta-2 ul {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-meta-2 ul {
    display: block;
  }
}

.product-details-meta-2 ul li {
  margin-right: 85px;
}

.product-details-meta-2 ul li:last-child {
  margin-right: 0px;
}

.product-details-meta-2 ul li span {
  display: inline-block;
  color: #545454;
  width: 85px;
}

.product-details-meta-2 ul li a {
  color: #1C1C1C;
}

.product-details-meta-2 ul li a:hover {
  color: #0A1039;
}

.pro-details-9-img img {
  width: 100%;
}

.pro-details-9-2-img {
  margin-left: -10px;
}

.pro-details-9-2-img img {
  width: 100%;
}

.product-details-content-9 {
  margin: 0 0 0 100px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-details-content-9 {
    margin: 0 0 0 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content-9 {
    margin: 0 0 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content-9 {
    margin: 30px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content-9 {
    margin: 30px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content-9 {
    margin: 30px 0 0 0px;
  }
}

.description-review-wrapper-9 {
  margin-right: 105px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .description-review-wrapper-9 {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .description-review-wrapper-9 {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .description-review-wrapper-9 {
    margin-right: 0px;
    margin-top: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .description-review-wrapper-9 {
    margin-right: 0px;
    margin-top: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .description-review-wrapper-9 {
    margin-right: 0px;
    margin-top: 35px;
  }
}

.pro-details-9-3-img img {
  width: 100%;
}

.product-details-9-middle {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .product-details-9-middle {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.pro-details-img-zoom {
  overflow: hidden;
}

.pro-details-img-zoom img {
  transition: all .5s ease 0s;
  transform: scale(1);
}

.pro-details-img-zoom:hover img {
  transform: scale(1.1);
}

.main-product-details-content {
  box-shadow: 0 10px 20px rgba(3, 9, 25, 0.04);
  background-color: #fff;
  padding: 68px 60px 103px 60px;
  margin-top: 67px;
  margin-left: 20px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-product-details-content {
    padding: 68px 30px 103px 30px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-product-details-content {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main-product-details-content {
    margin-left: 0px;
    margin-top: 37px;
    padding: 48px 15px 50px 15px;
    box-shadow: 0 10px 20px rgba(3, 9, 25, 0.06);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-product-details-content {
    margin-top: 17px;
  }
}

.product-details-bg {
  background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 88.2%, #fff 0%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-bg {
    background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 91.2%, #fff 0%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-bg {
    background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 44.5%, #fff 0%);
  }
}

@media only screen and (max-width: 767px) {
  .product-details-bg {
    background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 42.6%, #fff 0%);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-bg {
    background: linear-gradient(to bottom, #FBFBFB, #FBFBFB 43.9%, #fff 0%);
  }
}

.product-details-tab-small {
  flex-wrap: wrap;
  margin-top: 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-tab-small {
    margin-top: 17px;
  }
}

.product-details-tab-small a {
  display: block;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-tab-small a {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-tab-small a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.product-details-tab-small a img {
  width: 100%;
}

.pro-details-9-4-img img {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-area.pt-120 {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content-mrg {
    margin-top: 40px;
  }
  .product-details-area.pt-40 {
    padding-top: 0;
  }
  .product-details-area.pt-50 {
    padding-top: 0;
  }
  .product-details-area.pt-120 {
    padding-top: 80px;
  }
  .product-details-area.pb-95 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content-mrg {
    margin-top: 30px;
  }
  .product-details-area.pt-40 {
    padding-top: 0;
  }
  .product-details-area.pt-50 {
    padding-top: 0;
  }
  .product-details-area.pt-120 {
    padding-top: 30px;
  }
  .product-details-area.pb-95 {
    padding-bottom: 50px;
  }
}

/*---------- 17. Instagram style ------------*/
.instagram-wrap-1 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.instagram-wrap-1 .single-instafeed-wrap {
  flex: 0 0 33.333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.single-instafeed {
  list-style: none;
  overflow: hidden;
}

.single-instafeed a {
  display: block;
}

.single-instafeed a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.single-instafeed:hover a img {
  transform: scale(1.1);
}

.automobile-instafeed-mrg {
  margin-top: -56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .automobile-instafeed-mrg {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .automobile-instafeed-mrg {
    margin-top: 0px;
  }
}

.mega-fashion-instagram {
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .mega-fashion-instagram {
    padding: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .instagram-feed-area.mr-40 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-feed-area.mr-40 {
    margin-right: 0;
  }
  .instagram-area.pt-120 {
    padding-top: 60px;
  }
  .instagram-area.pb-115 {
    padding-bottom: 30px;
  }
}

/*---------- 18. Testimonial style ------------*/
.testimonial-active .slick-list {
  padding-right: 100%;
  margin-right: -100%;
  margin-left: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-active .slick-list {
    margin-right: -25%;
    padding-right: 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-active .slick-list {
    margin-right: -25%;
    padding-right: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active .slick-list {
    margin-right: -25%;
    padding-right: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active .slick-list {
    margin-left: -15px;
  }
}

.testimonial-active .slick-list .testimonial-plr-1 {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .testimonial-active .slick-list .testimonial-plr-1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active.pl-70 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active.pl-70 {
    padding-left: 0;
  }
}

.single-testimonial {
  background-color: #fff;
  padding: 47px 30px 46px 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial {
    padding: 47px 30px 46px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial {
    padding: 47px 30px 46px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial {
    padding: 47px 20px 46px 20px;
  }
}

.single-testimonial .testi-rating-quotes-icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.single-testimonial .testi-rating-quotes-icon .testi-rating i {
  color: #FFB300;
  font-size: 12px;
}

.single-testimonial p {
  color: #828495;
  font-size: 16px;
  line-height: 28px;
  margin: 27px 0 42px;
}

@media only screen and (max-width: 767px) {
  .single-testimonial p {
    font-size: 15px;
    margin: 27px 0 25px;
  }
}

.single-testimonial p span {
  color: #262626;
  font-weight: 500;
}

.single-testimonial .client-info-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.single-testimonial .client-info-wrap .client-img {
  margin-right: 20px;
}

.single-testimonial .client-info-wrap .client-info h3 {
  font-size: 18px;
  color: #222222;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 7px;
}

.single-testimonial .client-info-wrap .client-info span {
  font-size: 14px;
  color: #BBBBBB;
}

.single-testimonial.single-testimonial-modify {
  background-color: #FDFDFD;
  margin-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial.single-testimonial-modify {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial.single-testimonial-modify {
    margin-left: 0px;
  }
}

.single-testimonial.single-testimonial-modify > p {
  color: #828495;
  margin: 53px 0 53px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial.single-testimonial-modify > p {
    margin: 33px 0 33px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial.single-testimonial-modify > p {
    margin: 33px 0 33px;
  }
}

.single-testimonial.single-testimonial-modify > p span {
  color: #359043;
  font-weight: bold;
}

.single-testimonial.single-testimonial-gray-bg.slick-current {
  background-color: #FAFAFA;
}

.single-testimonial.single-testimonial-brown-bg.slick-current {
  background-color: #FDFAF4;
}

.testimonial-active-2 .slick-list {
  padding-right: 650px;
  margin-right: -20px;
  margin-left: -20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .testimonial-active-2 .slick-list {
    padding-right: 350px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-active-2 .slick-list {
    padding-right: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-active-2 .slick-list {
    padding-right: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active-2 .slick-list {
    padding-right: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2 .slick-list {
    padding-right: 0px;
    margin-right: -15px;
    margin-left: -15px;
  }
}

.testimonial-active-2 .slick-list .testimonial-plr-2 {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .testimonial-active-2 .slick-list .testimonial-plr-2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.testimonial-img-slider {
  position: relative;
  padding-top: 19px;
}

.testimonial-img-slider img {
  width: 100%;
}

.testimonial-img-slider .testimonial-img-slider-icon {
  position: absolute;
  right: 22px;
  top: 0;
}

.testimonial-img-slider .testimonial-img-slider-icon svg.icon-black path {
  fill: #262836;
}

.single-testimonial-2 .testimonial-img-slider-icon-3 {
  margin-bottom: 22px;
  margin-top: 28px;
}

.single-testimonial-2 h4 {
  color: #222D39;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.single-testimonial-2 p {
  line-height: 30px;
  font-size: 15px;
  margin: 16px 0 18px;
  width: 59%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-2 p {
    width: 100%;
    margin: 16px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2 p {
    width: 100%;
    margin: 16px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 p {
    width: 100%;
    margin: 16px 0 10px;
  }
}

.single-testimonial-2 .client-info-2 .testi-rating-2 {
  display: flex;
}

.single-testimonial-2 .client-info-2 .testi-rating-2 i {
  color: #DF9A24;
  font-size: 12px;
}

.single-testimonial-2 .client-info-2 h3 {
  font-size: 12px;
  font-weight: 600;
  color: #0A1039;
  margin: 5px 0 0;
}

.single-testimonial-2.fashion-3-testimonial p {
  width: 100%;
}

.testimonial-3-content-area {
  margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-3-content-area {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-3-content-area {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-3-content-area {
    margin-left: 0px;
  }
}

.testimonial-active-3 {
  position: relative;
  margin-top: -20px;
}

.testimonial-active-3.mega-testimonial-mt {
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .testimonial-active-3.mega-testimonial-mt {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active-3 {
    margin-top: 0px;
  }
}

.testimonial-title h3 {
  color: #151C0F;
  font-weight: bold;
  font-size: 29px;
  margin: 0;
  line-height: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-title h3 {
    line-height: 35px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-title h3 {
    line-height: 38px;
    font-size: 22px;
  }
}

.testimonial-title-img {
  margin-left: 18px;
}

@media only screen and (max-width: 767px) {
  .testimonial-title-img {
    margin-top: 20px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-title-img {
    margin-top: 0px;
    margin-left: 10px;
  }
}

.testimonial-title-img img {
  max-width: 100%;
}

.testimonials-3-btn {
  margin-top: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonials-3-btn {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonials-3-btn {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonials-3-btn {
    margin-top: 5px;
  }
}

.testimonials-3-btn a {
  font-size: 16px;
  font-weight: 500;
  color: #222D39;
  display: inline-block;
}

.testimonials-3-btn a svg {
  margin-left: 7px;
  transition: all .3s ease 0s;
}

.testimonials-3-btn a:hover {
  color: #97D694;
}

.testimonials-3-btn a:hover svg path {
  fill: #97D694;
}

.testimonial-img-slider-active-2 {
  margin-top: 110px;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-img-slider-active-2 {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-img-slider-active-2 {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-img-slider-active-2 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-img-slider-active-2 {
    margin-top: 30px;
  }
}

.testimonial-img-slider-active-2 .slick-list {
  margin-left: -17.5px;
  margin-right: -17.5px;
}

.testimonial-img-slider-active-2 .slick-list .testimonial-img-slider-2 {
  cursor: pointer;
  padding-left: 17.5px;
  padding-right: 17.5px;
}

.testimonial-img-slider-active-2 .slick-list .testimonial-img-slider-2 img {
  width: 100%;
  opacity: .5;
}

.testimonial-img-slider-active-2 .slick-list .testimonial-img-slider-2.slick-current img {
  opacity: 1;
}

.testimonial-slider-site {
  margin: 0px 30px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-slider-site {
    margin: 50px 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-slider-site {
    margin: 30px 0px 0 0;
  }
}

.testimonial-image-site img {
  width: 100%;
}

.single-testimonial-3 h4 {
  color: #222D39;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.single-testimonial-3 p {
  line-height: 28px;
  font-size: 16px;
  margin: 16px 0 49px;
  width: 55%;
  font-weight: 300;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-testimonial-3 p {
    width: 93%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-testimonial-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-3 p {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-3 p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-3 p {
    width: 100%;
    margin: 16px 0 10px;
    line-height: 26px;
    font-size: 14px;
  }
}

.single-testimonial-3 .client-info-3 .testi-rating-3 {
  display: flex;
}

.single-testimonial-3 .client-info-3 .testi-rating-3 i {
  color: #DF9A24;
  font-size: 12px;
}

.single-testimonial-3 .client-info-3 h3 {
  font-size: 12px;
  font-weight: 600;
  color: #0A1039;
  margin: 5px 0 0;
}

.testimonial-active-5 .slick-list {
  padding: 0 570px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .testimonial-active-5 .slick-list {
    padding: 0 370px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-active-5 .slick-list {
    padding: 0 330px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-active-5 .slick-list {
    padding: 0 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active-5 .slick-list {
    padding: 0 150px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active-5 .slick-list {
    padding: 0 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-active-5 .slick-list {
    padding: 0 60px;
  }
}

.testimonial-active-5 .slick-list .single-testimonial {
  margin: 0 30px;
  opacity: .6;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-active-5 .slick-list .single-testimonial {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-active-5 .slick-list .single-testimonial {
    margin: 0 15px;
  }
}

.testimonial-active-5 .slick-list .single-testimonial.slick-current {
  opacity: 1;
}

.testimonial-bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

.testimonial-bg-shape img {
  width: 100%;
}

.testimonial-img-tab {
  margin-right: -10px;
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
}

.testimonial-img-tab a {
  flex: 0 0 50%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 17px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .testimonial-img-tab a {
    flex: 0 0 33.333%;
  }
}

.testimonial-img-tab a img {
  opacity: .28;
  width: 100%;
  filter: grayscale(100%);
}

.testimonial-img-tab a.active img {
  opacity: 1;
  filter: grayscale(0%);
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 0 24px 0.7px rgba(0, 0, 0, 0.16);
}

.testimonial-area {
  position: relative;
}

.testimonial-area .organic-position-8 {
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.5s;
  position: absolute;
  bottom: 26%;
  right: 19%;
  z-index: 9;
}

.testimonial-area .organic-position-8 img {
  width: 107px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .testimonial-area .organic-position-8 {
    right: 8%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .testimonial-area .organic-position-8 {
    right: 3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-area .organic-position-8 {
    bottom: 13%;
    right: 6%;
  }
  .testimonial-area .organic-position-8 img {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-area .organic-position-8 {
    bottom: 8%;
    right: 10%;
  }
  .testimonial-area .organic-position-8 img {
    width: 70px;
  }
}

.testimonial-active-6 .slick-list {
  margin-right: -15px;
  margin-left: -15px;
}

.testimonial-active-6 .slick-list .testimonial-wrap-plr {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 45px;
}

.testimonial-active-6 .single-testimonial {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
}

.pagination-style-1 ul {
  display: flex;
  justify-content: center;
}

.pagination-style-1 ul li {
  margin: 0 4px;
}

.pagination-style-1 ul li button {
  font-size: 0;
  border: none;
  width: 13px;
  height: 13px;
  background-color: #E2E2E2;
  border-radius: 100%;
  padding: 0;
  transition: all .3s ease 0s;
}

.pagination-style-1 ul li.slick-active button {
  background-color: #FF437D;
}

.kids-2-testimonial {
  background-color: #6459FF;
}

.kids-2-testimonial .testimonial-active-5 .slick-list {
  padding: 18px 570px 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .kids-2-testimonial .testimonial-active-5 .slick-list {
    padding: 18px 370px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .kids-2-testimonial .testimonial-active-5 .slick-list {
    padding: 18px 300px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .kids-2-testimonial .testimonial-active-5 .slick-list {
    padding: 18px 200px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .kids-2-testimonial .testimonial-active-5 .slick-list {
    padding: 18px 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .kids-2-testimonial .testimonial-active-5 .slick-list {
    padding: 18px 15px 0;
  }
}

.kids-2-testimonial .single-testimonial {
  padding: 30px 30px 29px 70px;
}

@media only screen and (max-width: 767px) {
  .kids-2-testimonial .single-testimonial {
    padding: 30px 30px 29px 30px;
  }
}

.kids-2-testimonial .single-testimonial.slick-current.slick-active {
  margin-top: -18px !important;
  padding: 47px 30px 46px 70px;
}

@media only screen and (max-width: 767px) {
  .kids-2-testimonial .single-testimonial.slick-current.slick-active {
    padding: 47px 30px 46px 30px;
  }
}

.testimonial-next-img {
  position: absolute;
  right: 15px;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .testimonial-next-img {
    position: static;
    margin-top: 30px;
  }
}

.testimonial-next-img img {
  width: 205px;
  opacity: .2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-next-img img {
    width: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-next-img img {
    width: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-img-slider-active {
    margin-top: 30px;
  }
  .testimonial-area.pt-110 {
    padding-top: 50px;
  }
  .testimonial-area.pt-150 {
    padding-top: 80px;
  }
  .testimonial-area.pt-90 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-img-slider-active {
    margin-top: 30px;
  }
  .testimonial-area.pt-110 {
    padding-top: 20px;
  }
  .testimonial-area.pt-90 {
    padding-top: 0px;
  }
  .testimonial-area.pb-145 {
    padding-bottom: 60px;
  }
}

/*---------- 19. About style ------------*/
.about-area {
  position: relative;
}

.about-area .product-bg-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .about-area .product-bg-shape {
    top: -7px;
  }
}

.about-area .product-bg-shape img {
  width: 100%;
}

.about-img {
  overflow: hidden;
}

.about-img img {
  max-width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.about-img:hover img {
  transform: scale(1.05);
}

@media only screen and (max-width: 767px) {
  .about-content {
    margin-top: 20px;
  }
}

.about-content span {
  font-size: 22px;
  font-family: "Pacifico";
  color: #885643;
}

.about-content h2 {
  font-size: 29px;
  font-weight: 500;
  line-height: 43px;
  margin: 18px 0 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content h2 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content h2 {
    font-size: 22px;
    line-height: 35px;
    margin: 18px 0 14px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content h2 {
    font-size: 20px;
    line-height: 33px;
    margin: 10px 0 10px;
  }
}

.about-content p {
  font-size: 15px;
  line-height: 30px;
  color: #777777;
  margin: 0px 0 54px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content p {
    margin: 0px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content p {
    margin: 0px 0 20px;
  }
}

.furniture-about-content h3 {
  font-size: 29px;
  color: #262836;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .furniture-about-content h3 {
    font-size: 22px;
  }
}

.furniture-about-content p {
  font-size: 15px;
  line-height: 30px;
  width: 52%;
  margin: 16px 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .furniture-about-content p {
    width: 60%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .furniture-about-content p {
    width: 73%;
  }
}

@media only screen and (max-width: 767px) {
  .furniture-about-content p {
    width: 100%;
    margin: 16px 0 20px;
  }
}

.growing-story-area {
  background: linear-gradient(to left, #FAFAFA, #FAFAFA 75%, #fff 0%);
}

@media only screen and (max-width: 767px) {
  .growing-story-area {
    background: linear-gradient(to left, #FAFAFA, #FAFAFA 100%, #fff 0%);
  }
}

.growing-story-content {
  margin: 0 0 0 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .growing-story-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .growing-story-content {
    margin: 0 0 30px 0px;
    padding-top: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .growing-story-content {
    margin: 0 0 0px 0px;
    padding-top: 0px;
  }
}

.growing-story-content h3 {
  font-size: 29px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .growing-story-content h3 {
    font-size: 20px;
  }
}

.growing-story-content p {
  font-size: 16px;
  line-height: 30px;
  width: 63%;
  margin: 48px 0 55px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .growing-story-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .growing-story-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .growing-story-content p {
    width: 100%;
    margin: 28px 0 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .growing-story-content p {
    width: 100%;
    margin: 20px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .growing-story-content p {
    width: 100%;
    font-size: 14px;
    margin: 12px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .growing-story-content p {
    width: 100%;
    font-size: 14px;
    margin: 7px 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-mega-fashion {
    padding-bottom: 60px;
  }
}

.about-content-mega-fashion h2 {
  font-size: 29px;
  line-height: 50px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-mega-fashion h2 {
    font-size: 26px;
    line-height: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-mega-fashion h2 {
    font-size: 21px;
    line-height: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-mega-fashion h2 {
    font-size: 24px;
    line-height: 40px;
  }
}

.about-content-mega-fashion p {
  font-size: 15px;
  line-height: 30px;
  margin: 34px 0 51px;
  width: 48%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-mega-fashion p {
    margin: 20px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-mega-fashion p {
    margin: 20px 0 30px;
    width: 78%;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-mega-fashion p {
    margin: 10px 0 20px;
    width: 100%;
  }
}

.mega-fashion-bg-img {
  margin: 0 30px;
}

@media only screen and (max-width: 767px) {
  .mega-fashion-bg-img {
    margin: 0 0px;
  }
}

.shape-1 {
  position: absolute;
  content: "";
  left: 24px;
  top: 50px;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  z-index: 9;
  background: linear-gradient(270deg, white 0%, #8ad9e6 100%);
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

.fashion1-polygon {
  position: absolute;
  bottom: 20%;
  left: 38.5%;
}

.fashion1-polygon img {
  width: 31px;
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

.shape-3 {
  position: absolute;
  content: "";
  left: 56%;
  top: 23%;
  width: 15px;
  height: 15px;
  z-index: 9;
  background-color: #FFE600;
  transform: rotate(-45deg);
  animation: playbutton-effect 4s linear infinite;
  animation-direction: normal;
  animation-delay: 2s;
  animation-direction: alternate;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shape-3 {
    top: 17%;
  }
}

@media only screen and (max-width: 767px) {
  .shape-3 {
    top: 9%;
  }
}

.about-story-img {
  position: relative;
  overflow: hidden;
}

.about-story-img img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.about-story-img .about-story-img-content {
  position: absolute;
  top: 14%;
  right: 2.5%;
  text-align: center;
  width: 159px;
  height: 159px;
  border-radius: 100%;
  background-color: #333333;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-story-img .about-story-img-content {
    width: 120px;
    height: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .about-story-img .about-story-img-content {
    width: 120px;
    height: 120px;
  }
}

.about-story-img .about-story-img-content h3 {
  margin: 50px 0 9px;
  font-size: 29px;
  line-height: 23px;
  font-weight: bold;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-story-img .about-story-img-content h3 {
    margin: 30px 0 9px;
  }
}

@media only screen and (max-width: 767px) {
  .about-story-img .about-story-img-content h3 {
    margin: 30px 0 9px;
    font-size: 26px;
  }
}

.about-story-img .about-story-img-content span {
  font-size: 29px;
  font-weight: 300;
  color: #ffffff;
  display: block;
  line-height: 1;
}

.about-story-img:hover img {
  transform: scale(1.03);
}

.about-content-2 {
  margin: 100px 0 0 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-2 {
    margin: 100px 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-2 {
    margin: 0px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-2 {
    margin: 30px 0 0 0px;
  }
}

.about-content-2 h3 {
  font-size: 29px;
  line-height: 46px;
  font-weight: bold;
  margin: 0 0 22px;
}

@media only screen and (max-width: 767px) {
  .about-content-2 h3 {
    font-size: 23px;
    line-height: 40px;
    margin: 0 0 15px;
  }
}

.about-content-2 p {
  font-size: 15px;
  line-height: 30px;
}

.mission-content p {
  font-size: 15px;
  line-height: 30px;
  margin: 0 0 20px;
  width: 88%;
}

@media only screen and (max-width: 767px) {
  .mission-content p {
    width: 100%;
  }
}

.mission-img {
  overflow: hidden;
}

.mission-img img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.mission-img:hover img {
  transform: scale(1.03);
}

.consumer-video-wrap {
  position: relative;
  overflow: hidden;
}

.consumer-video-wrap img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.consumer-video-wrap .consumer-video-content {
  position: absolute;
  top: 10%;
  left: 0%;
  min-width: 580px;
}

@media only screen and (max-width: 767px) {
  .consumer-video-wrap .consumer-video-content {
    min-width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0%;
    right: 0%;
  }
}

.consumer-video-wrap .consumer-video-content h3 {
  font-size: 29px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .consumer-video-wrap .consumer-video-content h3 {
    font-size: 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .consumer-video-wrap .consumer-video-content h3 {
    font-size: 22px;
  }
}

.consumer-video-wrap .consumer-video-content .video-icon {
  margin-top: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .consumer-video-wrap .consumer-video-content .video-icon {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .consumer-video-wrap .consumer-video-content .video-icon {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .consumer-video-wrap .consumer-video-content .video-icon {
    margin-top: 25px;
  }
}

.consumer-video-wrap .consumer-video-content .video-icon a {
  display: inline-block;
  width: 117px;
  height: 117px;
  background-color: #C3C3C3;
  border-radius: 100%;
  line-height: 117px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .consumer-video-wrap .consumer-video-content .video-icon a {
    width: 95px;
    height: 95px;
    line-height: 95px;
  }
}

@media only screen and (max-width: 767px) {
  .consumer-video-wrap .consumer-video-content .video-icon a {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .consumer-video-wrap .consumer-video-content .video-icon a svg {
    width: 20px;
  }
}

.consumer-video-wrap .consumer-video-content .video-icon:hover a {
  background-color: #333;
}

.consumer-video-wrap:hover img {
  transform: scale(1.03);
}

.growing-story-img img {
  max-width: 100%;
}

/*---------- 20. Sidebar style ------------*/
.shop-sidebar-style {
  margin-right: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-sidebar-style {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-sidebar-style {
    margin-top: 50px;
  }
}

.shop-sidebar-style.shop-sidebar-style-mrg2 {
  margin-right: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .shop-sidebar-style.shop-sidebar-style-mrg2 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shop-sidebar-style.shop-sidebar-style-mrg2 {
    margin-right: 0px;
  }
}

.sidebar-widget h4.pro-sidebar-title, .sidebar-widget h4.pro-sidebar-title-2, .sidebar-widget h4.pro-sidebar-title-3,
.sidebar-widget-2 h4.pro-sidebar-title,
.sidebar-widget-2 h4.pro-sidebar-title-2,
.sidebar-widget-2 h4.pro-sidebar-title-3,
.sidebar-widget-3 h4.pro-sidebar-title,
.sidebar-widget-3 h4.pro-sidebar-title-2,
.sidebar-widget-3 h4.pro-sidebar-title-3 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin: 0 0 0;
}

.sidebar-widget-categori ul li {
  margin-bottom: 37px;
}

.sidebar-widget-categori ul li:last-child {
  margin-bottom: 0px;
}

.sidebar-widget-categori ul li a {
  color: #777777;
  font-size: 16px;
  display: block;
  padding-left: 36px;
  position: relative;
}

.sidebar-widget-categori ul li a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 0px;
  z-index: 9;
  background-color: #333333;
  transition: all .3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-widget-categori ul li a.active {
  font-weight: bold;
  color: #333333;
}

.sidebar-widget-categori ul li a.active:before {
  height: 45px;
  opacity: 1;
  visibility: visible;
}

.sidebar-widget-categori ul li a:hover {
  font-weight: bold;
  color: #333333;
}

.sidebar-widget-categori ul li a:hover:before {
  height: 45px;
  opacity: 1;
  visibility: visible;
}

.sidebar-widget-size {
  margin-left: 18px;
}

.sidebar-widget-size.size-mrg-none {
  margin-left: 0;
}

.sidebar-widget-size ul {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-widget-size ul li {
  margin-right: 5px;
  margin-bottom: 5px;
}

.sidebar-widget-size ul li a {
  font-size: 13px;
  color: #828495;
  display: inline-block;
  border: 1px solid #E3E4E9;
  width: 31px;
  height: 31px;
  line-height: 31px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget-size ul li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.sidebar-widget-size ul li a:hover {
  border: 1px solid #333333;
  background-color: #333333;
  color: #fff;
}

.sidebar-widget-size.widget-filter-size ul li a {
  width: 31px;
  height: 31px;
  line-height: 31px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .sidebar-widget-size.widget-filter-size ul li a {
    width: 29px;
    height: 29px;
    line-height: 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget-size.widget-filter-size ul li a {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget-size.mt-45 {
    margin-top: 25px;
  }
  .sidebar-widget-size.mt-50 {
    margin-top: 30px;
  }
}

.price-filter {
  margin-left: 18px;
}

.price-filter.price-mrg-none {
  margin-left: 0;
}

.price-filter #slider-range {
  background: #E3E4E9 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50px;
  height: 3px;
  margin-bottom: 12px;
  margin-left: auto;
}

.price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #333 none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.price-filter #slider-range .ui-slider-handle {
  background: #333 none repeat scroll 0 0;
  border: medium none;
  height: 16px;
  margin-left: 0;
  width: 4px;
  top: -7px;
  cursor: ew-resize;
  border-radius: 0;
}

.price-filter .price-slider-amount {
  margin: 22px 0 0;
}

.price-filter .price-slider-amount .label-input {
  margin: 25px 0 0;
  display: flex;
  align-items: center;
}

.price-filter .price-slider-amount .label-input span {
  color: #828495 !important;
  margin-right: 5px;
}

.price-filter .price-slider-amount .label-input input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  color: #828495 !important;
  width: 75%;
}

@media only screen and (max-width: 767px) {
  .price-filter.mt-45 {
    margin-top: 25px;
  }
  .price-filter.mt-50 {
    margin-top: 30px;
  }
}

.sidebar-widget-brand-logo {
  margin-left: 18px;
}

.sidebar-widget-brand-logo.brand-logo-mrg-none {
  margin-left: 0;
}

.sidebar-widget-brand-logo ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget-brand-logo ul {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget-brand-logo ul {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget-brand-logo ul {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.sidebar-widget-brand-logo ul li {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget-brand-logo ul li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget-brand-logo ul li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget-brand-logo ul li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.sidebar-widget-brand-logo ul li a {
  display: block;
  overflow: hidden;
}

.sidebar-widget-brand-logo ul li a img {
  width: 100%;
  transform: scale(1);
  transition: all .3s ease 0s;
}

.sidebar-widget-brand-logo ul li:hover a img {
  transform: scale(1.03);
}

@media only screen and (max-width: 767px) {
  .sidebar-widget-brand-logo.mt-45 {
    margin-top: 25px;
  }
  .sidebar-widget-brand-logo.mt-50 {
    margin-top: 30px;
  }
}

.sidebar-widget-categori-2 ul {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-widget-categori-2 ul li a {
  margin: 0 10px;
  padding: 0 30px 17px;
  display: inline-block;
  font-size: 18px;
  color: #777777;
  line-height: 1;
  border-bottom: 2px solid transparent;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget-categori-2 ul li a {
    padding: 0 15px 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-widget-categori-2 ul li a {
    padding: 0 8px 17px;
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget-categori-2 ul li a {
    padding: 0 10px 10px;
    margin: 0 0px 10px;
    font-size: 16px;
  }
}

.sidebar-widget-categori-2 ul li a.active {
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #333;
}

.sidebar-widget-categori-2 ul li a:hover {
  color: #333;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget-categori-2.mb-60 {
    margin-bottom: 30px;
  }
  .sidebar-widget-categori-2.mt-45 {
    margin-top: 30px;
  }
}

.widget-style-top {
  margin-bottom: 65px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F2F2F2;
}

@media only screen and (max-width: 767px) {
  .widget-style-top {
    margin-bottom: 45px;
    padding-bottom: 0px;
  }
}

.shop-filter {
  line-height: 1;
  position: relative;
}

.shop-filter > a {
  color: #777777;
  text-transform: uppercase;
}

.shop-filter > a::before {
  position: absolute;
  content: "";
  right: -30px;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #848484;
  z-index: 2;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .shop-filter > a::before {
    right: -14px;
  }
}

.shop-filter > a.active::before {
  border-bottom: 6px solid #848484;
  border-top: none;
}

.shop-filter > a:hover {
  color: #333;
}

.product-filter-wrapper {
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  font-size: 12px;
  padding: 48px 40px 0px;
  display: none;
  width: 100%;
  margin-top: 20px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .product-filter-wrapper {
    padding: 48px 20px 18px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-filter-wrapper {
    padding: 48px 20px 18px;
  }
}

@media only screen and (max-width: 767px) {
  .product-filter-wrapper {
    padding: 48px 20px 18px;
  }
}

.sidebar-widget-categori-3 ul li {
  display: inline-block;
  margin: 0 5px 5px;
}

.sidebar-widget-categori-3 ul li a {
  font-size: 15px;
  color: #333;
}

.sidebar-widget-categori-3 ul li a:hover {
  color: #0A1039;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget-categori-3.mt-45 {
    margin-top: 25px;
  }
  .sidebar-widget-categori-3.mt-50 {
    margin-top: 30px;
  }
}

.sidebar-search form {
  position: relative;
}

.sidebar-search form input {
  background-color: transparent;
  color: #262626;
  height: 41px;
  border: 1px solid #cdcdcd;
  padding: 2px 40px 2px 10px;
  font-size: 13px;
}

.sidebar-search form input::-moz-input-placeholder {
  color: #262626;
  opacity: 1;
}

.sidebar-search form input::-webkit-input-placeholder {
  color: #262626;
  opacity: 1;
}

.sidebar-search form .button-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #2f2f2f;
  background-color: transparent;
  border: none;
  padding: 5px 20px;
  transition: all .3s ease 0s;
}

.sidebar-search form .button-search:hover {
  color: #0A1039;
}

@media only screen and (max-width: 767px) {
  .sidebar-search.mb-70 {
    margin-bottom: 50px;
  }
}

.sidebar-post-wrap .single-sidebar-post {
  display: flex;
  border-bottom: 1px solid #DCDCDC;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.sidebar-post-wrap .single-sidebar-post:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
  flex: 0 0 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-post-wrap .single-sidebar-post .sidebar-post-img {
    flex: 0 0 60px;
  }
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-img img {
  width: 100%;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content {
  margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-post-wrap .single-sidebar-post .sidebar-post-content {
    margin-left: 10px;
  }
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
  font-size: 16px;
  color: #333;
  margin: 0 0 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 {
    font-size: 14px;
  }
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 a {
  color: #333;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content h4 a:hover {
  color: #0A1039;
}

.sidebar-post-wrap .single-sidebar-post .sidebar-post-content span {
  color: #2f2f2f;
  font-size: 12px;
}

.sidebar-widget-tag ul {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-widget-tag ul li {
  margin: 0 5px 5px 0;
}

.sidebar-widget-tag ul li a {
  color: #828495;
  line-height: 1;
  padding: 10px 15px 11px;
  display: inline-block;
  border: 1px solid #E3E4E9;
}

.sidebar-widget-tag ul li a:hover {
  color: #fff;
  background-color: #333;
  border: 1px solid #333;
}

/*---------- 21. Shop style ------------*/
.shop-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.shop-top-bar .shop-top-bar-left .shop-tab a {
  margin-right: 20px;
}

.shop-top-bar .shop-top-bar-left .shop-tab a:last-child {
  margin-right: 0;
}

.shop-top-bar .shop-top-bar-left .shop-tab a svg path {
  fill: #ACACAC;
}

.shop-top-bar .shop-top-bar-left .shop-tab a.active svg path {
  fill: #000;
}

.shop-page-list {
  line-height: 1;
}

.shop-page-list ul li {
  display: inline-block;
  color: #777777;
  margin-right: 17px;
  font-weight: 500;
  text-transform: uppercase;
}

.shop-page-list ul li:last-child {
  margin-right: 0px;
}

.shop-page-list ul li.active a {
  color: #333333;
  font-weight: bold;
}

.shop-page-list ul li a {
  color: #777777;
}

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 1px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li {
    margin: 0 4px;
  }
}

.pro-pagination-style ul li a {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #535353;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.pro-pagination-style ul li a.active, .pro-pagination-style ul li a:hover {
  background-color: #262626;
  color: #fff;
  border: 1px solid #262626;
}

.pro-pagination-style ul li a i {
  line-height: 40px;
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style ul li a i {
    line-height: 40px;
  }
}

.pro-pagination-style ul li:last-child {
  position: relative;
  top: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-pagination-style.mt-70 {
    margin-top: 0px;
  }
  .pro-pagination-style.mt-50 {
    margin-top: 0px;
  }
  .pro-pagination-style.mt-20 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-70 {
    margin-top: 0px;
  }
  .pro-pagination-style.mt-50 {
    margin-top: 0px;
  }
  .pro-pagination-style.mt-20 {
    margin-top: 0px;
  }
}

.shop-categorie-tab {
  display: flex;
  justify-content: center;
}

.shop-categorie-tab a {
  font-size: 16px;
  color: #777777;
  display: inline-block;
  line-height: 1;
  padding: 9px 40px 9px;
  border-bottom: 1px solid #F6F6F6;
}

@media only screen and (max-width: 767px) {
  .shop-categorie-tab a {
    padding: 9px 20px 9px;
  }
}

.shop-categorie-tab a.active {
  color: #333;
  font-weight: 500;
  background-color: #F6F6F6;
}

@media only screen and (max-width: 767px) {
  .shop-categorie-tab.mb-75 {
    margin-bottom: 30px;
  }
}

/*---------- 22. Breadcrumb style ------------*/
.breadcrumb-ptb-1 {
  padding: 123px 0 138px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-ptb-1 {
    padding: 103px 0 118px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-1 {
    padding: 60px 0 60px;
  }
}

.breadcrumb-ptb-2 {
  padding: 113px 0 110px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-ptb-2 {
    padding: 60px 0 60px;
  }
}

.breadcrumb-area {
  position: relative;
}

.breadcrumb-area .breadcrumb-img-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.breadcrumb-area .breadcrumb-img-1 img {
  width: 333px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    width: 50px;
  }
}

.breadcrumb-area .breadcrumb-img-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.breadcrumb-area .breadcrumb-img-2 img {
  width: 323px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    width: 50px;
  }
}

.breadcrumb-content {
  position: relative;
  z-index: 9;
}

.breadcrumb-content h2 {
  font-size: 29px;
  font-weight: bold;
  margin: 0 0 16px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content h2 {
    font-size: 25px;
    margin: 0 0 10px;
  }
}

.breadcrumb-content p {
  font-size: 15px;
  line-height: 30px;
  width: 38%;
  margin: 0 auto;
}

.breadcrumb-content p.left {
  margin: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content p {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content p {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content p {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-content p {
    width: 85%;
  }
}

.breadcrumb-content ul {
  margin: 25px 0 0;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content ul {
    margin: 15px 0 0;
  }
}

.breadcrumb-content ul li {
  display: inline-block;
  margin: 0 1px;
  text-transform: capitalize;
  color: #777777;
  font-size: 18px;
}

.breadcrumb-content ul li.active {
  color: #333333;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content ul li {
    font-size: 16px;
  }
}

.breadcrumb-content ul li span {
  margin-right: 4px;
  display: inline-block;
  margin: 6px 4px 0 0;
  line-height: 1;
  position: relative;
  top: 1px;
}

.breadcrumb-content ul li a {
  text-transform: capitalize;
  color: #777777;
}

.breadcrumb-content ul li a:hover {
  color: #0A1039;
}

/*---------- 23. Contact style ------------*/
.contact-form-padding {
  padding: 80px 100px 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-padding {
    padding: 80px 60px 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-padding {
    padding: 60px 30px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-padding {
    padding: 50px 20px 35px;
  }
}

.contact-form-area h2 {
  font-size: 27px;
  font-weight: 600;
  color: #222222;
  margin: 0 0 38px;
}

@media only screen and (max-width: 767px) {
  .contact-form-area h2 {
    margin: 0 0 20px;
  }
}

.contact-form-area .single-contact-form {
  margin-bottom: 30px;
}

.contact-form-area .single-contact-form input, .contact-form-area .single-contact-form textarea {
  border: 1px solid #EEEEEE;
  background-color: #ffffff;
  height: 62px;
  font-size: 13px;
  font-weight: 500;
  padding: 2px 25px;
  color: #999999;
}

.contact-form-area .single-contact-form input::-moz-input-placeholder, .contact-form-area .single-contact-form textarea::-moz-input-placeholder {
  color: #999999;
  opacity: 1;
}

.contact-form-area .single-contact-form input::-webkit-input-placeholder, .contact-form-area .single-contact-form textarea::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

.contact-form-area .single-contact-form textarea {
  height: 152px;
  padding: 27px 25px;
}

.contact-form-area .single-contact-form p {
  font-size: 12px;
  color: #999999;
  font-style: italic;
  margin: 29px 0 39px;
}

@media only screen and (max-width: 767px) {
  .contact-form-area .single-contact-form p {
    margin: 29px 0 29px;
  }
}

.contact-form-area .single-contact-form button.submit {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  line-height: 1;
  padding: 20px 40px 20px;
  background-color: #333333;
}

.contact-form-area .single-contact-form button.submit:hover {
  background-color: #0A1039;
}

.contact-info-area {
  margin: 0 0 0 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info-area {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-area {
    margin: 0 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-area {
    margin: 30px 0 0 0px;
  }
}

.single-contact-info {
  margin: 0 0 52px;
}

@media only screen and (max-width: 767px) {
  .single-contact-info {
    margin: 0 0 32px;
  }
}

.single-contact-info:last-child {
  margin: 0 0 0px;
}

.single-contact-info .contact-info-title {
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .single-contact-info .contact-info-title {
    margin: 0 0 15px;
  }
}

.single-contact-info .contact-info-title h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  line-height: 1;
  color: #222222;
}

.single-contact-info p {
  line-height: 31px;
  color: #999999;
  margin: 0 0 1px;
}

.single-contact-info ul {
  display: flex;
  flex-wrap: wrap;
}

.single-contact-info ul li {
  margin-right: 23px;
}

.single-contact-info ul li:last-child {
  margin-right: 0;
}

.single-contact-info ul li a {
  font-size: 16px;
  color: #CFCFCF;
}

.single-contact-info ul li a:hover {
  color: #0A1039;
}

#contact-map {
  height: 600px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #contact-map {
    height: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #contact-map {
    height: 400px;
  }
}

@media only screen and (max-width: 767px) {
  #contact-map {
    height: 300px;
  }
}

/*---------- 24. Cart style ------------*/
.cart-check-order-link {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .cart-check-order-link {
    display: block;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-check-order-link {
    margin-bottom: 0px;
    display: flex;
  }
}

.cart-check-order-link a {
  display: inline-block;
  font-size: 16px;
  color: #777777;
  padding: 20px 70px;
  margin: 0 25px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-check-order-link a {
    padding: 20px 50px;
    margin: 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-check-order-link a {
    padding: 20px 50px;
    margin: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-check-order-link a {
    padding: 20px 20px;
    margin: 0 0px;
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-check-order-link a {
    display: inline-block;
    font-size: 14px;
  }
}

.cart-check-order-link a.active {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  background-color: #FAFAFA;
  border-radius: 15px 15px 0 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-check-order-link a.active {
    font-size: 16px;
  }
}

.cart-check-order-link a:hover {
  color: #333333;
  font-weight: bold;
}

.cart-table-content {
  padding: 47px 92px 95px 40px;
  background-color: #fff;
  margin-bottom: 70px;
}

.cart-table-content.wishlist-wrap {
  padding: 45px 40px 50px 40px;
  margin-bottom: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content.wishlist-wrap {
    padding: 45px 15px 50px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content.wishlist-wrap {
    padding: 45px 15px 50px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content {
    padding: 47px 40px 95px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content {
    padding: 47px 15px 95px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content {
    padding: 40px 15px 50px 15px;
    margin-bottom: 40px;
  }
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 16px;
  text-transform: capitalize;
  color: #333333;
  font-weight: 500;
  padding: 0 0 55px 0;
}

.cart-table-content table thead > tr th.th-text-center {
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table thead > tr th {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 50px 30px 50px;
    text-align: center;
  }
}

.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 0px 0px 20px;
}

.cart-table-content table tbody > tr td.cart-product {
  width: 430px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.cart-product {
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.cart-product {
    text-align: center;
  }
}

.cart-table-content table tbody > tr td.cart-product .product-img-info-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.cart-product .product-img-info-wrap {
    display: block;
  }
}

.cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-img {
  flex: 0 0 98px;
  margin: 0 35px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-img {
    margin: 0 15px 0 0;
    flex: 0 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-img {
    margin: 0 0px 15px 0;
  }
}

.cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-img a img {
  width: 98px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-img a img {
    width: 60px;
  }
}

.cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-info h4 {
  font-size: 16px;
  line-height: 1;
  margin: 0 0 13px;
}

.cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-info h4 a {
  color: #333333;
}

.cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-info h4 a:hover {
  color: #0A1039;
}

.cart-table-content table tbody > tr td.cart-product .product-img-info-wrap .product-info span {
  display: block;
  color: #999999;
  margin: 0 0 8px;
  line-height: 1;
}

.cart-table-content table tbody > tr td.product-price {
  width: 130px;
  text-align: center;
}

.cart-table-content table tbody > tr td.product-price span.amount {
  color: #777777;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.cart-quality {
  width: 170px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.cart-quality {
    width: 130px;
  }
}

.cart-table-content table tbody > tr td.product-total {
  width: 170px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-total {
    width: 130px;
  }
}

.cart-table-content table tbody > tr td.product-total span.amount {
  color: #777777;
  font-size: 16px;
}

.cart-table-content table tbody > tr td.product-remove {
  width: 130px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-remove {
    width: 100px;
  }
}

.cart-table-content table tbody > tr td.product-remove a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  background-color: #F6F6F6;
}

.cart-table-content table tbody > tr td.product-remove a svg path {
  fill: #CBCBCB;
  transition: all .3s ease 0s;
}

.cart-table-content table tbody > tr td.product-remove a:hover {
  background-color: #0A1039;
}

.cart-table-content table tbody > tr td.product-remove a:hover svg path {
  fill: #0A1039;
}

.cart-table-content table tbody > tr td.product-wishlist-cart {
  width: 130px;
  text-align: center;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #333333;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 7px 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-wishlist-cart > a {
    padding: 4px 5px;
  }
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #0A1039;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    justify-content: center;
  }
}

.cart-shiping-update-wrapper a {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #777777;
  border: 1px solid #EFEFEF;
  margin-left: 14px;
  padding: 18px 30px 20px;
}

.cart-shiping-update-wrapper a:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper a {
    margin-bottom: 14px;
    padding: 14px 20px 16px;
  }
}

.cart-shiping-update-wrapper a:hover {
  border: 1px solid #464646;
  color: #333333;
}

.proceed-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 47px;
}

@media only screen and (max-width: 767px) {
  .proceed-btn {
    margin-bottom: 20px;
    justify-content: center;
  }
}

.proceed-btn a {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  padding: 17px 30px 19px;
  color: #ffffff;
  background-color: #333333;
}

.proceed-btn a:hover {
  background-color: #0A1039;
}

.discount-tax-wrap {
  background-color: #ffffff;
  padding: 50px 55px 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .discount-tax-wrap {
    padding: 50px 25px 55px;
  }
}

@media only screen and (max-width: 767px) {
  .discount-tax-wrap {
    padding: 50px 25px 55px;
    margin-top: 30px;
  }
}

.discount-tax-wrap h4 {
  font-size: 16px;
  font-weight: bold;
  color: #434343;
  position: relative;
  margin: 0 0 20px;
  padding: 0 0 10px;
}

.discount-tax-wrap h4:before {
  position: absolute;
  content: "";
  height: 3px;
  width: 50px;
  background-color: #EEEEEE;
  left: 0;
  bottom: 0;
}

.discount-tax-wrap .discount-code p {
  font-size: 13px;
  color: #999999;
  margin: 0 0 20px;
}

.discount-tax-wrap .discount-code input {
  height: 45px;
  font-size: 13px;
  color: #999999;
  padding: 2px 20px;
  border: none;
  background-color: #F6F6F6;
}

.discount-tax-wrap .discount-code input::-moz-input-placeholder {
  color: #999999;
  opacity: 1;
}

.discount-tax-wrap .discount-code input::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

.discount-tax-wrap .discount-code button {
  background-color: transparent;
  font-size: 12px;
  font-weight: bold;
  color: #333333;
  line-height: 1;
  padding: 18px 30px 20px;
  border: 1px solid #333333;
  margin: 35px 0 0;
  transition: all .3s ease 0s;
}

.discount-tax-wrap .discount-code button:hover {
  background-color: #333;
  color: #fff;
}

/*-------- 25. Login register style ---------*/
.login-register-wrapper .login-register-tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-register-tab-list {
    margin-bottom: 20px;
  }
}

.login-register-wrapper .login-register-tab-list a {
  position: relative;
}

.login-register-wrapper .login-register-tab-list a::before {
  background-color: #454545;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-wrapper .login-register-tab-list a h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 20px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.login-register-wrapper .login-register-tab-list a:last-child::before {
  display: none;
}

.login-register-wrapper .login-register-tab-list a.active h4 {
  color: #0A1039;
}

.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrapper .login-form-container {
    padding: 80px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container {
    padding: 40px 15px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  font-size: 14px;
  height: 50px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #010225;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 2px;
  width: 17px;
  color: #010225;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn {
  padding: 10px 0 19px;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
  float: right;
  font-size: 15px;
  color: #010225;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a:hover {
  color: #0A1039;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button {
  background-color: #0A1039;
  color: #fff;
  border: medium none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 30px 13px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button:hover {
  background-color: #010225;
}

/*-------- 26. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
  background-color: #fff;
}

.myaccount-tab-menu a {
  border: 1px solid #ccc;
  border-bottom: none;
  font-weight: 600;
  font-size: 13px;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ccc;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #0A1039;
  border-color: #0A1039;
  color: #ffffff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 600;
}

.myaccount-content p {
  line-height: 30px;
  font-size: 15px;
}

.myaccount-content .welcome a:hover {
  color: #0A1039;
}

.myaccount-content .welcome strong {
  font-weight: 600;
  color: #0A1039;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #0A1039;
  text-transform: uppercase;
  font-weight: 600;
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #1f2226;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #0A1039;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
/*---------- 27. Checkout style ------------*/
.checkout-left-wrap {
  background-color: #fff;
  padding: 45px 63px 77px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-left-wrap {
    padding: 45px 30px 77px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-left-wrap {
    padding: 45px 20px 47px;
  }
}

.checkout-left-wrap .login-guest-top .checkout-tab {
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .checkout-left-wrap .login-guest-top .checkout-tab {
    margin-bottom: 30px;
  }
}

.checkout-left-wrap .login-guest-top .checkout-tab a {
  font-size: 18px;
  color: #333333;
  display: inline-block;
  padding: 0 40px 22px;
  position: relative;
  border-bottom: 1px solid #333333;
}

@media only screen and (max-width: 767px) {
  .checkout-left-wrap .login-guest-top .checkout-tab a {
    padding: 0 15px 22px;
  }
}

.checkout-left-wrap .login-guest-top .checkout-tab a:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: #333;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
}

.checkout-left-wrap .login-guest-top .checkout-tab a.active {
  font-weight: bold;
}

.checkout-left-wrap .login-guest-top .checkout-tab a.active:before {
  opacity: 1;
  visibility: visible;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0;
  position: relative;
  line-height: 1;
  padding: 0 0 16px;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap h4::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 50px;
  background-color: #EEEEEE;
  left: 0;
  bottom: 0;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style {
  margin-top: 36px;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style input {
  height: 45px;
  border: none;
  background-color: #F8F8F8;
  font-size: 13px;
  color: #BCBCBC;
  margin-bottom: 10px;
  padding: 2px 20px;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box .checkout-login-toggle-btn {
  padding: 10px 0 19px;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box .checkout-login-toggle-btn input[type="checkbox"] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 2px;
  width: 17px;
  color: #010225;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box .checkout-login-toggle-btn label {
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box .checkout-login-toggle-btn label {
    font-size: 14px;
  }
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box .checkout-login-toggle-btn a {
  float: right;
  font-size: 15px;
  color: #333333;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box .checkout-login-toggle-btn a {
    font-size: 14px;
  }
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box .checkout-login-toggle-btn a:hover {
  color: #0A1039;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box button {
  background-color: #333333;
  color: #fff;
  border: medium none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 30px 13px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.checkout-left-wrap .login-guest-top .checkout-login-wrap .checkout-login-style .checkout-button-box button:hover {
  background-color: #0A1039;
}

.checkout-left-wrap .login-guest-top .checkout-guest-wrap h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 0;
  position: relative;
  line-height: 1;
  padding: 0 0 16px;
}

.checkout-left-wrap .login-guest-top .checkout-guest-wrap h4::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 50px;
  background-color: #EEEEEE;
  left: 0;
  bottom: 0;
}

.checkout-left-wrap .login-guest-top .checkout-guest-wrap .checkout-guest-style {
  margin-top: 36px;
}

.checkout-left-wrap .login-guest-top .checkout-guest-wrap .checkout-guest-style input {
  height: 45px;
  border: none;
  background-color: #F8F8F8;
  font-size: 13px;
  color: #BCBCBC;
  margin-bottom: 10px;
  padding: 2px 20px;
}

.checkout-left-wrap .login-guest-top .checkout-guest-wrap .checkout-guest-style .guest-login-toggle-btn {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
}

.checkout-left-wrap .login-guest-top .checkout-guest-wrap .checkout-guest-style .guest-login-toggle-btn input[type="checkbox"] {
  height: 15px;
  margin: 0;
  width: 17px;
  color: #010225;
}

.checkout-left-wrap .login-guest-top .checkout-guest-wrap .checkout-guest-style .guest-login-toggle-btn label {
  font-size: 13px;
  margin-left: 15px;
  margin-bottom: 0;
}

.checkout-left-wrap .shipping-address-wrap {
  margin-top: 70px;
}

@media only screen and (max-width: 767px) {
  .checkout-left-wrap .shipping-address-wrap {
    margin-top: 40px;
  }
}

.checkout-left-wrap .shipping-address-wrap .billing-info {
  margin-bottom: 25px;
}

.checkout-left-wrap .shipping-address-wrap .billing-info input {
  font-size: 13px;
  color: #828495;
  height: 45px;
  background-color: #F6F6F6;
  border: none;
  padding: 2px 10px 2px 20px;
}

.checkout-left-wrap .shipping-address-wrap .billing-info input::-moz-input-placeholder {
  color: #999999;
  opacity: 1;
}

.checkout-left-wrap .shipping-address-wrap .billing-info input::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

.checkout-left-wrap .shipping-address-wrap .checkout-save-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.checkout-left-wrap .shipping-address-wrap .checkout-save-info input {
  width: 14px;
  height: 14px;
  position: relative;
  top: 1px;
}

.checkout-left-wrap .shipping-address-wrap .checkout-save-info span {
  font-size: 13px;
  color: #777777;
  display: inline-block;
  margin-left: 10px;
}

h4.checkout-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 30px;
  position: relative;
  line-height: 1;
  padding: 0 0 16px;
}

h4.checkout-title::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 50px;
  background-color: #EEEEEE;
  left: 0;
  bottom: 0;
}

.payment-details {
  padding: 70px 70px 65px;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .payment-details {
    padding: 70px 30px 65px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-details {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .payment-details {
    margin-top: 40px;
    padding: 50px 20px 45px;
  }
}

.payment-details > ul {
  margin: 37px 0 0;
}

.payment-details > ul li {
  font-size: 16px;
  color: #333333;
  display: block;
  margin-bottom: 22px;
}

.payment-details > ul li:last-child {
  margin-bottom: 0;
}

.payment-details > ul li span {
  float: right;
}

.payment-details .total-order {
  margin: 58px 0 0;
}

.payment-details .total-order > ul li {
  font-size: 16px;
  color: #333333;
  display: block;
  font-weight: bold;
}

.payment-details .total-order > ul li span {
  float: right;
}

.payment-method {
  margin: 17px 0 0;
}

.payment-method .sin-payment {
  margin-bottom: 32px;
  display: block;
  overflow: hidden;
}

.payment-method .sin-payment:last-child {
  margin-bottom: 0;
}

.payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 7px;
}

.payment-method .sin-payment label {
  font-size: 16px;
  margin: 0px 0 0 28px;
  display: flex;
  align-items: center;
  color: #333333;
}

@media only screen and (max-width: 767px) {
  .payment-method .sin-payment label {
    display: block;
  }
}

.payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.payment-method .sin-payment label a {
  text-decoration: underline;
}

.payment-method .sin-payment .payment-box {
  margin: 16px 0 0;
  display: none;
}

.payment-method .sin-payment .payment-box form input {
  font-size: 13px;
  color: #828495;
  height: 45px;
  background-color: #F6F6F6;
  border: none;
  width: 100%;
  padding: 2px 20px;
}

.payment-method .sin-payment .payment-box form input::-moz-input-placeholder {
  color: #999999;
  opacity: 1;
}

.payment-method .sin-payment .payment-box form input::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

.payment-method .sin-payment .payment-box p {
  font-size: 14px;
  margin: 0;
}

.back-continue-wrap {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .back-continue-wrap {
    margin-top: 30px;
  }
}

.back-continue-wrap a {
  display: inline-block;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #333333;
  line-height: 1;
  padding: 18px 45px 19px;
}

@media only screen and (max-width: 767px) {
  .back-continue-wrap a {
    padding: 16px 25px 17px;
  }
}

.back-continue-wrap a:hover {
  background-color: #333;
  color: #fff;
}

/*---------- 28. Order complete style ------------*/
.order-complete-title {
  padding: 52px 85px 60px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-complete-title {
    padding: 40px 50px 48px;
  }
}

@media only screen and (max-width: 767px) {
  .order-complete-title {
    padding: 30px 20px 38px;
  }
}

.order-complete-title h3 {
  font-size: 30px;
  color: #333333;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-complete-title h3 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .order-complete-title h3 {
    font-size: 18px;
    line-height: 30px;
  }
}

.order-product-details {
  padding: 21px 0px 20px;
  background-color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .order-product-details {
    padding: 21px 10px 20px;
  }
}

.order-product-details table {
  width: 100%;
  text-align: center;
}

.order-product-details table thead tr th {
  font-size: 16px;
  color: #777777;
  font-weight: bold;
  width: 25%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-product-details table thead tr th {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .order-product-details table thead tr th {
    width: 100%;
    font-size: 14px;
    padding: 0 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .order-product-details table thead tr th {
    width: 25%;
    font-size: 12px;
    padding: 0 0px;
  }
}

.order-product-details table tbody tr td {
  font-size: 13px;
  color: #777777;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .order-product-details table tbody tr td {
    padding: 0 0px 10px;
  }
}

/*-------- 29. Compare style ---------*/
.compare-page-content-wrap {
  background-color: #fff;
}

.compare-table .table tbody tr {
  margin-bottom: 20px;
}

.compare-table .table tbody tr td {
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #ccc;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td {
    padding: 10px 20px;
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
  }
}

.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 100%;
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
    margin-top: 35px;
  }
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 210px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 100%;
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 20px;
  }
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #0A1039;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title .title {
    font-size: 16px;
  }
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #0A1039;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 14px;
  font-weight: 400;
}

.compare-table .table tbody tr td.pro-remove a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  background-color: #F6F6F6;
}

.compare-table .table tbody tr td.pro-remove a:hover {
  background-color: #0A1039;
}

.compare-table .table tbody tr td.pro-remove:hover {
  color: #0A1039;
}

.compare-table .table tbody tr td.pro-remove i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #e7a415;
}

/*------ end Compare Page Wrapper -----*/
/*-------- 30. Order tracking style ---------*/
.order-tracking-content p {
  line-height: 30px;
  margin: 0 auto;
  text-align: center;
  width: 86%;
  color: #2f2f2f;
}

.order-tracking-content .order-tracking-form {
  margin: 30px 0 0;
}

.order-tracking-content .order-tracking-form .sin-order-tracking {
  margin: 0 0 36px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking label {
  color: #2f2f2f;
  font-weight: 600;
  margin: 0 0 10px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input {
  background: transparent;
  border: 1px solid #cecece;
  height: 70px;
  padding: 10px 20px;
  color: #2f2f2f;
  font-size: 14px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input:focus {
  border: 1px solid #262626;
}

.order-tracking-content .order-tracking-form .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-tracking-form .order-track-btn a {
  display: block;
  font-weight: bold;
  color: #fff;
  background-color: #262626;
  line-height: 1;
  padding: 22px 45px;
  text-transform: capitalize;
}

.order-tracking-content .order-tracking-form .order-track-btn a:hover {
  background-color: #0A1039;
}

/*---------- 31. Others style ------------*/
.empty-cart-content img {
  max-width: 100%;
}

.empty-cart-content h3 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 50px 0 41px;
  color: #262626;
}

@media only screen and (max-width: 767px) {
  .empty-cart-content h3 {
    font-size: 17px;
    margin: 30px 0 31px;
  }
}

.empty-cart-content .empty-cart-btn a {
  display: inline-block;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #262626;
  padding: 18px 32px;
}

.empty-cart-content .empty-cart-btn a:hover {
  background-color: #0A1039;
}

.error-content h1 {
  font-size: 200px;
  color: #fff;
  margin: 0;
  line-height: 150px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h1 {
    font-size: 180px;
    line-height: 135px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content h1 {
    font-size: 120px;
    line-height: 120px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error-content h1 {
    font-size: 150px;
    line-height: 150px;
  }
}

.error-content h3 {
  font-size: 64px;
  color: #fff;
  margin: 40px 0 70px;
  font-family: "Vandella";
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .error-content h3 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content h3 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content h3 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content h3 {
    font-size: 30px;
    margin: 10px 0 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .error-content h3 {
    font-size: 40px;
    margin: 10px 0 30px;
  }
}

.error-btn a {
  display: inline-block;
  color: #000;
  line-height: 1;
  text-transform: uppercase;
  background-color: #fff;
  padding: 18px 30px;
  z-index: 1;
  position: relative;
}

.error-btn a:hover {
  color: #ffffff;
}

.single-store h3 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 28px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .single-store h3 {
    font-size: 20px;
    margin-bottom: 12px;
  }
}

.single-store ul li {
  margin-bottom: 5px;
}

.single-store ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-store.mb-50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-store.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .store-area.pt-120 {
    padding-top: 60px;
  }
  .store-area.pb-70 {
    padding-bottom: 30px;
  }
}
