/*---------- 6. Product style ------------*/

.product-area {
    position: relative;
    .organic-position-3 {
        animation-iteration-count: infinite;
	    animation-name: bounce-img-2;
        animation-duration: 2.5s;
        position: absolute;
        top: 4.5%;
        right: 8%;
        z-index: 9;
        @media #{$xx-layout} {
            top: 1.5%;
        }
        @media #{$lg-layout} {
            top: -1.5%;
        }
        @media #{$md-layout} {
            top: -1.5%;
        }
        @media #{$xs-layout} {
            top: -1.5%;
        }
        img {
            width: 150px;
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
    .organic-position-4 {
        animation-iteration-count: infinite;
	    animation-name: bounce-img-2;
        animation-duration: 2.5s;
        position: absolute;
        top: 46%;
        left: 0%;
        z-index: 9;
        img {
            width: 151px;
            @media #{$xx-layout} {
                img {
                    width: 100px;
                }
            }
        }
        @media #{$xl-layout} {
            top: 50%;
            img {
                width: 60px;
            }
        }
        @media #{$lg-layout} {
            top: 50%;
            img {
                width: 60px;
            }
        }
        @media #{$md-layout} {
            img {
                width: 60px;
            }
        }
        @media #{$xs-layout} {
            top: 45%;
            img {
                width: 40px;
            }
        }
    }
    .organic-position-5 {
        animation-iteration-count: infinite;
	    animation-name: bounce-img;
        animation-duration: 2.5s;
        position: absolute;
        top: 54%;
        left: 1%;
        z-index: 9;
        img {
            width: 37px;
        }
        @media #{$xs-layout} {
            left: 0%;
            img {
                width: 20px;
            }
        }
    }
    .organic-position-6 {
        animation-iteration-count: infinite;
	    animation-name: bounce-img;
        animation-duration: 2.5s;
        position: absolute;
        bottom: 26%;
        right: 0%;
        z-index: 9;
        img {
            width: 49px;
        }
        @media #{$xs-layout} {
            img {
                width: 30px;
            }
        }
    }
    .organic-position-7 {
        animation-iteration-count: infinite;
	    animation-name: bounce-img-2;
        animation-duration: 2.5s;
        position: absolute;
        bottom: 6%;
        right: 4%;
        z-index: 9;
        @media #{$xs-layout} {
            bottom: 1%;
            right: 12%;
        }
        img {
            width: 162px;
            @media #{$md-layout} {
                width: 100px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
}

.product-tab-list-1 {
    display: flex;
    justify-content: center;
    a {
        font-size: 18px;
        display: inline-block;
        color: #777;
        padding: 0 45px 20px;
        position: relative;
        @media #{$lg-layout} {
            padding: 0 30px 20px;
        }
        @media #{$md-layout} {
            padding: 0 25px 20px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 12px;
            margin-bottom: 10px;
        }
        @media #{$sm-layout} {
            padding: 0 10px 12px;
            margin-bottom: 10px;
        }
        &.active {
            color: #333;
            font-weight: bold;
            &:before {
                position: absolute;
                background-color: #333;
                height: 2px;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                content: "";
                width: 80px;
                @media #{$xs-layout} {
                    width: 100%;
                }
            }
        }
    }
    @media #{$lg-layout} {
        &.mb-90 {
            margin-bottom: 50px;
        }
    }
    @media #{$md-layout} {
        &.mb-90 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-90 {
            margin-bottom: 15px;
        }
        &.mb-65 {
            margin-bottom: 15px;
        }
        &.mb-50 {
            margin-bottom: 20px;
        }
    }
    &.tab-list-1-pl {
        a {
            padding: 0 0 20px 0px;
            margin-left: 90px;
            @media #{$xx-layout} {
                margin-left: 60px;
            }
            @media #{$xl-layout} {
                margin-left: 50px;
            }
            @media #{$lg-layout} {
                margin-left: 40px;
            }
            @media #{$md-layout} {
                margin-left: 18px;
                font-size: 15px;
            }
            @media #{$xs-layout} {
                margin-left: 18px;
                font-size: 15px;
                padding: 0 0 10px 0px;
            }
            @media #{$sm-layout} {
                font-size: 18px;
            }
            &:first-child {
                margin-left: 0px;
            }
            &:before {
                left: 50%;
                transform: translateX(-50%);
                @media #{$md-layout} {
                    width: 100%;
                }
                @media #{$xs-layout} {
                    width: 100%;
                }
            }
        }
    }
    &.tab-list-1-left {
        justify-content: left;
        @media #{$xs-layout} {
            &.mt-100 {
                margin-top: 30px;
            }
        }
    }
    &.tab-list-1-right {
        justify-content: right;
        @media #{$xs-layout} {
            justify-content: center;
        }
    }
    &.tab-list-1-modify {
        a {
            padding: 0 37px 13px;
            &.active {
                &:before {
                    width: 71%;
                }
            }
            @media #{$xs-layout} {
                padding: 0 17px 13px;
            }
        }
    }
}

.product-slider-active-1 , 
.product-slider-active-2 , 
.product-slider-active-4 {
    .slick-list {
        margin-right: -15px;
        margin-left: -15px;
        .product-wrap-plr-1 {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.product-slider-active-3 {
    .slick-list {
        margin-right: -15px;
        margin-left: -15px;
        padding-right: 220px;
        @media #{$xx-layout} {
            padding-right: 120px;
        }
        @media #{$xl-layout} {
            padding-right: 120px;
        }
        @media #{$lg-layout} {
            padding-right: 200px;
        }
        @media #{$md-layout} {
            padding-right: 120px;
        }
        @media #{$xs-layout} {
            padding-right: 0px;
        }
        @media #{$sm-layout} {
            padding-right: 160px;
        }
        .product-wrap-plr-1 {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.product-wrap {
    position: relative;
    overflow: hidden;
    @media #{$xl-layout} {
        &.mb-100 {
            margin-bottom: 30px;
        }
    }
    @media #{$lg-layout} {
        &.mb-100 {
            margin-bottom: 30px;
        }
        &.mb-90 {
            margin-bottom: 50px;
        }
    }
    @media #{$md-layout} {
        &.mb-55 {
            margin-bottom: 40px;
        }
        &.mb-50 {
            margin-bottom: 40px;
        }
        &.mb-100 {
            margin-bottom: 40px;
        }
        &.mb-90 {
            margin-bottom: 50px;
        }
    }
    @media #{$xs-layout} {
        &.mb-55 {
            margin-bottom: 30px;
        }
        &.mb-50 {
            margin-bottom: 30px;
        }
        &.mb-100 {
            margin-bottom: 30px;
        }
        &.mb-90 {
            margin-bottom: 30px;
        }
    }
    .product-img {
        overflow: hidden;
        position: relative;
        a {
            display: block;
            img {
                width: 100%;
            }
        }
        &.product-img-zoom {
            a {
                img {
                    transition: all .5s ease 0s;
                    transform: scale(1);
                }
            }
        }
        .product-offer {
            position: absolute;
            left: 70px;
            top: 70px;
            width: 88px;
            height: 88px;
            line-height: 88px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background-color: #0CBDEE;
            h5 {
                font-weight: 500;
                font-size: 22px;
                line-height: 29px;
                color: $white;
                margin: 0;
            }
        }
    }
    .product-content {
        h4 {
            font-size: 15px;
            margin: 0 0 9px;
            a {
                color: #333;
                &:hover {
                    color: $theme-color-dark-blue
                }
            }
        }
        &.product-content-border {
            border: 1px solid #F2F2F2;
            border-top: none;
            border-radius: 0 0 10px 10px;
        }
        &.product-content-padding {
            padding: 35px 10px 30px;
        }
    }
    .product-action-position-1 {
        position: absolute;
        background-color: #fff;
        border: 1px solid #F0F0F0;
        padding: 25px 10px 27px;
        left: 0;
        right: 0;
        bottom: -30px;
        transition: all .3s ease 0s;
        visibility: hidden;
        opacity: 0;
        @media #{$md-layout} {
            padding: 20px 10px 22px;
        }
        @media #{$xs-layout} {
            padding: 15px 10px 17px;
        }
    }
    .product-action-2 {
        top: 60%;
        transform: translateY(-50%);
        left: 0;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        right: 0;
        text-align: center;
        transition: all 0.6s ease 0s;
        .product-action-2-style {
            background-color: #fff;
            box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
            display: inline-block;
            padding: 9px 17px 7px;
            border-radius: 50px;
            button {
                border: none;
                background-color: transparent;
                color: #B2B2B2;
                font-size: 20px;
                padding: 0 5px;
                position: relative;
                background-color: transparent;
                border: none;
                &:hover {
                    color: $theme-color-chocolate;
                }
                &::before {
                    background: #DFE3E8 none repeat scroll 0 0;
                    content: "";
                    height: 20px;
                    position: absolute;
                    right: -3px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                }
                &:last-child::before {
                    display: none;
                }
            }
            &.green-3-action-2 {
                button {
                    color: #B2B2B2;
                    &:hover {
                        color: $theme-color-green-3;
                    }
                }
            }
        }
    }
    .product-content-2 {
        h4 {
            font-size: 14px;
            margin: 0 0 9px;
            font-weight: 300;
            a {
                color: #232953;
                &:hover {
                    color: #333;
                }
            }
        }
    }
    .product-action-wrap {
        > .product-action-cart {
            margin: 18px 0 18px;
            @media #{$lg-layout} {
                margin: 14px 0 14px;
            }
            @media #{$md-layout} {
                margin: 14px 0 14px;
            }
            @media #{$xs-layout} {
                margin: 10px 0 12px;
            }
            > button {
                border: none;
                padding: 7px 32px 7px;
                background-color: #333333;
                color: #fff;
                font-weight: 500;
                font-size: 12px;
                position: relative;
                &.padding-dec {
                    padding: 6px 20px 7px; 
                }
                @media #{$lg-layout} {
                    padding: 6px 22px;
                }
                @media #{$md-layout} {
                    padding: 8px 22px;
                }
                @media #{$xs-layout} {
                    padding: 6px 22px;
                }
                &:hover {
                    background-color: $theme-color-dark-blue
                }
            }
        }
        > button {
            border: none;
            padding: 0;
            background-color: transparent;
            color: #B2B2B2;
            font-size: 20px;
            &:hover {
                color: $theme-color-dark-blue;
            }
        }
        &.product-action-wrap-modify {
            > .product-action-cart {
                margin: 10px 0 10px;
                > button {
                    padding: 3px 20px 5px;
                }
            }
        }
    }
    &.product-wrap-gray {
        background-color: #F8F8F8;
        padding: 52px 30px 45px 110px;
        @media #{$xl-layout} {
            padding: 52px 30px 45px 90px;
        }
        @media #{$lg-layout} {
            padding: 52px 30px 45px 30px;
        }
        @media #{$md-layout} {
            padding: 52px 20px 45px 20px;
        }
        @media #{$xs-layout} {
            padding: 52px 20px 45px 20px;
        }
        @media #{$sm-layout} {
            padding: 52px 20px 45px 50px;
        }
    }
    .product-content-3 {
        h4 {
            font-size: 19px;
            font-weight: 500;
            margin: 0px 0 12px;
            a {
                color: #333;
                &:hover {
                    color: #666;
                }
            }
        }
        .price-cart-wrap {
            display: flex;
            .product-price-3 {
                margin-right: 45px;
                span {
                    font-size: 24px;
                    font-weight: bold;
                }
            }
            .product-cart-3 {
                a {
                    font-size: 26px;
                    color: #AFAFAF;
                    &:hover {
                        color: #333;
                    }
                }
            }
        }
    }
    .product-content-position-1 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 35px;
    }
    .product-content-position-2 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
    }
    .product-content-4 {
        h4 {
            font-size: 18px;
            font-weight: 500;
            margin: 0 0 18px;
            @media #{$lg-layout} {
                margin: 0 0 10px;
            }
            @media #{$xs-layout} {
                margin: 0 0 10px;
            }
            a {
                color: #232953;
                &:hover {
                    color: $theme-color-green-2;
                }
            }
        }
    }
    .product-content-5 {
        h4 {
            font-size: 22px;
            font-family: $pacifico;
            color: $theme-color-green-3;
            margin: 0;
            position: relative;
            &::before {
                position: absolute;
                left: -135px;
                top: 50%;
                transform: translateY(-50%);
                background-color: $theme-color-green-3;
                width: 108px;
                height: 1px;
                content: "";
                @media #{$xs-layout} {
                    display: none;
                }
                @media #{$xs-layout} {
                    display: block;
                    width: 50px;
                    left: -65px;
                }
            }
        }
        h3 {
            font-size: 29px;
            font-weight: 500;
            color: $theme-color-black;
            margin: 17px 0 28px;
            @media #{$lg-layout} {
                margin: 12px 0 15px;
            }
            @media #{$xs-layout} {
                margin: 8px 0 13px;
                font-size: 25px;
            }
            a {
                color: $theme-color-black;
                &:hover {
                    color: $theme-color-green-3;
                }
            }
        }
        span {
            font-size: 24px;
            font-weight: bold;
            font-family: $montserrat;
            color: $theme-color-black;
        }
        &.product-content-5-mrg {
            margin: 110px 0 0 60px;
            @media #{$lg-layout} {
                margin: 40px 0 0 0px;
            }
            @media #{$md-layout} {
                margin: 50px 0 0 0px;
            }
            @media #{$xs-layout} {
                margin: 20px 0 0 0px;
            }
        }
    }
    .product-timer {
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 20px;
        opacity: 1;
        visibility: visible;
        transition: all .3s ease 0s;
    } 
    &.fashion2-product-mt {
        margin-top: -50px;
        @media #{$xs-layout} {
            margin-top: 0px;
        }
    }
    &:hover {
        .product-img-zoom {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
        .product-action-position-1 {
            bottom: 0;
            visibility: visible;
            opacity: 1;
        }
        .product-action-2 {
            opacity: 1;
            visibility: visible;
            top: 50%;
        }
        .product-timer {
            opacity: 0;
            visibility: hidden;
            bottom: -20px;
        }
    }
}

.product-price {
    span {
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        &.old-price {
            color: #777777;
            font-weight: 300;
            text-decoration: line-through;
            margin-left: 12px;
        }
    }
}
.product-price-2 {
    span {
        font-size: 12px;
        font-weight: 300;
        color: #232953;
        &.old-price {
            font-weight: 300;
            text-decoration: line-through;
            margin-left: 12px;
        }
    }
}
.product-price-3 {
    span {
        font-size: 16px;
        font-weight: 300;
        color: #232953;
        &.old-price {
            text-decoration: line-through;
            margin-left: 8px;
        }
    }
}

.tab-content{
    &.jump {
        .tab-pane {
            display: block;
            height: 0;
            opacity: 0;
            overflow: hidden;
            &.active {
                display: block;
                height: auto;
                opacity: 1;
                overflow: visible;
                animation-name: hastechZoomIn;
                animation-duration: 500ms;
                animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
                animation-play-state: running;
            }
        }
    }
    @media #{$md-layout} {
        &.mt-80 {
            margin-top: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mt-80 {
            margin-top: 30px;
        }
    }
}
.tab-content{
    &.jump-2 {
        .tab-pane {
            display: block;
            height: 0;
            opacity: 0;
            overflow: hidden;
            &.active {
                display: block;
                height: auto;
                opacity: 1;
                overflow: visible;
                animation-name: hastechMoveLeftBig;
                animation-duration: 500ms;
                animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
                animation-play-state: running;
            }
        }
    }
}


.product-tab-list-2 {
    a {
        display: block;
        width: 100%;
        font-size: 18px;
        color: #777777;
        margin-bottom: 59px;
        @media #{$md-layout} {
            font-size: 16px;
            margin-bottom: 45px;
        }
        @media #{$xs-layout} {
            margin-bottom: 35px;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        &.active {
            font-weight: bold;
            color: #333;
            svg {
                path {
                    fill: #333;
                }
            }
        }
        svg {
            width: 32px;
            margin-right: 22px;
            @media #{$md-layout} {
                margin-right: 10px;
            }
            path {
                fill: #3A3A3A;
            }
        }
        &:hover {
            color: #333;
            svg {
                path {
                    fill: #333;
                }
            }
        }
    }
}
.product-tab-list-3 {
    display: flex;
    a {
        display: inline-block;
        font-size: 18px;
        color: #777777;
        padding: 0 39px 24px;
        border-bottom: 2px solid transparent;
        @media #{$md-layout} {
            font-size: 16px;
        }
        @media #{$xs-layout} {
            padding: 0 10px 15px;
            margin-bottom: 15px;
        }
        &:last-child {
            margin-bottom: 0px;
        }
        &.active {
            font-weight: bold;
            color: $theme-color-yellow;
            border-bottom: 2px solid $theme-color-yellow;
        }
        &:hover {
            font-weight: bold;
            color: $theme-color-yellow;
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 40px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 20px;
        }
        &.mb-80 {
            margin-bottom: 20px;
        }
    }
    &.product-tab-list-3-green {
        a {
            &.active {
                color: $theme-color-black;
                border-bottom: 2px solid $theme-color-green;
            }
            &:hover {
                color: $theme-color-black;
            }
        }
    }
    &.product-tab-list-3-black {
        a {
            &.active {
                color: $theme-color-black;
                border-bottom: 2px solid $theme-color-black;
            }
            &:hover {
                color: $theme-color-black;
            }
        }
    }
}

.pre-order-bg-color {
    background: linear-gradient(to right, #FFF9F0, #FFF9F0 40.66667%, #fff 0%);
    @media #{$xs-layout} {
        background: linear-gradient(to right, #FFF9F0, #FFF9F0 61.66667%, #fff 0%);
    }
}

.pre-order-img {
    margin-right: 55px;
    overflow: hidden;
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    a {
        display: block;
        img {
            width: 100%;
            transform: scale(1);
            transition: all .5s ease 0s;
        }
    }
    &:hover {
        a {
            img {
                transform: scale(1.03);
            }
        }
    }
}

.pre-order-content {
    h3 {
        color: $theme-color-black;
        font-size: 29px;
        font-weight: 500;
        a {
            color: $theme-color-black;
        }
    }
    h4 {
        color: #777777;
        font-size: 16px;
        margin: 10px 0 20px;
        > span {
            font-weight: bold;
            color: $theme-color-yellow;
            margin-right: 12px;
        }
    }
    p {
        font-size: 15px;
        line-height: 30px;
        margin: 0;
    }
    > span {
        font-size: 24px;
        color: #232953;
        font-weight: 300;
        display: block;
        margin: 27px 0 46px;
        @media #{$md-layout} {
            margin: 15px 0 20px;
        }
        @media #{$xs-layout} {
            margin: 15px 0 20px;
        }
    }
    @media #{$xs-layout} {
        padding-bottom: 30px;
    }
    &.pre-order-green {
        h4 {
            color: #232953;
            font-weight: 500;
            > span {
                color: $theme-color-green;
                font-weight: bold;
            }
        }
        p {
            width: 80%;
            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$xs-layout} {
                width: 100%;
            }
        }
    }
}

.pre-order-img-2 {
	margin: 0 0 0 120px;
    @media #{$xl-layout} {
        margin: 0 0 0 50px;
    }
    @media #{$lg-layout} {
        margin: 0 0 0 50px;
    }
    @media #{$md-layout} {
        margin: 0 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0 0 0 0px;
    }
    a {
        display: block;
        img {
            max-width: 100%;
        }
    }
}
.pre-order-img-3 {
    text-align: right;
    a {
        display: block;
        img {
            max-width: 100%;
        }
    }
}

.sessional-bg-color {
    background: linear-gradient(to left, #F8ECE2, #F8ECE2 40.5%, #fff 0%);
    padding: 72px 0 75px;
    @media #{$md-layout} {
        background: linear-gradient(to left, #F8ECE2, #F8ECE2 49.5%, #fff 0%);
    }
    @media #{$xs-layout} {
        background: linear-gradient(to left, #F8ECE2, #F8ECE2 50%, #fff 0%);
        padding: 50px 0 25px;
    }
}

.sessional-content {
    h2 {
        font-size: 29px;
        font-weight: 500;
        margin: 0;
        line-height: 1;
    }
    p {
        font-size: 15px;
        line-height: 30px;
        margin: 32px 0 70px;
        @media #{$md-layout} {
            margin: 25px 0 30px;
        }
        @media #{$xs-layout} {
            margin: 12px 0 20px;
        }
    }
}

.sessional-img-wrap {
    position: relative;
    .sessional-img {
        float: right;
        margin-right: -30px;
        position: relative;
        @media #{$xl-layout} {
            margin-right: 0px;
        }
        @media #{$lg-layout} {
            margin-right: 0px;
        }
        @media #{$md-layout} {
            margin-right: 0px;
        }
        @media #{$xs-layout} {
            margin-right: 0px;
        }
        .sessional-shap {
            position: absolute;
            top: 10%;
            left: 25%;
            width: 15px;
            height: 15px;
            background-color: #FFE600;
            transform: rotate(45deg);
            z-index: 9;
        }
        a {
            display: block;
            img {
                max-width: 100%;
            }
        }
    }
    .sessional-img-content {
        position: absolute;
        left: 12.7%;
        top: 50%;
        transform: translateY(-50%);
        @media #{$lg-layout} {
            left: 0%;
        }
        @media #{$md-layout} {
            left: 0%;
        }
        @media #{$xs-layout} {
            left: 0%;
        }
        h2 {
            font-size: 142px;
            font-weight: bold;
            line-height: 110px;
            color: #D18437;
            margin: 0 0 27px;
            position: relative;
            @media #{$lg-layout} {
                font-size: 100px;
                line-height: 80px;
            }
            @media #{$md-layout} {
                font-size: 80px;
                line-height: 70px;
            }
            @media #{$xs-layout} {
                font-size: 80px;
                line-height: 75px;
            }
        }
        h3 {
            font-size: 63px;
            font-weight: bold;
            line-height: 54px;
            color: #06103A;
            margin: 0 0 0px;
            position: relative;
            span {
                margin-left: -33px;
                display: inline-block;
            }
        }
    }
    @media #{$xs-layout} {
        margin-top: 20px;
    }
}
.cake-product-img {
    margin-right: 100px;
    overflow: hidden;
    @media #{$lg-layout} {
        margin-right: 50px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    a {
        display: block;
        img {
            width: 100%;
            transition: all .5s ease 0s;
            transform: rotate(0);
        }
    }
    &:hover {
        a {
            img {
                transform: rotate(10deg);
            }
        }
    }
}

.arrival-product-details {
    @media #{$xs-layout} {
        margin: 15px 0 0px;
    }
    h4 {
        font-size: 22px;
        font-family: $pacifico;
        color: $theme-color-chocolate;
        margin: 0;
    }
    h3 {
        font-size: 29px;
        font-weight: 500;
        margin: 18px 0 26px;
        @media #{$xs-layout} {
            margin: 10px 0 15px;
        }
        a {
            color: $theme-color-black;
            &:hover {
                color: $theme-color-chocolate;
            }
        }
    }
    span {
        color: $theme-color-dark-blue;
        font-size: 30px;
        letter-spacing: 1px;
    }
    @media #{$lg-layout} {
        &.ml-50 {
            margin-left: 0;
        }
    }
    @media #{$md-layout} {
        &.ml-50 {
            margin-left: 0;
        }
    }
    @media #{$xs-layout} {
        &.ml-50 {
            margin-left: 0;
        }
    }
}
.product-action-wrap-2 {
    display: flex;
    align-items: center;
    margin: 37px 0 0;
    &.treeplant-action-mrg-dec {
        margin: 30px 0 0;
        @media #{$xs-layout} {
            justify-content: center;
        }
    }
    @media #{$xs-layout} {
        margin: 16px 0 0;
    }
    .product-action-wrap-2-right {
        margin-left: 23px;
        @media #{$lg-layout} {
            margin-left: 15px;
        }
        @media #{$xs-layout} {
            margin-left: 15px;
        }
        > button {
            border: none;
            padding: 0;
            background-color: transparent;
            color: #B2B2B2;
            margin-right: 15px;
            font-size: 20px;
            &:last-child {
                margin-right: 0px;
            }
            @media #{$lg-layout} {
                margin-right: 10px;
            }
            @media #{$xs-layout} {
                margin-right: 10px;
            }
            &.green-2 {
                &:hover {
                    color: $theme-color-green-2;
                }
            }
            &.green-3 {
                &:hover {
                    color: $theme-color-green-3;
                }
            }
            &.brown {
                margin-right: 8px;
                &:last-child {
                    margin-right: 0px;
                }
                &:hover {
                    color: $theme-color-brown;
                }
            }
            &:hover {
                color: $theme-color-chocolate;
            }
        }
    }
}

.organic-product-mt {
    margin-top: 30px;
}


.organic-product-shape-top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    img {
        width: 100%;
    }
}

.organic-product-shape-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    img {
        width: 100%;
    }
}

.discount-content {
    h3 {
        font-size: 65px;
        color: #172419;
        margin: 0;
        font-family: $vandella;
        @media #{$md-layout} {
            font-size: 55px;
        }
        @media #{$xs-layout} {
            font-size: 45px;
        }
    }
    h2 {
        font-size: 128px;
        color: $theme-color-green-2;
        font-family: $vandella;
        margin: 10px 0px 60px;
        @media #{$lg-layout} {
            font-size: 110px;
            margin: 10px 0px 40px;
        }
        @media #{$md-layout} {
            font-size: 90px;
            margin: 10px 0px 40px;
        }
        @media #{$xs-layout} {
            font-size: 66px;
            margin: 10px 0px 30px;
        }
    }
    &.discount-content-flower {
        h3 {
            color: #333;
        }
        h2 {
            color: #D20769;
            margin: 0px 0px 60px;
            @media #{$lg-layout} {
                margin: 10px 0px 40px;
            }
            @media #{$md-layout} {
                margin: 10px 0px 20px;
            }
            @media #{$xs-layout} {
                margin: 10px 0px 20px;
            }
        }
    }
}
.discount-wrap {
    padding: 90px 0 42px 60px;
    @media #{$xs-layout} {
        padding: 30px 0 30px 30px;
    }
    .discount-img {
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
                transform: scale(1);
                transition: all .5s ease 0s;
            }
        }
    }
    > h3 {
        font-size: 36px;
        font-weight: 300;
        color: #fff;
        margin: 49px 0 0 -60px;
        @media #{$lg-layout} {
            margin: 26px 0 0 -60px;
        }
        @media #{$xs-layout} {
            margin: 26px 0 0 -30px;
        }
        span {
            font-weight: bold;
            line-height: 55px;
        }
    }
    &:hover {
        .discount-img {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.product-bundle-negative-mrg {
    margin-top: -95px;
    @media #{$lg-layout} {
        margin-top: -70px;
    }
    @media #{$md-layout} {
        margin-top: 0px;
    }
    @media #{$xs-layout} {
        margin-top: 0px;
    }
}

.product-tab-list-4 {
    justify-content: flex-end;
    @media #{$xs-layout} {
        justify-content: center;
        margin: 20px 0 0;
    }
    a {
        color: #06103A;
        font-size: 18px;
        font-weight: 500;
        display: inline-block;
        line-height: 1;
        padding: 19px 80px;
        background-color: #F3F3F3;
        margin-left: 10px;
        position: relative;
        &:first-child {
            margin-left: 0;
        }
        @media #{$lg-layout} {
            padding: 19px 60px;
        }
        @media #{$md-layout} {
            padding: 14px 30px 15px;
        }
        @media #{$xs-layout} {
            padding: 12px 20px 15px;
        }
        &::before {
            background-image: url(../../assets/images/icon-img/tab-shape.png);
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            bottom: 0px;
            background-repeat: repeat;
            display: block;
            width: 100%;
            height: 4px;
            z-index: 9;
        }
        &.active {
            background-color: $theme-color-pink;
            color: $white;
        }
    }
}

.flower-arrival-content {
    padding: 0 120px;
    @media #{$xx-layout} {
        padding: 0 80px;
    }
    @media #{$xl-layout} {
        padding: 0 80px;
    }
    @media #{$lg-layout} {
        padding: 0 50px;
    }
    @media #{$md-layout} {
        padding: 0 50px;
    }
    @media #{$xs-layout} {
        padding: 0 15px;
    }
    @media #{$sm-layout} {
        padding: 0 30px;
    }
    h3 {
        color: $theme-color-pink-2;
        font-size: 60px;
        font-family: $vandella;
        @media #{$xs-layout} {
            font-size: 50px;
            margin: 0;
        }
    }
    p {
        color: #777777;
        font-size: 15px;
        line-height: 30px;
        margin: 25px 0 35px;
        width: 85%;
        @media #{$md-layout} {
            margin: 15px 0 25px;
        }
        @media #{$xs-layout} {
            margin: 5px 0 15px;
            width: 100%;
        }
    }
}
.grid-sizer {
	width: 25%;
}


.single-car-details {
    background-color: $white;
    padding: 0 12px 65px;
    box-shadow: 0 5px 20px rgba(29, 8, 67, 0.03);
    @media #{$md-layout} {
        margin-top: 80px;
    }
    @media #{$xs-layout} {
        margin-top: 60px;
    }
    .single-car-form-wrap {
        background-color: #F9F9F9;
        padding: 35px 56px 120px 43px;
        @media #{$xs-layout} {
            padding: 35px 20px 120px 20px;
        }
        .single-car-form {
            width: 100%;
            height: 50px;
        }
        .single-car-submit {
            text-align: center;
            margin-top: 38px;
            button {
                font-weight: bold;
                color: #fff;
                background-color: $theme-color-red;
                border: none;
                padding: 9px 47px 10px;
                &:hover {
                    background-color: $theme-color-dark-blue;
                }
            } 
        }
    }
    .single-car-img {
        margin-top: -60px;
        @media #{$md-layout} {
            text-align: center;
        }
        @media #{$xs-layout} {
            text-align: center;
        }
        img {
            max-width: 100%;
        }
    }
}

.product-tab-list-5 {
    a {
        display: block;
        color: #777777;
        font-size: 17px;
        line-height: 1;
        width: 100%;
        padding: 20px 20px 20px 50px;
        background-color: transparent;
        position: relative;
        @media #{$lg-layout} {
            font-size: 14px;
            padding: 14px 20px 14px 50px;
        }
        &:before {
            position: absolute;
            content: "";
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            height: 2px;
            width: 11px;
            background-color: #707070;
            z-index: 2;
        }
        &.active {
            font-weight: bold;
            color: $theme-color-black;
            background-color: #FCEBEB;
        }
        &:hover {
            font-weight: bold;
            color: $theme-color-black;
        }
    }
    @media #{$lg-layout} {
        &.mb-50 {
            margin-bottom: 0px;
        }
    }
}

.product-tab-list-6 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
        font-size: 18px;
        color: #777777;
        display: inline-block;
        padding: 0 12px 18px;
        margin: 0 25px;
        position: relative;
        @media #{$xs-layout} {
            padding: 0 5px 10px;
            margin: 0 10px 10px;
            font-size: 16px;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0px;
            right: 0;
            bottom: 0;
            height: 2px;
            width: 0;
            background-color: #333333;
            z-index: 2;
        }
        &.active {
            font-weight: bold;
            color: #333333;
            &:before {
                width: 100%;
            }
        }
    }
    @media #{$xs-layout} {
        &.mb-140 {
            margin-bottom: 20px;
        }
    }
}

.product-tab-list-7 {
    display: flex;
    flex-wrap: wrap;
    a {
        font-size: 18px;
        color: #707070;
        display: inline-block;
        padding: 0 14px 28px;
        margin: 0 31px;
        position: relative;
        &:first-child {
            margin: 0 31px 0 0;
        }
        @media #{$md-layout} {
            padding: 0 14px 20px;
        }
        @media #{$xs-layout} {
            padding: 0 6px 10px;
            margin: 0 6px;
            &:first-child {
                margin: 0 6px 0 0;
            }
        }
        &:before {
            position: absolute;
            content: "";
            left: 0px;
            right: 0;
            bottom: 0;
            height: 2px;
            width: 0;
            background-color: #333333;
            z-index: 2;
        }
        &.active {
            font-weight: bold;
            color: #333333;
            &:before {
                width: 100%;
            }
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 20px;
        }
    }
}


.dot-style-5 {
    ul {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 15px;
        right: 17px;
        z-index: 9;
        li {
            margin: 0px 5px;
            @media #{$xs-layout} {
                margin: 0px 2px;
            }
            button {
                padding: 0;
                font-size: 0;
                background-color: #CBCBCB;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: none;
            }
            &.slick-active {
                button {
                    background-color: #404040;
                }
            }
        }
    }
}

.sessional-sale-content {
    padding: 250px 150px 165px;
    @media #{$xx-layout} {
        padding: 220px 50px 165px;
    }
    @media #{$xl-layout} {
        padding: 220px 50px 200px;
    }
    @media #{$lg-layout} {
        padding: 143px 30px 123px;
    }
    @media #{$md-layout} {
        padding: 80px 40px 80px;
    }
    @media #{$xs-layout} {
        padding: 60px 20px 60px;
    }
    h2 {
        font-size: 29px;
        font-weight: bold;
        color: #151C0F;
        margin: 0;
    }
    p {
        font-size: 16px;
        line-height: 30px;
        width: 89%;
        margin: 48px 0 55px;
        @media #{$xl-layout} {
            margin: 30px 0 37px;
        }
        @media #{$lg-layout} {
            margin: 20px 0 30px;
        }
        @media #{$xs-layout} {
            margin: 10px 0 20px;
            width: 100%;
            font-size: 14px;
        }
    }
}

.sessional-sale-banner {
    background: linear-gradient(to top, #D9F9FF, #D9F9FF 83%, #fff 0%);
    position: relative;
    text-align: right;
    padding: 50px 230px 30px 0;
    @media #{$xx-layout} {
        padding: 50px 50px 30px 0;
    }
    @media #{$xl-layout} {
        padding: 50px 50px 30px 0;
    }
    @media #{$lg-layout} {
        padding: 50px 50px 30px 0;
    }
    @media #{$md-layout} {
        padding: 100px 40px 30px 0;
    }
    @media #{$xs-layout} {
        padding: 80px 20px 30px 0;
    }
    .sessional-sale-img {
        display: inline-block;
        position: relative;
        &:after {
            width: 198px;
            height: 198px;
            background-color: #FBFBFB;
            border-radius: 100%;
            position: absolute;
            content: "";
            top: -50px;
            left: -26px;
            @media #{$lg-layout} {
                width: 150px;
                height: 150px;
            }
            @media #{$md-layout} {
                width: 150px;
                height: 150px;
                left: 0px;
            }
            @media #{$xs-layout} {
                width: 100px;
                height: 100px;
            }
            @media #{$sm-layout} {
                width: 150px;
                height: 150px;
            }
        }
        img {
            position: relative;
            z-index: 2;
            @media #{$lg-layout} {
                width: 230px;
            }
            @media #{$xs-layout} {
                width: 120px;
            }
            @media #{$sm-layout} {
                width: 220px;
            }
        }
    }
    .sessional-sale-banner-content {
        position: absolute;
        left: 135px;
        top: 45%;
        text-align: left;
        @media #{$xx-layout} {
            left: 95px;
        }
        @media #{$xl-layout} {
            left: 55px;
        }
        @media #{$lg-layout} {
            left: 40px;
        }
        @media #{$md-layout} {
            left: 100px;
        }
        @media #{$xs-layout} {
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
        }
        h2 {
            color: #347AB5;
            font-size: 142px;
            font-weight: bold;
            margin: 0 0 10px;
            line-height: 110px;
            @media #{$xx-layout} {
                font-size: 115px;
            }
            @media #{$xl-layout} {
                font-size: 90px;
            }
            @media #{$lg-layout} {
                font-size: 90px;
            }
            @media #{$md-layout} {
                font-size: 120px;
            }
            @media #{$xs-layout} {
                font-size: 50px;
                line-height: 50px
            }
            @media #{$sm-layout} {
                font-size: 100px;
                line-height: 100px
            }
        }
        h3 {
            color: #151C0F;
            font-size: 68px;
            font-weight: bold;
            margin: 0 0 0px;
            line-height: 57px;
            @media #{$xs-layout} {
                font-size: 45px;
                line-height: 40px
            }
            @media #{$sm-layout} {
                font-size: 55px;
                line-height: 50px
            }
        }
    }
}

.kids-2-bg-color {
    background: linear-gradient(to right, #E8FFFB, #E8FFFB 46.5%, #fff 0%);
    @media #{$md-layout} {
        background: linear-gradient(to right, #E8FFFB, #E8FFFB 33%, #fff 0%);
    }
    @media #{$xs-layout} {
        background: linear-gradient(to right, #E8FFFB, #E8FFFB 100%, #fff 0%);
        padding: 60px 0 60px;
    }
}
.kids-chair-img {
    margin: 0 110px 0 0;
    @media #{$md-layout} {
        margin: 20px 10px 0 0;
    }
    @media #{$xs-layout} {
        margin: 0 0px 15px 0;
    }
    a {
        display: block;
        img {
            width: 100%;
        }
    }
}

.mega-fashion-pro-title {
    h4 {
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 25px;
        @media #{$xs-layout} {
            margin: 0 0 15px;
        }
        a {
            color: $theme-color-black;
            &:hover {
                color: $theme-color-dark-blue;
            }
        }
    }
}

.trending-product-img {
    position: relative;
    a {
        display: block;
        img {
            width: 100%;
        }
    }
    &.trending-nrgative-mrg {
        margin-right: -30px;
        @media #{$xs-layout} {
            margin-right: 0px;
        }
    }
    .trending-product1-shape1 {
        position: absolute;
        top: -23px;
        left: 4%;
        img {
            width: 47px;
            animation: playbutton-effect 4s linear infinite;
            animation-direction: normal;
            animation-delay: 2s;
            animation-direction: alternate;
        }
    }
    .trending-product1-shape2 {
        position: absolute;
        bottom: 12px;
        left: 25%;
        img {
            width: 161px;
            animation: playbutton-effect 4s linear infinite;
            animation-direction: normal;
            animation-delay: 2s;
            animation-direction: alternate;
        }
    }
    .trending-product2-shape1 {
        position: absolute;
        top: 0;
        left: 16px;
        img {
            width: 148px;
            animation: playbutton-effect 4s linear infinite;
            animation-direction: normal;
            animation-delay: 2s;
            animation-direction: alternate;
            @media #{$xs-layout} {
                width: 90px;
            }
        }
    }
    .trending-product2-shape2 {
        position: absolute;
        right: 93px;
        bottom: 20px;
        @media #{$xs-layout} {
            right: 80px;
            bottom: 10px;
        }
        img {
            width: 85px;
            animation: playbutton-effect 4s linear infinite;
            animation-direction: normal;
            animation-delay: 2s;
            animation-direction: alternate;
            @media #{$xs-layout} {
                width: 65px;
            }
        }
    }
    .trending-product3-shape2 {
        position: absolute;
        left: 15%;
        bottom: 3%;
        img {
            width: 71px;
            animation: playbutton-effect 4s linear infinite;
            animation-direction: normal;
            animation-delay: 2s;
            animation-direction: alternate;
            @media #{$xs-layout} {
                width: 50px;
            }
        }
    }
    .trending-product3-shape1 {
        position: absolute;
        right: 70px;
        top: 10%;
        @media #{$xs-layout} {
            right: 30px;
        }
        img {
            width: 107px;
            animation: playbutton-effect 4s linear infinite;
            animation-direction: normal;
            animation-delay: 2s;
            animation-direction: alternate;
            @media #{$xs-layout} {
                width: 80px;
            }
        }
    }
}

.trending-product-content {
    h2 {
        color: #151C0F;
        font-size: 29px;
        font-weight: bold;
        margin: 0;
        line-height: 50px;
        @media #{$md-layout} {
            font-size: 19px;
            line-height: 38px;
        }
        @media #{$xs-layout} {
            font-size: 18px;
            line-height: 35px;
        }
        a {
            color: #151C0F;
            &:hover {
                color: $theme-color-dark-blue;
            }
        }
    }
    p {
        font-size: 15px;
        line-height: 30px;
        margin: 16px 0 41px;
        &.peragraph-width {
            width: 89%;
            @media #{$xs-layout} {
                width: 100%;
            }
        }
        @media #{$md-layout} {
            margin: 10px 0 30px;
        }
        @media #{$xs-layout} {
            margin: 10px 0 20px;
        }
    }
    @media #{$md-layout} {
        &.mt-90 {
            margin-top: 0px;
        }
        &.ml-70 {
            margin-left: 30px; 
        }
    }
    @media #{$xs-layout} {
        &.mt-90 {
            margin-top: 20px;
        }
        &.ml-70 {
            margin-left: 0px; 
        }
    }
}

.shop-list-wrap {
    .product-list-img {
        overflow: hidden;
        position: relative;
        a {
            img {
                width: 100%;
                transform: scale(1);
                transition: all .5s ease 0s;
            }
        }
        .shop-list-quickview {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translatex(-50%);
            > button {
                border: none;
                padding: 0;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                background-color: #333;
                color: #fff;
                display: inline-block;
                opacity: 0;
                visibility: hidden;
                transform: scale(0);
                transition: all .3s ease 0s;
                &:hover {
                    background-color: $theme-color-dark-blue;
                }
            }
        }
    }
    &:hover {
        .product-list-img {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
        .shop-list-quickview {
            button {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }
        }
    }
}

.shop-list-content {
    h3 {
        font-size: 20px;
        margin: 0;
        a {
            color: $theme-color-black;
            &:hover {
                color: $theme-color-dark-blue;
            }
        }
    }
    .pro-list-price {
        margin: 15px 0 16px;
        @media #{$sm-layout} {
            margin: 15px 0 10px;
        }
        span {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            &.old-price {
                color: #777777;
                font-weight: 300;
                text-decoration: line-through;
                margin-left: 12px;
            }
        }
    }
    p {
        font-size: 15px;
        line-height: 30px;
        margin: 0 0 25px;
        @media #{$sm-layout} {
            margin: 0 0 15px;
        }
    }
    .product-list-action {
        display: flex;
        flex-wrap: wrap;
        > button {
            border: none;
            padding: 0;
            background-color: transparent;
            color: #B2B2B2;
            font-size: 20px;
            transition: all .3s ease 0s;
            &:hover {
                color: $theme-color-dark-blue;
            }
        }
        .product-action-cart {
            > button {
                border: none;
                padding: 7px 32px 7px;
                background-color: #333333;
                color: #fff;
                font-weight: 500;
                font-size: 12px;
                margin: 0 15px;
                &:hover {
                    background-color: $theme-color-dark-blue;
                }
            }
        }
    }
    @media #{$xs-layout} {
        &.ml-20 {
            margin-left: 0px;
        }
        margin-top: 20px;
    }
    @media #{$sm-layout} {
        &.ml-20 {
            margin-left: 0px;
        }
        margin-top: 0px;
    }
}

@media #{$xx-layout} {
    .organic-discount {
        background-position: 86%;
    }
}
@media #{$lg-layout} {
    .organic-discount {
        background-position: 86%;
    }
    .product-area {
        &.pb-120 {
            padding-bottom: 60px;
        }
        &.pb-60 {
            padding-bottom: 0px;
        }
        &.pb-90 {
            padding-bottom: 30px;
        }
        &.pb-110 {
            padding-bottom: 50px;
        }
        &.pb-100 {
            padding-bottom: 40px;
        }
        &.pt-125 {
            padding-top: 70px;
        }
        &.pt-145 {
            padding-top: 80px;
        }
        &.pb-145 {
            padding-bottom: 80px;
        }
    }
}

@media #{$md-layout} {
    .organic-discount {
        background-position: 86%;
    }
    .product-area {
        &.pb-100 {
            padding-bottom: 40px;
        }
        &.pb-90 {
            padding-bottom: 10px;
        }
        &.pb-95 {
            padding-bottom: 30px;
        }
        &.pt-125 {
            padding-top: 50px;
        }
        &.pb-110 {
            padding-bottom: 40px;
        }
    }
    .product-area {
        &.pt-135 {
            padding-top: 80px;
        }
    }
    .flower-arrival-bg {
        background-position: 100%;
    }
}

@media #{$xs-layout} {
    .organic-discount {
        background-position: 86%;
    }
    .product-area {
        &.pb-100 {
            padding-bottom: 20px;
        }
        &.pb-115 {
            padding-bottom: 35px;
        }
        &.pb-105 {
            padding-bottom: 25px;
        }
        &.pb-95 {
            padding-bottom: 15px;
        }
        &.pb-90 {
            padding-bottom: 15px;
        }
    }
    .product-area {
        &.pt-135 {
            padding-top: 40px;
        }
        &.pt-80 {
            padding-top: 30px;
        }
    }
    &.pt-125 {
        padding-top: 30px;
    }
    &.pb-110 {
        padding-bottom: 30px;
    }
    .organic-discount {
        &.pb-100 {
            padding-bottom: 60px;
        }
    }
}

.nice-select {
    &.nice-select-style-3 {
        border-radius: 0;
        font-size: 14px;
        height: 50px;
        font-weight: 400;
        line-height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        color: #342D0E;
        width: 100%;
        border: none;
        font-weight: 400;
        ul {
            width: 100%;
        }
        &.nice-select-shop {
            border: 1px solid #EFEFEF;
            font-weight: 300;
            padding-left: 26px;
            color: #808080;
        }
        &.cart-tax-select {
            height: 45px;
            line-height: 45px;
            font-size: 13px;
            color: #999999;
            background-color: #F6F6F6;
            @media #{$md-layout} {
                margin-bottom: 20px;
            }
            @media #{$xs-layout} {
                margin-bottom: 20px;
            }
        }
    }
    &::after {
        display: none;
    }
    &:before {
        position: absolute;
        content: "";
        right: 15px;
        top: 50%;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #0B1154;
        z-index: 2;
        transform: translateY(-50%);
    }
    &.open {
        &:before {
            border-bottom: 6px solid #0B1154;
            border-top: none;
        }
    }
}






