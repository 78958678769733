/*---------- 20. Sidebar style ------------*/

.shop-sidebar-style {
	margin-right: 30px;
    @media #{$md-layout} {
        margin-top: 50px;
    }
    @media #{$xs-layout} {
        margin-top: 50px;
    }
    &.shop-sidebar-style-mrg2 {
        margin-right: 20px;
        @media #{$xx-layout} {
            margin-right: 0px;
        }
        @media #{$xl-layout} {
            margin-right: 0px;
        }
    }
}
.sidebar-widget , 
.sidebar-widget-2 , 
.sidebar-widget-3 {
    h4 {
        &.pro-sidebar-title , 
        &.pro-sidebar-title-2 , 
        &.pro-sidebar-title-3 {
            font-size: 18px;
            color: #333;
            font-weight: 600;
            margin: 0 0 0;
        }
    }
}

.sidebar-widget-categori {
    ul {
        li {
            margin-bottom: 37px;
            &:last-child {
                margin-bottom: 0px;
            }
            a {
                color: #777777;
                font-size: 16px;
                display: block;
                padding-left: 36px;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 5px;
                    height: 0px;
                    z-index: 9;
                    background-color: $theme-color-black;
                    transition: all .3s ease 0s;
                    opacity: 0;
                    visibility: hidden;
                }
                &.active {
                    font-weight: bold;
                    color: $theme-color-black;
                    &:before {
                        height: 45px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
                &:hover {
                    font-weight: bold;
                    color: $theme-color-black;
                    &:before {
                        height: 45px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.sidebar-widget-size {
    margin-left: 18px;
    &.size-mrg-none {
        margin-left: 0;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            margin-right: 5px;
            margin-bottom: 5px;
            a {
                font-size: 13px;
                color: #828495;
                display: inline-block;
                border: 1px solid #E3E4E9;
                width: 31px;
                height: 31px;
                line-height: 31px;
                text-align: center;
                @media #{$lg-layout} {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                }
                &:hover {
                    border: 1px solid $theme-color-black;
                    background-color: $theme-color-black;
                    color: #fff;
                }
            }
        }
    }
    &.widget-filter-size {
        ul {
            li {
                a {
                    width: 31px;
                    height: 31px;
                    line-height: 31px;
                    @media #{$xx-layout} {
                        width: 29px;
                        height: 29px;
                        line-height: 29px;
                    }
                    @media #{$xl-layout} {
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
    @media #{$xs-layout} {
        &.mt-45 {
            margin-top: 25px;
        }
        &.mt-50 {
            margin-top: 30px;
        }
    }
}

.price-filter{
    margin-left: 18px;
    &.price-mrg-none {
        margin-left: 0;
    }
    #slider-range {
        background: #E3E4E9 none repeat scroll 0 0;
        border: medium none;
        border-radius: 50px;
        height: 3px;
        margin-bottom: 12px;
        margin-left: auto;
        span {
            transition: all .0s ease 0s;
        }
        .ui-slider-range {
            position: absolute;
            display: block;
            border: 0;
            background: #333 none repeat scroll 0 0;
            border: none;
            transition: all .0s ease 0s;
        }
        .ui-slider-handle {
            background: #333 none repeat scroll 0 0;
            border: medium none;
            height: 16px;
            margin-left: 0;
            width: 4px;
            top: -7px;
            cursor: ew-resize;
            border-radius: 0;
        }
    }
    .price-slider-amount {
        margin: 22px 0 0;
        .label-input {
            margin: 25px 0 0;
            display: flex;
            align-items: center;
            span {
                color: #828495 !important;
                margin-right: 5px;
            }
            input {
                background: transparent;
                padding: 0;
                border: none;
                font-size: 14px;
                font-weight: 400;
                height: auto;
                color: #828495 !important;
                width: 75%;
            }
        }
    }
    @media #{$xs-layout} {
        &.mt-45 {
            margin-top: 25px;
        }
        &.mt-50 {
            margin-top: 30px;
        }
    }
}

.sidebar-widget-brand-logo {
    margin-left: 18px;
    &.brand-logo-mrg-none {
        margin-left: 0;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;
        @media #{$xl-layout} {
            margin-left: -15px;
            margin-right: -15px;
        }
        @media #{$lg-layout} {
            margin-left: -15px;
            margin-right: -15px;
        }
        @media #{$xs-layout} {
            margin-left: -15px;
            margin-right: -15px;
        }
        li {
            flex: 0 0 33.333%;
            max-width: 33.333%;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            @media #{$xl-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$lg-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$xs-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            a {
                display: block;
                overflow: hidden;
                img {
                    width: 100%;
                    transform: scale(1);
                    transition: all .3s ease 0s;
                }
            }
            &:hover {
                a {
                    img {
                        transform: scale(1.03);
                    }
                }
            }
        }
    }
    @media #{$xs-layout} {
        &.mt-45 {
            margin-top: 25px;
        }
        &.mt-50 {
            margin-top: 30px;
        }
    }
}

.sidebar-widget-categori-2 {
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            a {
                margin: 0 10px;
                padding: 0 30px 17px;
                display: inline-block;
                font-size: 18px;
                color: #777777;
                line-height: 1;
                border-bottom: 2px solid transparent;
                @media #{$lg-layout} {
                    padding: 0 15px 17px;
                }
                @media #{$md-layout} {
                    padding: 0 8px 17px;
                    margin: 0 0px;
                }
                @media #{$xs-layout} {
                    padding: 0 10px 10px;
                    margin: 0 0px 10px;
                    font-size: 16px;
                }
                &.active {
                    font-weight: bold;
                    color: #333;
                    border-bottom: 2px solid #333;
                }
                &:hover {
                    color: #333;
                }
            }
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
        &.mt-45 {
            margin-top: 30px;
        }
    }
}


.widget-style-top {
    margin-bottom: 65px;
    padding-bottom: 20px;
	border-bottom: 1px solid #F2F2F2;
    @media #{$xs-layout} {
        margin-bottom: 45px;
        padding-bottom: 0px;
    }
}

.shop-filter {
	line-height: 1;
	position: relative;
    > a {
        color: #777777;
        text-transform: uppercase;
        &::before {
            position: absolute;
            content: "";
            right: -30px;
            top: 50%;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid #848484;
            z-index: 2;
            transform: translateY(-50%);
            @media #{$xs-layout} {
                right: -14px;
            }
        }
        &.active {
            &::before {
                border-bottom: 6px solid #848484;
                border-top: none;
            }
        }
        &:hover {
            color: #333;
        }
    }
}

.product-filter-wrapper {
	box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: 30px;
	font-size: 12px;
	padding: 48px 40px 0px;
	display: none;
	width: 100%;
    margin-top: 20px;
    @media #{$xx-layout} {
        padding: 48px 20px 18px;
    }
    @media #{$xl-layout} {
        padding: 48px 20px 18px;
    }
    @media #{$xs-layout} {
        padding: 48px 20px 18px;
    }
}

.sidebar-widget-categori-3 {
    ul {
        li {
            display: inline-block;
            margin: 0 5px 5px;
            a {
                font-size: 15px;
                color: #333;
                &:hover {
                    color: $theme-color-dark-blue;
                }
            }
        }
    }
    @media #{$xs-layout} {
        &.mt-45 {
            margin-top: 25px;
        }
        &.mt-50 {
            margin-top: 30px;
        }
    }
}

.sidebar-search {
    form {
        position: relative;
        input {
            background-color: transparent;
            color: #262626;
            height: 41px;
            border: 1px solid #cdcdcd;
            padding: 2px 40px 2px 10px;
            font-size: 13px;
            &::-moz-input-placeholder {
                color: #262626;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #262626;
                opacity: 1;
            }
        }
        .button-search {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            color: #2f2f2f;
            background-color: transparent;
            border: none;
            padding: 5px 20px;
            transition: all .3s ease 0s;
            &:hover {
                color: $theme-color-dark-blue;
            }
        }
    }
    @media #{$xs-layout} {
        &.mb-70 {
            margin-bottom: 50px;
        }
    }
}

.sidebar-post-wrap {
    .single-sidebar-post {
        display: flex;
        border-bottom: 1px solid #DCDCDC;
        padding-bottom: 20px;
        margin-bottom: 20px;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
        .sidebar-post-img {
            flex: 0 0 100px;
            @media #{$lg-layout} {
                flex: 0 0 60px;
            }
            img {
                width: 100%;
            }
        }
        .sidebar-post-content {
            margin-left: 20px;
            @media #{$lg-layout} {
                margin-left: 10px;
            }
            h4 {
                font-size: 16px;
                color: #333;
                margin: 0 0 3px;
                @media #{$lg-layout} {
                    font-size: 14px;
                }
                a {
                    color: #333;
                    &:hover {
                        color: $theme-color-dark-blue;
                    }
                }
            }
            span {
                color: #2f2f2f;
                font-size: 12px;
            }
        }
    }
}

.sidebar-widget-tag {
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            margin: 0 5px 5px 0;
            a {
                color: #828495;
                line-height: 1;
                padding: 10px 15px 11px;
                display: inline-block;
                border: 1px solid #E3E4E9;
                &:hover {
                    color: #fff;
                    background-color: #333;
                    border: 1px solid #333;
                }
            }
        }
    }
}





