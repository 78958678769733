/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/


/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css?family=Bubblegum+Sans|Rochester|Chewy|Montserrat:300,400,500,600,700,800,900|Pacifico|Poppins:300,400,500,600,700,800,900|Work+Sans:300,400,500,600,700,800,900&display=swap');

/*-- Common Style --*/

*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    line-height: 24px;
    font-size: 14px;
    font-style: normal; 
    font-weight: 400;
    color: #333333;
    font-family: $poppins;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333333;
    font-weight: 400;
    margin-top: 0;
    font-family: $poppins;
}

p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #777;
    margin-bottom: 15px;
    font-family: $poppins;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

p:last-child {
    margin-bottom: 0;
}

a,
button {
    color: inherit;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    color: #333333;
}


a,
button,
img,
input,
span {
    transition: all .3s ease 0s;
}

*:focus {
    outline: none !important;
}

a:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

button,
input[type="submit"] {
    cursor: pointer;
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}


/*-- 
    - Common Classes
-----------------------------------------*/

.fix {
    overflow: hidden
}

.hidden {
    display: none;
}

.clear {
    clear: both;
}

.section {
    float: left;
    width: 100%;
}
.f-right {
    float: right;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.bg-img {
    background-position: center center;
    background-size: cover;
}

.position-relative {
    position: relative;
}





/*************************
  Basic margin padding
*************************/

.m-0 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.p-0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}


/*************************
         Margin top
*************************/

.mt-0 {
    margin-top: 0
}

.mt-5 {
    margin-top: 5px !important;
}
.mt-10 {
    margin-top: 10px
}

.mt-12 {
    margin-top: 12px
}

.mt-15 {
    margin-top: 15px
}

.mt-20 {
    margin-top: 20px
}

.mt-25 {
    margin-top: 25px
}

.mt-30 {
    margin-top: 30px
}

.mt-35 {
    margin-top: 35px
}

.mt-40 {
    margin-top: 40px
}

.mt-45 {
    margin-top: 45px
}

.mt-50 {
    margin-top: 50px
}
.mt-55 {
    margin-top: 55px
}

.mt-60 {
    margin-top: 60px
}

.mt-65 {
    margin-top: 65px
}

.mt-70 {
    margin-top: 70px
}
.mt-75 {
    margin-top: 75px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-90 {
    margin-top: 90px
}

.mt-100 {
    margin-top: 100px;
}

.mt-110 {
    margin-top: 110px
}
.mt-115 {
    margin-top: 115px
}

.mt-120 {
    margin-top: 120px
}
.mt-125 {
    margin-top: 125px
}

.mt-130 {
    margin-top: 130px;
    @media #{$lg-layout} {
        margin-top: 70px;
    }
    @media #{$md-layout} {
        margin-top: 50px;
    }
    @media #{$xs-layout} {
        margin-top: 60px;
    }
}
.mt-135 {
    margin-top: 135px
}

.mt-140 {
    margin-top: 140px
}

.mt-150 {
    margin-top: 150px
}
.mt-160 {
    margin-top: 160px
}
.mt-155 {
    margin-top: 155px
}
.mt-175 {
    margin-top: 175px
}


/*************************
      Margin right
*************************/

.mr-0 {
    margin-right: 0px
}

.mr-10 {
    margin-right: 10px
}

.mr-12 {
    margin-right: 12px
}

.mr-15 {
    margin-right: 15px
}

.mr-20 {
    margin-right: 20px
}

.mr-25 {
    margin-right: 25px
}

.mr-30 {
    margin-right: 30px
}

.mr-35 {
    margin-right: 35px
}

.mr-40 {
    margin-right: 40px
}
.mr-45 {
    margin-right: 45px
}

.mr-50 {
    margin-right: 50px
}
.mr-55 {
    margin-right: 55px
}

.mr-60 {
    margin-right: 60px
}

.mr-65 {
    margin-right: 65px
}

.mr-70 {
    margin-right: 70px
}

.mr-80 {
    margin-right: 80px
}

.mr-90 {
    margin-right: 90px
}

.mr-100 {
    margin-right: 100px;
}

.mr-110 {
    margin-right: 110px
}

.mr-120 {
    margin-right: 120px
}

.mr-130 {
    margin-right: 130px
}

.mr-140 {
    margin-right: 140px
}

.mr-150 {
    margin-right: 150px
}


/*************************
      Margin bottom
*************************/

.mb-0 {
    margin-bottom: 0
}

.mb-10 {
    margin-bottom: 10px
}

.mb-12 {
    margin-bottom: 12px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}
.mb-25 {
    margin-bottom: 25px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-35 {
    margin-bottom: 35px
}

.mb-40 {
    margin-bottom: 40px
}
.mb-37 {
    margin-bottom: 37px
}

.mb-45 {
    margin-bottom: 45px
}

.mb-50 {
    margin-bottom: 50px
}

.mb-55 {
    margin-bottom: 55px
}

.mb-60 {
    margin-bottom: 60px
}

.mb-65 {
    margin-bottom: 65px
}

.mb-70 {
    margin-bottom: 70px
}

.mb-75 {
    margin-bottom: 75px
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-85 {
    margin-bottom: 85px;
}

.mb-90 {
    margin-bottom: 90px
}

.mb-95 {
    margin-bottom: 95px
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-110 {
    margin-bottom: 110px
}

.mb-120 {
    margin-bottom: 120px
}

.mb-130 {
    margin-bottom: 130px
}

.mb-140 {
    margin-bottom: 140px
}

.mb-150 {
    margin-bottom: 150px;
}
.mb-160 {
    margin-bottom: 160px;
    @media #{$lg-layout} {
        margin-bottom: 100px;
    }
    @media #{$md-layout} {
        margin-bottom: 80px;
    }
    @media #{$xs-layout} {
        margin-bottom: 60px;
    }
}
.mb-170 {
    margin-bottom: 170px;
}
.mb-180 {
    margin-bottom: 180px;
}


/*************************
        Margin left
*************************/

.ml-0 {
    margin-left: 0
}

.ml-10 {
    margin-left: 10px
}

.ml-12 {
    margin-left: 12px
}

.ml-15 {
    margin-left: 15px
}

.ml-20 {
    margin-left: 20px
}

.ml-25 {
    margin-left: 25px
}

.ml-30 {
    margin-left: 30px
}

.ml-35 {
    margin-left: 35px
}

.ml-40 {
    margin-left: 40px
}
.ml-45 {
    margin-left: 45px
}

.ml-50 {
    margin-left: 50px
}

.ml-55 {
    margin-left: 55px
}

.ml-60 {
    margin-left: 60px
}

.ml-70 {
    margin-left: 70px
}
.ml-75 {
    margin-left: 75px
}

.ml-80 {
    margin-left: 80px
}

.ml-90 {
    margin-left: 90px
}
.ml-95 {
    margin-left: 95px
}

.ml-100 {
    margin-left: 100px
}
.ml-105 {
    margin-left: 105px
}

.ml-110 {
    margin-left: 110px
}

.ml-120 {
    margin-left: 120px
}

.ml-130 {
    margin-left: 130px
}
.ml-135 {
    margin-left: 135px
}

.ml-140 {
    margin-left: 140px
}
.ml-145 {
    margin-left: 145px
}

.ml-150 {
    margin-left: 150px
}
.ml-200 {
    margin-left: 200px
}
.ml-300 {
    margin-left: 300px
}
.ml-ntv5 {
    margin-left: -5px
}


/*************************
        Padding top
*************************/

.pt-0 {
    padding-top: 0
}

.pt-5 {
    padding-top: 5px !important;
}
.pt-10 {
    padding-top: 10px
}

.pt-15 {
    padding-top: 15px
}

.pt-20 {
    padding-top: 20px
}

.pt-25 {
    padding-top: 25px
}

.pt-30 {
    padding-top: 30px
}
.pt-35 {
    padding-top: 35px
}

.pt-40 {
    padding-top: 40px
}

.pt-45 {
    padding-top: 45px
}

.pt-50 {
    padding-top: 50px
}

.pt-55 {
    padding-top: 55px
}

.pt-60 {
    padding-top: 60px
}

.pt-65 {
    padding-top: 65px
}

.pt-70 {
    padding-top: 70px;
}

.pt-75 {
    padding-top: 75px
}
.pt-85 {
    padding-top: 85px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-95 {
    padding-top: 95px;
}



.pt-90 {
    padding-top: 90px;
}

.pt-100 {
    padding-top: 100px;
}



.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-125 {
    padding-top: 125px;
}

.pt-128 {
    padding-top: 128px
}

.pt-130 {
    padding-top: 130px;
    @media #{$lg-layout} {
        padding-top: 70px;
    }
    @media #{$md-layout} {
        padding-top: 50px;
    }
    @media #{$xs-layout} {
        padding-top: 30px;
    }
}

.pt-135 {
    padding-top: 135px
}
.pt-165 {
    padding-top: 165px;
    @media #{$md-layout} {
        padding-top: 75px;
    }
    @media #{$xs-layout} {
        padding-top: 45px;
    }
}

.pt-140 {
    padding-top: 140px;
    @media #{$xs-layout} {
        padding-top: 55px;
    }
}
.pt-145 {
    padding-top: 145px;
    @media #{$md-layout} {
        padding-top: 65px;
    }
    @media #{$xs-layout} {
        padding-top: 35px;
    }
}

.pt-150 {
    padding-top: 150px;
    @media #{$md-layout} {
        padding-top: 70px;
    }
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}

.pt-155 {
    padding-top: 155px;
    @media #{$lg-layout} {
        padding-top: 95px;
    }
    @media #{$md-layout} {
        padding-top: 75px;
    }
    @media #{$xs-layout} {
        padding-top: 55px;
    }
}

.pt-160 {
    padding-top: 160px;
    @media #{$lg-layout} {
        padding-top: 100px;
    }
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}
.pt-170 {
    padding-top: 170px
}

.pt-180 {
    padding-top: 180px;
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$xs-layout} {
        padding-top: 50px;
    }
}
.pt-185 {
    padding-top: 185px;
}

.pt-190 {
    padding-top: 190px;
    @media #{$xs-layout} {
        padding-top: 80px;
    }
}
.pt-195 {
    padding-top: 195px;
}

.pt-200 {
    padding-top: 200px;
    @media #{$xs-layout} {
        padding-top: 70px;
    }
}
.pt-220 {
    padding-top: 220px;
}
.pt-250 {
    padding-top: 250px;
    @media #{$lg-layout} {
        padding-top: 150px;
    }
    @media #{$md-layout} {
        padding-top: 120px;
    }
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}
.pt-255 {
    padding-top: 255px;
    @media #{$md-layout} {
        padding-top: 150px;
    }
    @media #{$xs-layout} {
        padding-top: 80px;
    }
}
.pt-210 {
    padding-top: 210px;
}

.pt-230 {
    padding-top: 230px
}

.pt-270 {
    padding-top: 270px;
}

.pt-240 {
    padding-top: 240px
}
.pt-340 {
    padding-top: 340px;
}
.pt-350 {
    padding-top: 350px;
    @media #{$lg-layout} {
        padding-top: 250px;
    }
    @media #{$md-layout} {
        padding-top: 200px;
    }
    @media #{$xs-layout} {
        padding-top: 60px;
    }
    @media #{$sm-layout} {
        padding-top: 120px;
    }
}

.pt-365 {
    padding-top: 365px
}

.pt-360 {
    padding-top: 360px;
    @media #{$lg-layout} {
        padding-top: 250px;
    }
    @media #{$md-layout} {
        padding-top: 200px;
    }
    @media #{$xs-layout} {
        padding-top: 100px;
    }
}

.pt-370 {
    padding-top: 370px
}

.pt-380 {
    padding-top: 380px
}

.pt-400 {
    padding-top: 400px
}


/*************************
        Padding right
*************************/

.pr-0 {
    padding-right: 0
}

.pr-10 {
    padding-right: 10px
}

.pr-15 {
    padding-right: 15px
}

.pr-20 {
    padding-right: 20px
}

.pr-30 {
    padding-right: 30px
}

.pr-40 {
    padding-right: 40px
}

.pr-50 {
    padding-right: 50px
}

.pr-60 {
    padding-right: 60px
}

.pr-70 {
    padding-right: 70px
}

.pr-80 {
    padding-right: 80px
}

.pr-90 {
    padding-right: 90px
}

.pr-100 {
    padding-right: 100px
}

.pr-110 {
    padding-right: 110px
}

.pr-120 {
    padding-right: 120px
}

.pr-130 {
    padding-right: 130px
}

.pr-140 {
    padding-right: 140px
}

.pr-155 {
    padding-right: 155px
}

.pr-270 {
    padding-right: 270px
}


/*************************
        Padding bottom
*************************/

.pb-0 {
    padding-bottom: 0
}

.pb-5 {
    padding-bottom: 5px !important;
}
.pb-10 {
    padding-bottom: 10px
}

.pb-15 {
    padding-bottom: 15px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-25 {
    padding-bottom: 25px
}

.pb-30 {
    padding-bottom: 30px
}
.pb-35 {
    padding-bottom: 35px
}

.pb-40 {
    padding-bottom: 40px
}

.pb-45 {
    padding-bottom: 45px
}

.pb-50 {
    padding-bottom: 50px;
}
.pb-55 {
    padding-bottom: 55px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-65 {
    padding-bottom: 65px
}

.pb-70 {
    padding-bottom: 70px;
}
.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-85 {
    padding-bottom: 85px
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-95 {
    padding-bottom: 95px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-105 {
    padding-bottom: 105px
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
    @media #{$lg-layout} {
        padding-bottom: 60px;
    }
    @media #{$md-layout} {
        padding-bottom: 40px;
    }
    @media #{$xs-layout} {
        padding-bottom: 20px;
    }
}

.pb-125 {
    padding-bottom: 125px;
    @media #{$lg-layout} {
        padding-bottom: 65px;
    }
    @media #{$md-layout} {
        padding-bottom: 45px;
    }
    @media #{$xs-layout} {
        padding-bottom: 25px;
    }
}

.pb-130 {
    padding-bottom: 130px;
    @media #{$lg-layout} {
        padding-bottom: 70px;
    }
    @media #{$md-layout} {
        padding-bottom: 50px;
    }
    @media #{$xs-layout} {
        padding-bottom: 30px;
    }
}
.pb-135 {
    padding-bottom: 135px;
    @media #{$md-layout} {
        padding-bottom: 75px;
    }
    @media #{$xs-layout} {
        padding-bottom: 60px;
    }
}

.pb-140 {
    padding-bottom: 140px;
    @media #{$xs-layout} {
        padding-bottom: 60px;
    }
}

.pb-145 {
    padding-bottom: 145px;
    @media #{$md-layout} {
        padding-bottom: 45px;
    }
    @media #{$xs-layout} {
        padding-bottom: 20px;
    }
}

.pb-150 {
    padding-bottom: 150px;
    @media #{$md-layout} {
        padding-bottom: 70px;
    }
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}
.pb-155 {
    padding-bottom: 155px;
    @media #{$lg-layout} {
        padding-bottom: 95px;
    }
    @media #{$md-layout} {
        padding-bottom: 75px;
    }
    @media #{$xs-layout} {
        padding-bottom: 55px;
    }
}
.pb-160 {
    padding-bottom: 160px;
    @media #{$lg-layout} {
        padding-bottom: 100px;
    }
    @media #{$md-layout} {
        padding-bottom: 80px;
    }
    @media #{$xs-layout} {
        padding-bottom: 60px;
    }
}

.pb-170 {
    padding-bottom: 170px
}

.pb-190 {
    padding-bottom: 190px
}

.pb-175 {
    padding-bottom: 175px
}

.pb-180 {
    padding-bottom: 180px;
    @media #{$md-layout} {
        padding-bottom: 80px;
    }
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}

.pb-280 {
    padding-bottom: 280px
}

.pb-200 {
    padding-bottom: 200px
}
.pb-205 {
    padding-bottom: 205px
}
.pb-210 {
    padding-bottom: 210px
}
.pb-215 {
    padding-bottom: 215px
}
.pb-250 {
    padding-bottom: 250px;
    @media #{$lg-layout} {
        padding-bottom: 200px;
    }
    @media #{$md-layout} {
        padding-bottom: 150px;
    }
    @media #{$xs-layout} {
        padding-bottom: 60px;
    }
    @media #{$sm-layout} {
        padding-bottom: 100px;
    }
}
.pb-285 {
    padding-bottom: 285px
}

.pb-270 {
    padding-bottom: 270px;
}

.pb-290 {
    padding-bottom: 290px
}
.pb-315 {
    padding-bottom: 315px;
    @media #{$md-layout} {
        padding-bottom: 215px;
    }
    @media #{$xs-layout} {
        padding-bottom: 120px;
    }
}


/*************************
    Padding left
*************************/

.pl-0 {
    padding-left: 0
}

.pl-10 {
    padding-left: 10px
}

.pl-15 {
    padding-left: 15px
}

.pl-20 {
    padding-left: 20px
}

.pl-30 {
    padding-left: 30px
}
.pl-35 {
    padding-left: 35px
}

.pl-40 {
    padding-left: 40px
}
.pl-45 {
    padding-left: 45px
}

.pl-50 {
    padding-left: 50px
}

.pl-60 {
    padding-left: 60px
}

.pl-70 {
    padding-left: 70px
}

.pl-80 {
    padding-left: 80px
}

.pl-90 {
    padding-left: 90px
}

.pl-100 {
    padding-left: 100px
}

.pl-110 {
    padding-left: 110px
}

.pl-120 {
    padding-left: 120px
}

.pl-130 {
    padding-left: 130px
}

.pl-140 {
    padding-left: 140px
}

.pl-150 {
    padding-left: 150px
}

.pl-155 {
    padding-left: 155px
}

.pl-190 {
    padding-left: 190px
}


/***************************
    Page section padding 
****************************/

.ptb-0 {
    padding: 0
}

.ptb-10 {
    padding: 10px 0
}

.ptb-20 {
    padding: 20px 0
}

.ptb-30 {
    padding: 30px 0
}

.ptb-35 {
    padding: 35px 0
}

.ptb-32 {
    padding: 32px 0
}

.ptb-40 {
    padding: 40px 0
}

.ptb-50 {
    padding: 50px 0
}

.ptb-60 {
    padding: 60px 0
}

.ptb-70 {
    padding: 70px 0
}

.ptb-80 {
    padding: 80px 0
}

.ptb-90 {
    padding: 90px 0
}

.ptb-100 {
    padding: 100px 0
}

.ptb-110 {
    padding: 110px 0
}

.ptb-120 {
    padding: 120px 0
}

.ptb-130 {
    padding: 130px 0
}

.ptb-140 {
    padding: 140px 0
}

.ptb-150 {
    padding: 150px 0
}

.ptb-160 {
    padding: 160px 0
}

.ptb-170 {
    padding: 170px 0
}

.ptb-177 {
    padding: 177px 0
}

.ptb-180 {
    padding: 180px 0
}

.ptb-190 {
    padding: 190px 0
}

.ptb-200 {
    padding: 200px 0
}

.ptb-210 {
    padding: 210px 0
}

.ptb-220 {
    padding: 220px 0
}

.ptb-290 {
    padding: 290px 0
}

.ptb-310 {
    padding: 310px 0
}


/***************************
    Page section margin 
****************************/

.mtb-0 {
    margin: 0
}

.mtb-10 {
    margin: 10px 0
}

.mtb-15 {
    margin: 15px 0
}

.mtb-20 {
    margin: 20px 0
}

.mtb-30 {
    margin: 30px 0
}

.mtb-40 {
    margin: 40px 0
}

.mtb-50 {
    margin: 50px 0
}

.mtb-60 {
    margin: 60px 0
}

.mtb-70 {
    margin: 70px 0
}

.mtb-80 {
    margin: 80px 0
}

.mtb-90 {
    margin: 90px 0
}

.mtb-100 {
    margin: 100px 0
}

.mtb-110 {
    margin: 110px 0
}

.mtb-120 {
    margin: 120px 0
}

.mtb-130 {
    margin: 130px 0
}

.mtb-140 {
    margin: 140px 0
}

.mtb-150 {
    margin: 150px 0;
}

.mtb-290 {
    margin: 290px 0;
}


/*-- 
    - Input Placeholder
-----------------------------------------*/

input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
    background: #eceff8;
    border: 2px solid #eceff8;
    height: 45px;
    box-shadow: none;
    padding-left: 10px;
    font-size: 14px;
    color: #737373;
    width: 100%;
}

select {
    width: 100%;
    background: transparent;
    border: 0px solid #eceff8;
    box-shadow: none;
    font-size: 14px;
    color: #737373;
}

option {
    background: #fff;
    border: 0px solid #626262;
    padding-left: 10px;
    font-size: 14px;
}

input:focus {
    background: transparent;
}

textarea {
    resize: vertical;
    background: #eceff8;
    border: 2px solid #eceff8;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    color: #737373;
}

textarea:focus {
    background: transparent;
    border: 2px solid #4FC1F0;
    outline: none;
}

button, input, optgroup, select, textarea {
    
}

.custom-d-flex {
    display: flex !important;
}
.custom-align-item-center {
    align-items: center;
}
.custom-align-item-end {
    align-items: flex-end;
}

.custom-col-5 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    width: 100%; 
    min-height: 1px;
    @media #{$xx-layout} {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media #{$xl-layout} {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media #{$lg-layout} {
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
    @media #{$md-layout} {
        flex: 0 0 50%;
        max-width: 50%;
        &.custom-col-md-33 {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
    }
    @media #{$xs-layout} {
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%;
        &.custom-col-xs-50 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        
    }
    @media #{$sm-layout} {
        flex: 0 0 50%;
        max-width: 50%;
        &.custom-col-sm-33 {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
    }
}


.custom-col-5-2 {
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    width: 100%; 
    min-height: 1px;
    @media #{$lg-layout} {
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
    @media #{$md-layout} {
        flex: 0 0 50%;
        max-width: 50%;
        &.custom-col-md-33 {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
    }
    @media #{$xs-layout} {
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%;
        &.custom-col-xs-50 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        
    }
    @media #{$sm-layout} {
        flex: 0 0 50%;
        max-width: 50%;
        &.custom-col-sm-33 {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
    }
}



.custom-col-8 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    position: relative;
    width: 100%; 
    min-height: 1px;
    @media #{$md-layout} {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media #{$xs-layout} {
        flex: 0 0 50%;
        max-width: 50%;
    }
}




/*-- 
    Scroll Up 
-----------------------------------------*/

#scrollUp {
    width: 50px;
    height: 50px;
    background-color: #262626;
    color: #fff;
    right: 50px;
    bottom: 53px;
    text-align: center;
    overflow: hidden;
    border-radius: 50px;
    z-index: 9811 !important;
    @media #{$xs-layout} {
        width: 40px;
        height: 40px;
        right: 20px;
    }
    & i {
        display: block;
        line-height: 50px;
        font-size: 22px;
        @media #{$xs-layout} {
            line-height: 40px;
            font-size: 16px;
        }
    }
}

.height-100vh {
    height: 100vh;
}
.transparent-bar {
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 99;
}



/*--
    Background Color
------------------------------------------*/

.bg-white {
    background-color: #fff;
}
.bg-gray {
    background-color: #FAFAFA;
}
.bg-gray-2 {
    background-color: #FBFBFB;
}
.bg-gray-3 {
    background-color: #F3F3F3;
}
.bg-gray-4 {
    background-color: #EFEFEF;
}
.bg-gray-5 {
    background-color: #F8F8F8;
}
.bg-gray-6 {
    background-color: #F9F9F9;
}
.bg-gray-7 {
    background-color: #F6F6F6;
}
.bg-gray-8 {
    background-color: #FAFBFC;
}
.bg-gray-9 {
    background-color: #F7F7F7;
}

.bg-brown {
    background-color: #FCF9F2;
}
.gradient-bg-1 {
    background: linear-gradient(180deg, rgba(18,18,106,1) 0%, rgba(54,15,187,1) 100%); 
}
.gradient-bg-2 {
    background: linear-gradient(0deg, rgba(255,248,221,1) 0%, rgba(255,255,255,1) 100%);
}
.gradient-bg-3 {
    background: linear-gradient(270deg, rgba(183,240,192,1) 0%, rgba(255,255,255,1) 100%);
}

.gradient-bg-4 {
    background: linear-gradient(193deg, rgba(255,255,255,1) 0%, rgba(232,238,255,1) 100%);
}
.gradient-bg-5 {
    background: linear-gradient(90deg, rgba(232,255,220,1) 0%, rgba(255,251,233,1) 100%); 
}
.gradient-bg-6 {
    background: linear-gradient(184deg, rgba(252,249,242,1) 0%, rgba(252,228,211,1) 100%); 
}
.gradient-bg-7 {
    background: linear-gradient(0deg, rgba(248,108,255,1) 0%, rgba(0,189,255,1) 100%);
}

.bg-light-green {
    background-color: #F5FFF7;
}
.bg-light-green-2 {
    background-color: #F2F8F3;
}
.bg-light-green-3 {
    background-color: #DDEDD2;
}
.bg-light-yellow {
    background-color: #FFF9F0;
}
.bg-light-orange {
    background-color: #FCF5ED;
}
.bg-light-pink {
    background-color: #FFF8F8;
}
.bg-blue {
    background-color: #453FEE;
}

.bg-blue-2 {
    background-color: #5D58FE;
}

.bg-theme-color-black {
    background-color: $theme-color-black;
}
.bg-theme-color-yellow {
    background-color: $theme-color-yellow;
}
.bg-theme-color-green-3 {
    background-color: $theme-color-green-3;
}
.bg-theme-color-pink {
    background-color: $theme-color-pink;
}
.bg-theme-color-brown {
    background-color: $theme-color-brown;
}


.border-top-1 {
    border-top: 1px solid #FAFAFA;
}
.border-top-2 {
    border-top: 1px solid #EEEEEE;
}
.border-top-3 {
    border-top: 1px solid #5853E8; 
}
.border-top-4 {
    border-top: 1px solid #F2F2F2;
}
.border-top-5 {
    border-top: 1px solid #F1F1F1;
}

.default-overlay {
    position: relative;
    &:before {
        content: "";
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 250ms ease-out;
        z-index: 9;
    }
}

@media #{$xs-layout} {
    .res-white-overly-xs {
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: #fff;
            opacity: .4;
            z-index: 9;
        }
    }
} 
@media #{$xs-layout} {
    .res-black-overly-xs {
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: #000;
            opacity: .5;
            z-index: 9;
        }
    }
} 
@media #{$md-layout} {
    .res-white-overly-md {
        position: relative;
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: #fff;
            opacity: .5;
            z-index: 1;
        }
    }
} 



.jarallax-img {
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
}

.section-padding-1 {
    .container-fluid {
        padding: 0 55px;
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px;
        }
    }
}


.section-padding-2 {
    .container-fluid {
        padding: 0 30px;
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px;
        }
    }
}

.section-padding-3 {
    .container-fluid {
        padding: 0 0px 0 70px;
        @media #{$xl-layout} {
            padding: 0 0px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 0px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 0px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px 0 30px;
        }
    }
}

.section-padding-4 {
    .container-fluid {
        padding: 0 0px 0 100px;
        @media #{$xl-layout} {
            padding: 0 0px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 0px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px 0 30px;
        }
    }
}

.section-padding-5 {
    .container-fluid {
        padding: 0 75px;
        @media #{$xl-layout} {
            padding: 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px;
        }
    }
}
.section-padding-6 {
    .container-fluid {
        padding: 0 0px 0 366px;
        @media #{$xx-layout} {
            padding: 0 0px 0 97px;
        }
        @media #{$xl-layout} {
            padding: 0 0px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 0px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 0px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
    }
}

.section-padding-7 {
    .container-fluid {
        padding: 0 366px 0 0px;
        @media #{$xx-layout} {
            padding: 0 97px 0 0px;
        }
        @media #{$xl-layout} {
            padding: 0 15px 0 0px;
        }
        @media #{$lg-layout} {
            padding: 0 30px 0 0px;
        }
        @media #{$md-layout} {
            padding: 0 40px 0 0px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
    }
}

.section-padding-8 {
    .container-fluid {
        padding: 0 0px 0 75px;
        @media #{$xl-layout} {
            padding: 0 0px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 0px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 0px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 0px 0 0px;
        }
    }
}

.section-padding-9 {
    .container-fluid {
        padding: 0 32px 0 75px;
        @media #{$xl-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 30px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
    }
}

.section-padding-10 {
    .container-fluid {
        padding: 0 50px;
        @media #{$xl-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 30px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
    }
}

.section-padding-11 {
    .container-fluid {
        padding: 0 100px;
        @media #{$xl-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
    }
}
.section-padding-12 {
    .container-fluid {
        padding: 0 90px;
        @media #{$xl-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
    }
}

.section-padding-13 {
    .container-fluid {
        padding: 0 0px 0 60px;
        @media #{$xl-layout} {
            padding: 0 0px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 0px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px 0 30px;
        }
    }
}

.section-padding-14 {
    .container-fluid {
        padding: 0 366px 0 0px;
        @media #{$xx-layout} {
            padding: 0 55px 0 0px;
        }
        @media #{$xl-layout} {
            padding: 0 15px 0 0px;
        }
        @media #{$lg-layout} {
            padding: 0 30px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px 0 30px;
        }
    }
}


.section-padding-15 {
    .container-fluid {
        padding: 0 0px 0 366px;
        @media #{$xx-layout} {
            padding: 0 0px 0 55px;
        }
        @media #{$xl-layout} {
            padding: 0 0px 0 15px;
        }
        @media #{$lg-layout} {
            padding: 0 30px 0 30px;
        }
        @media #{$md-layout} {
            padding: 0 40px 0 40px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px 0 30px;
        }
    }
}



.btn-hover {
    a {
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #0A1039;
            transform: scaleY(0);
            transform-origin: 50%;
            transition-property: transform;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
        }
        &:hover {
            &::before {
                transform: scaleY(1);
            }
        }
    }
}


@media #{$xs-layout} {
    .xs-mb-30 {
        margin-bottom: 30px;
    }
}



.btn-style-1 {
    a {
        display: inline-block;
        color: #333333;
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        position: relative;
        z-index: 2;
        &.btn-border-1 {
            border: 1px solid #3A3A3A;
            &:hover {
                border: 1px solid #0A1039;
            }
        }
        &.btn-ptb-1 {
            padding: 18px 50px 19px;
            @media #{$xs-layout} {
                padding: 14px 31px 15px;
            }
        }
        &.btn-ptb-2 {
            padding: 19px 30px 21px;
            @media #{$md-layout} {
                padding: 19px 24px 21px;
            }
        }
        &.btn-ptb-3 {
            padding: 19px 43px 21px;
            @media #{$lg-layout} {
                padding: 19px 25px 21px;
            }
            @media #{$xs-layout} {
                padding: 19px 25px 21px;
            }
        }
        &.btn-1-bg-1 {
            background-color: #F3F3F3;
        }
        &.btn-ptb-4 {
            padding: 17px 50px 19px;
            @media #{$lg-layout} {
                padding: 19px 25px 21px;
            }
            @media #{$md-layout} {
                padding: 19px 25px 21px;
            }
            @media #{$xs-layout} {
                padding: 19px 25px 21px;
            }
        }
        &:hover {
            color: #fff;
        }
    }
}


.btn-style-2 {
    a {
        display: inline-block;
        color: #222D39;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        position: relative;
        z-index: 2;
        &.btn-ptb-1 {
            padding: 18px 36px 19px;
            @media #{$xs-layout} {
                padding: 14px 31px 15px;
            }
        }
        &.btn-ptb-2-white-bg {
            background-color: $white;
        }
        &:hover {
            color: #fff;
        }
    }
}

.btn-style-3 {
    a {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: $white;
        line-height: 1;
        padding: 20px 48px 20px;
        z-index: 2;
        position: relative;
        background-color: $theme-color-yellow;
        &.btn3-bg-green {
            background-color: $theme-color-green;
        }
        &.btn3-bg-chocolate {
            background-color: $theme-color-chocolate;
        }
        &.btn3-bg-green-2 {
            background-color: $theme-color-green-2;
        }
        &.btn3-bg-green-3 {
            background-color: $theme-color-green-3;
        }
        &.btn3-bg-brown {
            background-color: #CF8A35;
        }
        &.btn3-bg-white {
            background-color: $white;
        }
        &.btn3-text-blue {
            color: #222D39;
            &:hover {
                color: $white;
            }
        }
        &.btn3-text-black {
            color: #262836;
            &:hover {
                color: $white;
            }
        }
        i {
            position: relative;
            font-weight: bold;
            font-size: 20px;
            top: 2px;
            margin-left: 9px;
        }
        @media #{$xs-layout} {
            font-size: 14px;
        }
        &.btn3-ptb-1 {
            padding: 20px 48px 20px;
            @media #{$xs-layout} {
                padding: 15px 35px 15px;
            }
        }
        &.btn3-ptb-2 {
            padding: 20px 35px 20px;
            @media #{$xs-layout} {
                padding: 15px 35px 15px;
            }
        }
        &.btn3-ptb-3 {
            padding: 18px 22px 20px;
            @media #{$xs-layout} {
                padding: 15px 22px 15px;
            }
        }
        &.btn3-ptb-4 {
            padding: 13px 35px 14px;
            @media #{$xl-layout} {
                padding: 13px 30px 14px;
            }
            @media #{$xs-layout} {
                padding: 15px 22px 15px;
            }
        }
        &.btn3-ptb-5 {
            padding: 20px 42px 20px;
            @media #{$xs-layout} {
                padding: 15px 35px 15px;
            }
        }
        &.btn3-ptb-6 {
            padding: 18px 34px 20px;
            @media #{$xs-layout} {
                padding: 15px 35px 15px;
            }
        }
        &.btn3-ptb-7 {
            padding: 20px 57px 20px;
            @media #{$xs-layout} {
                padding: 15px 35px 15px;
            }
        }
        &.btn3-ptb-8 {
            padding: 13px 30px 14px;
            @media #{$xs-layout} {
                padding: 15px 22px 15px;
            }
        }
    }
}

.padding-25-row-col {
    .row {
        margin-right: -12.5px;
        margin-left: -12.5px;
        div[class^="col-"] {
            padding-left: 12.5px;
            padding-right: 12.5px;
        }
    }
}


.padding-100-row-col {
    .row {
        margin-right: -50px;
        margin-left: -50px;
        @media #{$xl-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$lg-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$md-layout} {
            margin-right: -20px;
            margin-left: -20px;
        }
        @media #{$xs-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        div[class^="col-"] {
            padding-left: 50px;
            padding-right: 50px;
            @media #{$xl-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$lg-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$md-layout} {
                padding-left: 20px;
                padding-right: 20px;
            }
            @media #{$xs-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.padding-40-row-col {
    .row {
        margin-right: -20px;
        margin-left: -20px;
        @media #{$xl-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$lg-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        @media #{$md-layout} {
            margin-right: -20px;
            margin-left: -20px;
        }
        @media #{$xs-layout} {
            margin-right: -15px;
            margin-left: -15px;
        }
        div[class^="col-"] {
            padding-left: 20px;
            padding-right: 20px;
            @media #{$xl-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$lg-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            @media #{$md-layout} {
                padding-left: 20px;
                padding-right: 20px;
            }
            @media #{$xs-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.padding-3-row-col {
    .row {
        margin-right: -1.5px;
        margin-left: -1.5px;
        div[class^="col-"] {
            padding-left: 1.5px;
            padding-right: 1.5px;
        }
    }
}
.padding-10-row-col {
    .row {
        margin-right: -5px;
        margin-left: -5px;
        div[class^="col-"] {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}
.padding-16-row-col {
    .row {
        margin-right: -8px;
        margin-left: -8px;
        div[class^="col-"] {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}
.padding-20-row-col {
    .row {
        margin-right: -10px;
        margin-left: -10px;
        div[class^="col-"] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

/* img bounce */
@-webkit-keyframes bounce-img {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    50% {
        opacity: 1;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);  
    }
}

@keyframes bounce-img {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    50% {
        opacity: 1;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

/* img bounce 2 */
@-webkit-keyframes bounce-img-2 {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }
    50% {
        opacity: 1;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);  
    }
}

@keyframes bounce-img-2 {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }
    50% {
        opacity: 1;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}


/* img zoom */
@keyframes playbutton-effect {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
  }
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}











