/*---------- 14. Footer style ------------*/

.footer-area {
    position: relative;
    &.kids-footer {
        position: relative;
        &::before {
            background-image: url(../../assets/images/icon-img/testimonial-shape-2.png);
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0px;
            background-repeat: repeat;
            display: block;
            width: 100%;
            height: 9px;
            z-index: 9;
        }
    }
    .organic-footer-shape-top {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 0;
        @media #{$xs-layout} {
            top: -10px;
        }
        img {
            width: 100%;
        }
    }
}

.footer-widget {
    h3.footer-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        margin: 0 0 50px;
        @media #{$md-layout} {
            margin: 0 0 30px;
        }
        @media #{$xs-layout} {
            margin: 0 0 25px;
        }
        &.footer-title-white {
            color: $white;
        }
    }
    h3.footer-title-2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        margin: 0 0 50px;
        @media #{$md-layout} {
            margin: 0 0 30px;
        }
        @media #{$xs-layout} {
            margin: 0 0 25px;
        }
    }
    h3.footer-title-3 {
        font-size: 22px;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        margin: 0 0 50px;
        @media #{$lg-layout} {
            font-size: 19px;
        }
        @media #{$md-layout} {
            margin: 0 0 30px;
            font-size: 18px;
        }
        @media #{$xs-layout} {
            margin: 0 0 25px;
            font-size: 20px;
        }
        &.footer-title-3-black {
            color: #2B292D;
        }
    }
    .footer-info-list {
        ul {
            li {
                margin-bottom: 40px;
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
                @media #{$xs-layout} {
                    margin-bottom: 17px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-size: 16px;
                    color: #777;
                    font-weight: 300;
                    display: block;
                    line-height: 1;
                    &:hover {
                        color: $theme-color-dark-blue;
                    }
                }
            }
        }
        &.info-list-mrg-dec {
            ul {
                li {
                    margin-bottom: 26px;
                    @media #{$md-layout} {
                        margin-bottom: 20px;
                    }
                    @media #{$xs-layout} {
                        margin-bottom: 17px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        color: #828495;
                        &:hover {
                            color: $theme-color-dark-blue;
                        }
                    }
                }
            }
        }
        &.footer-info-list-white {
            ul {
                li {
                    a {
                        color: $white;
                        &:hover {
                            color: $theme-color-dark-blue;
                        }
                    }
                }
            }
        }
    }
    .footer-info-list-2 {
        ul {
            li {
                margin-bottom: 26px;
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
                @media #{$xs-layout} {
                    margin-bottom: 17px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: #828495;
                    font-size: 16px;
                    font-weight: 300;
                    display: block;
                    line-height: 1;
                    &:hover {
                        color: $theme-color-dark-blue;
                    }
                }
            }
        }
    }
    .footer-info-list-3 {
        ul {
            li {
                margin-bottom: 35px;
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
                @media #{$xs-layout} {
                    margin-bottom: 17px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: #BEBCFE;
                    font-size: 18px;
                    display: block;
                    line-height: 1;
                    &:hover {
                        color: $theme-color-dark-blue;
                    }
                }
            }
        }
        &.footer-info-list-3-black {
            ul {
                li {
                    a {
                        color: #777;
                        &:hover {
                            color: $theme-color-dark-blue;
                        }
                    }
                }
            }
        }
    }
    .footer-connect {
        p {
            color: #828495;
            font-weight: 300;
            line-height: 28px;
            margin-bottom: 9px;
        }
        a {
            color: #828495;
            font-weight: 300;
            line-height: 28px;
            margin-bottom: 7px;
            display: block;
            &:hover {
                color: $theme-color-dark-blue;
            }
        }
    }
}

.app-google-store {
    margin: 32px 0 0;
    a {
        display: inline-block;
        margin-right: 20px;
        @media #{$lg-layout} {
            margin-right: 2px;
        }
        @media #{$xs-layout} {
            margin-right: 2px;
            margin-bottom: 5px;
        }
        &:last-child {
            margin-right: 0;
        }
        img {
            max-width: 100%;
        }
    }
}

.copyright {
    p {
        color: #AFAFAF;
        a {
            color: #AFAFAF;
        }
    }
    &.copyright-ptb {
        padding: 23px 0 25px;
    }
}

.footer-menu {
    nav {
        ul {
            li {
                display: inline-block;
                margin-right: 17px;
                &:last-child {
                    margin-right: 0px;
                }
                a {
                    color: #2B292D;
                    display: inline-block;
                    &:hover {
                        color: $theme-color-dark-blue;
                    }
                }
            }
        }
    }
    @media #{$md-layout} {
        text-align: center;
    }
    @media #{$xs-layout} {
        text-align: center;
    }
    &.footer-menu-right {
        text-align: right;
        @media #{$xs-layout} {
            text-align: center;
        }
    }
    &.footer-menu-right-blue {
        nav {
            ul {
                li {
                    a {
                        color: #9E9E9E;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
        }
    }
    &.footer-menu-right-white {
        nav {
            ul {
                li {
                    a {
                        color: $white;
                        &:hover {
                            color: $theme-color-dark-blue
                        }
                    }
                }
            }
        }
    }
}

.social-icon {
    display: flex;
    flex-wrap: wrap;
    a {
        display: inline-block;
        margin-left: 30px;
        color: #CFCFCF;
        font-size: 16px;
        &:first-child {
            margin-left: 0px;
        }
        &:hover {
            color: #0A1039;
        }
    }
    &.social-icon-right {
        justify-content: flex-end;
        @media #{$md-layout} {
            justify-content: center;
        }
        @media #{$xs-layout} {
            justify-content: center;
        }
    }
    &.social-icon-center {
        justify-content: center;
        a {
            font-size: 17px;
        }
    }
    &.social-icon-white {
        a {
            color: #fff;
            &:hover {
                color: #0A1039;
            }
        }
    }
    &.fashion-1-social {
        a {
            margin-left: 30px;
            @media #{$xx-layout} {
                margin-left: 20px;
            }
            @media #{$xl-layout} {
                margin-left: 15px;
            }
            @media #{$lg-layout} {
                margin-left: 10px;
                font-size: 14px;
            }
            &:first-child {
                margin-left: 0px;
            }
        }
    }
}

.copyright-ptb-2 {
    padding: 35px 0;
}

.copyright-2 {
    p {
        color: #9E9E9E;
        a {
            color: #9E9E9E;
        }
    }
    @media #{$md-layout} {
        padding: 8px 0 10px;
    }
    @media #{$xs-layout} {
        padding: 10px 0;
        text-align: center;
    }
    &.copyright-2-white {
        p {
            color: $white;
            a {
                color: $white;
            }
        }
    }
    &.copyright-2-right {
        p {
            text-align: right;
            @media #{$xs-layout} {
                text-align: center;
            }
        }
    }
}

.footer-top-style-3 {
    p {
        color: #828495;
        font-weight: 300;
        line-height: 28px;
        margin: 40px auto 37px;
        width: 35%;
        text-align: center;
        &.white {
            color: $white;
        }
        @media #{$lg-layout} {
            width: 45%;
        }
        @media #{$md-layout} {
            width: 60%;
            margin: 20px auto 17px;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 20px auto 17px;
        }
    }
    @media #{$xs-layout} {
        &.pb-75 {
            padding-bottom: 45px;
        }
    }
}

.footer-menu-2 {
    margin-top: 57px;
    @media #{$md-layout} {
        margin-top: 27px;
    }
    @media #{$xs-layout} {
        margin-top: 27px;
    }
    nav {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li {
                display: inline-block;
                margin-right: 40px;
                @media #{$xs-layout} {
                    margin-right: 20px;
                }
                &:last-child {
                    margin-right: 0px;
                }
                a {
                    color: #828495;
                    font-size: 16px;
                    font-weight: 500;
                    display: inline-block;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
            }
        }
    }
    &.footer-menu-2-white {
        nav {
            ul {
                li {
                    a {
                        color: $white;
                        &:hover {
                            color: $theme-color-dark-blue;
                        }
                    }
                }
            }
        }
    }
}

.copyright-ptb-3 {
	padding: 40px 0 43px;
    @media #{$xs-layout} {
        padding: 20px 0 25px;
    }
}

.footer-bg-shape {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    img {
        width: 100%;
    }
}
.footer-about {
    p {
        font-size: 16px;
        line-height: 28px;
        font-weight: 300;
        margin: 30px 0 50px;
        width: 74%;
        color: $white;
        @media #{$lg-layout} {
            width: 100%;
        }
        @media #{$md-layout} {
            width: 100%;
        }
        @media #{$xs-layout} {
            width: 100%;
            margin: 20px 0 20px;
        }
    }
    &.footer-about-black {
        p {
            color: #777777;
            width: 77%;
            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$xs-layout} {
                width: 100%;
            }
        }
    }
}





@media #{$lg-layout} {
    .footer-top {
        &.pb-110 {
            padding-bottom: 55px;
        }
    }
}
@media #{$md-layout} {
    .footer-area {
        &.pt-115 {
            padding-top: 80px;
        }
    }
    .footer-top {
        &.pb-110 {
            padding-bottom: 35px;
        }
    }
}
@media #{$xs-layout} {
    .footer-area {
        &.pt-115 {
            padding-top: 50px;
        }
        &.pt-120 {
            padding-top: 60px;
        }
    }
    .footer-top {
        &.pb-70 {
            padding-bottom: 20px;
        }
        &.pet-footer-top {
            &.pb-50 {
                padding-bottom: 10px;
            }
        }
    }
}

.pet-food-footer-1 {
	position: absolute;
	top: -40px;
	left: 0;
	z-index: 9;
    @media #{$xs-layout} {
        top: -10px;
    }
    img {
        width: 202px;
        @media #{$xl-layout} {
            width: 150px;
        }
        @media #{$lg-layout} {
            width: 150px;
        }
        @media #{$md-layout} {
            width: 150px;
        }
        @media #{$xs-layout} {
            width: 60px;
        }
    }
}

.pet-food-footer-2 {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 9;
    @media #{$xl-layout} {
        top: -20px;
    }
    @media #{$lg-layout} {
        top: -20px;
    }
    @media #{$md-layout} {
        top: -20px;
    }
    @media #{$xs-layout} {
        top: -20px;
    }
    img {
        width: 207px;
        @media #{$xl-layout} {
            width: 150px;
        }
        @media #{$lg-layout} {
            width: 150px;
        }
        @media #{$md-layout} {
            width: 150px;
        }
        @media #{$xs-layout} {
            width: 80px;
        }
    }
}

.kids-2-footer-area {
    background-color: #5F3AF5;
    position: relative;
    &::before {
        background-image: url(../../assets/images/icon-img/kids-2-footer-shape.png);
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0px;
        background-repeat: repeat;
        display: block;
        width: 100%;
        height: 26px;
        z-index: 9;
    }
}














